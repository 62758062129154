import {Component, OnInit, ViewChild, ChangeDetectorRef} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import * as wjcCore from '@grapecity/wijmo';
import * as wjcGrid from '@grapecity/wijmo.grid';
import * as wjcInput from '@grapecity/wijmo.input';
import {Subject} from 'rxjs';
import {Lifting} from '../lifting';
import {OrderService} from '../../core/service/order.service';
import {LiftingService} from '../../core/service/lifting.service';
import {CustomGridEditor} from '../../core/custom-grid-editor';
import * as pdf from '@grapecity/wijmo.pdf';
import {CommonService} from '../../core/common.service';
import {Constant} from 'src/app/core/constant';
import {GeneralSituationCheckerService} from '../../core/general-situation-checker.service';
import {User} from '../../master/user/user';
import { DatePipe } from '@angular/common';

@Component({
    selector: 'app-lifting-detail',
    templateUrl: './lifting-detail.component.html',
    styleUrls: ['./lifting-detail.component.scss']
})

/**
 * 揚重申請編集/登録
 */
export class LiftingDetailComponent implements OnInit {
    apiData: Subject<any> = new Subject();
    liftings: Lifting[] = [];
    // youjuIDに紐づく揚重情報格納用
    liftingData: any = {};
    // 仮登録の資材情報格納用
    liftingTemporary: any = [];

    /* 納入申請なし用項目 */
    relationContacts: any = new wjcCore.CollectionView([]);
    relationMails: any = [];
    consigneeName = '';
    consigneeMobile = '';
    requestSection: string;

    applicantContact: any = new wjcCore.CollectionView([]);
    nounyuModalData: any = [];
    cargoMaterials: any = new wjcCore.CollectionView([]);
    loginUserData: any = {};
    youjuId: string;
    orderId: any;
    model: object = {};
    authority: number;
    information: any;

    orderData: any = {};
    nounyuGridFlag: boolean = false;
    hasOrder: number;
    hasOrderFlag: boolean = false;
    nounyuGridData = new wjcCore.CollectionView([]);

    liftingType = [];
    elevator = [];
    workType = Constant.WORKTYPE;
    statusList = Constant.LIFTING_STATUS_LIST;
    forkLift = Constant.FORKLIFT;
    mixed = Constant.MIXED;
    trolley = Constant.TROLLEY;
    used = Constant.USED;
    truckType = Constant.TRUCKTYPE;
    enable = Constant.ENABLE;

    relationMailList = [];

    isLoading = false;
    siteId: string;
    editDeliveryInfo: boolean;
    applicantContactGridReadOnly = false;
    relationCompanyGridReadOnly = false;

    // 定数取得
    constant = Constant;

    // エラー表示
    public errorMessage: string;
    public errorProperty = 'none';
    public warningMessage: string;
    public warningProperty = 'none';
    public successProperty = 'none';

    situationCheckerResult: any;

    isValidated = true;

    // reference to grid component
    @ViewChild('nounyuGrid', {static: true}) nounyuGrid: wjcGrid.FlexGrid;
    @ViewChild('applicantContactGrid', {static: true}) applicantContactGrid: wjcGrid.FlexGrid;
    @ViewChild('comboliftingType', {static: true}) liftingTypeSelect: wjcInput.ComboBox;
    @ViewChild('comboElevator', {static: true}) elevatorSelect: wjcInput.ComboBox;
    @ViewChild('requestPopup', {static: true}) requestPopup: wjcInput.Popup;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private liftingService: LiftingService,
        private orderService: OrderService,
        public changeDetectorRef: ChangeDetectorRef,
        public commonService: CommonService,
        public situationChecker: GeneralSituationCheckerService,
        private datepipe: DatePipe) {
        this.situationCheckerResult = {};

        // APIからデータを取得した後の挙動を定義(揚重取ってきた後の動きにのみ使われている)
        this.apiData.subscribe(apidata => {
            console.log(apidata);

            this.liftingData = apidata;
            this.consigneeName = apidata.consigneeName;
            this.consigneeMobile = apidata.consigneeMobile;
            this.relationMails = apidata.relationMails;
            // this.relationContacts = apidata.relationContacts;

            // 元受け情報Gridに値を格納 Collectionviewにすべきかも
            const relContacts = [];
            // 元請け情報編集権限があれば表示する
            if (this.situationCheckerResult.editRelationContacts && apidata.relationContacts.length > 0) {
              // for (let i = 0; i < apidata.relationContacts.length; i++) {
              let ri = 0;
              for (let i = 0; i < this.loginUserData.site.relationName.length; i++) {
                  let data = apidata.relationContacts[ri];
                  if (i === data.hierarchy) {
                    // 請負体系の文字列を追加
                    data.relationName = this.loginUserData.site.relationName[i];
                    // グリッドのitemSourceに入れる
                    relContacts[i] = data;
                    if (ri + 1 < apidata.relationContacts.length) {
                      ++ri;
                    }
                  } else {
                    data = {};
                    data.companyName = '';
                    data.personName = '';
                    data.personMobile = '';
                    data.personMail = '';
                    data.relationName = this.loginUserData.site.relationName[i];
                    // グリッドのitemSourceに入れる
                    relContacts[i] = data;
                  }
              }
              this.relationContacts.sourceCollection = relContacts;
            }

            // 申請者情報を作成(申請者情報Gridに値を格納) Collectionviewにすべきかも
            // this.applicantContact = [this.liftingData.applicantContact];
            this.applicantContact = new wjcCore.CollectionView([this.liftingData.applicantContact]);
            this.applicantContactGrid.refresh();

            // 配列を値渡しでコピー(搬入・揚重情報Gridに値を格納) Collectionviewにすべきかも
            const cargoMt = JSON.parse(JSON.stringify(apidata.cargos));
            this.cargoMaterials = new wjcCore.CollectionView(cargoMt);
            this.cargoMaterials.refresh();
            // this.cargoMaterials = apidata.cargos.map(x => Object.assign({}, x));

            // liftingdataが代わっているので再呼び出し
            this.situationCheckerResult = this.situationChecker.getLiftingHeaderStatus(
                this.loginUserData, this.liftingData, apidata.hasOrder);
            this.changeDetectorRef.detectChanges();

            // ステータスチェック(この前にonInitで一回決めてるけど)
            // if (this.situationChecker.getLiftingHeaderStatus(this.loginUserData, this.liftingData, this.orderFlag).hasOrder) {
            // 搬入区分、エレベーター編集許可
            this.editDeliveryInfo = !this.situationCheckerResult.editDeliveryInfo;
            // 申請者情報グリッドの編集可否
            this.applicantContactGridReadOnly = !this.situationCheckerResult.editApplicantContact;
            // 元請け情報の編集可否
            this.relationCompanyGridReadOnly = !this.situationCheckerResult.editRelationContacts;

            this.situationCheckerResult.hasOrder ? this.hasOrder = 1 : this.hasOrder = 0;

            // 仮登録データがあればそれをグリッドに反映
            if (this.liftingTemporary) {
                // cargoMaterialsを更新(搬入・揚重情報Grid)
                this.cargoMaterialsGridDataCreate(this.liftingTemporary);

                if (this.situationCheckerResult.hasOrder) {
                    // orderIdを更新(向こうの画面の仮情報に対しても、こっちで持ってた納入依頼IDを付けてあげる)
                    let id = [];
                    this.liftingTemporary.forEach(item => {
                        item.cargoMaterials.forEach(val => {
                            if (val.orderId) {
                                id = id.concat(val.orderId); // 既存の納入ID配列の中身に納入IDを足す
                            }
                        });
                    });
                    const set = new Set(id);　// 納入IDの重複を捨ててる
                    this.orderId = Array.from(set); // 新しい納入ID配列
                    // 納入依頼グリッド作成(納入idが一個でもあれば納入Gridを作る)
                    if (this.orderId.length > 0) {
                        this.nounyuGridFormat(this.orderId);
                    }
                }
            } else {
                // 仮データがない場合は申請情報を反映
                this.cargoMaterialsGridDataCreate(this.cargoMaterials.sourceCollection);
                if (this.situationCheckerResult.hasOrder) {
                    // OrderID取得
                    if (apidata.orders) {
                        this.orderId = apidata.orders.map(x => x.id); // 納入IDのみの配列を作成
                        // 納入依頼グリッド作成
                        if (this.orderId.length > 0) {
                            this.nounyuGridFormat(this.orderId);
                        }
                    }
                }
            }

            this.changeDetectorRef.detectChanges();
        });
    }

    // ページに入ってきたときに走る
    ngOnInit() {
        /*
         * 各ID取得
         */
        this.siteId = this.commonService.getRoutedSiteFromPath() !== '' ? this.commonService.getRoutedSiteFromPath() : '';
        // URLからIDを取得(新規作成か既存編集か)
        this.youjuId = this.route.snapshot.params.id;
        // URLクエリパラメータからorderフラグを取得(納入依頼ありかなしか)
        this.hasOrderFlag = Boolean(this.route.snapshot.queryParamMap.get('order'));
        // URLクエリパラメータからorderIdを取得(納入から揚重を作りに来たのかどうか)
        this.orderId = Number(this.route.snapshot.queryParamMap.get('orderId'));
        const topPath = this.siteId !== '' ? this.siteId + '/login' : 'login';

        this.commonService.userLoggedIn(this.siteId).subscribe(
            (userData: User) => {
                console.log(userData);
                this.loginUserData = userData;
                this.authority = this.loginUserData.authority;

                this.situationCheckerResult = this.situationChecker.getLiftingHeaderStatus(
                    this.loginUserData, this.liftingData, this.hasOrderFlag);
                this.changeDetectorRef.detectChanges();

                // hasOrderフラグ取得
                // this.hasOrderFlag = this.situationCheckerResult.hasOrder;
                // フラグがあればorderありにする
                this.situationCheckerResult.hasOrder ? this.hasOrder = 1 : this.hasOrder = 0;

                if (this.loginUserData.site) {
                    // 搬入区分とエレベーター情報をログイン現場から取得
                    this.elevator = this.loginUserData.site.elevator;
                    this.liftingType = this.loginUserData.site.liftingType;
                } else {
                    this.router.navigate([topPath]);
                }

                // 元請け情報設定（非野原のみ）
                this.loginUserData.site.relationName.forEach((val, index) => {
                    this.relationContacts.sourceCollection[index] = {
                        relationName: val
                    };
                });
                this.relationContacts.refresh();

                // 基本情報の保存データがあれば反映
                this.information = JSON.parse(sessionStorage.getItem('informationData'));

                // 揚重資材情報からの登録前データ
                // this.liftingTemporary = this.liftingService.getCreateData();
                this.liftingTemporary = JSON.parse(sessionStorage.getItem('cargosData'));
                sessionStorage.removeItem('cargosData'); // 呼んだら消す
                console.log('資材情報からの登録前データ: ', this.liftingTemporary);

                // 搬入区分、エレベーター編集許可
                this.editDeliveryInfo = !this.situationCheckerResult.editDeliveryInfo;

                // 揚重申請idがあればAPIからデータを取得する(揚重申請の新規作成か既存編集か)
                if (this.youjuId) {
                    // 揚重申請情報からorderIdを取得
                    this.isLoading = true;
                    this.liftingService.getLifting(this.youjuId).subscribe(
                        (data: Lifting) => {
                            this.isLoading = false;
                            this.apiData.next(data);
                        },
                        (error) => {
                            this.isLoading = false;
                            this.displayError(this.commonService.getErrorDetail(error));
                        });
                } else {
                    /*
                     * ログイン情報反映
                     */
                     console.log(this.information);
                     if (this.information) {
                        const data = this.information;
                        sessionStorage.removeItem('informationData');
                        this.applicantContact.sourceCollection = data.applicantContact;
                        this.relationContacts.sourceCollection = data.relationContacts;
                        this.consigneeName = data.consigneeName;
                        this.consigneeMobile = data.consigneeMobile;
                        this.relationMails = data.relationMails;
                        this.liftingData.deliveryType = data.deliveryType;
                        this.liftingData.elevator = data.elevator;
                  } else {
                      // 申請者情報を作成
                      this.applicantContact.sourceCollection = [
                          {
                              companyName: this.loginUserData.companyName,
                              personName: this.loginUserData.name,
                              personMobile: this.loginUserData.mobile,
                              personMail: this.loginUserData.email,
                              fax: this.loginUserData.fax,
                              hierarchy: 0,
                              type: 0
                          }
                      ];
                  }
                  this.applicantContactGrid.refresh();

                  // 搬入資材からのデータがある場合(右下新規作成からなんか入力or登録して戻ってきたかどうか)
                  if (this.liftingTemporary) {
                    // 搬入資材グリッド表示用データ作成
                    this.cargoMaterialsGridDataCreate(this.liftingTemporary);
                    let id = [];
                    this.liftingTemporary.forEach((item: { cargoMaterials: any[]; }) => {
                      item.cargoMaterials.forEach(val => {
                          if (val.orderId) {
                              id = id.concat(val.orderId);
                          }
                      });
                    });
                    const set = new Set(id);
                    this.orderId = Array.from(set);
                    // this.liftingData.relationContacts = this.relationContacts;
                    // this.liftingData.cargos = this.cargoMaterials;
                  }

                  // 納入依頼一覧を取得(ベースとなった納入依頼の情報を取得)
                  if (this.situationCheckerResult.hasOrder && this.orderId) {
                    // グリッド作成
                    this.nounyuGridFormat(this.orderId);
                  }

                  // 搬入区分、エレベーター編集許可
                    this.editDeliveryInfo = !this.situationCheckerResult.editDeliveryInfo;
                  // 申請者情報グリッドの編集可否
                    this.applicantContactGridReadOnly = !this.situationCheckerResult.editApplicantContact;
                  // 元請け情報の編集可否
                    if (!this.situationCheckerResult.hasOrder) {
                      this.relationCompanyGridReadOnly = !this.situationCheckerResult.editRelationContacts;
                  }
              }

              // // 編集マークつける
              // this.cargoMaterialsGrid.formatItem.addHandler((s: wjcGrid.FlexGrid, e: wjcGrid.FormatItemEventArgs) => {
              //   if (!s.columns[e.col]) {
              //       return;
              //   }
              //   this.commonService.addGridEditMark(s, e, 'id');
              // });

              // 納入依頼一覧のデータを呼び出し
              // this.callOrderAPI();

              this.changeDetectorRef.detectChanges();
            },
            (error) => {
                // ログイン情報がない場合は、ルートに遷移
                console.log(error);
                this.router.navigate([topPath]);
            }
        );

    }

    // 納入一覧を取得する
    // private callOrderAPI() {
    //     this.isLoading = true;
    //     this.orderService.getAllOrders(1).subscribe(
    //         (res) => {
    //             this.isLoading = false;
    //             const resultObj = [];
    //             res.map(val => {
    //                 val.requestDateTime = new Date(val.requestDateTime);

    //                 const grouped = this.commonService.groupBy(val.materials, 'status');
    //                 // ステータスが0: 未発注のものだけを配列化
    //                 if (grouped[0]) {
    //                     val.sel = false;
    //                     resultObj.push(val);
    //                 }
    //                 this.nounyuModalData = resultObj;
    //             });
    //         },
    //         (error) => {
    //             this.isLoading = false;
    //             this.displayError(this.commonService.getErrorDetail(error));
    //         });
    // }

    /**
     * 搬入・揚重情報Gridに入れるデータの加工
     * @param data 配列
     */
    private cargoMaterialsGridDataCreate(data) {
        data.forEach((val, index) => {
            this.cargoMaterials.sourceCollection[index] = {
                cargoIndex: val.cargoIndex,
                id: val.id,
                confirmDate: val.confirmDateTime == null ? null : this.dateTimeCheck(val.confirmDateTime, 0),
                confirmDateTime: val.confirmDateTime == null ? null : this.dateTimeCheck(val.confirmDateTime, 1),
                confirmLift: val.confirmLift,
                confirmLiftingNumber: val.confirmLiftingNumber,
                status: this.toStatusString(val.status, val.wasClosed),
                requestStartDate: val.requestStartDateTime == null ? null : this.dateTimeCheck(val.requestStartDateTime, 0),
                requestStartDateTime: val.requestStartDateTime == null ? null : this.dateTimeCheck(val.requestStartDateTime, 1),
                requestEndDateTime: val.requestEndDateTime == null ? null : this.dateTimeCheck(val.requestEndDateTime, 1),
                useLiftStartTime: val.useLiftStartTime,
                useLiftEndTime: val.useLiftEndTime,
                materialName: val.materialName,
                requestGate: val.requestGate,
                truckSize: val.truckSize,
                workType: this.workType[val.workType],
                loadingFloor: val.loadingFloor,
                unloadingFloor: val.unloadingFloor,
                unloadFloor: val.unloadFloor,
                liftingCount: val.liftingCount,
                truckCount: val.truckCount,
                remark: val.remark,
                mixed: val.mixed,
                packingMaterial: val.packingMaterial,
                trolley: val.trolley,
                palette: val.palette,
                batakaku: val.batakaku,
                truckType: val.truckType,
                unic: val.unic,
                forkLift: val.forkLift,
                useForkLift: val.useForkLift,
                otherWeight: val.otherWeight,
                cargoWidth: val.cargoWidth,
                cargoDepth: val.cargoDepth,
                cargoHeight: val.cargoHeight,
                necessaryTrolley: val.necessaryTrolley,
                materialImageUrl: val.materialImageUrl,
                cargoMaterials: val.cargoMaterials,
            };
        });
        this.cargoMaterials.refresh();
    }

    // 申請者、元受けGridの初期化
    initGrid(flexgrid: wjcGrid.FlexGrid) {
        // カラムに応じてクイック編集をかける
        flexgrid.selectionChanged.addHandler((s, e) => {
            // if (s.columns[e['col']].binding === 'note') {
            setTimeout(() => {
                flexgrid.startEditing(false);
            }, 50);
            // }
        });

        // flexgrid.cellEditEnded.addHandler((s: wjcGrid.FlexGrid, e: wjcGrid.FormatItemEventArgs) => {
        //   const r = s.rows[e.row];
        //   const c = s.columns[e.col];
        //   if (r && c && c.binding === 'personMail') {
        //     const item = r.dataItem.personMail;
        //     if (this.commonService.validateEmailAddress(item)) {
        //       this.warningProperty = 'none';
        //       this.addNewRelationMail(item);
        //       this.isValidated = true;
        //     } else {
        //       this.isValidated = false;
        //       e.cancel = true;
        //       this.displayWarning('メールアドレスを正しく入力してください。');
        //     }
        //   } else if (r && c && c.binding === 'fax') {
        //     const item = r.dataItem.fax;
        //     this.checkPhone(item);
        //   } else if (r && c && c.binding === 'personMobile') {
        //     const item = r.dataItem.personMobile;
        //     this.checkPhone(item);
        //   }
        // });
    }

    // モーダルで出る納入依頼選択のGridを初期化する(このGrid今使ってない。。。データ取得もコメント化されてるし)
    initializeModalGrid(flex: wjcGrid.FlexGrid) {
        flex.formatItem.addHandler((s: wjcGrid.FlexGrid, e: wjcGrid.FormatItemEventArgs) => {
            if (e.panel == s.cells && s.rows[e.row].dataItem !== undefined) {
                wjcCore.setAttribute(
                    e.cell.parentElement,
                    'aria-selected',
                    s.rows[e.row].dataItem.sel
                );
            }
        });
    }

    // 初期化：関係メールアドレス用Gridの初期化
    initializeRelationMailsGrid(flex: wjcGrid.FlexGrid) {
        // 初期状態でセルが選択されないようにする
        flex.select(-1, -1);
        // 関係メールアドレスの入力補助：LocalStorage
        this.relationMailList = JSON.parse(localStorage.getItem('relationMailList'));
        // ソート
        // console.log(this.relationMailList);
        if (this.relationMailList != null && this.relationMailList.length !== 0) {
            this.relationMailList.sort(function (val1, val2) {
                return (val1.added < val2.added ? 1 : -1);
            });
        }

        new CustomGridEditor(flex, 'mail', wjcInput.AutoComplete, {
            displayMemberPath: 'label',
            itemsSource: this.relationMailList
        }, 200);

        flex.cellEditEnded.addHandler((s: wjcGrid.FlexGrid, e: wjcGrid.FormatItemEventArgs) => {
          const r = s.rows[e.row];
          const c = s.columns[e.col];
          if (r && c && c.binding === 'mail') {
            const item = r.dataItem.mail;
            if (this.commonService.validateEmailAddress(item)) {
              this.warningProperty = 'none';
              this.addNewRelationMail(item);
              this.isValidated = true;
            } else {
              this.isValidated = false;
              e.cancel = true;
              this.displayWarning('メールアドレスを正しく入力してください。');
            }
          }
        });
      }

    // オートコンプリートのリスト追加：関連メールアドレスGrid
    addNewRelationMail(item) {
      if (!item) {
        return;
      }
      const res = this.commonService.getRowAutoComplite(this.relationMailList, item);
      // console.log(res);
      if (res.data) {
        // リストに追加
        if (this.relationMailList != null) {
          this.relationMailList.push(res.data);
        } else {
          this.relationMailList = [res.data];
        }
      } else {
        // ソート順更新用
        this.relationMailList[res.update.index].added = res.update.added;
      }
      // 上限を超えたらaddedが低いものを削除
      if (this.relationMailList.length > this.commonService.limit) {
        // console.log(this.relationMailList[res.min.index]);
        this.relationMailList.splice(res.min.index, 1);
      }
      // ソート
      this.relationMailList.sort(function (val1, val2) {
        return (val1.added < val2.added ? 1 : -1);
      });
      localStorage.setItem('relationMailList', JSON.stringify(this.relationMailList));
    }

    // 搬入・揚重情報Gridの初期化
    cargoMaterialsInit(flex: wjcGrid.FlexGrid) {
        /*
        * カラムヘッダーにリンクを設定(クリック時に編集画面に行く)
        */
        flex.addEventListener(flex.hostElement, 'mouseup', (e: MouseEvent) => {
            if (flex.rows.length === 0) {
                return;
            }

            const ht = flex.hitTest(e);
            if (ht.row < 0) {
                return;
            }
            const id = flex.rows[ht.row].dataItem.id;
            const cargoIndex = flex.rows[ht.row].dataItem.cargoIndex;
            const status = flex.rows[ht.row].dataItem.status;

            // 行選択したときのみリンクする
            if (ht.panel == flex.rowHeaders) {
                // console.log(this.liftingTemporary, this.youjuId);

                this.informationDataSave();
                if (this.liftingTemporary) {
                    // leftingIndexを渡して、Storageからデータを参照する
                    sessionStorage.setItem('cargosData', JSON.stringify(this.liftingTemporary));
                }

                if (this.youjuId !== undefined) {
                    const _status = String(this.toStatunNumber(status));
                    sessionStorage.setItem('cargoStatus', _status);
                    this.router.navigate([`${this.loginUserData.site.id}/lifting/${this.youjuId}/${id}`],
                        {queryParams: {cargoIndex, order: this.situationCheckerResult.hasOrder}});
                } else {
                    this.router.navigate([`${this.loginUserData.site.id}/lifting/new/create`],
                        {queryParams: {cargoIndex, order: this.situationCheckerResult.hasOrder}});
                }
            }
        });

        // 編集マークつける
        flex.formatItem.addHandler((s: wjcGrid.FlexGrid, e: wjcGrid.FormatItemEventArgs) => {
          if (!s.columns[e.col]) {
                return;
          }
          this.commonService.addGridEditMark(s, e, 'id');
        });
    }

    /**
     * 納入依頼グリッド作成
     * @param id 納入IDの配列
     */
    private nounyuGridFormat(id) {

        if (this.situationCheckerResult.canOrderAccess && this.situationCheckerResult.hasOrder) {
            // hasOrder=TRUEのときは、引数の納入依頼IDごとにAPIから納入依頼情報をひいてくる
            // 詳細1件取るAPIを複数回呼んでいる
            if (typeof id === 'number') {
                this.isLoading = true;
                this.orderService.getOrder(id).subscribe(res => {
                    this.isLoading = false;
                    this.orderData = res;
                    res.requestDateTime = new Date(res.requestDateTime);
                    this.nounyuGridData.sourceCollection.push(res);
                    this.nounyuGridData.refresh();
                    // グリッド未表示ならグリッド作成する
                    this.createNounyuGridObject();
                }, error => {
                    this.isLoading = false;
                    this.displayError(this.commonService.getErrorDetail(error));
                });
            } else {
                for (let i = 0; i < id.length; i++) {
                    this.orderService.getOrder(id[i]).subscribe(res => {
                        this.isLoading = false;
                        this.orderData = res;
                        res.requestDateTime = new Date(res.requestDateTime);
                        this.nounyuGridData.sourceCollection.push(res);
                        this.nounyuGridData.refresh();
                        if (i === id.length - 1) { // 最期だけ
                            // グリッド未表示ならグリッド作成する
                            this.createNounyuGridObject();
                        }
                    }, error => {
                        this.isLoading = false;
                        this.displayError(this.commonService.getErrorDetail(error));
                    });
                }
            }
        } else {
            for (let i = 0; i < this.liftingData.orders.length; i++) {
                let data: any = [];
                data = this.liftingData.orders[i];
                data.requestDateTime = new Date(data.requestDateTime);
                data.personName = data.name;
                data.relationCompanies = [];
                this.liftingData.relationContacts.map((x, i) => {
                    data.relationCompanies[x.hierarchy] = {
                        companyName: x.companyName
                    };
                });
                this.nounyuGridData.sourceCollection.push(data); // ここ配列に配列をPushしてるけどそんなことできたか？
                this.nounyuGridData.refresh();
            }
            // グリッド未表示ならグリッド作成する
            this.createNounyuGridObject();
        }
    }

    createNounyuGridObject() {
        // hasOrder=TRUEでも、納入依頼の情報がないうちは表示しない
        if (!this.nounyuGridFlag && this.situationCheckerResult.canOrderAccess && this.situationCheckerResult.hasOrder) {
            // グリッド作成
            const gridFormat = new wjcGrid.FlexGrid('#nounyuGrid', {
                autoGenerateColumns: false,
                isReadOnly: true,
                headersVisibility: 'Column',
                columns: this.orderGridCreate(),
                itemsSource: this.nounyuGridData
            });
            this.nounyuGridFlag = true;
            this.flexInitialized(gridFormat);
        }
    }

    // 納入依頼グリッドの初期化関数
    flexInitialized(flexgrid: wjcGrid.FlexGrid) {
        new CustomGridEditor(flexgrid, 'orderDate', wjcInput.InputDate, {
            format: 'd'
        }, null);
        new CustomGridEditor(flexgrid, 'requestDate', wjcInput.InputDate, {
            format: 'd'
        }, null);
        new CustomGridEditor(flexgrid, 'requestDateTime', wjcInput.InputTime, {
            format: 't',
            step: 15
        }, null);
        flexgrid.formatItem.addHandler((s: wjcGrid.FlexGrid, e: wjcGrid.FormatItemEventArgs) => {
            // 納入依頼Noをテキストリンクにする
            if (e.panel !== s.columnHeaders && s.columns[e.col].binding === 'number') {
                const data = s.rows[e.row].dataItem;
                e.cell.innerHTML = '<a href="/' + this.loginUserData.site.id +
                    '/order/' + data.id + '" target="_blank">' + data.number + '</a>';
            }
        });
    }

    // 納入依頼グリッドカラム作成
    private orderGridCreate() {
        const gridColumns = [
            {header: '納入依頼ID', binding: 'id', align: 'center', width: 150, visible: false},
            {header: '納入依頼No', binding: 'number', align: 'center', width: 150},
            {header: '発注者', binding: 'personName', align: 'center', width: 150},
            {header: '発注日', binding: 'orderDate', align: 'center', width: 150},
            {header: '希望納入日', binding: 'requestDateTime', align: 'center', width: 150, format: 'd'},
            {header: '希望納入時間', binding: 'requestDateTime', align: 'center', width: 150, format: 't'},
            {header: '希望納入区画', binding: 'requestSection', align: 'center', width: 150},
            {header: '荷受人名', binding: 'consigneeName', align: 'center', width: 150},
            {header: '荷受人連絡先', binding: 'consigneeMobile', align: 'center', width: 150}
        ];
        // 請負体系に合わせてグリッドを作成する
        this.loginUserData.site.relationName.forEach((value, index) => {
            const inputData = {
                header: value,
                binding: 'relationCompanies[' + index + '].companyName',
                align: 'center',
                width: 150
            };
            gridColumns.splice(2 + index, 0, inputData);
        });
        return gridColumns;
    }

    // モーダルから納入依頼を選択
    private nounyuDataAdd(grid: wjcGrid.FlexGrid) {
        const gridData = grid.itemsSource;
        const selectedID = [];
        for (let i = 0; i < gridData.length; i++) {
            if (gridData[i].sel) {
                // this.orderId = gridData[i].id;
                selectedID.push(gridData[i].id);
            }
        }
        if (selectedID.length > 0) {
            this.nounyuGridFormat(selectedID);
            this.orderId = selectedID;
        }
    }

    private displayError(message: string) {
        this.errorMessage = message;
        this.errorProperty = '';
        this.changeDetectorRef.detectChanges();
    }

    private displayWarning(message: string) {
        this.warningMessage = message;
        this.warningProperty = '';
        this.changeDetectorRef.detectChanges();
    }

    private clearError() {
        this.errorProperty = 'none';
        this.warningProperty = 'none';
        this.changeDetectorRef.detectChanges();
    }

    private displaySuccess(message: string) {
        this.errorMessage = message;
        this.successProperty = '';
        this.changeDetectorRef.detectChanges();
    }

    private toStatunNumber(status: string) {
        if (status === '') {
            return -1;
        } else if (status === '申請中（締切済み）') {
            return 1;
        } else {
            return this.statusList.indexOf(status);
        }
    }

    private toStatusString(status: number, deadFlag: boolean) {
        /*
         * 搬入希望日が揚重申請締切日を超えているかどうかの判定
         */
        // // let commonService = new CommonService();
        // // 今日の日付を取得
        // let today = this.commonService.getDateTime();
        // let day = today.getDate();
        // // 締切日の日付を換算
        // let options = {
        //   dueDateType: user.site.liftingRequestDueDateType,
        //   dueDate: user.site.liftingRequestDueDate,
        //   dueWeek: user.site.liftingRequestDueWeek,
        //   dueDayOfWeek: user.site.liftingRequestDueDayOfWeek,
        // };
        // // n日前
        // if (options.dueDateType == 0) {
        //   day += options.dueDate;
        // } else {
        //   let dayofweek = today.getDay();
        //   // 今日の曜日と期限の曜日を比較
        //   if (options.dueDayOfWeek < dayofweek) {
        //     // もし期限の曜日を超えていたら、締め切りは+1週間後の日曜日
        //     day += 7 * (options.dueWeek + 1) - dayofweek;
        //   } else {
        //     // そうでなければ、単純にn週間後の日曜日
        //     day += (7 * options.dueWeek) - dayofweek;
        //   }
        // }
        // let deadline = this.commonService.getDateTime();
        // deadline.setDate(day);
        // // 希望日との前後比較をする
        // let requestDate = new Date(days);
        /*****/

        if (status === -1) {
            return '';
        } else if (status === 1 && deadFlag) {
            // 申請中かつ揚重申請締切日が搬入希望日を超えている場合は
            // 申請中（締め切り済み）と表示する
            return '申請中（締切済み）';
        } else {
            return this.statusList[status];
        }
    }

    /*
     * ボタン出し分け
     */
    get editable() {
        // 野原顧客は許可しない
        // if (this.authority === this.constant.AUTHORITY_NOHARA_CUSTOMER) {
        //   return false;
        // } else {
        //   // 新規の場合誰でも許可
        //   if (this.youjuId === undefined) {
        //     return true;
        //   } else {
        //     // 既存の場合は、
        //     // 全てのステータスが完了済みでない場合
        //     // 管理者権限の場合
        //     // 揚重センターもしくは非野原かつ自分で作成したものの場合
        //     // 現場担当者の場合
        //     // 手配担当者で確定済みステータスの場合に許可
        //     if (!this.allStatus3 && this.authority === this.constant.AUTHORITY_ADMIN ||
        //         ((this.authority === this.constant.AUTHORITY_LIFTING ||
        //           this.authority === this.constant.AUTHORITY_NOT_NOHARA_CUSTOMER) && this.createUserCheck()) ||
        //           this.authority === this.constant.AUTHORITY_SITE_STAFF ||
        //           (this.authority === this.constant.AUTHORITY_ARRANGE_STAFF && this.hasStatus1)
        //         ) {
        //       return true;
        //     } else {
        //       return false;
        //     }
        //   }
        // }
        // return this.situationChecker.getLiftingHeaderStatus(this.loginUserData, this.liftingData, this.orderFlag).editable;
        return this.situationCheckerResult.editable;
    }

    // 申請ボタン表示
    get showApplication() {
        // return this.situationChecker.getLiftingHeaderStatus(this.loginUserData, this.liftingData, this.orderFlag).Application;
        return this.situationCheckerResult.Application;
    }

    // 保存ボタン
    get showSave() {
        // return this.situationChecker.getLiftingHeaderStatus(this.loginUserData, this.liftingData, this.orderFlag).Save;
        return this.situationCheckerResult.Save;
    }

    // 帳票ボタン
    get showReport() {
        // return this.situationChecker.getLiftingHeaderStatus(this.loginUserData, this.liftingData, this.orderFlag).Report;
        return this.situationCheckerResult.Report;
    }

    // 保存せず戻る
    get showBackNoSave() {
        // return this.situationChecker.getLiftingHeaderStatus(this.loginUserData, this.liftingData, this.orderFlag).BackNoSave;
        return this.situationCheckerResult.BackNoSave;
    }

    // 戻る
    get showBack() {
        return this.situationCheckerResult.Back;
    }

    // 搬入・揚重情報の新規作成
    get showCreateCargo() {
        // return this.situationChecker.getLiftingHeaderStatus(this.loginUserData, this.liftingData, this.orderFlag).CreateCargo;
        return this.situationCheckerResult.CreateCargo;
    }

    // オートコンプリート初期化:搬入区分とエレベーターで文字数制限を付ける
    initializeWordCount(input, num) {
        input.inputElement.maxLength = num;
    }

    // 申請ボタン押されたとき
    private applicationRequest() {
        // confirm row deletion
        this.requestPopup.show(true, (sender: wjcInput.Popup) => {
            // delete the row
            if (sender.dialogResult === 'wj-hide-ok') {
                this.clearError();

                const createData = this.submitDataCreate();
                console.log(createData);

                if (createData.cargos == null || createData.cargos.length === 0) {
                    this.displayError('資材情報がありません。');
                    return;
                }

                // 申請フラグを立てる
                createData.isRequest = true;

                this.clearError();
                this.isLoading = true;
                this.liftingService.createLifting(createData).subscribe(
                    (data: Lifting) => {
                        this.isLoading = false;
                        this.router.navigate([`${this.loginUserData.site.id}/lifting`]);
                    },
                    (error) => {
                        this.isLoading = false;
                        this.displayError(this.commonService.getErrorDetail(error));
                    }
                );
            }
        });
    }

    // 揚重申請保存 保存を押されたとき
    private saveLifting() {
        const createData = this.submitDataCreate();
        console.log(createData);

        if (createData.cargos == null || createData.cargos.length === 0) {
            this.displayError('資材情報がありません。');
            return;
        }

        if (createData.relationContacts) {
            for (let i = 0; i < createData.relationContacts.length; i++) {
                const relation = createData.relationContacts[i];
                if ((relation.personName || relation.personMobile || relation.personMail) && !relation.companyName) {
                    this.displayWarning('元請け情報の会社名を入力してください。');
                    return;
                }
            }
        }

        // 新規作成で申請を許可しない
        createData.isRequest = false;

        this.clearError();
        this.isLoading = true;
        this.liftingService.createLifting(createData).subscribe(
            (data: Lifting) => {
                this.isLoading = false;
                this.router.navigate([`${this.loginUserData.site.id}/lifting`]);
            },
            (error) => {
                this.isLoading = false;
                this.displayError(this.commonService.getErrorDetail(error));
            }
        );
    }

    // 搬入資材登録へ進む 右下の新規作成を押されたとき
    cargosCreate() {
        // 基本情報を保存
        this.informationDataSave();

        // liftingTemporaryがあればStorageに保存
        if (this.liftingTemporary) {
            sessionStorage.setItem('cargosData', JSON.stringify(this.liftingTemporary));
        }

        // order情報をStorageに保存
        const queryParamID = this.route.snapshot.queryParamMap.get('orderId');
        if (queryParamID) {
            let passingData = {
                num: this.nounyuGridData.sourceCollection[0].number,
                orderRequestDate: this.nounyuGridData.sourceCollection[0].requestDateTime
            };
            sessionStorage.setItem('passing', JSON.stringify(passingData));
        }

        // cargoのステータスをStorageに保存
        // 未申請以外のステータスがあるかどうか
        // const allBeforeRequest = this.situationChecker.getLiftingHeaderStatus(
        //     this.loginUserData, this.liftingData, this.orderFlag).allBeforeRequest;
        // if (allBeforeRequest) {
        if (this.situationCheckerResult.allBeforeRequest) {
            sessionStorage.setItem('isUnapplied', '0');
        } else {
            sessionStorage.setItem('isUnapplied', '1');
        }

        if (this.youjuId !== undefined) {
            this.router.navigate([`${this.loginUserData.site.id}/lifting/${this.youjuId}/create`], {queryParams: {order: this.situationCheckerResult.hasOrder}});
        } else {
            this.router.navigate([`${this.loginUserData.site.id}/lifting/new/create`], {queryParams: {order: this.situationCheckerResult.hasOrder}});
        }

    }

    // 基本情報を保存
    informationDataSave() {
        const informationData = {
            applicantContact: this.applicantContact.sourceCollection,
            relationContacts: this.relationContacts.sourceCollection,
            consigneeName: this.consigneeName,
            consigneeMobile: this.consigneeMobile,
            relationMails: this.relationMails,
            deliveryType: this.liftingTypeSelect.selectedIndex,
            elevator: this.elevatorSelect.selectedIndex
        };
        sessionStorage.setItem('informationData', JSON.stringify(informationData));
    }

    // データ整形関数(APIに送る用のデータに補正する)
    submitDataCreate() {
        let data: any = {};
        const requestSectionSum: any = [];

        this.liftingData.id ? data = this.liftingData : data = {};

        // console.log(this.youjuId, this.liftingData);

        // this.liftingData.cargos = this.dateTimeCheck(data.cargos);
        /*
         * 全データを取得
         */
        data.siteId = this.siteId;
        data.deliveryType = this.liftingTypeSelect.selectedIndex;
        data.elevator = this.elevatorSelect.selectedIndex;
        data.hasOrder = this.hasOrder;

        // 申請者情報
        data.applicantContact = this.applicantContact.sourceCollection[0];
        // 固定データ
        data.applicantContact.hierarchy = 0;
        data.applicantContact.type = 0;

        data.orders = [];
        if (this.orderId) {
            this.orderId.forEach(_id => {
                data.orders.push({id: _id});
            });
        }

        // 仮登録データがあればcargosを更新
        if (this.liftingTemporary && this.liftingTemporary.length > 0) {
            data.cargos = this.liftingTemporary;
        }

        /*
         * 納入依頼がない場合は以下情報を追加
         */
        if (!this.situationCheckerResult.hasOrder) {
            data.relationContacts = [];
            this.relationContacts.sourceCollection.forEach((val, index) => {
                // 全部未入力ならデータに入れない
                if (!val.companyName && !val.personName && !val.personMobile && !val.personMail) {
                    return;
                }
                data.relationContacts.push({
                    companyName: val.companyName,
                    personName: val.personName,
                    personMobile: val.personMobile,
                    personMail: val.personMail,
                    hierarchy: index,
                    type: 1
                });
            });
            // メールアドレスの空行を削除
            const address = this.relationMails.filter((s) => {
                return (s.mail !== null && s.mail !== undefined && s.mail !== '');
            });
            data.relationMails = address;
            data.consigneeName = this.consigneeName;
            data.consigneeMobile = this.consigneeMobile;
        }

        return data;
    }

    // 時間文字列の桁数を揃える
    dateTimeCheck(date: string, type: number) {
        let day: any = new Date(date);
        // console.log(day);
        // もし不正な値になったら、日時を分割して処理
        if (day == 'Invalid Date') {
            const _date = date.slice(0, -6);
            const _time = date.slice(-5);
            if (type === 0) {
                day = new Date(_date);
                const result = day.getFullYear()
                    + '/' + ('0' + (day.getMonth() + 1)).slice(-2)
                    + '/' + ('0' + day.getDate()).slice(-2);
                return result;
            } else {
                return _time;
            }
        } else {
            // そうでなければ、そのまま日時変換の関数にかける
            return this.dateTimeConversion(day, type);
        }
    }

    /*
    * 確定日時を現場作業時間に合わせて変換する
    * @param {String} date 確定日時
    * @return {String} day
    */
    private dateTimeConversion(date: string, type: number) {
        const day = new Date(date);
        // console.log(day);

        const startTime = this.loginUserData.site.startLiftingHour;
        const split = startTime.split(':');
        const hour = Number(split[0]);
        const minuit = Number(split[1]);

        let result;
        // 日付の変換
        if (type === 0) {
            if (day.getHours() < hour) {
                // 日付を一日前にして時間を24hプラスする
                day.setDate(day.getDate() - 1);
            }
            result = day.getFullYear()
                + '/' + ('0' + (day.getMonth() + 1)).slice(-2)
                + '/' + ('0' + day.getDate()).slice(-2);
        } else {
            if (day.getHours() < hour) {
                result = day.getHours() + 24 + ':' + ('0' + day.getMinutes()).slice(-2);
            } else {
                // 24時間以内の指定であれば、そのまま表示
                result = day.getHours() + ':' + ('0' + day.getMinutes()).slice(-2);
            }
        }

        // 日付を返す
        return result;
    }

    /**
     * 以下、帳票出力
     */
    export() {
        // 揚重データ取得
        let data: any = {};
        this.liftingData.id ? data = this.liftingData : data = {};
        // 現場データ取得
        const site = this.loginUserData.site;
        // cargoデータ取得
        const cargos = this.cargoMaterials.sourceCollection;

        // console.log(site, data, cargos);
        let doc = new pdf.PdfDocument({
            pageSettings: {
                layout: pdf.PdfPageOrientation.Landscape,
                size: pdf.PdfPageSize.A4,
                margins: {
                    left: 32,
                    top: 20,
                    right: 32,
                    bottom: 20
                }
            },
            // tslint:disable-next-line:max-line-length
            ended: (sender: pdf.PdfDocument, args: pdf.PdfDocumentEndedEventArgs) => pdf.saveBlob(args.blob, data.liftingNumber + '.pdf')
        });

        doc.registerFont({source: 'https://demo.grapecity.com/wijmo/sample/fonts/ipaexg.ttf', name: 'ipaexg'});
        doc.setFont(new pdf.PdfFont('ipaexg', 9, 'normal', 'normal'));

        const topRectHeight = 45;
        doc.drawText('搬入・揚重計画申込書（新規/変更/取消）', null, 20, {
            align: pdf.PdfTextHorizontalAlign.Center,
            font: new pdf.PdfFont('ipaexg', 14, 'oblique', 'bold')
        });
        doc.moveDown();
        doc.drawText('工事名称：' + site.name, null, null, {
            align: pdf.PdfTextHorizontalAlign.Center,
            font: new pdf.PdfFont('ipaexg', 12, 'oblique', 'bold')
        });

        doc.paths.rect(0, 0, 80, topRectHeight).stroke();
        doc.drawText('提出日', 0, 5, {width: 80, height: 20, align: pdf.PdfTextHorizontalAlign.Center});
        // doc.drawText('搬入・揚重希望日', 80, 5, {width: 100, height: 20, align: pdf.PdfTextHorizontalAlign.Center});
        doc.paths.moveTo(0, 20).lineTo(80, 20).stroke();
        doc.drawText(new Date().toLocaleDateString(), 0, 28, {
            width: 80,
            height: 20,
            align: pdf.PdfTextHorizontalAlign.Center
        });
        doc.paths.moveTo(80, 0).lineTo(80, topRectHeight).stroke();

        const rboxLeft = doc.width - 60;
        doc.paths.rect(rboxLeft, 0, 60, topRectHeight).stroke();
        doc.paths.moveTo(rboxLeft, 20).lineTo(doc.width, 20).stroke();

        doc.drawText('搬入区分', rboxLeft, 5, {width: 60, height: 20, align: pdf.PdfTextHorizontalAlign.Center});
        doc.drawText(this.liftingTypeSelect.selectedValue, rboxLeft, 28, {
            width: 60,
            height: 25,
            align: pdf.PdfTextHorizontalAlign.Center
        });

        const elevatorLeft = doc.width - 100;
        const elevatorTop = topRectHeight + 10;
        doc.paths.rect(elevatorLeft, elevatorTop, 100, topRectHeight).stroke();
        doc.drawText('本設エレベーター', elevatorLeft, elevatorTop + 5, {
            width: 100,
            height: 20,
            align: pdf.PdfTextHorizontalAlign.Center
        });
        if (this.situationCheckerResult.hasOrder) {
            doc.paths.moveTo(elevatorLeft, elevatorTop + 22).lineTo(doc.width, elevatorTop + 22).stroke();
        } else {
            doc.paths.moveTo(elevatorLeft, elevatorTop + 20).lineTo(doc.width, elevatorTop + 20).stroke();
        }
        doc.drawText(this.elevatorSelect.selectedValue, elevatorLeft, elevatorTop + 27, {
            width: 100,
            height: 50,
            align: pdf.PdfTextHorizontalAlign.Center
        });

        let applicationTopPos = 0;
        /*
         * 納入依頼ありの場合
         */
        if (this.situationCheckerResult.hasOrder) {
            doc.paths.rect(elevatorLeft/2, elevatorTop, elevatorLeft/2, topRectHeight).stroke();
            doc.paths.moveTo(elevatorLeft/2, elevatorTop + 22).lineTo(elevatorLeft, elevatorTop + 22).stroke();
            doc.paths.moveTo((elevatorLeft/2) + 135, elevatorTop).lineTo((elevatorLeft/2) + 135, elevatorTop + 44).stroke();

            const relationContacts = this.nounyuGridData.sourceCollection[0];
            doc.drawText('荷受人', elevatorLeft/2, elevatorTop + 5, {
                  width: 135,
                  height: 20,
                  align: pdf.PdfTextHorizontalAlign.Center
            });
            if (relationContacts) {
                doc.drawText(relationContacts.consigneeName, (elevatorLeft / 2) + 135, elevatorTop + 5, {
                  width: (elevatorLeft / 2) - 135,
                  height: 20,
                  align: pdf.PdfTextHorizontalAlign.Center
                });
            }

            doc.drawText('荷受人連絡先', elevatorLeft/2, elevatorTop + 27, {
                  width: 135,
                  height: 20,
                  align: pdf.PdfTextHorizontalAlign.Center
             });
            if (relationContacts) {
              doc.drawText(relationContacts.consigneeMobile, (elevatorLeft / 2) + 135, elevatorTop + 27, {
                width: (elevatorLeft / 2) - 135,
                height: 20,
                align: pdf.PdfTextHorizontalAlign.Center
              });
            }

            applicationTopPos += 50;

            const rh = topRectHeight / 2;
            for (let i = 1; i <= site.relationName.length; i++) {
              doc.paths.rect(0, elevatorTop, elevatorLeft / 2, rh * i).stroke();
              doc.paths.moveTo(135, elevatorTop + (rh * (i - 1))).lineTo(135, elevatorTop + (rh * i)).stroke();
              const relationName = site.relationName[i - 1];

              // 請負体系
              doc.drawText(relationName, 0, elevatorTop + 5 + (rh * (i - 1)), {
                width: 135,
                height: rh,
                align: pdf.PdfTextHorizontalAlign.Center
              });
              applicationTopPos += rh;

              // 元請け会社名
              if (relationContacts) {
                if (relationContacts.relationCompanies[i - 1]) {
                    const companyName = relationContacts.relationCompanies[i - 1].companyName;
                    doc.drawText(companyName, 135, elevatorTop + 5 + (rh * (i - 1)), {
                      width: (elevatorLeft / 2) - 135,
                      height: rh,
                      align: pdf.PdfTextHorizontalAlign.Center
                    });
                  }
                }
            }

            // 請負体系が一つだけの場合
            if (site.relationName.length === 1) {
              applicationTopPos += rh;
            }

            // 申請者情報が入るスペースがない場合は改ページ
            if (applicationTopPos + 90 > doc.height) {
              doc.addPage(doc.currentPageSettings);
              applicationTopPos = 0;
            }

            const applicantContact = this.applicantContact.sourceCollection[0];
            applicationTopPos += 20;

            doc.paths.rect(0, applicationTopPos, elevatorLeft, 75).stroke();

            doc.paths.moveTo(0, applicationTopPos + 22).lineTo(elevatorLeft, applicationTopPos + 22).stroke();
            doc.paths.moveTo(0, applicationTopPos + 44).lineTo(elevatorLeft, applicationTopPos + 44).stroke();

            doc.paths.moveTo(elevatorLeft/4, applicationTopPos + 22).lineTo(elevatorLeft/4, applicationTopPos + 75).stroke();
            doc.paths.moveTo((elevatorLeft/4)*2, applicationTopPos + 22).lineTo((elevatorLeft/4)*2, applicationTopPos + 75).stroke();
            doc.paths.moveTo((elevatorLeft/4)*3, applicationTopPos + 22).lineTo((elevatorLeft/4)*3, applicationTopPos + 75).stroke();
            // applicationTopPos += 22;

            // 申請者情報ラベル
            doc.drawText('申請者情報', 0, applicationTopPos + 5, {
                width: elevatorLeft,
                height: 27,
                align: pdf.PdfTextHorizontalAlign.Center
            });
            applicationTopPos += 27;

            doc.drawText('所属会社名', 0, applicationTopPos, {
                width: elevatorLeft/4,
                height: 27,
                align: pdf.PdfTextHorizontalAlign.Center
            });
            doc.drawText('責任者名', elevatorLeft/4, applicationTopPos, {
                width: elevatorLeft/4,
                height: 27,
                align: pdf.PdfTextHorizontalAlign.Center
            });
            doc.drawText('責任者ＴＥＬ（携帯）', (elevatorLeft/4)*2, applicationTopPos, {
                width: elevatorLeft / 4,
                height: 27,
                align: pdf.PdfTextHorizontalAlign.Center
            });
            doc.drawText('責任者メールアドレス', (elevatorLeft/4)*3, applicationTopPos, {
                width: elevatorLeft / 4,
                height: 27,
                align: pdf.PdfTextHorizontalAlign.Center
            });
            applicationTopPos += 27;

            // 申請者情報内容
            doc.drawText(applicantContact.companyName, 0, applicationTopPos, {
                width: elevatorLeft / 4,
                height: 27,
                align: pdf.PdfTextHorizontalAlign.Center,
            });
            doc.drawText(applicantContact.personName, elevatorLeft/4,applicationTopPos, {
                width: elevatorLeft / 4,
                height: 27,
                align: pdf.PdfTextHorizontalAlign.Center
            });
            doc.drawText(applicantContact.personMobile, (elevatorLeft / 4) * 2, applicationTopPos, {
                width: elevatorLeft / 4,
                height: 27,
                align: pdf.PdfTextHorizontalAlign.Center
            });
            doc.drawText(applicantContact.personMail, (elevatorLeft / 4) * 3, applicationTopPos, {
                width: elevatorLeft / 4,
                height: 27,
                align: pdf.PdfTextHorizontalAlign.Center
            });
            applicationTopPos += 27;

        } else {
            // 納入依頼なし
            applicationTopPos = elevatorTop;

            // 所属会社名列の枠
            doc.paths.rect(0, applicationTopPos, elevatorLeft, topRectHeight).stroke();
            // 所属会社名列の区切り線
            doc.paths.moveTo(0, applicationTopPos + 20).lineTo(elevatorLeft, applicationTopPos + 20).stroke();
            // 縦線
            const item1 = 4; // 項目数
            for (let i = 1; i < item1; ++i) {
                doc.paths.moveTo((elevatorLeft / item1) * i, applicationTopPos).
                  lineTo((elevatorLeft / item1) * i, applicationTopPos + topRectHeight).stroke();
            }

            doc.drawText('所属会社名', 0, applicationTopPos + 5, {
                width: (elevatorLeft / item1),
                height: 27,
                align: pdf.PdfTextHorizontalAlign.Center
            });

            doc.drawText('責任者名', (elevatorLeft / item1), applicationTopPos + 5, {
              width: (elevatorLeft / item1),
              height: 27,
              align: pdf.PdfTextHorizontalAlign.Center
            });

            doc.drawText('責任者ＴＥＬ（携帯）', (elevatorLeft / item1) * 2, applicationTopPos + 5, {
              width: (elevatorLeft / item1),
              height: 27,
              align: pdf.PdfTextHorizontalAlign.Center
            });

            doc.drawText('荷受人名前・TEL（携帯）', (elevatorLeft / item1) * 3, applicationTopPos + 5, {
              width: (elevatorLeft / item1),
              height: 27,
              align: pdf.PdfTextHorizontalAlign.Center
            });

            doc.drawText(this.applicantContact.sourceCollection[0].companyName, 0, applicationTopPos + 27, {
                width: (elevatorLeft / item1),
                height: 27,
                align: pdf.PdfTextHorizontalAlign.Center
            });

            doc.drawText(this.applicantContact.sourceCollection[0].personName, (elevatorLeft / item1), applicationTopPos + 27, {
                width: (elevatorLeft / item1),
                height: 27,
                align: pdf.PdfTextHorizontalAlign.Center
            });
            doc.drawText(this.applicantContact.sourceCollection[0].personMobile, (elevatorLeft / item1) * 2, applicationTopPos + 27, {
                width: (elevatorLeft / item1),
                height: 27,
                align: pdf.PdfTextHorizontalAlign.Center
            });
            doc.drawText(this.consigneeName ? this.consigneeName + '（' + this.consigneeMobile + '）' : '' , (elevatorLeft / item1) * 3, applicationTopPos + 27, {
                width: (elevatorLeft / item1),
                height: 27,
                align: pdf.PdfTextHorizontalAlign.Center
            });
            applicationTopPos += topRectHeight;

            // doc.paths.moveTo(0, applicationTopPos).lineTo(elevatorLeft, applicationTopPos).stroke();

            for (let i = 1; i <= site.relationName.length; i++) {
              // スペース足りなければ改ページ
              if (doc.height - applicationTopPos < 20) {
                doc.addPage(doc.currentPageSettings);
                applicationTopPos = 0;
              }

              const relationName = site.relationName[i - 1];
              const relationContacts = this.relationContacts.sourceCollection[i - 1];

              // 請負体系の枠
              doc.paths.rect(0, applicationTopPos, elevatorLeft, topRectHeight).stroke();
              // 請負体系の横線
              doc.paths.moveTo(0, applicationTopPos + 20).lineTo(elevatorLeft, applicationTopPos + 20).stroke();
              // 縦線
              for (let i = 1; i < item1; ++i) {
                doc.paths.moveTo((elevatorLeft / item1) * i, applicationTopPos).
                  lineTo((elevatorLeft / item1) * i, applicationTopPos + topRectHeight).stroke();
              }

              doc.drawText(relationName, 0, (applicationTopPos + 5), {
                  width: (elevatorLeft / item1),
                  height: 27,
                  align: pdf.PdfTextHorizontalAlign.Center
              });
              doc.drawText(relationContacts.companyName, 0, (applicationTopPos + 27), {
                  width: (elevatorLeft / item1),
                  height: 27,
                  align: pdf.PdfTextHorizontalAlign.Center
              });

              doc.drawText('担当者名', (elevatorLeft / item1), (applicationTopPos + 5), {
                  width: (elevatorLeft / item1),
                  height: 27,
                  align: pdf.PdfTextHorizontalAlign.Center
              });
              doc.drawText(relationContacts.personName, (elevatorLeft / item1), (applicationTopPos + 27), {
                  width: (elevatorLeft / item1),
                  height: 27,
                  align: pdf.PdfTextHorizontalAlign.Center
              });
              doc.drawText('担当者ＴＥＬ（携帯）', (elevatorLeft / item1) * 2, (applicationTopPos + 5), {
                  width: (elevatorLeft / item1),
                  height: 27,
                  align: pdf.PdfTextHorizontalAlign.Center
              });
              doc.drawText(relationContacts.personMobile, (elevatorLeft / item1) * 2, (applicationTopPos + 27), {
                  width: (elevatorLeft / item1),
                  height: 27,
                  align: pdf.PdfTextHorizontalAlign.Center
              });
              doc.drawText('担当者メールアドレス', (elevatorLeft / item1) * 3, (applicationTopPos + 5), {
                  width: (elevatorLeft / item1),
                  height: 27,
                  align: pdf.PdfTextHorizontalAlign.Center
              });
              doc.drawText(relationContacts.personMail, (elevatorLeft / item1) * 3, (applicationTopPos + 27), {
                  width: (elevatorLeft / item1),
                  height: 27,
                  align: pdf.PdfTextHorizontalAlign.Center
              });

              applicationTopPos += topRectHeight;
            }
        }

        // 余白が少なかったら改ページ
        if (doc.height - applicationTopPos < 80) {
          doc.addPage(doc.currentPageSettings);
          applicationTopPos = 0;
        }

        // 揚重ヘッダー
        const item2 = 4;
        const cargolen = cargos.length;
        const yojuWidth = 52;
        const yojuHeight = 35;
        const youjuLeft = doc.width - (yojuWidth * item2);
        let youjuTop = applicationTopPos + 20;

        // doc.paths.rect(0, youjuTop, doc.width, 80).stroke();
        // doc.paths.rect(0, youjuTop, doc.width, 40).stroke();
        doc.paths.moveTo(0, youjuTop).lineTo( doc.width, youjuTop).stroke();
        doc.paths.moveTo(0, youjuTop + 15).lineTo( doc.width, youjuTop + 15).stroke();
        doc.paths.moveTo(0, youjuTop + 40).lineTo( doc.width, youjuTop + 40).stroke();

        // 搬入ヘッダー
        doc.drawText('搬入', 0, youjuTop + 2, {width: youjuLeft, height: 15, align: pdf.PdfTextHorizontalAlign.Center});

        const numWidth = 18;
        const reqWidth = 68;
        let leftPos = 0;

        doc.paths.moveTo(leftPos, youjuTop).lineTo(leftPos, (youjuTop + 40)).stroke();
        doc.drawText('No.', leftPos, youjuTop + 22, {
            width: numWidth,
            height: yojuHeight,
            align: pdf.PdfTextHorizontalAlign.Center,
        });
        leftPos += numWidth;
        doc.paths.moveTo(leftPos, (youjuTop + 15)).lineTo(leftPos, (youjuTop + 40)).stroke();

        doc.drawText('搬入希望日時', leftPos, youjuTop + 22, {
           width: reqWidth,
           height: yojuHeight,
           align: pdf.PdfTextHorizontalAlign.Center,
        });
        leftPos += reqWidth;
        doc.paths.moveTo(leftPos, (youjuTop + 15)).lineTo(leftPos, (youjuTop + 40)).stroke();

        doc.drawText('揚重機使用希望時間', leftPos, youjuTop + 18, {
            width: reqWidth,
            height: yojuHeight,
            align: pdf.PdfTextHorizontalAlign.Center,
        });
        leftPos += reqWidth;
        doc.paths.moveTo(leftPos, (youjuTop + 15)).lineTo(leftPos, (youjuTop + 40)).stroke();

        doc.drawText('搬入確定日', leftPos, youjuTop + 22, {
            width: 65,
            height: yojuHeight,
            align: pdf.PdfTextHorizontalAlign.Center,
        });
        leftPos += 65;
        doc.paths.moveTo(leftPos, (youjuTop + 15)).lineTo(leftPos, (youjuTop + 40)).stroke();

        doc.drawText('確定時間', leftPos, youjuTop + 22, {
            width: 50,
            height: yojuHeight,
            align: pdf.PdfTextHorizontalAlign.Center,
        });
        leftPos += 50;
        doc.paths.moveTo(leftPos, (youjuTop + 15)).lineTo(leftPos, (youjuTop + 40)).stroke();

        doc.drawText('資材名', leftPos, youjuTop + 22, {
            width: 65,
            height: yojuHeight,
            align: pdf.PdfTextHorizontalAlign.Center,
        });
        leftPos += 65;
        doc.paths.moveTo(leftPos, (youjuTop + 15)).lineTo(leftPos, (youjuTop + 40)).stroke();

        doc.drawText('搬入希望ゲート', leftPos, youjuTop + 22, {
            width: 65,
            height: yojuHeight,
            align: pdf.PdfTextHorizontalAlign.Center,
        });
        leftPos += 65;
        doc.paths.moveTo(leftPos, (youjuTop + 15)).lineTo(leftPos, (youjuTop + 40)).stroke();

        doc.drawText('車種', leftPos, youjuTop + 22, {
            width: 65,
            height: yojuHeight,
            align: pdf.PdfTextHorizontalAlign.Center,
        });
        leftPos += 65;
        doc.paths.moveTo(leftPos, (youjuTop + 15)).lineTo(leftPos, (youjuTop + 40)).stroke();

        doc.drawText('予定台数', leftPos, youjuTop + 22, {
            width: 45,
            height: yojuHeight,
            align: pdf.PdfTextHorizontalAlign.Center,
        });
        leftPos += 45;
        doc.paths.moveTo(leftPos, (youjuTop + 15)).lineTo(leftPos, (youjuTop + 40)).stroke();

        doc.drawText('揚重確定番号', leftPos, youjuTop + 22, {
            width: 65,
            height: yojuHeight,
            align: pdf.PdfTextHorizontalAlign.Center,
        });
        leftPos += 65;
        doc.paths.moveTo(leftPos, (youjuTop + 15)).lineTo(leftPos, (youjuTop + 40)).stroke();

        doc.paths.moveTo(leftPos, youjuTop).lineTo(leftPos, youjuTop + 15).stroke();
        doc.drawText('揚重作業', leftPos, youjuTop + 2, {
            width: yojuWidth * 4,
            height: 15,
            align: pdf.PdfTextHorizontalAlign.Center
        });

        doc.drawText('作業区分', leftPos, youjuTop + 22, {
          width: yojuWidth,
          height: 25,
          align: pdf.PdfTextHorizontalAlign.Center
        });
        leftPos += yojuWidth;
        doc.paths.moveTo(leftPos, youjuTop + 15).lineTo(leftPos, youjuTop + 40).stroke();
        doc.drawText('揚重', leftPos, youjuTop + 17, {
            width: (yojuWidth * 2),
            height: 6,
            align: pdf.PdfTextHorizontalAlign.Center,
        });
        // 横線
        doc.paths.moveTo(leftPos, youjuTop + 15 + 12).lineTo(leftPos + (yojuWidth * 2), youjuTop + 15 + 12).stroke();

        doc.drawText('積込階', leftPos, youjuTop + 29, {
            width: yojuWidth,
            height: 6,
            align: pdf.PdfTextHorizontalAlign.Center,
        });
        leftPos += yojuWidth;
        doc.paths.moveTo(leftPos, youjuTop + 15 + 12).lineTo(leftPos, youjuTop + 40).stroke();
        doc.drawText('荷降階', leftPos, youjuTop + 29, {
            width: yojuWidth,
            height: 6,
            align: pdf.PdfTextHorizontalAlign.Center,
        });
        leftPos += yojuWidth;
        doc.paths.moveTo(leftPos, youjuTop + 15).lineTo(leftPos, youjuTop + 40).stroke();
        doc.drawText('揚重回数', leftPos, youjuTop + 22, {
            width: yojuWidth,
            height: 25,
            align: pdf.PdfTextHorizontalAlign.Center,
        });
        doc.paths.moveTo(doc.width, youjuTop).lineTo(doc.width, youjuTop + 40).stroke();

        let top;
        let bottom;
        let newpage = false;
        let margin = 40;
        let j = 0;
        // 段ごとの枠を描画
        for (let i = 0; i < cargolen; ++i) {
            // console.log(cargos[i]);
            // 枠がページをはみ出す場合は改ページ
            if (newpage) {
                margin = 0;
                j = (i - 1);
                newpage = false;
            }
            top = (youjuTop + (yojuHeight * (i - j)) + margin);
            bottom = top + yojuHeight;
            if (bottom > (doc.height + 20)) {// マージン分を超えなければOK
                doc.addPage(doc.currentPageSettings);
                top = 0;
                youjuTop = top;
                newpage = true;
            }

            leftPos = 0;
            doc.paths.moveTo(leftPos, top).lineTo(leftPos, (top + yojuHeight)).stroke();

            leftPos += numWidth;
            doc.paths.moveTo(leftPos, top).lineTo(leftPos, (top + yojuHeight)).stroke();

            // doc.drawText('搬入希望日時', leftPos, youjuTop + 18, {
            leftPos += reqWidth;
            doc.paths.moveTo(leftPos, top).lineTo(leftPos, (top + yojuHeight)).stroke();

            // doc.drawText('揚重機使用希望時間', leftPos, youjuTop + 18, {
            leftPos += reqWidth;
            doc.paths.moveTo(leftPos, top).lineTo(leftPos, (top + yojuHeight)).stroke();

            // doc.drawText('搬入確定日', leftPos, youjuTop + 22, {
            leftPos += 65;
            doc.paths.moveTo(leftPos, top).lineTo(leftPos, (top + yojuHeight)).stroke();

            // doc.drawText('確定時間', leftPos, youjuTop + 22, {
            leftPos += 50;
            doc.paths.moveTo(leftPos, top).lineTo(leftPos, (top + yojuHeight)).stroke();

            // doc.drawText('資材名', leftPos, youjuTop + 22, {
            leftPos += 65;
            doc.paths.moveTo(leftPos, top).lineTo(leftPos, (top + yojuHeight)).stroke();

            // doc.drawText('搬入希望ゲート', leftPos, youjuTop + 18, {
            leftPos += 65;
            doc.paths.moveTo(leftPos, top).lineTo(leftPos, (top + yojuHeight)).stroke();

            // doc.drawText('車種', leftPos, youjuTop + 22, {
            leftPos += 65;
            doc.paths.moveTo(leftPos, top).lineTo(leftPos, (top + yojuHeight)).stroke();

            // doc.drawText('予定台数', leftPos, youjuTop + 22, {
            leftPos += 45;
            doc.paths.moveTo(leftPos, top).lineTo(leftPos, (top + yojuHeight)).stroke();

            // doc.drawText('揚重確定番号', leftPos, youjuTop + 22, {
            leftPos += 65;
            doc.paths.moveTo(leftPos, top).lineTo(leftPos, (top + yojuHeight)).stroke();

            // 揚重作業縦線
            leftPos += yojuWidth;
            doc.paths.moveTo(leftPos, top).lineTo(leftPos, (top + yojuHeight)).stroke();
            leftPos += yojuWidth;
            doc.paths.moveTo(leftPos, top).lineTo(leftPos, (top + yojuHeight)).stroke();
            leftPos += yojuWidth;
            doc.paths.moveTo(leftPos, top).lineTo(leftPos, (top + yojuHeight)).stroke();

            doc.paths.moveTo(doc.width, top).lineTo(doc.width, (top + yojuHeight)).stroke();
            // 底の線
            doc.paths.moveTo(0, top + yojuHeight).lineTo(doc.width, top + yojuHeight).stroke();

            leftPos = 0;
            // No.
            doc.drawText(cargos[i].cargoIndex.toString(), leftPos, top + 10, {
                width: numWidth,
                height: yojuHeight,
                align: pdf.PdfTextHorizontalAlign.Center,
            });
            leftPos += numWidth;

            // 搬入希望時間
            doc.drawText(cargos[i].requestStartDate + '\n' + cargos[i].requestStartDateTime + '～' + cargos[i].requestEndDateTime,
            leftPos, top + 5, {
                width: reqWidth,
                height: yojuHeight,
                align: pdf.PdfTextHorizontalAlign.Center,
            });
            leftPos += reqWidth;
            // 揚重機使用希望時間
            if (cargos[i].useLiftStartTime != null && cargos[i].useLiftEndTime != null) {
              doc.drawText(cargos[i].useLiftStartTime.substring(0, 5) + '～' + cargos[i].useLiftEndTime.substring(0, 5),
              leftPos, top + 10, {
                    width: reqWidth,
                    height: yojuHeight,
                    align: pdf.PdfTextHorizontalAlign.Center,
                });
            }
            leftPos += reqWidth;
            // 搬入確定日
            if (cargos[i].confirmDate != null) {
              doc.drawText(cargos[i].confirmDate,
                leftPos, top + 10, {
                width: 65,
                height: yojuHeight,
                align: pdf.PdfTextHorizontalAlign.Center,
              });
            }
            leftPos += 65;
            // 確定時間
            if (cargos[i].confirmDate != null) {
              doc.drawText(cargos[i].confirmDateTime,
                  leftPos, top + 10, {
                  width: 50,
                  height: yojuHeight,
                  align: pdf.PdfTextHorizontalAlign.Center,
              });
            }
            leftPos += 50;
            // 資材名
            doc.drawText(cargos[i].materialName,
              leftPos, top + 10, {
              width: 65,
              height: yojuHeight,
              align: pdf.PdfTextHorizontalAlign.Center,
            });
            leftPos += 65;
            // 搬入希望ｹﾞｰﾄ
            doc.drawText(cargos[i].requestGate,
              leftPos, top + 10, {
              width: 65,
              height: yojuHeight,
              align: pdf.PdfTextHorizontalAlign.Center,
            });
            leftPos += 65;
            // 車種
            doc.drawText(cargos[i].truckSize, leftPos, top + 10, {
                width: 65,
                height: yojuHeight,
                align: pdf.PdfTextHorizontalAlign.Center,
            });
            leftPos += 65;
            // 予定台数
            doc.drawText(cargos[i].truckCount + '台',
            leftPos, top + 10, {
              width: 40,
              height: yojuHeight,
              align: pdf.PdfTextHorizontalAlign.Center
            });
            leftPos += 45;
            // 揚重確定番号
            doc.drawText(cargos[i].confirmLiftingNumber, leftPos, top + 10, {
                width: 65,
                height: yojuHeight,
                align: pdf.PdfTextHorizontalAlign.Center,
            });
            leftPos += 65;

            // 作業区分
            doc.drawText(cargos[i].workType, leftPos, top + 10, {
                width: yojuWidth,
                height: yojuHeight,
                align: pdf.PdfTextHorizontalAlign.Center,
            });
            leftPos += yojuWidth;
            // 積込階
            doc.drawText(cargos[i].loadingFloor, leftPos, top + 10, {
                width: yojuWidth,
                height: yojuHeight,
                align: pdf.PdfTextHorizontalAlign.Center,
            });
            leftPos += yojuWidth;
            // 荷降階
            doc.drawText(cargos[i].unloadingFloor, leftPos, top + 10, {
                width: yojuWidth,
                height: yojuHeight,
                align: pdf.PdfTextHorizontalAlign.Center,
            });
            leftPos += yojuWidth;
            // 揚重回数
            doc.drawText(cargos[i].liftingCount.toString(), leftPos, top + 10, {
                width: yojuWidth,
                height: yojuHeight,
                align: pdf.PdfTextHorizontalAlign.Center,
            });
        }

        // 注意事項の高さ計算
        let cautionHeight = 0;
        if (site.liftingPrecaution != null) {
          const size = doc.measureText(site.liftingPrecaution,
            new pdf.PdfFont('ipaexg', 9, 'normal', 'normal'),
            {
              width: (doc.width / 2),
              align: pdf.PdfTextHorizontalAlign.Left,
            });
          cautionHeight = size.size.height;
          console.log(cautionHeight);
        }

        let noteTop = (top + yojuHeight + margin);
        let noteHeight = doc.height - noteTop;
        bottom = noteTop + 20 + cautionHeight;
        if (bottom >= doc.height) {
            doc.addPage(doc.currentPageSettings);
            noteTop = 0;
            noteHeight = doc.height;
        }
        doc.paths.rect(0, noteTop, doc.width, noteHeight).stroke();
        doc.paths.moveTo((doc.width / 2), noteTop).lineTo((doc.width / 2), doc.height).stroke();
        doc.drawText('搬入・揚重作業基本注意事項', 2, noteTop + 5, {
            width: (doc.width / 2),
            height: 20,
            align: pdf.PdfTextHorizontalAlign.Left,
        });
        doc.moveDown();
        // 搬入・揚重作業基本注意事項
        let liftingPrecaution = '';
        if (site.liftingPrecaution != null) {
            liftingPrecaution = site.liftingPrecaution;
        }
        doc.drawText(liftingPrecaution, null, null, {
            width: (doc.width / 2),
            height: noteHeight - 20,
            align: pdf.PdfTextHorizontalAlign.Left,
        });
        doc.drawText('備考', (doc.width / 2) + 5, noteTop + 10, {
            width: (doc.width / 2),
            height: 20,
            align: pdf.PdfTextHorizontalAlign.Left
        });
        // 2P目
        for (let i = 0; i < cargolen; ++i) {
            doc.addPage(doc.currentPageSettings);
            const detailRectHeight = doc.height - 35;
            const detailItemHeight = detailRectHeight / 19;
            doc = this.getPdfCargoLayout(doc, detailRectHeight, detailItemHeight);
            // 左側入力項目
            doc.drawText(site.name, 0, 5, {
                height: 20,
                align: pdf.PdfTextHorizontalAlign.Left
            });

            let top = 65;
            doc.drawText(data.applicantContact.companyName, 55, top, {
                width: 45 + (detailItemHeight * 5),
                height: detailItemHeight,
                align: pdf.PdfTextHorizontalAlign.Left
            });
            top += detailItemHeight;
            doc.drawText(data.applicantContact.personName, 55, top, {
                width: 45 + (detailItemHeight * 5),
                height: detailItemHeight,
                align: pdf.PdfTextHorizontalAlign.Left
            });
            top += detailItemHeight;
            doc.drawText(data.applicantContact.personMobile, 55, top, {
                width: 45 + (detailItemHeight * 5),
                height: detailItemHeight,
                align: pdf.PdfTextHorizontalAlign.Left
            });
            top += detailItemHeight + 5;
            doc.drawText(cargos[i].materialName, 55, top, {
                width: 45 + (detailItemHeight * 5),
                height: detailItemHeight,
                align: pdf.PdfTextHorizontalAlign.Left
            });

            top += detailItemHeight;
            let mixed = '';
            if (cargos[i].mixed != null) {
                mixed = this.mixed[cargos[i].mixed];
            }
            doc.drawText('混載（　' + mixed + '　)', 55, top + 5, {
                width: 245,
                height: detailItemHeight,
                align: pdf.PdfTextHorizontalAlign.Left
            });
            top += detailItemHeight + 5;
            doc.drawText(cargos[i].packingMaterial, 55, top, {
                width: 45 + (detailItemHeight * 5),
                height: detailItemHeight,
                align: pdf.PdfTextHorizontalAlign.Left
            });
            top += (detailItemHeight * 2) + 5;
            let trolley = '';
            if (cargos[i].trolley != null) {
                trolley = this.trolley[cargos[i].trolley];
            }
            doc.drawText('台車　　　　　(　' + trolley + '　）', 55, top, {
                width: 250,
                height: detailItemHeight,
                align: pdf.PdfTextHorizontalAlign.Center
            });
            top += detailItemHeight + 5;
            // パレット
            let palette = '';
            if (cargos[i].palette != null) {
                palette = this.used[cargos[i].palette];
            }
            doc.drawText('(　　' + palette + '　　)', 55, top, {
                width: 250,
                height: detailItemHeight,
                align: pdf.PdfTextHorizontalAlign.Center
            });
            top += detailItemHeight + 5;

            // パタ角
            let batakaku = '';
            if (cargos[i].palette != null) {
                batakaku = this.used[cargos[i].batakaku];
            }
            doc.drawText('(　　' + batakaku + '　　)', 55, top, {
                width: 250,
                height: detailItemHeight,
                align: pdf.PdfTextHorizontalAlign.Center
            });
            top += detailItemHeight + 5;

            // 搬入車輌
            let truckSize = '';
            if (cargos[i].truckSize != null) {
                truckSize = cargos[i].truckSize;
            }
            let truckType = '';
            if (cargos[i].truckType != null) {
                truckType = this.constant.TRUCKTYPE[cargos[i].truckType]
            }
            doc.drawText(truckSize + '　( ' + truckType + ' )', 55, top, {
                width: 150,
                height: detailItemHeight,
                align: pdf.PdfTextHorizontalAlign.Left
            });

            let unic = '';
            if (cargos[i].unic != null) {
                unic = this.enable[cargos[i].unic];
            }
            doc.drawText('ユニック車　( ' + unic + ' )', 55, top + 12, {
                width: 250,
                height: detailItemHeight,
                align: pdf.PdfTextHorizontalAlign.Left
            });


            let forkLift = '';
            if (cargos[i].forkLift != null) {
                forkLift = this.enable[cargos[i].forkLift];
            }

            doc.drawText('フォークリフトで荷降し（　' + forkLift + '　)', 55, top + 24, {
              width: 250,
              height: detailItemHeight,
              align: pdf.PdfTextHorizontalAlign.Left
            });
            top += detailItemHeight * 2;

            // 備考
            let useForkLift = '';
            if (cargos[i].useForkLift != null) {
                useForkLift = this.forkLift[cargos[i].useForkLift];
            }
            doc.drawText('フォークリフト使用　　　' + useForkLift, 55, top + 5, {
                width: 250,
                height: detailItemHeight,
                align: pdf.PdfTextHorizontalAlign.Center
            });

            let necessaryTrolley = '';
            if (cargos[i].necessaryTrolley != null) {
                necessaryTrolley = cargos[i].necessaryTrolley;
            }
            doc.drawText('台車　　大凡　　　' + necessaryTrolley + '台程度　必要', 55, top + 15, {
                width: 250,
                height: detailItemHeight,
                align: pdf.PdfTextHorizontalAlign.Center
            });

            // コメント
            top += detailItemHeight;
            doc.drawText(cargos[i].remark, 55, top + 10, {
                width: 250,
                height: detailItemHeight,
                align: pdf.PdfTextHorizontalAlign.Left
            });

            // 荷姿の形状
            doc.drawText('荷姿の形状　（具体的に記入、図及び写真添付可）', 305, 60, {
                width: doc.width - 295,
                height: 20,
                align: pdf.PdfTextHorizontalAlign.Left
            });
            const detailWidth = (doc.width - 330) / 2;
            const detailHeight = 14;
            const detailLeft = 310;
            let detailBottom = doc.height;

            top = 60;
            let imgData;
            let material;
            let imgIndex = 0;
            let materialIndex = 0;
            let newPage = true;
            const imgHeight = ((doc.height - 100) / 2);
            let weight = 0;
            while (newPage) {
                newPage = false;
                // 画像表示位置の基準を設定
                doc.drawText(' ', (detailLeft + detailWidth + 10), top, {
                    width: detailWidth,
                    height: 20,
                    align: pdf.PdfTextHorizontalAlign.Right
                });
                doc.moveDown();
                // 画像(2列目)
                if (cargos[i].materialImageUrl != null && cargos[i].materialImageUrl.length > imgIndex) {
                    imgData = this.drawMaterialImage(doc, cargos[i].materialImageUrl, imgIndex, detailWidth, imgHeight);
                    doc = imgData[0];
                    imgIndex = imgData[1];
                    newPage = imgData[2];
                }
                // 資材情報(1列目)
                if (cargos[i].cargoMaterials[materialIndex] != null && cargos[i].cargoMaterials.length > materialIndex) {
                    detailBottom = 80 + (detailHeight * cargos[i].cargoMaterials.length);
                    if (newpage || (detailBottom > 435)) {
                        detailBottom = doc.height - 15;
                    } else {
                        detailBottom = 435;
                    }
                    material = this.drawMaterial(doc, cargos[i].cargoMaterials,
                        materialIndex, detailLeft, detailBottom, detailWidth, detailHeight, weight);
                    doc = material[0];
                    materialIndex = material[1];
                    if (!newPage) {
                        newPage = material[2];
                    }
                    weight = material[3];
                    // console.log(material);
                }
                if (newPage) {
                    doc.addPage(doc.currentPageSettings);
                    doc = this.getPdfCargoLayout(doc, detailRectHeight, detailItemHeight);
                }
            }

            let otherWeight = 0;
            if (cargos[i].otherWeight != null) {
                otherWeight = cargos[i].otherWeight;
            }
            const total = weight + otherWeight;
            doc.drawText('積載重量（材料の重量　　　　　' + weight + 'ｋｇ　＋　台車・パレット等重量　　　　　' + otherWeight + 'ｋｇ）', 305, 435, {
                width: doc.width - 295,
                height: 20,
                align: pdf.PdfTextHorizontalAlign.Left
            });
            doc.drawText('＝（合計 　　　' + total + 'ｋｇ)', 305, 460, {
                width: doc.width - 350,
                height: 20,
                align: pdf.PdfTextHorizontalAlign.Right
            });
            let cargoWidth = '';
            if (cargos[i].cargoWidth != null) {
                cargoWidth = cargos[i].cargoWidth;
            }
            let cargoDepth = '';
            if (cargos[i].cargoDepth != null) {
                cargoDepth = cargos[i].cargoDepth;
            }
            let cargoHeight = '';
            if (cargos[i].cargoHeight != null) {
                cargoHeight = cargos[i].cargoHeight;
            }
            doc.drawText('荷姿の寸法（巾　　　　' + cargoWidth + 'ｍｍ　Ｘ　奥行　　　　' + cargoDepth + 'ｍｍ　Ｘ　高さ　　　　' + cargoHeight + 'ｍｍ）', 305, 485, {
                width: doc.width - 295,
                height: 20,
                align: pdf.PdfTextHorizontalAlign.Left
            });
        }
        doc.end();
    }

    // 帳票の２P目以降のレイアウトを取得
    private getPdfCargoLayout(doc, detailRectHeight, detailItemHeight) {
        const pen = new pdf.PdfPen({width: 1.5});
        doc.paths.rect(0, 25, doc.width, 30).stroke(pen);
        doc.drawText('荷姿及び梱包方法調査票', (doc.width / 2) + 50, 40, {
            width: 200,
            height: 20,
            align: pdf.PdfTextHorizontalAlign.Left
        });
        doc.drawText('年　　　　月　　　　日', (doc.width / 2) + 250, 40, {
            width: (doc.width - ((doc.width / 2) + 255)),
            height: 20,
            align: pdf.PdfTextHorizontalAlign.Right
        });
        doc.paths.rect(0, 25, doc.width, detailRectHeight).stroke(pen);
        doc.paths.moveTo(50, 55).lineTo(50, doc.height - 10).stroke(pen);
        doc.paths.moveTo(300, 55).lineTo(300, doc.height - 10).stroke(pen);

        // doc.paths.moveTo(0, 60 + (detailItemHeight * 3)).lineTo(300, 60 + (detailItemHeight * 3)).stroke(pen);
        let top = 65;
        doc.drawText('会社名', 0, top, {
            width: 50,
            height: detailItemHeight,
            align: pdf.PdfTextHorizontalAlign.Center
        });
        top += detailItemHeight;
        doc.drawText('記入者', 0, top, {
            width: 50,
            height: detailItemHeight,
            align: pdf.PdfTextHorizontalAlign.Center
        });
        top += detailItemHeight;
        doc.drawText('ＴＥＬ', 0, top, {
            width: 50,
            height: detailItemHeight,
            align: pdf.PdfTextHorizontalAlign.Center
        });
        top += detailItemHeight;
        doc.paths.moveTo(0, top).lineTo(300, top).stroke(pen);
        top += 5;
        doc.drawText('資材名', 0, top, {
            width: 50,
            height: (detailItemHeight * 2),
            align: pdf.PdfTextHorizontalAlign.Center
        });
        top += detailItemHeight * 2;
        doc.paths.moveTo(0, top).lineTo(300, top).stroke(pen);
        top += 5;
        doc.drawText('梱包材', 0, top, {
            width: 50,
            height: (detailItemHeight * 2),
            align: pdf.PdfTextHorizontalAlign.Center
        });
        top += detailItemHeight * 2;
        doc.paths.moveTo(0, top).lineTo(300, top).stroke();
        top += 5;
        doc.drawText('台車', 0, top, {
            width: 50,
            height: detailItemHeight,
            align: pdf.PdfTextHorizontalAlign.Center
        });
        top += detailItemHeight;
        doc.paths.moveTo(0, top).lineTo(300, top).stroke(pen);
        top += 5;
        doc.drawText('パレット', 0, top, {
            width: 50,
            height: detailItemHeight,
            align: pdf.PdfTextHorizontalAlign.Center
        });
        top += detailItemHeight;
        doc.paths.moveTo(0, top).lineTo(300, top).stroke();
        top += 5;
        doc.drawText('パタ角', 0, top, {
            width: 50,
            height: detailItemHeight,
            align: pdf.PdfTextHorizontalAlign.Center
        });
        top += detailItemHeight;
        doc.paths.moveTo(0, top).lineTo(300, top).stroke(pen);

        top += 5;
        doc.drawText('搬入車輌', 0, top, {
            width: 50,
            height: (detailItemHeight * 2),
            align: pdf.PdfTextHorizontalAlign.Center
        });

        top += detailItemHeight * 2;
        doc.paths.moveTo(0, top).lineTo(300, top).stroke(pen);
        top += 5;
        doc.drawText('備考', 0, top, {
            width: 50,
            height: (detailItemHeight * 2),
            align: pdf.PdfTextHorizontalAlign.Center
        });
        top += detailItemHeight * 1;
        doc.paths.moveTo(0, top).lineTo(300, top).stroke(pen);
        top += 5;
        doc.drawText('申請時ｺﾒﾝﾄ', 0, top, {
            width: 50,
            height: detailItemHeight,
            align: pdf.PdfTextHorizontalAlign.Center
        });

        doc.drawText('※複数の資材を搬入する協力会社の方は、荷姿・梱包方法が資材によって異なる場合は、コピーをとり個別に記入して下さい。',
            5,
            doc.height - 5, {
            width: doc.width - 5,
            height: 20,
            align: pdf.PdfTextHorizontalAlign.Left,
            font: new pdf.PdfFont('ipaexg', 9, 'normal', 'normal')
        });

        return doc;
    }

    // 資材情報明細を表示
    private drawMaterial(doc, cargoMaterials, index, x, y, width, height, weight) {
        doc.drawText('明細', x, 82, {width: ((width - 70) / 2), height: 'auto', align: pdf.PdfTextHorizontalAlign.Center});
        doc.drawText('寸法', x + ((width - 70) / 2), 82, {
            width: ((width - 70) / 2),
            height: 'auto',
            align: pdf.PdfTextHorizontalAlign.Center
        });
        doc.drawText('数量', x + (width - 70), 82, {width: 40, height: 'auto', align: pdf.PdfTextHorizontalAlign.Center});
        doc.drawText('単位', x + (width - 70) + 40, 82, {width: 30, height: 'auto', align: pdf.PdfTextHorizontalAlign.Center});
        doc.paths.rect(x, 80, width, height).stroke();
        doc.paths.moveTo(x + ((width - 70) / 2), 80).lineTo(x + ((width - 70) / 2), 80 + height).stroke();
        doc.paths.moveTo(x + (width - 70), 80).lineTo(x + (width - 70), 80 + height).stroke();
        doc.paths.moveTo(x + (width - 70) + 40, 80).lineTo(x + +(width - 70) + 40, 80 + height).stroke();
        // 明細
        let top = 80;
        for (index; index < cargoMaterials.length; index++) {
            // console.log(cargoMaterials[index]);
            top += height;
            doc.drawText(cargoMaterials[index].partsName, x + 2, top + 2 + (index*10), {
                width: ((width - 70) / 2),
                height: height+10,
                align: pdf.PdfTextHorizontalAlign.Left
            });
            doc.drawText(cargoMaterials[index].partsNumber, x + ((width - 70) / 2), top + 2 + (index*10), {
                width: ((width - 70) / 2),
                height: 'auto',
                align: pdf.PdfTextHorizontalAlign.Center
            });
            doc.drawText(cargoMaterials[index].quantity.toString(), x + (width - 70), top + 2 + (index*10), {
                width: 40,
                height: 'auto',
                align: pdf.PdfTextHorizontalAlign.Center
            });
            doc.drawText(cargoMaterials[index].unit, x + (width - 70) + 40, top + 2 + (index*10), {
                width: 30,
                height: 'auto',
                align: pdf.PdfTextHorizontalAlign.Center
            });
            doc.paths.rect(x, top + (index*10), width, height+10).stroke();
            doc.paths.moveTo(x + ((width - 70) / 2), top + (index*10)).lineTo(x + ((width - 70) / 2), top + (index*10) + height+10).stroke();
            doc.paths.moveTo(x + (width - 70), top + (index*10)).lineTo(x + (width - 70), top + (index*10) + height+10).stroke();
            doc.paths.moveTo(x + (width - 70) + 40, top + (index*10)).lineTo(x + +(width - 70) + 40, top + (index*10) + height+10).stroke();

            // 個数×重量
            weight += (cargoMaterials[index].weight * cargoMaterials[index].quantity);
            // ページを超える場合は改ページ
            if ((top + (height * 2)) > y) {
                return Array(doc, index + 1, true, weight);
            }
        }
        return Array(doc, null, false, weight);
    }

    // 画像表示
    private drawMaterialImage(doc, materialImageUrl, index, width, height) {
        let imgHeight = 0;
        let imgWidth = 0;
        for (index; index < materialImageUrl.length; index++) {
            imgHeight = height;
            imgWidth = width;
            console.log(index);
            // console.log(materialImageUrl[index]);
            const imgFile = materialImageUrl[index];
            const img = doc.openImage(imgFile.url);
            // console.log(img);
            if (img.height > imgHeight) {
                imgHeight = img.height;
            }
            doc.drawImage(img, null, null, {width: imgWidth, height: imgHeight, stretchProportionally: true});
            doc.moveDown();
            if (doc.y > 435) {
                // 改ページ情報を渡す
                return Array(doc, index + 1, true);
            }
        }
        // 改ページしない
        return Array(doc, null, false);
    }

    checkPhone(tel = '') {
      const phoneResult = this.commonService.validatePhoneNum(tel);
      if (!phoneResult) {
            this.displayWarning('電話番号を正しい形式で入力してください。');
            this.isValidated = false;
      } else {
            this.warningProperty = 'none';
            this.isValidated = true;
      }
      this.changeDetectorRef.detectChanges();
    }

}
