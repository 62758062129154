export class Constant {
    // `authority` INT(11) NOT NULL COMMENT '権限 1:管理者 2:現場担当者 3:揚重センター 4:手配担当者 5:野原顧客 6:非野原顧客',
    public static readonly AUTHORITY_ADMIN = 1;
    public static readonly AUTHORITY_SITE_STAFF = 2;
    public static readonly AUTHORITY_LIFTING = 3;
    public static readonly AUTHORITY_ARRANGE_STAFF = 4;
    public static readonly AUTHORITY_NOHARA_CUSTOMER = 5;
    public static readonly AUTHORITY_NOT_NOHARA_CUSTOMER = 6;
    public static readonly ORDER_STATUS_LIST = {
        // STATUS_ADD_NEW: {NAME: ''},
        // STATUS_NO_ORDER: {NAME: '未手配'},
        // STATUS_ORDERING: {NAME: '手配中'},
        // STATUS_CANCELED: {NAME: '取消し'},
        // STATUS_ARRANGED: {NAME: '仮手配'},
        // STATUS_DELIVERED: {NAME: '納入済み'},
        // STATUS_ARRANGED_CONFIRMED: {NAME: '手配確定'},
        // STATUS_ARRANGED_CANCELED: {NAME: '手配キャンセル'},
        // public static readonly ORDER_GRID_STATUS = ['未手配', '手配中', '取消し', '仮手配', '納入済み', '未手配あり', '手配確定', '手配キャンセル'];
        STATUS_ADD_NEW: -1,
        STATUS_NO_ORDER: 0,
        STATUS_ORDERING: 1,
        STATUS_CANCELED: 2,
        STATUS_ARRANGED: 3,
        STATUS_ARRANGED_CONFIRMED: 4,
        STATUS_DELIVERED: 5,
        STATUS_ARRANGED_CANCELED: 6,
    };
    //
    // public static ORDER_STATUS_LIST[];
    //
    //
    // public static ORDER_STATUS_NAME(status : number){
    //
    // }

    public static readonly CARGO_STATUS_LIST = {
        // STATUS_BEFORE_REQUEST: {NAME: '未申請'},
        // STATUS_REQUESTED: {NAME: '申請中'},
        // STATUS_REQUESTED_DEADLINE_PASSED: {NAME: '申請中（締切済み）'},
        // STATUS_CONFIRMED: {NAME: '確定'},
        // STATUS_DELIVERED: {NAME: '納入完了'},

        STATUS_BEFORE_REQUEST: 0,
        STATUS_REQUESTED: 1,
        STATUS_CONFIRMED: 2,
        STATUS_DELIVERED: 3,
    };
    public static readonly ORDER_CHENGE_STATUS = {
        NONE: 0, // 変更なし
        ORDER_CHANGE: 1, // 資材情報が変更された。
        LIFTING_CHANGE: 2 // 揚重申請が対応された。
    };



    public static readonly WORKTYPE = ['揚重', '盛替', '荷降'];
    public static readonly LIFTING_STATUS_LIST = ['未申請', '申請中', '確定', '納入完了'];
    public static readonly FORKLIFT = ['使用する', '使用しない'];
    public static readonly MIXED = ['する', 'しない'];
    public static readonly TROLLEY = ['ある', 'ない'];
    public static readonly USED = ['使用', '使わない'];
    public static readonly TRUCKTYPE = ['箱車ショート', '箱車ロング', '平車ショート', '平車ロング', 'パワーゲート車ショート', 'パワーゲート車ロング', 'ウィング車'];
    public static readonly ENABLE = ['可能', '不可'];
    public  static readonly ORDER_HAS_NO_ORDER = '未手配あり';
    // public static readonly ORDER_GRID_STATUS =        ['未手配', '手配中', '取消し', '仮手配', '手配確定', '納入済み', '手配キャンセル'];
    public static readonly MATERIAL_STATUS_STR_LIST =  ['未手配', '手配中', '取消し', '仮手配', '手配確定', '納入済み', '手配キャンセル'];
    public static readonly CARGO_STATUS_STR_LIST = ['未申請', '申請中', '確定', '納入完了'];
    public static readonly SITE_BEFORE_DATE_SETTING = ['日前', '週間前'];
    public static readonly WEEK = ['日曜日', '月曜日', '火曜日', '水曜日', '木曜日', '金曜日', '土曜日'];
}
