import {Component, OnInit} from '@angular/core';
import {CommonService} from '../common.service';

@Component({
    selector: 'app-page-not-found',
    templateUrl: './page-not-found.component.html',
    styleUrls: ['./page-not-found.component.scss']
})
export class PageNotFoundComponent implements OnInit {
    routedSiteId = '';

    constructor(private commonS: CommonService) {
    }

    ngOnInit() {
        const topStr = this.commonS.getRoutedSiteFromPath();
        if (topStr !== '') {
            this.routedSiteId = '/' + topStr;
        } else {
            this.routedSiteId = topStr;
        }
    }

}
