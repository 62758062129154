import {Component, OnInit, Input, ViewChild, ChangeDetectionStrategy, ChangeDetectorRef} from '@angular/core';
import {Router} from '@angular/router';
import * as wjcCore from '@grapecity/wijmo';
import * as wjcGrid from '@grapecity/wijmo.grid';
import {FlexGrid, CellRange} from '@grapecity/wijmo.grid';
import {BehaviorSubject, Subject} from 'rxjs';
import {User} from '../user';
import {UserService} from '../../../core/service/user.service';
import {SessionErrorService} from 'src/app/core/session-error.service';
import {CommonFilterUiService} from '../../../core/common-filter-ui.service';
import {CommonService} from '../../../core/common.service';

@Component({
    selector: 'app-user-list',
    templateUrl: './user-list.component.html',
    styleUrls: ['./user-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserListComponent implements OnInit {
    apiData: Subject<any> = new Subject();
    users: User[] = [];
    gridData = new wjcCore.CollectionView([]);
    currentPage = 1;
    userListFilter;
    searchKeys = {};
    isLoading = false;
    sortCondition = {sortKey: 'name', sortOrder: 'asc'};
    userListGrid;
    siteId: string;

    authorityList = [
        {id: 1, name: '管理者'},
        {id: 2, name: '現場担当者'},
        {id: 3, name: '揚重センター'},
        {id: 4, name: '手配担当者'},
        {id: 5, name: '施工業者（野原顧客）'},
        {id: 6, name: '施工業者'}
    ];

    isLastPage = false;

    // reference to grid component
    @ViewChild('userGrid', {static: true}) grid: FlexGrid;

    // エラー表示
    public errorMessage: string;
    public errorProperty = 'none';
    public successProperty = 'none';

    private displayError(message: string) {
        this.errorMessage = message;
        this.errorProperty = '';
        this.changeDetectorRef.detectChanges();
    }

    private displaySuccess(message: string) {
        this.errorMessage = message;
        this.successProperty = '';
        this.changeDetectorRef.detectChanges();
    }

    // コンストラクタでルーターを定義しておく
    constructor(
        private router: Router,
        private userService: UserService,
        public changeDetectorRef: ChangeDetectorRef,
        public sessionErrorService: SessionErrorService,
        private commonFilterService: CommonFilterUiService,
        private commonService: CommonService) {
        this.apiData.subscribe(apidata => {
            this.isLoading = false;
            console.log(apidata);
            const gridData = [];

            apidata.forEach(item => {
                const authority = this.authorityList.filter((object) => {
                    return object.id == item.authority;
                }).shift();

                const sites = [];
                if (item.site) {
                  sites.push(item.site[0].name);
                    // item.site.forEach(element => {
                    //   sites.push(element.name);
                    // });
                }

                gridData.push({
                    id: item.id,
                    name: item.name,
                    email: item.email,
                    authority: authority.name,
                    companyName: item.companyName,
                    mobile: item.mobile,
                    site: sites
                    // site: sites.join(',')
                });
            });

            this.gridData = new wjcCore.CollectionView(gridData);
            this.gridData.sortDescriptions.clear();
            this.gridData.sortDescriptions.push(
                new wjcCore.SortDescription(this.sortCondition.sortKey,
                    this.sortCondition.sortOrder === 'asc'));
            this.isLastPage = this.commonService.isPagingLimit(this.gridData.sourceCollection);

            if (0 !== Object.keys(this.searchKeys).length) {
                for (let key of Object.keys(this.searchKeys)) {
                    const col = this.commonFilterService.getColumnBindingIndex(this.grid.columns, key);
                    const colFilter = this.userListFilter.getColumnFilter(col);
                    colFilter.conditionFilter.condition1.value = this.commonFilterService.getFilterValSkeleton(colFilter.column);
                    colFilter.conditionFilter.condition1.operator =
                        this.commonFilterService.getFilterOperatorSkeleton(colFilter.column);
                }
                this.userListFilter.apply();
            }
            this.gridData.refresh();
            this.changeDetectorRef.detectChanges();
        });
    }

    ngOnInit() {
      this.siteId = this.commonService.getRoutedSiteFromPath();
    }

    flexInitialized(flexgrid: wjcGrid.FlexGrid) {
        // カスタムフィルタの条件作成
        this.userListGrid = flexgrid;
        for (let i = 0; i < this.userListGrid.columns.length; i++) {
            this.userListGrid.columns[i]['search_condition'] = null;
        }
        this.userListFilter.filterColumns = [
            'name', 'email', 'companyName',
            'site.name', 'authority', 'mobile'
        ];
        this.userListGrid.columns[0]['search_condition'] = 4;
        this.userListGrid.columns[1]['search_condition'] = 4;
        this.userListGrid.columns[2]['search_condition'] = 0;
        this.userListGrid.columns[3]['search_condition'] = 4;
        this.userListGrid.columns[4]['search_condition'] = 4;
        this.userListGrid.columns[5]['search_condition'] = 0;
        this.userListGrid.columns[6]['search_condition'] = 4;

        flexgrid.addEventListener(flexgrid.hostElement, 'mouseup', (e: MouseEvent) => {
            if (flexgrid.rows.length === 0) {
                return;
            }

            const ht = flexgrid.hitTest(e);
            let id = '';
            if (ht.row >= 0) {
                id += flexgrid.rows[ht.row].dataItem.id;
            }
            // 行選択したときのみリンクする
            if (ht.panel === flexgrid.rowHeaders) {
              const siteId = this.commonService.getRoutedSiteFromPath();
              this.router.navigate([`${siteId}/master/user/${id}`]);
            }
        });

        flexgrid.formatItem.addHandler((s: wjcGrid.FlexGrid, e: wjcGrid.FormatItemEventArgs) => {
            if (!s.columns[e.col]) {
                return;
            }

            this.commonService.addGridEditMark(s, e, 'id');
        });

        const topPath = 'login';
        this.commonService.userLoggedIn('').subscribe(
            (userData: User) => {
                console.log(userData);

                this.isLoading = true;
                this.userService.getAllUsers(this.currentPage, {}, this.sortCondition).subscribe(
                    (data: User[]) => {
                        this.isLoading = false;
                        this.apiData.next(data);
                    },
                    (error) => {
                        this.isLoading = false;
                        this.displayError(this.commonService.getErrorDetail(error));
                    }
                );

                this.changeDetectorRef.detectChanges();
            },
            (error) => {
                // ログイン情報がない場合は、ルートに遷移
                console.log(error);
                this.router.navigate([topPath]);
            }
        );
    }

    // export grid or selection to CSV
    exportGridToCsv() {
        const rng = new CellRange(0, 0, this.grid.rows.length - 1, this.grid.columns.length - 1);
        const csv = this.grid.getClipString(rng, true, true);
        this.exportCSV(csv, 'AccountList.csv');
    }

    exportCSV(csv: string, fileName: string) {
        const bom = new Uint8Array([0xEF, 0xBB, 0xBF]);
        const blob = new Blob([bom, csv], {type: 'text/csv;charset=utf-8'});
        if (navigator.msSaveBlob) { // IE
            navigator.msSaveBlob(blob, fileName);
        } else {
            const e = document.createElement('a');
            e.setAttribute('href', URL.createObjectURL(blob));
            e.setAttribute('download', fileName);
            e.style.display = 'none';
            document.body.appendChild(e);
            e.click();
            document.body.removeChild(e);
        }
    }

    /*
     * ページング処理
     */
    nextPage() {
        const current = this.currentPage + 1;
        this.isLoading = true;
        this.userService.getAllUsers(current, this.searchKeys, this.sortCondition).subscribe(
            (data: User[]) => {
                this.isLoading = false;
                this.changeDetectorRef.detectChanges();
                if (data.length > 0) {
                    this.currentPage = current;
                    this.apiData.next(data);
                }
            },
            (error) => {
                this.isLoading = false;
                this.displayError(this.commonService.getErrorDetail(error));
            }
        );
    }

    prevPage() {
        if (this.currentPage > 1) {
            const current = this.currentPage - 1;
            this.isLoading = true;
            this.userService.getAllUsers(current, this.searchKeys, this.sortCondition).subscribe(
                (data: User[]) => {
                    this.isLoading = false;
                    this.currentPage = current;
                    this.apiData.next(data);
                },
                (error) => {
                    this.isLoading = false;
                    this.displayError(this.commonService.getErrorDetail(error));
                }
            );
        } else {
            return false;
        }
    }

    /**
     * フィルタアイコンを押されたとき、そのカラムのデータ型に応じた
     * フィルタUIに既存テンプレートを差し替える関数
     * @param filter フィルタオブジェクト
     * @param event イベント引数
     */
    editTemplateFilter(filter, event) {
        console.log('changed');
        this.commonFilterService.setupSearchFilter(filter, event, this.searchKeys);
    }

    /**
     * フィルタが適用/キャンセル/クリアされたときの挙動を示す関数
     * クリアとキャンセルを分けて処理できるよう判断する処理必要
     * こちらからソートするとソート時もこちらのイベントのみ発火する
     * @param filter フィルタオブジェクト
     * @param event イベント引数
     */
    applyCustomFilter(filter = null, event = null) {
        console.log(filter, event, 'FilterChanged');
        const colFilter = filter.getColumnFilter(event.col);

        // キャンセル時はソート条件が変わっているかを確認し、変わっていればデータを再取得する
        // const isClear = colFilter.column.binding in this.searchKeys && !colFilter.conditionFilter.condition1.value;
        const sortDesc = filter.grid.collectionView.sortDescriptions[0];
        const isAsc = sortDesc.ascending ? 'asc' : 'desc';
        const isSort = (sortDesc.property !== this.sortCondition.sortKey) || (isAsc !== this.sortCondition.sortOrder);

        // 適用ボタンかどうか
        if (!event.cancel) {
            const searchInputed = this.commonFilterService.getInputedFilterValue(colFilter.column);
            // 入力値があるか
            if (searchInputed !== '' && searchInputed != null) {
                this.searchKeys[colFilter.column.binding] = searchInputed;
                // フィルタ適用時,フィルター色変更のため、Wijmo内フィルタに対してもフィルタをかける
                colFilter.conditionFilter.condition1.value = this.commonFilterService.getFilterValSkeleton(colFilter.column);
                colFilter.conditionFilter.condition1.operator = this.commonFilterService.getFilterOperatorSkeleton(colFilter.column);
                filter.apply();
            } else {
                // 適用が押されたけど入力値がない
                delete this.searchKeys[colFilter.column.binding];
                colFilter.clear();
            }
        } else {
            // ソートするか
            if (isSort) {
                this.sortCondition.sortKey = sortDesc.property;
                this.sortCondition.sortOrder = sortDesc.ascending ? 'asc' : 'desc';
            } else {
                // クリア時は該当カラムの検索条件を削除してデータ取得自体は実行
                delete this.searchKeys[colFilter.column.binding];
                colFilter.clear();
            }
        }

        console.log('SearchKey', this.searchKeys);
        this.isLoading = true;
        this.currentPage = 1;
        this.userService.getAllUsers(this.currentPage, this.searchKeys, this.sortCondition).subscribe(
            (data: User[]) => {
                this.isLoading = false;
                this.apiData.next(data);
            },
            (error) => {
                this.isLoading = false;
                this.displayError(this.commonService.getErrorDetail(error));
            }
        );
    }

}
