import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable, of } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';
import { Site } from '../../master/construction/site';
import {User} from "../../master/user/user";

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'POST'
  })
};

@Injectable({ providedIn: 'root' })
export class SiteService {
  constructor(private http: HttpClient){ }

  // 現場一覧を取得
  getAllSites(page: number, limited: boolean, searchKeys = {}, sort = {}): Observable<Site[]> {
    const limit = 30;
    // offsetは0から始まる
    let offset = limit * (page - 1);
    if (limited) {
      if (offset < 0) {
        offset = 0;
      }
      let url = environment.apiUrl + '/site?';
      url += 'limit=' + limit;
      url += '&offset=' + offset;

      if (0 !== Object.keys(searchKeys).length) {
        for (let key of Object.keys(searchKeys)) {
          if (key === 'open') {
            if (searchKeys[key].length === 1) {
              url += '&' + 'open' + '=' + searchKeys[key][0];
            }
          } else {
            url += '&' + key + '=' + searchKeys[key];
          }
        }
      }

      if (sort['sortKey']) {
        // APIパラメータ用にキーを補正
        if (sort['sortKey'].indexOf('site.name') !== -1) {
          url += '&sort=' + 'site';
        } else {
          url += '&sort=' + sort['sortKey'];
        }
        url += '&order=' + sort['sortOrder'];
      }

      return this.http.get<Site[]>(url);
    } else {
      return this.http.get<Site[]>(`${environment.apiUrl}/site`);
    }
  }
  // siteIdで指定した現場情報を取得
  getSite(id: string): Observable<Site> {
    return this.http.get<Site>(`${environment.apiUrl}/site/${id}`);
  }
  // siteIdで指定した納入可能日を取得
  getOrderableDates(id: string): Observable<Site> {
    return this.http.get<Site>(`${environment.apiUrl}/site/orderableDates?siteId=${id}`);
  }

  createSite(siteInfo: Site): Observable<Site> {
    const body = JSON.stringify(siteInfo);
    console.log(body);
    return this.http.post<Site>(`${environment.apiUrl}/site`, body, httpOptions).pipe(
      tap(() => console.log(`added site`)),
    );
  }

  // siteIdで指定した現場情報を取得
  getSiteNameById(id: string): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/site/nameById/${id}`);
  }

  // /*
  //  * エラー詳細を取得
  //  */
  // private handleError<T>(operation = 'operation', result?: T) {
  //   return (error: any): Observable<T> => {

  //     // TODO: send the error to remote logging infrastructure
  //     console.error(error); // log to console instead

  //     // TODO: better job of transforming error for user consumption
  //     console.log(`${operation} failed: ${error.message}`);

  //     // Let the app keep running by returning an empty result.
  //     return of(result as T);
  //   };
  // }
}
