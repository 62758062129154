import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-common-filter-ui',
    templateUrl: './common-filter-ui.component.html',
    styleUrls: ['./common-filter-ui.component.scss']
})
export class CommonFilterUiComponent implements OnInit {

    constructor() {
    }

    ngOnInit() {
        // window.onkeydown = this.cancel;
    }

    cancel(e = null) {
        console.log('event66www6', e);
        return e.key.indexOf('Enter') === -1;
    }


}
