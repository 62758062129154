import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { UserService } from '../../core/service/user.service';
import { User } from '../../master/user/user';
import { SessionErrorService } from 'src/app/core/session-error.service';

@Component({
  selector: 'app-password-reset',
  templateUrl: './password-reset.component.html',
  styleUrls: ['./password-reset.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PasswordResetComponent implements OnInit {
  alertMessage: string;
  displayProperty;
  constructor(
    private userService: UserService,
    public changeDetectorRef: ChangeDetectorRef
  ) {
    // private userService: UserService;
  }

  ngOnInit() {
    this.displayProperty = 'none';
  }

  passwordReset(userId: string){
    this.userService.userPasswordReset(userId).subscribe(
      (data: User) => {
        console.log(data);
        this.alertMessage = '新しいパスワードをメールアドレスに送信しました';
        this.displayProperty = '';
        this.changeDetectorRef.detectChanges();
      },
      (error) =>{
        this.alertMessage = error.error.message;
        this.displayProperty = '';
        this.changeDetectorRef.detectChanges();
      }
    );
  }
}
