import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as wjcCore from '@grapecity/wijmo';
import * as wjcGrid from '@grapecity/wijmo.grid';
import * as wjcInput from '@grapecity/wijmo.input';
import { Subject } from 'rxjs';
import { Order } from '../order';
import { OrderService } from '../../core/service/order.service';
import { CommonService } from '../../core/common.service';
import { CookieService } from 'ngx-cookie-service';
import { CustomGridEditor } from '../../core/custom-grid-editor';
import { Constant } from 'src/app/core/constant';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { GeneralSituationCheckerService } from '../../core/general-situation-checker.service';
import { OrderStatusService } from '../../core/order-status.service';
import { User } from '../../master/user/user';
import { SiteService } from 'src/app/core/service/site.service';
import { DatePipe } from '@angular/common';

@Component({
    selector: 'app-order-detail',
    templateUrl: './order-detail.component.html',
    styleUrls: ['./order-detail.component.scss']
})
export class OrderDetailComponent implements OnInit {
    apiData: Subject<any> = new Subject();
    @ViewChild('thePopup', { static: true }) thePopup: wjcInput.Popup;
    @ViewChild('orderingPopup', { static: true }) orderingPopup: wjcInput.Popup;

    orders: Order[] = [];
    materials = new wjcCore.CollectionView([]);
    materialsGrid: wjcGrid.FlexGrid;
    orderId: string;
    siteId: string;
    loginUserData: any = {};
    orderData: any = {};
    userData = {};
    cloneData: any = {};
    warningList = [];

    statusMap: wjcGrid.DataMap;
    unitMap: wjcGrid.DataMap;
    arr = Array;
    requestDate: Date;
    requestTime: string;
    timeData: any = [];
    queryParam: string;

    private unitList = [];
    statusList = Constant.MATERIAL_STATUS_STR_LIST;
    statusSelectableList = [];
    relationLabel = [];
    // ポップアップメッセージ用宣言
    popup = {};

    relationCompanies: any = [];
    relationMails: any = [];

    periodStart: string;
    periodEnd: string;
    noLiftingDays: Order;
    currentRowsStatus: string;

    materialsUnitList = [];
    makerUnitList = [];
    partsNameUnitList = [];
    partsNumberUnitList = [];
    relationMailList = [];
    relationCompaniesList = [];

    isLoading = false;
    isDisabled = false;
    isRequestDateDisabled = false;
    validateCheck = true;
    detailReadOnly: boolean;

    // 定数取得
    constant = Constant;

    situationCheckerResult: any;
    situationCheckerDetailResult: any;

    // エラー表示
    public errorMessage: string;
    public errorProperty = 'none';
    public requestDateProperty = 'none';
    // エラーバリエーション定義
    warningVariation = [
        { id: 1, message: "数量は半角数字で入力してください。" },
        { id: 2, message: "メールアドレスを正しく入力してください。" },
        { id: 3, message: "資材情報を入力してください。" },
        { id: 4, message: "資材情報に未入力項目があります。" },
        { id: 5, message: "電話番号を正しい形式で入力してください。" }
    ];

    @ViewChild('customDateTime', { static: true }) customDateTime: wjcInput.ComboBox;
    @ViewChild('theDate', { static: false }) calendarItem: wjcInput.InputDate;

    private orderableDateList;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private orderService: OrderService,
        private commonService: CommonService,
        private cookieService: CookieService,
        public changeDetectorRef: ChangeDetectorRef,
        public situationChecker: GeneralSituationCheckerService,
        public orderStatusService: OrderStatusService,
        private siteService: SiteService,
        private datePipe: DatePipe) {
        this.situationCheckerResult = {};
        this.situationCheckerDetailResult = {};

        // APIから取ってきた後の動きを定義(納入の詳細を取ってきたときだけ使われる)
        this.apiData.subscribe(apidata => {
            this.orderData = apidata;

            this.situationCheckerResult = this.situationChecker.getOrderHeaderStatus(this.loginUserData, this.orderData);

            this.relationMails = this.orderData.relationMails;
            if (this.orderData.relationMails.length > 0) {
                this.relationMails = this.orderData.relationMails;
            } else {
                this.relationMails.push({ mail: '' });
            }

            apidata.relationCompanies.forEach((value) => {
                this.relationCompanies[value.hierarchy] = value.companyName;
            });

            if (this.cloneData) {
                this.materials.sourceCollection = this.cloneData.materials.map((data) => {
                    const materialsData = {
                        maker: data.maker,
                        partsName: data.partsName,
                        partsNumber: data.partsNumber,
                        quantity: data.quantity,
                        unit: data.unit
                    };
                    return materialsData;
                });
            } else {
                // 日時計算
                this.dateTimeConversion(this.orderData.requestDateTime);

                this.materials.sourceCollection = JSON.parse(JSON.stringify(apidata.materials));
                this.materials.sourceCollection = this.materials.sourceCollection.map(data => {
                    data.sel = false;
                    // ステータスを文字列に変換
                    data.status = this.orderStatusService.toStrings(data.status);
                    // 揚重申請締切済みのものがあれば揚重申請Noに（締切済み）を付ける
                    if (data.liftingNumber && data.wasClosed) {
                        data.liftingNumber = data.liftingNumber + '（締切済み）';
                    }
                    // 発注資材の確定日を文字列から日付型に変換
                    if (data.confirmDateTime) {
                        data.confirmDateTime = new Date(data.confirmDateTime);
                    }
                    return data;
                });

                // 資材が一つもない場合はselのみ追加
                if (this.materials.sourceCollection.length === 0) {
                    this.materials.sourceCollection.push({
                        sel: false
                    });
                }
                this.materialsGrid.refresh();

            }

            // 項目の編集可否制限
            this.isDisabled = !this.situationCheckerResult.EditInputArea;
            this.isRequestDateDisabled = !this.situationCheckerResult.canEditReauestDate;

            // 新規行の追加可否判定
            this.materialsGrid.allowAddNew = this.situationCheckerResult.detailAddRow;

            // 参照のみの判定
            this.detailReadOnly = this.situationCheckerResult.detailReadOnly;
            console.log(this.detailReadOnly);
            this.materialsGrid.isReadOnly = this.detailReadOnly;

            // 手配ステータスが空欄もしくは未手配以外のものは変更不可
            this.materialsIsChange(this.materialsGrid);

            this.changeDetectorRef.detectChanges();
        });

    }

    // サーバー時間の今日を元にした納入依頼指定可能日の一覧を返す
    isOrderableDate(target: string) {
        if (this.orderableDateList && this.orderableDateList.indexOf(target) !== -1) {
            return true;
        } else {
            return false;
        }
    }

    // 日付取得関数
    getDefaultOrderableDate() {
        return new Date();
    }

    // ページに来た時に走る
    ngOnInit() {
        // URLからIDを取得
        this.siteId = this.commonService.getRoutedSiteFromPath() !== '' ? this.commonService.getRoutedSiteFromPath() : '';
        this.orderId = this.route.snapshot.paramMap.get('id');

        this.cloneData = this.orderService.getCreateData(); // コピーされたデータ持ってくる

        // API呼び出し
        this.callLoggedIn();

        // 単位リストの取得(ブラウザのローカル)
        const units = this.cookieService.get('unitList');
        if (units !== '') {
            this.unitList = JSON.parse(units);
        } else {
            this.unitList = ['個', '本'];
        }

        // 請負元社の入力補助：LocalStorage
        this.relationCompaniesList = JSON.parse(localStorage.getItem('relationCompaniesList'));
        // ソート
        if (this.relationCompaniesList != null && this.relationCompaniesList.length !== 0) {
            this.relationCompaniesList.sort(function (val1, val2) {
                return (val1.added < val2.added ? 1 : -1);
            });
        }

        // grid用データマップ
        this.statusSelectableList = this.orderStatusService.getSelectableList();
        this.statusMap = new wjcGrid.DataMap(this.statusSelectableList);
        this.unitMap = new wjcGrid.DataMap(this.unitList);

        this.requestDate = new Date();
    }

    // 発注資材情報Gridの初期化
    initializeGrid(flex: wjcGrid.FlexGrid) {
        this.materialsGrid = flex;

        // 単位の入力補助：LocalStorage
        if (localStorage.getItem('materialsUnitList')) {
            this.materialsUnitList = JSON.parse(localStorage.getItem('materialsUnitList'));
        } else {
            this.materialsUnitList = [{ label: '個', value: '個', added: 0 }, { label: '本', value: '本', added: 1 }];
            localStorage.setItem('materialsUnitList', JSON.stringify(this.materialsUnitList));
        }
        // ソート
        this.materialsUnitList.sort(function (val1, val2) {
            return (val1.added < val2.added ? 1 : -1);
        });

        // メーカーの入力補助：LocalStorage
        this.makerUnitList = JSON.parse(localStorage.getItem('makerUnitList'));
        // ソート
        if (this.makerUnitList != null && this.makerUnitList.length !== 0) {
            this.makerUnitList.sort(function (val1, val2) {
                return (val1.added < val2.added ? 1 : -1);
            });
        }

        // 品名の入力補助：LocalStorage
        this.partsNameUnitList = JSON.parse(localStorage.getItem('partsNameUnitList'));
        // ソート
        if (this.partsNameUnitList != null && this.partsNameUnitList.length !== 0) {
            this.partsNameUnitList.sort(function (val1, val2) {
                return (val1.added < val2.added ? 1 : -1);
            });
        }

        // 品番・規格の入力補助：LocalStorage
        this.partsNumberUnitList = JSON.parse(localStorage.getItem('partsNumberUnitList'));
        // ソート
        if (this.partsNumberUnitList != null && this.partsNumberUnitList.length !== 0) {
            this.partsNumberUnitList.sort(function (val1, val2) {
                return (val1.added < val2.added ? 1 : -1);
            });
        }

        new CustomGridEditor(flex, 'unit', wjcInput.AutoComplete, {
            displayMemberPath: 'label',
            itemsSource: this.materialsUnitList
        }, 10);
        const maker = new CustomGridEditor(flex, 'maker', wjcInput.AutoComplete, {
            displayMemberPath: 'label',
            itemsSource: this.makerUnitList
        }, 200);
        new CustomGridEditor(flex, 'partsName', wjcInput.AutoComplete, {
            displayMemberPath: 'label',
            itemsSource: this.partsNameUnitList
        }, 200);
        new CustomGridEditor(flex, 'partsNumber', wjcInput.AutoComplete, {
            displayMemberPath: 'label',
            itemsSource: this.partsNumberUnitList
        }, 200);

        // 編集モードに入る前にセルの情報を取得する
        flex.beginningEdit.addHandler((s: wjcGrid.FlexGrid, e: wjcGrid.CellRangeEventArgs) => {
            const r = s.rows[e.row], c = s.columns[e.col];
            this.currentRowsStatus = r.dataItem.status;
        })
        // 編集完了直前イベントにバリデートを入れる
        flex.cellEditEnding.addHandler((s: wjcGrid.FlexGrid, e: wjcGrid.CellEditEndingEventArgs) => {
            const c = s.columns[e.col];
            if (c && c.binding === 'quantity') {
                // let value = wjcCore.changeType(s.activeEditor.value, wjcCore.DataType.Number, c.format);
                const value = s.activeEditor.value;
                // 0〜9の整数のみ
                const pattern = /^([0-9]\d*|0)$/;
                if (value && !pattern.test(value)) {
                    // e.cancel = true;
                    // ID1: 数量は半角数字で入力してください。
                    this.displayWarning(1);
                } else {
                    this.clearWarning(1);
                }
            }
            else if (c && c.binding === 'status') {
                const value = s.activeEditor.value;
                // 編集前後で値が同じなら何もしない
                if (this.currentRowsStatus === value) {
                    return;
                } else {
                    // 値が異なっていたら、変更可能なステータスかチェックする
                    const beforeStutas = this.orderStatusService.toStatus(this.currentRowsStatus);
                    const afterStatus = this.orderStatusService.toStatus(value);
                    const result = this.orderStatusService.isChangeable(beforeStutas, afterStatus);
                    // 判定がFALSEの場合は、ポップアップを表示して元に戻す
                    if (!result) {
                        e.cancel = true;
                        // ポップアップメッセージ
                        this.popup = { header: 'ステータス変更エラー', body: 'このステータスには変更できません。' };
                        this.orderingPopup.show(true, (senderNext: wjcInput.Popup) => { });
                    }
                }
            }
        });

        // 編集完了時にオートコンプリートリストにモノを追加
        flex.cellEditEnded.addHandler((s: wjcGrid.FlexGrid, e: wjcGrid.FormatItemEventArgs) => {
            const r = s.rows[e.row];
            const c = s.columns[e.col];
            if (!r || !c) {
                return;
            }
            if (c.binding === 'unit') {
                const item = r.dataItem.unit;
                this.addNewMaterialsUnit(item);
            } else if (c.binding === 'maker') {
                const item = r.dataItem.maker;
                this.addNewMaterialsMaker(item);
            } else if (c.binding === 'partsName') {
                const item = r.dataItem.partsName;
                this.addNewMaterialsPartsName(item);
            } else if (c.binding === 'partsNumber') {
                const item = r.dataItem.partsNumber;
                this.addNewMaterialsPartsNumber(item);
            }
        });

        // カラムに応じてクイック編集をかける(数量のみ)
        flex.selectionChanged.addHandler((s, e) => {
            if (s.columns[e['col']].binding === 'quantity') {
                setTimeout(() => {
                    flex.startEditing(false);
                }, 50);
            }
        });

        // グリッドのフォーマット設定
        flex.formatItem.addHandler((s: wjcGrid.FlexGrid, e: wjcGrid.FormatItemEventArgs) => {
            if (s.rows.length === 0 || s.columns.length === 0) {
                return;
            }

            // グリッドのデフォルト高さを設定
            flex.rows.defaultSize = 35;

            // ステータスが「取消し」の明細行は背景色を灰色にする
            for (let i = 0; i < flex.rows.length; i++) {
                const data = flex.rows[i].dataItem;
                const checkStatus = this.constant.MATERIAL_STATUS_STR_LIST[this.constant.ORDER_STATUS_LIST.STATUS_CANCELED];
                if (data !== undefined && data.status == checkStatus) {
                    flex.rows[i].cssClass = "bg-secondary"
                }
            }

            // ステータスが未手配の行にキャンセルと削除ボタンを表示
            if (e.panel !== s.columnHeaders && s.columns[e.col].binding === 'recodeRemove') {
                const detailCheclResult = this.situationChecker.getOrderDetailStatus(this.loginUserData, this.orderData, e.row);
                const deleteRow = detailCheclResult.deleteRow;
                const CancelRow = detailCheclResult.CancelRow;

                if (!this.detailReadOnly) {
                    if (deleteRow) {
                        e.cell.innerHTML = '<div class="btn btn-outline-dark px-3 py-0 removeBtn">削除</div>';
                    } else if (CancelRow) {
                        e.cell.innerHTML = '<div class="btn btn-outline-dark px-3 py-0 removeBtn">キャンセル</div>';
                    } else {
                        e.cell.innerHTML = '<p class="mb-0">-</p>';
                    }
                } else {
                    e.cell.innerHTML = '<p class="mb-0">-</p>';
                }
            }

            // 揚重申請Noをテキストリンクにする
            if (e.panel !== s.columnHeaders && s.columns[e.col].binding === 'liftingNumber') {
                const data = s.rows[e.row].dataItem;
                if (data !== undefined && data.liftingNumber) {
                    e.cell.innerHTML = '<a href="/' + this.loginUserData.site.id +
                        '/lifting/' + data.liftingId + '" target="_blank">' + data.liftingNumber + '</a>';
                }
            }

            // 行選択のクラス設定
            if (e.panel !== s.columnHeaders && s.rows[e.row].dataItem !== undefined) {
                wjcCore.setAttribute(
                    e.cell.parentElement,
                    'aria-selected',
                    s.rows[e.row].dataItem.sel
                );
            }

        });

        // グリッドセルのクリックイベント検知
        flex.hostElement.addEventListener('click', (e: MouseEvent) => {
            const view = flex.collectionView;
            const currentCell = this.materialsGrid.hitTest(e.pageX, e.pageY);
            const detailCheckResult = this.situationChecker.getOrderDetailStatus(this.loginUserData, this.orderData, currentCell.row);
            const deleteRow = detailCheckResult.deleteRow;
            const CancelRow = detailCheckResult.CancelRow;

            // 削除ボタンを押したときの制御
            if ((e.target as HTMLElement).classList.contains('removeBtn')) {
                // prevent the grid from getting the key
                e.preventDefault();

                if (deleteRow) {
                    // ポップアップメッセージ
                    this.popup = { header: '行の削除', body: '本当にこの行を削除しますか？' };
                    // confirm row deletion
                    this.thePopup.show(true, (sender: wjcInput.Popup) => {
                        // delete the row
                        if (sender.dialogResult === 'wj-hide-ok') {
                            (view as wjcCore.CollectionView).remove(view.currentItem);
                        }
                    });
                } else if (CancelRow) {
                    // ポップアップメッセージ
                    this.popup = { header: 'キャンセル', body: '本当にこの行をキャンセルしますか？' };
                    this.thePopup.show(true, (sender: wjcInput.Popup) => {
                        if (sender.dialogResult === 'wj-hide-ok') {
                            // 押された資材のステータスを2（取り消し）にする。
                            view.currentItem.status = this.statusList[2];
                            flex.refresh(true);
                            this.changeDetectorRef.detectChanges();
                            // console.log(this.orderData.materials);
                        }
                    });
                }
            }
        }, true);
    }

    callLoggedIn() {
        this.isLoading = true;
        const topPath = this.siteId !== '' ? this.siteId + '/login' : 'login';
        this.commonService.userLoggedIn(this.siteId).subscribe(
            (userData: User) => {
                this.isLoading = false;
                console.log(userData);
                this.loginUserData = userData;

                // 現場ベースの情報取得
                this.relationLabel = this.loginUserData.site.relationName;
                // カスタムInputTimeを作成
                const startTime = this.loginUserData.site.startLiftingHour;
                this.timeData = this.commonService.customInputTime(startTime);
                // 開始日/終了日を設定
                this.periodStart = this.loginUserData.site.periodStart;
                this.periodEnd = this.loginUserData.site.periodEnd;
                this.noLiftingDays = this.loginUserData.site.noLiftingDays;

                this.situationCheckerResult = this.situationChecker.getOrderHeaderStatus(this.loginUserData, this.orderData);

                this.callOrderAPI();
            },
            (error) => {
                this.isLoading = false;
                // ログイン情報がない場合は、ルートに遷移
                console.log(error);
                this.router.navigate([topPath]);
            }
        );
    }

    callOrderableDates() {
        // 納入依頼期限日設定
        this.siteService.getOrderableDates(this.siteId).subscribe(data => {
            this.orderableDateList = data.dates;

            if (!this.orderId) {
                if (data.dates && data.dates.length > 0) {
                    this.requestDate = new Date(data.dates[0]); // 初期値で希望日を今日として持ち始める
                } else {
                    this.requestDate = new Date();
                }
            }

            this.getMessage();
            // ユーザー情報を取得した後にカレンダーを初期化
            // this.customizeCalendar(this.calendarItem.calendar);
            this.changeDetectorRef.detectChanges();
        }, error => {
            // this.getErrorDetail(error);
        });
    }


    /*
     * APIデータ呼び出し(納入依頼の詳細を取る)
    */
    private callOrderAPI() {
        if (this.orderId) {
            const id = Number(this.orderId);
            this.isLoading = true;
            this.orderService.getOrder(id).subscribe(
                (data: Order) => {
                    this.isLoading = false;
                    console.log('更新: ', data);
                    this.apiData.next(data);

                    this.callOrderableDates();
                },
                (error) => {
                    this.orderId = null;
                    this.isLoading = false;
                    this.displayError(this.commonService.getErrorDetail(error));
                }
            );
        } else {
            if (this.cloneData && Object.keys(this.cloneData).length > 0) {
                console.log('コピー: ', this.cloneData);
                this.apiData.next(this.cloneData);
            }
            this.orderData.companyName = this.loginUserData.companyName;
            this.orderData.companyAddress = this.loginUserData.companyAddress;
            this.orderData.tel = this.loginUserData.tel;
            this.orderData.fax = this.loginUserData.fax;
            this.orderData.personName = this.loginUserData.name;
            this.orderData.mobile = this.loginUserData.mobile;
            if (localStorage.getItem('recent_request_section')) {
                this.orderData.requestSection = localStorage.getItem('recent_request_section');
            }
            if (localStorage.getItem('recent_consignee_name')) {
                this.orderData.consigneeName = localStorage.getItem('recent_consignee_name');
            }
            if (localStorage.getItem('recent_consignee_mobile')) {
                this.orderData.consigneeMobile = localStorage.getItem('recent_consignee_mobile');
            }

            // 項目の編集可否制限
            this.isDisabled = !this.situationCheckerResult.EditInputArea;
            this.isRequestDateDisabled = !this.situationCheckerResult.canEditReauestDate;

            // 新規行の追加可否判定
            this.materialsGrid.allowAddNew = this.situationCheckerResult.detailAddRow;

            // 参照のみの判定
            this.detailReadOnly = this.situationCheckerResult.detailReadOnly;
            this.materialsGrid.isReadOnly = this.detailReadOnly;

            this.callOrderableDates();
        }
    }

    // 禁止日選択時のメッセージ
    private getMessage() {
        // console.log(this.requestDate);
        // //console.log(this.loginUserData);
        // // 禁止日の設定の基準は今日のため、開始日を今日に設定
        // let today = this.commonService.getDateTime();
        // let periodStart = today.getFullYear() + '/' + (today.getMonth()+1) + '/' + today.getDate();
        // let options = {
        //    dueDate: this.loginUserData.site.orderDueDate,
        //    dueDateType: this.loginUserData.site.orderDueDateType,
        //    dueWeek: this.loginUserData.site.orderDueWeek,
        //    dueDayOfWeek: this.loginUserData.site.orderDueDayOfWeek
        // };
        // let isEnableDate = this.commonService.isEnableDate(this.requestDate,
        // periodStart, this.loginUserData.site.periodEnd, this.loginUserData.site.noLiftingDays, options);
        // console.log(new Date(this.loginUserData.site.periodEnd));
        const today = new Date(this.requestDate);
        const year = today.getFullYear();
        const month = ('0' + (today.getMonth() + 1)).slice(-2);
        const day = ('0' + today.getDate()).slice(-2);
        const target = year + '-' + month + '-' + day;

        // ※ 日付チェックの判断おかしくないか？
        const isEnableDate: boolean = this.isOrderableDate(target);
        const ReauestDateValidate = this.situationCheckerResult.ReauestDateValidate;
        if (!isEnableDate && ReauestDateValidate) {
            this.requestDateProperty = '';
            this.validateCheck = false;
        } else {
            this.requestDateProperty = 'none';
            this.validateCheck = true;
        }
    }

    // 初期化：関係メールアドレス用Gridの初期化
    initializeRelationMailsGrid(flex: wjcGrid.FlexGrid) {
        // 関係メールアドレスの入力補助：LocalStorage
        this.relationMailList = JSON.parse(localStorage.getItem('relationMailList'));
        // ソート
        if (this.relationMailList != null && this.relationMailList.length !== 0) {
            this.relationMailList.sort(function (val1, val2) {
                return (val1.added < val2.added ? 1 : -1);
            });
        }
        console.log(this.relationMailList);

        new CustomGridEditor(flex, 'mail', wjcInput.AutoComplete, {
            displayMemberPath: 'label',
            itemsSource: this.relationMailList
        }, 200);

        flex.cellEditEnded.addHandler((s: wjcGrid.FlexGrid, e: wjcGrid.FormatItemEventArgs) => {
            const r = s.rows[e.row];
            const c = s.columns[e.col];
            if (r && c && c.binding === 'mail') {
                const item = r.dataItem.mail;
                if (this.commonService.validateEmailAddress(item)) {
                    this.addNewRelationMail(item);
                    this.validateCheck = true;
                    this.clearWarning(2);
                } else {
                    e.cancel = true;
                    this.validateCheck = false;
                    // ID2: メールアドレスを正しく入力してください。
                    this.displayWarning(2);
                }
            }
        });
    }

    /* エラー表示 */
    private displayError(message: string) {
        this.errorMessage = message;
        this.errorProperty = '';
        this.changeDetectorRef.detectChanges();
    }
    private clearError() {
        this.errorProperty = 'none';
        this.changeDetectorRef.detectChanges();
    }
    private displayWarning(id: number) {
        // すでに表示されていたらリストに追加しない
        if (this.warningList.some(list => list.id == id)) {
            return;
        }
        this.warningList = this.warningList.concat(this.warningVariation.filter(error => error.id == id));
    }
    private clearWarning(id: number) {
        this.warningList.some((error, index) => { if (error.id == id) this.warningList.splice(index, 1) });
    }

    /* ステータス判定 */
    get canCreateYoju() {
        return this.situationCheckerResult.CreateLifting;
    }

    get showOrder() {
        return this.situationCheckerResult.Order;
    }

    get showSave() {
        return this.situationCheckerResult.Save;
    }

    get showDelete() {
        return this.situationCheckerResult.Delete;
    }

    get showCancel() {
        return this.situationCheckerResult.Cancel;
    }

    get showCopy() {
        return this.situationCheckerResult.Copy;
    }

    get orderNoOpen() {
        return this.situationCheckerResult.orderNoOpen;
    }

    /*
    * Function: changeStatus
    * 手配状況一括変更の値を選択行の手配状況セルに反映する
    */
    private changeStatus(combo: wjcInput.ComboBox, grid: wjcGrid.FlexGrid) {
        const items = grid.itemsSource.sourceCollection;
        // null選択できないようにisRequired=TRUEにセット
        if (combo.selectedIndex !== -1) {
            combo.isRequired = true;
        }
        // 変更可能かチェック
        let checkResultList = [];
        items.forEach((e, index) => {
            // 選択行のみ実行
            if (e.sel) {
                // 変更前後のステータスをチェック
                let beforeStatus = this.orderStatusService.toStatus(e.status);
                let afterStatus = this.orderStatusService.toStatus(combo.selectedValue);
                let checkResult = this.orderStatusService.isChangeable(beforeStatus, afterStatus);
                checkResultList[index] = checkResult;
            }
        })
        // isChangeable=FALSEが含まれていたらステータス変更をキャンセル
        if (checkResultList.includes(false)) {
            // ポップアップメッセージ
            this.popup = { header: 'ステータス変更エラー', body: '変更できないステータスが含まれています。' };
            this.orderingPopup.show(true, (senderNext: wjcInput.Popup) => { });
        } else {
            // すべてTRUEならステータスを変更する
            items.forEach((e, index) => {
                if (e.sel) {
                    e.status = combo.selectedValue;
                }
            })
        }
        grid.refresh();
    }
    /*
    * Function: dateTimeConversion
    * 納入希望日時を現場作業時間に合わせて変換する
    * @param {String} date 納入希望日時
    */
    private dateTimeConversion(date: string) {
        const request = new Date(date);

        const startTime = this.loginUserData.site.startLiftingHour;
        const split = startTime.split(':');
        const hour = Number(split[0]);
        const minuit = Number(split[1]);

        if (request.getHours() < hour) {
            // 日付を一日前にして時間を24hプラスする
            request.setDate(request.getDate() - 1);
            this.requestDate = request;
            const setTime = request.getHours() + 24 + ':' + ('0' + request.getMinutes()).slice(-2);
            this.requestTime = setTime;
        } else {
            // 24時間以内の指定であれば、そのまま表示
            this.requestDate = request;
            const setTime = request.getHours() + ':' + ('0' + request.getMinutes()).slice(-2);
            this.requestTime = setTime;
        }
    }


    /*
    * カレンダーカスタマイズ
    */

    // カレンダースタイル設定
    customizeCalendar(calendar: wjcInput.Calendar) {
        console.log(this.situationCheckerResult);
        calendar.formatItem.addHandler((sender: wjcInput.Calendar, e: wjcInput.FormatItemEventArgs) => {
            const weekday = e.data.getDay();
            const today = e.data;
            let isEnableDate: boolean;
            if (this.situationCheckerResult.ReauestDateValidate) {
                // 野原顧客の場合は、締切日を考慮した選択可否をカレンダーに反映
                const year = today.getFullYear();
                const month = ('0' + (today.getMonth() + 1)).slice(-2);
                const day = ('0' + today.getDate()).slice(-2);
                const target = year + '-' + month + '-' + day;
                isEnableDate = this.isOrderableDate(target);
            } else {
                // それ以外は、工期・過去・休日・禁止日をカレンダーに反映
                const user = this.loginUserData;
                isEnableDate = this.commonService.isEnableDate(today, this.periodStart, this.periodEnd, this.noLiftingDays);
            }

            wjcCore.toggleClass(e.item, 'date-invalid', !isEnableDate);
            wjcCore.toggleClass(e.item, 'date-sunday', weekday == 0);
            wjcCore.toggleClass(e.item, 'date-saturday', weekday == 6);
        });
    }

    /*****/

    // 初期化：文字数制限
    initializeWordCount(input, num) {
        input.inputElement.maxLength = num;
    }

    /*
     * 資材情報の編集不可判定
     */
    materialsIsChange(grid: wjcGrid.FlexGrid) {
        const items = grid.itemsSource.sourceCollection;
        if (!this.detailReadOnly) {
            items.forEach((e, index) => {
                // ステータスだけ編集可能
                const rowsStatus = this.situationChecker.getOrderDetailStatus(this.loginUserData, this.orderData, index);
                const OnlyStatusChange = rowsStatus.OnlyStatusChange;
                // 明細編集可能
                const isEditable = rowsStatus.Edit;

                if (OnlyStatusChange) {
                    // メーカーから単位までを編集不可にする
                    for (let i = 1; i <= 5; i++) {
                        grid.columns[i].isReadOnly = true;
                    }
                    // 手配ステータスは編集可能
                    grid.columns[13].isReadOnly = false;
                } else {
                    if (!isEditable) {
                        grid.rows[index].isReadOnly = true;
                    }
                }

                // if (e.status && e.status !== '') {
                //   // 手配担当の場合はステータスとチェックボックスのみ操作できる
                //   if (this.loginUserData.authority === this.constant.AUTHORITY_ARRANGE_STAFF) {
                //     if (e.status === '納入済み') {
                //       grid.rows[index].isReadOnly = true;
                //     } else {
                //       // メーカーから単位までを編集不可にする
                //       for (let i = 1; i <= 5; i++) {
                //         grid.columns[i].isReadOnly = true;
                //       }
                //     }
                //   } else {
                //     // それ以外は未手配の場合は行ごと操作不可にする
                //     if (e.status !== '未手配') {
                //       grid.rows[index].isReadOnly = true;
                //     }
                //   }
                // }
            });
        }
        grid.refresh();
    }

    /*
     * 揚重申請へのリンク
     */
    private createYoujuNew() {
        sessionStorage.removeItem('liftingData');
        window.open('/' + this.loginUserData.site.id + '/lifting/new?order=true&orderId=' + this.orderId, '_blank');
    }

    /*
     * キャンセルデータ作成
     */
    // private submitDataCancel() {
    //     const arrayRelationCompanies = [];

    //     // relationCompanies データ作成
    //     this.relationCompanies.forEach((value, index) => {
    //         arrayRelationCompanies[index] = {
    //             companyName: value,
    //             hierarchy: index
    //         };
    //     });

    //     this.orderData.siteId = this.siteId;
    //     this.orderData.orderDate = this.commonService.getDateTime().toLocaleDateString();
    //     this.orderData.relationCompanies = arrayRelationCompanies;

    //     // 資材のステータスを取り消しに
    //     this.orderData.materials = this.materials.sourceCollection.map(x => Object.assign({}, x));
    //     this.orderData.materials.forEach(val => {
    //         val.status = 2;
    //     });

    //     this.orderData.requestDateTime = this.requestDate.toLocaleDateString() + ' ' + this.customDateTime.selectedItem;
    //     return this.orderData;
    // }

    /*
     * ユーザー情報のCRUD操作
     */
    private submitDataCreate() {
        const arrayRelationCompanies = [];
        // relationCompanies データ作成
        this.relationCompanies.forEach((value, index) => {
            // 空白が入力されている場合は除外する
            if (value != '') {
                arrayRelationCompanies.push({
                    companyName: value,
                    hierarchy: index
                });
            }
        });

        this.orderData.siteId = this.siteId;
        this.orderData.orderDate = this.commonService.getDateTime().toLocaleDateString();
        this.orderData.relationCompanies = arrayRelationCompanies;

        if (this.orderData.requestSection) {
            localStorage.setItem('recent_request_section', this.orderData.requestSection);
        }
        if (this.orderData.consigneeName) {
            localStorage.setItem('recent_consignee_name', this.orderData.consigneeName);
        }
        if (this.orderData.consigneeMobile) {
            localStorage.setItem('recent_consignee_mobile', this.orderData.consigneeMobile);
        }

        // メールアドレスの空行を削除
        const address = this.relationMails.filter((s) => {
            return (s.mail !== null && s.mail !== undefined && s.mail !== '');
        });
        this.orderData.relationMails = address;
        /*
         * 発注資材処理
         */
        // 2021.1.21 stringifyはDate型をUTCにしてしまう問題対策
        let dp = this.datePipe;
        const _materials = JSON.parse(JSON.stringify(this.materials.sourceCollection, function (key, val) {
            if (this[key] instanceof Date) {
                return dp.transform(new Date(val), 'yyyy/MM/dd HH:mm');
            }
            return val;
        }));
        this.orderData.materials = [];
        if (_materials.length > 0) {
            _materials.forEach(s => {
                if (s.maker || s.partsName || s.partsNumber || s.quantity || s.unit) {
                    // どれか一つでも入っていればデータを格納
                    // ステータス変換
                    // s.status = this.statusList.indexOf(s.status);
                    s.status = this.orderStatusService.toStatus(s.status);
                    // 揚重申請Noに（締切済み）の文字があればカットする
                    if (s.liftingNumbe && s.liftingNumbe.indexOf('（締切済み）') !== -1) {
                        s.liftingNumber = s.liftingNumber.replace('（締切済み）', '');
                    }
                    // 初期値設定
                    if (!this.orderId) {
                        s.liftingNumber = '';
                        s.status = this.constant.ORDER_STATUS_LIST.STATUS_ADD_NEW;
                        s.cargoIndex = 0;
                    }
                    this.orderData.materials.push(s);
                } else {
                    // 上記どれも入っていなければ空行と判定
                    return false;
                }
            });
        }
        /*****/

        this.orderData.requestDateTime = this.requestDate.toLocaleDateString() + ' ' + this.customDateTime.selectedItem;
        return this.orderData;
    }

    // データ作成・更新
    private createData(): void {
        const createData = this.submitDataCreate();
        console.log(createData);

        // 資材情報の入力チェック
        let materialsCheck: boolean = createData.materials.every(val =>
            (val.maker && val.partsName && val.partsNumber && val.quantity && val.unit)
        );

        if (!createData.materials || createData.materials.length === 0) {
            // ID3: 資材情報を入力してください。
            this.displayWarning(3);
            return;
        } else if (!materialsCheck) {
            // ID4: 資材情報に未入力項目があります。
            this.displayWarning(4);
            this.clearWarning(3);
        } else {
            this.clearWarning(3);
            this.clearWarning(4);
            this.isLoading = true;
            this.orderService.createOrder(createData).subscribe(
                () => {
                    this.isLoading = false;
                    this.router.navigate([this.siteId + '/order']);
                },
                (error) => {
                    this.isLoading = false;
                    this.displayError(this.commonService.getErrorDetail(error));
                });
        }
    }

    // キャンセル
    private cancelData() {
        this.popup = { header: '納入依頼', body: '納入依頼の発注をキャンセルします。よろしいですか？', };
        this.thePopup.show(true, (sender: wjcInput.Popup) => {
            // delete the row
            if (sender.dialogResult === 'wj-hide-ok') {

                const createData = this.submitDataCreate();
                console.log(createData);

                // 資材のステータスを取り消しに
                createData.materials.forEach(val => {
                    val.status = this.constant.ORDER_STATUS_LIST.STATUS_CANCELED;
                });

                this.isLoading = true;
                this.orderService.orderingOrder(createData).subscribe(
                    () => {
                        this.isLoading = false;
                        this.router.navigate([this.siteId + '/order']);
                    },
                    (error) => {
                        this.isLoading = false;
                        this.displayError(this.commonService.getErrorDetail(error));
                    });
            }
        });
    }

    // データ削除
    private deleteData() {
        // ポップアップメッセージ
        this.popup = { header: '納入依頼削除', body: 'この納入依頼を削除しますか？', };
        // confirm row deletion
        this.thePopup.show(true, (sender: wjcInput.Popup) => {
            // delete the row
            if (sender.dialogResult === 'wj-hide-ok') {
                this.isLoading = true;
                this.orderService.deleteOrder(this.orderId).subscribe(
                    () => {
                        this.isLoading = false;
                        this.router.navigate([this.siteId + '/order']);
                    },
                    (error) => {
                        this.isLoading = false;
                        this.displayError(this.commonService.getErrorDetail(error));
                    });
            }
        });
    }

    // 納入依頼をコピーして登録
    private clone() {
        // ポップアップメッセージ
        this.popup = { header: '納入依頼複製', body: '内容をコピーして納入依頼を作成します。よろしいですか？', };
        this.thePopup.show(true, (sender: wjcInput.Popup) => {
            // delete the row
            if (sender.dialogResult === 'wj-hide-ok') {
                // 送信データ作成
                const createData = this.submitDataCreate();
                // 必要なものだけピックアップ
                const copyData = {
                    consigneeName: createData.consigneeName,
                    consigneeMobile: createData.consigneeMobile,
                    relationCompanies: createData.relationCompanies,
                    relationMails: createData.relationMails,
                    materials: createData.materials
                };

                // 資材のステータスをリセットする
                copyData.materials.forEach(val => {
                    val.status = this.constant.ORDER_STATUS_LIST.STATUS_ADD_NEW;
                });

                // 作成データを渡す
                this.orderService.setCreateData(copyData);
                // データを保持したまま新規ページを開く
                this.router.navigate([this.siteId + '/order/create']);
            }
        });
    }

    /**
     * 納入依頼発注
     */
    orderingData() {
        const createData = this.submitDataCreate();

        if (!createData.materials || createData.materials.length === 0) {
            // 資材情報を入力してください
            this.displayWarning(3);
            return;
        }
        this.errorProperty = 'none';
        this.changeDetectorRef.detectChanges();

        // 資材のステータスを未手配に
        if (!this.orderId) {
            createData.materials.forEach(val => {
                val.status = this.constant.ORDER_STATUS_LIST.STATUS_NO_ORDER;
            });
        }

        console.log(createData);
        // ポップアップメッセージ
        if (this.situationCheckerResult.orderNoOpen || !this.orderId) {
            this.popup = { header: '納入依頼', body: '納入依頼を発注します。よろしいですか？', };
        } else {
            this.popup = { header: '納入依頼', body: '納入依頼を更新します。よろしいですか？', };
        }
        this.thePopup.show(true, (sender: wjcInput.Popup) => {
            if (sender.dialogResult === 'wj-hide-ok') {
                // 資材情報の入力チェック
                let materialsCheck: boolean = createData.materials.every(val =>
                    (val.maker && val.partsName && val.partsNumber && val.quantity && val.unit)
                );
                if (!materialsCheck) {
                    // ID4: 発注資材情報に未入力項目があります
                    this.displayWarning(4);
                    return;
                } else {
                    this.clearWarning(4);
                    this.isLoading = true;

                    this.orderService.orderingOrder(createData).subscribe(
                        () => {
                            this.isLoading = false;
                            this.errorProperty = 'none';
                            this.changeDetectorRef.detectChanges();
                            if (this.orderData.requestSection) {
                                localStorage.setItem('recent_request_section', this.orderData.requestSection);
                            }
                            if (this.orderData.consigneeName) {
                                localStorage.setItem('recent_congignee_name', this.orderData.consigneeName);
                            }
                            if (this.orderData.consigneeMobile) {
                                localStorage.setItem('recent_congignee_mobile', this.orderData.consigneeMobile);
                            }

                            // ポップアップメッセージ
                            if (this.situationCheckerResult.orderNoOpen || !this.orderId) {
                                this.popup = { header: '納入依頼発注', body: 'この納入依頼を発注しました。' };
                            } else {
                                this.popup = { header: '納入依頼', body: '更新が完了しました。' };
                            }
                            this.orderingPopup.show(false, (senderNext: wjcInput.Popup) => {
                                this.router.navigate([this.siteId + '/order']);
                            });
                        },
                        (error) => {
                            this.isLoading = false;
                            this.displayError(this.commonService.getErrorDetail(error));
                        }
                    );
                }

            }
        });
    }

    // 納入希望日無効設定
    // setDesabledDate(date, element){
    //   let commonService = new CommonService();
    //   let today = commonService.getDateTime();
    //   // 禁止日の設定の基準は今日のため、開始日を今日に設定
    //   let periodStart = today.getFullYear() + '/' + (today.getMonth()+1) + '/' + today.getDate();
    //   //let periodStart = user.site.periodStart;
    //   let periodEnd = user.site.periodEnd;
    //   let noLiftingDays = user.site.noLiftingDays;
    //   let orderDueDate = user.site.orderDueDate;
    //   let orderDueDateType = user.site.orderDueDateType;
    //   let orderDueWeek = user.site.orderDueWeek;
    //   let orderDueDayOfWeek = user.site.orderDueDayOfWeek;
    //   let isEnableDate = commonService.isEnableDate(date,
    //   periodStart, periodEnd, noLiftingDays, orderDueDate, orderDueDateType, orderDueWeek, orderDueDayOfWeek);
    //   //console.log(date, isEnableDate, noLiftingDays);
    //   if(!isEnableDate){
    //     element.style.backgroundColor = "gray";
    //   }else{
    //     element.style.backgroundColor = "";
    //   }
    // }

    // オートコンプリートのリスト追加：単位
    addNewMaterialsUnit(item) {
        if (!item) {
            return;
        }
        const res = this.commonService.getRowAutoComplite(this.materialsUnitList, item);
        console.log(res);
        if (res.data) {
            // リストに追加
            if (this.materialsUnitList != null) {
                this.materialsUnitList.push(res.data);
            } else {
                this.materialsUnitList = [res.data];
            }
        } else {
            // ソート順更新用
            this.materialsUnitList[res.update.index].added = res.update.added;
        }
        // 上限を超えたらaddedが低いものを削除
        if (this.materialsUnitList.length > this.commonService.limit) {
            console.log(this.materialsUnitList[res.min.index]);
            this.materialsUnitList.splice(res.min.index, 1);
        }
        // ソート
        this.materialsUnitList.sort(function (val1, val2) {
            return (val1.added < val2.added ? 1 : -1);
        });
        console.log(this.materialsUnitList);
        localStorage.setItem('materialsUnitList', JSON.stringify(this.materialsUnitList));
    }

    // オートコンプリートのリスト追加：メーカー
    addNewMaterialsMaker(item) {
        if (!item) {
            return;
        }
        const res = this.commonService.getRowAutoComplite(this.makerUnitList, item);
        console.log(res);
        if (res.data) {
            // リストに追加
            if (this.makerUnitList != null) {
                this.makerUnitList.push(res.data);
            } else {
                this.makerUnitList = [res.data];
            }
        } else {
            // ソート順更新用
            this.makerUnitList[res.update.index].added = res.update.added;
        }
        // 上限を超えたらaddedが低いものを削除
        if (this.makerUnitList.length > this.commonService.limit) {
            console.log(this.makerUnitList[res.min.index]);
            this.makerUnitList.splice(res.min.index, 1);
        }
        // ソート
        this.makerUnitList.sort(function (val1, val2) {
            return (val1.added < val2.added ? 1 : -1);
        });
        console.log(this.makerUnitList);
        localStorage.setItem('makerUnitList', JSON.stringify(this.makerUnitList));
    }

    // オートコンプリートのリスト追加：品名
    addNewMaterialsPartsName(item) {
        if (!item) {
            return;
        }
        const res = this.commonService.getRowAutoComplite(this.partsNameUnitList, item);
        console.log(res);
        if (res.data) {
            // リストに追加
            if (this.partsNameUnitList != null) {
                this.partsNameUnitList.push(res.data);
            } else {
                this.partsNameUnitList = [res.data];
            }
        } else {
            // ソート順更新用
            this.partsNameUnitList[res.update.index].added = res.update.added;
        }
        // 上限を超えたらaddedが低いものを削除
        if (this.partsNameUnitList.length > this.commonService.limit) {
            console.log(this.partsNameUnitList[res.min.index]);
            this.partsNameUnitList.splice(res.min.index, 1);
        }
        // ソート
        this.partsNameUnitList.sort(function (val1, val2) {
            return (val1.added < val2.added ? 1 : -1);
        });
        console.log(this.partsNameUnitList);
        localStorage.setItem('partsNameUnitList', JSON.stringify(this.partsNameUnitList));
    }

    // オートコンプリートのリスト追加：品番・規格
    addNewMaterialsPartsNumber(item) {
        if (!item) {
            return;
        }
        const res = this.commonService.getRowAutoComplite(this.partsNumberUnitList, item);
        console.log(res);
        if (res.data) {
            // リストに追加
            if (this.partsNumberUnitList != null) {
                this.partsNumberUnitList.push(res.data);
            } else {
                this.partsNumberUnitList = [res.data];
            }
        } else {
            // ソート順更新用
            this.partsNumberUnitList[res.update.index].added = res.update.added;
        }
        // 上限を超えたらaddedが低いものを削除
        if (this.partsNumberUnitList.length > this.commonService.limit) {
            console.log(this.partsNumberUnitList[res.min.index]);
            this.partsNumberUnitList.splice(res.min.index, 1);
        }
        // ソート
        this.partsNumberUnitList.sort(function (val1, val2) {
            return (val1.added < val2.added ? 1 : -1);
        });
        console.log(this.partsNumberUnitList);
        localStorage.setItem('partsNumberUnitList', JSON.stringify(this.partsNumberUnitList));
    }

    // オートコンプリートのリスト追加：関連メールアドレス
    addNewRelationMail(item) {
        if (!item) {
            return;
        }
        const res = this.commonService.getRowAutoComplite(this.relationMailList, item);
        console.log(res);
        if (res.data) {
            // リストに追加
            if (this.relationMailList != null) {
                this.relationMailList.push(res.data);
            } else {
                this.relationMailList = [res.data];
            }
        } else {
            // ソート順更新用
            this.relationMailList[res.update.index].added = res.update.added;
        }
        // 上限を超えたらaddedが低いものを削除
        if (this.relationMailList.length > this.commonService.limit) {
            console.log(this.relationMailList[res.min.index]);
            this.relationMailList.splice(res.min.index, 1);
        }
        // ソート
        this.relationMailList.sort(function (val1, val2) {
            return (val1.added < val2.added ? 1 : -1);
        });
        localStorage.setItem('relationMailList', JSON.stringify(this.relationMailList));
    }

    // オートコンプリートのリスト追加：請負元社
    addRelationCompanies(autoComplete, hierarchy) {
        console.log(autoComplete, hierarchy);
        const item = autoComplete.text;
        if (!item) {
            // 入力ありから空白にした場合にアップデートされない
            this.relationCompanies[hierarchy] = item;
            return;
        }
        const res = this.commonService.getRowAutoComplite(this.relationCompaniesList, item);
        console.log(res);
        if (res.data) {
            // リストに追加
            if (this.relationCompaniesList != null) {
                this.relationCompaniesList.push(res.data);
            } else {
                this.relationCompaniesList = [res.data];
            }
        } else {
            // ソート順更新用
            this.relationCompaniesList[res.update.index].added = res.update.added;
        }
        // 上限を超えたらaddedが低いものを削除
        if (this.relationCompaniesList.length > this.commonService.limit) {
            console.log(this.relationCompaniesList[res.min.index]);
            this.relationCompaniesList.splice(res.min.index, 1);
        }
        // ソート
        this.relationCompaniesList.sort(function (val1, val2) {
            return (val1.added < val2.added ? 1 : -1);
        });
        // console.log(this.relationCompaniesList);
        localStorage.setItem('relationCompaniesList', JSON.stringify(this.relationCompaniesList));

        // 入力値を反映
        this.relationCompanies[hierarchy] = item;
    }

    /*
     * バリデーションチェック
    */
    phonenumberValid(s) {
        console.log(s);
        let targetTel;
        if (s && s.text) {
            targetTel = s.text;
        } else {
            targetTel = s;
        }
        // const phoneNumber = parsePhoneNumberFromString(targetTel, 'JP');
        const phoneResult = this.commonService.validatePhoneNum(targetTel);
        if (!phoneResult) {
            console.log('バリデーションエラー');
            this.displayWarning(5);
            this.validateCheck = false;
        } else {
            console.log('チェックOK');
            this.clearWarning(5);
            this.validateCheck = true;
        }
    }
}
