import {Component, OnInit} from '@angular/core';
import {CommonService} from '../core/common.service';

@Component({
    selector: 'app-auth-redirect',
    templateUrl: './auth-redirect.component.html',
    styleUrls: ['./auth-redirect.component.scss']
})
export class AuthRedirectComponent implements OnInit {
    routedSiteId = '';

    constructor(private commonS: CommonService) {
    }

    ngOnInit() {
        const hd = document.getElementsByTagName('header')[0];
        hd.style.display = 'none';

        const topStr = this.commonS.getRoutedSiteFromPath();
        if (topStr !== '') {
            this.routedSiteId = '/' + topStr;
        } else {
            this.routedSiteId = topStr;
        }
    }

}
