import {Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {UserService} from '../../core/service/user.service';
import {Location} from '@angular/common';
import * as wjcInput from '@grapecity/wijmo.input';
import {CommonService} from '../../core/common.service';

@Component({
    selector: 'app-password-change',
    templateUrl: './password-change.component.html',
    styleUrls: ['./password-change.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PasswordChangeComponent implements OnInit {
    @ViewChild('thePopup', {static: true}) thePopup: wjcInput.Popup;

    // エラー表示
    public errorMessage: string;
    public displayProperty = 'none';

    isNewPasswordOK = false;

    // ポップアップメッセージ用宣言
    popup = {header: 'パスワード変更', body: 'パスワードを変更しました。再度ログインを行ってください。OKを押すとログイン画面に戻ります。'};

    private displayError(message: string) {
        this.errorMessage = message;
        this.displayProperty = '';
        this.changeDetectorRef.detectChanges();
    }

    constructor(
        private router: Router,
        private userService: UserService,
        public changeDetectorRef: ChangeDetectorRef,
        private _location: Location,
        public commonService: CommonService
    ) {
    }

    ngOnInit() {
    }

    goBack() {
        this._location.back();
    }

    loginChange(userId: string, oldPassword: string, newPassword: string) {
        const siteId = this.commonService.getRoutedSiteFromPath();
        const topPath = siteId !== '' ? siteId + '/login' : 'login';

        // serviceにid/passを渡す
        this.userService.userPasswordChange(userId, oldPassword, newPassword).subscribe(
            () => {
                console.log('成功');
                // confirm row deletion
                this.thePopup.show(true, (sender: wjcInput.Popup) => {
                    // delete the row
                    if (sender.dialogResult === 'wj-hide-ok') {
                        this.router.navigate([topPath]);
                    }
                });
            },
            (error) => {
                this.displayError(this.commonService.getErrorDetail(error));
            }
        );
    }

    checkNewPassword(new1, new2) {
        if (new1 !== new2) {
            this.isNewPasswordOK = false;
            this.displayError('新パスワードの値が同一ではありません');
        } else {
            this.isNewPasswordOK = true;
            this.displayProperty = 'none';
        }
    }
}
