import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'app-common-loading',
    templateUrl: './common-loading.component.html',
    styleUrls: ['./common-loading.component.scss']
})
export class CommonLoadingComponent implements OnInit {

    @Input() isLoading: boolean;

    constructor() {
    }

    ngOnInit() {
    }

}
