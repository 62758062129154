import {Component, OnInit, ViewChild, ChangeDetectorRef} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import * as wijmo from '@grapecity/wijmo';
import * as input from '@grapecity/wijmo.input';
import * as grid from '@grapecity/wijmo.grid';
import {Site} from '../site';
import {SiteService} from '../../../core/service/site.service';
import * as $ from 'jquery';
import {Subject} from 'rxjs';
import {CommonService} from '../../../core/common.service';
import {Constant} from '../../../core/constant';
import {User} from '../../user/user';

declare var $: $;

@Component({
    selector: 'app-construction-detail',
    templateUrl: './construction-detail.component.html',
    styleUrls: ['./construction-detail.component.scss']
})
export class ConstructionDetailComponent implements OnInit {
    @ViewChild('theStartDate', {static: true}) startDate: input.InputDate;
    @ViewChild('theEndDate', {static: true}) endDate: input.InputDate;
    @ViewChild('deadlineDate', {static: true}) deadlineDate: input.ComboBox;
    @ViewChild('deadlineDateWeek', {static: true}) deadlineDateWeek: input.ComboBox;
    @ViewChild('replyDeadline', {static: true}) replyDeadline: input.ComboBox;
    @ViewChild('replyDeadlineWeek', {static: true}) replyDeadlineWeek: input.ComboBox;
    @ViewChild('nounyuDeadline', {static: true}) nounyuDeadline: input.ComboBox;
    @ViewChild('nounyuDeadlineWeek', {static: true}) nounyuDeadlineWeek: input.ComboBox;

    apiData: Subject<any> = new Subject();
    arr = Array;
    model: any = {};

    periodStart: Date = this.commonService.getDateTime();
    periodEnd: Date = this.commonService.getDateTime();
    startLiftingHour: Date = this.commonService.getDateTime();

    dropdownData = Constant.SITE_BEFORE_DATE_SETTING;
    week = Constant.WEEK;

    isLoading = false;
    isInvalid = false;

    // sites: Site[] = [];

    liftingTypeData: any[] = [];
    elevatorsData: any[] = [];
    relationData: any[] = [];

    liftingRequestDueDate = 1;
    liftingConfirmDueDate = 1;
    orderDueDate = 1;
    theInputTime = new Date('2019/01/01 0:00:00');
    openStatus = 'close';

    holidayList = [];
    noLiftingList = [];
    holidaySelect = [];
    noLiftingSelect = [];

    // エラー表示
    public errorMessage: string;
    public errorProperty = 'none';
    public successProperty = 'none';

    private displayError(message: string) {
        this.errorMessage = message;
        this.errorProperty = '';
        this.successProperty = 'none';
        this.changeDetectorRef.detectChanges();
    }

    private displaySuccess(message: string) {
        this.errorMessage = message;
        this.successProperty = '';
        this.errorProperty = 'none';
        this.changeDetectorRef.detectChanges();
    }

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private siteService: SiteService,
        public changeDetectorRef: ChangeDetectorRef,
        private commonService: CommonService) {
        // APIデータ処理
        this.apiData.subscribe(apiData => {
            console.log(apiData);
            // 日付型に変換
            this.periodStart = new Date(apiData.periodStart);
            this.periodEnd = new Date(apiData.periodEnd);
            this.startLiftingHour = new Date(apiData.startLiftingHour);

            apiData.open ? this.openStatus = 'open' : this.openStatus = 'close';

            this.theInputTime = new Date('2019/01/01 ' + apiData.startLiftingHour);

            // -- 締切日タイプ（0: 週間, 1: 日数）
            // 揚重申請締切
            apiData.liftingRequestDueDateType === 0 ?
                this.liftingRequestDueDate = apiData.liftingRequestDueDate :
                this.liftingRequestDueDate = apiData.liftingRequestDueWeek;
            // 揚重申請回答期限
            apiData.liftingConfirmDueDateType === 0 ?
                this.liftingConfirmDueDate = apiData.liftingConfirmDueDate :
                this.liftingConfirmDueDate = apiData.liftingConfirmDueWeek;
            // 納入依頼期限
            apiData.orderDueDateType === 0 ?
                this.orderDueDate = apiData.orderDueDate :
                this.orderDueDate = apiData.orderDueWeek;

            // リスト作成
            this.liftingTypeData = apiData.liftingType.map((val, i) => {
                return {name: val};
            });
            this.elevatorsData = apiData.elevator.map((val, i) => {
                return {name: val};
            });
            this.relationData = apiData.relationName.map((val, i) => {
                return {name: val};
            });

            /*
             * カレンダー初期設定
             */
            // noLiftingDaysにある値をカレンダー上にセット
            apiData.noLiftingDays.map((val) => {
                const date = val.date.replace(/-/g, '/');
                val.type == 1 ? this.holidayList.push(date) : this.noLiftingList.push(date);
            });
            // 祝日カレンダー
            this.holidaySelect = $('#dateSelect_calendar').multiDatesPicker({
                minDate: new Date(apiData.periodStart),
                maxDate: new Date(apiData.periodEnd),
                addDates: this.addDatesCheck(this.holidayList)
            });
            // 揚重禁止日カレンダー
            this.noLiftingSelect = $('#dateSelect_calendar2').multiDatesPicker({
                minDate: new Date(apiData.periodStart),
                maxDate: new Date(apiData.periodEnd),
                addDates: this.addDatesCheck(this.noLiftingList)
            });
        });
    }

    ngOnInit() {

        const id = this.route.snapshot.paramMap.get('id');
        // 新規か既存か
        if (id) {

            const topPath = 'login';
            this.commonService.userLoggedIn('').subscribe(
                (userData: User) => {
                    console.log(userData);

                    this.siteService.getSite(id).subscribe(
                        (data: Site) => {
                            this.model = data;
                            this.isInvalid = true;
                            this.apiData.next(data);
                        }, error => {
                            this.isLoading = false;
                            this.displayError(this.commonService.getErrorDetail(error));
                        }
                    );

                    this.changeDetectorRef.detectChanges();
                },
                (error) => {
                    // ログイン情報がない場合は、ルートに遷移
                    console.log(error);
                    this.router.navigate([topPath]);
                }
            );

        } else {
            // カレンダー初期設定
            this.holidaySelect = $('#dateSelect_calendar').multiDatesPicker({
                minDate: this.startDate.value,
                maxDate: this.endDate.value
            });
            this.noLiftingSelect = $('#dateSelect_calendar2').multiDatesPicker({
                minDate: this.startDate.value,
                maxDate: this.endDate.value
            });
        }
    }

    flexInitialized(flexgrid: grid.FlexGrid) {
        flexgrid.cellEditEnded.addHandler((s: grid.FlexGrid, e: grid.CellEditEndingEventArgs) => {
            this.gridDataCheck();
        });
        flexgrid.hostElement.addEventListener('keydown', (e: KeyboardEvent) => {
            if ((e.keyCode == wijmo.Key.Delete) || (e.keyCode == wijmo.Key.Back)) {
                this.gridDataCheck();
            }
        });
    }

    /*
     * Function
     */
    private addDatesCheck(val) {
        if (val.length > 0) {
            return val;
        }
    }

    private gridDataCheck() {
        const arr1 = this.deduplication(this.liftingTypeData.map(x => x.name));
        const arr2 = this.deduplication(this.elevatorsData.map(x => x.name));
        const arr3 = this.deduplication(this.relationData.map(x => x.name));

        const dataIsNull: boolean = arr1.length > 0 && arr2.length > 0 && arr3.length > 0;
        this.isInvalid = dataIsNull;
    }

    // 休日リスト作成
    private holiday() {
        const list = [];
        const start = new Date(this.startDate.value.getTime());
        const end = new Date(this.endDate.value.getTime());
        for (const i = start; i <= end; i.setDate(i.getDate() + 1)) {
            if (i.getDay() === 0 || i.getDay() === 6) {
                list.push(new Date(i).toLocaleDateString('ja-JP'));
            }
        }
        return list;
    }

    // 工期変更関数: 開始日/終了日を変更したらカレンダーにも反映する
    private periodDateChange() {
        // 休日リストを更新
        this.holidayList = this.holiday();
        // カレンダーの開始日もしくは終了日を更新
        $('#dateSelect_calendar').datepicker('option', {
            minDate: new Date(this.startDate.value),
            maxDate: new Date(this.endDate.value)
        });
        $('#dateSelect_calendar2').datepicker('option', {
            minDate: new Date(this.startDate.value),
            maxDate: new Date(this.endDate.value)
        });

        // 休日選択表示を更新
        $('#dateSelect_calendar').multiDatesPicker('addDates', this.holidayList);
    }

    // 日数入力は正の整数のみ許可
    private isNumberKey(e: KeyboardEvent) {
        return e.keyCode > 47 && e.keyCode < 58;
    }

    // 配列の重複＆空行削除
    private deduplication(array: any) {
        const data = [];
        array.filter((elem, index, self) => {
            if (elem !== undefined && elem !== '') {
                if (self.indexOf(elem) === index) {
                    data.push(elem);
                }
            }
        });
        return data;
    }

    // 現場登録
    private createData() {
        const arrayNoLiftingDays = [];

        // noLiftingDays データ作成
        if (this.holidaySelect[0].value !== '') {
            const holidaysList = this.holidaySelect[0].value.replace(/\s+/g, '').split(',');
            holidaysList.forEach((value, index) => {
                arrayNoLiftingDays.push({
                    date: value,
                    type: 1
                });
            });
        }
        if (this.noLiftingSelect[0].value !== '') {
            const noLiftingsList = this.noLiftingSelect[0].value.replace(/\s+/g, '').split(',');
            noLiftingsList.forEach((value, index) => {
                arrayNoLiftingDays.push({
                    date: value,
                    type: 2
                });
            });
        }

        // liftingRequestDate データ作成
        const deadlineDateIndex = this.deadlineDate.selectedIndex;
        if (deadlineDateIndex === 0) {
            this.model.liftingRequestDueDateType = deadlineDateIndex;
            this.model.liftingRequestDueDate = this.liftingRequestDueDate;
            this.model.liftingRequestDueWeek = -1;
            this.model.liftingRequestDueDayOfWeek = -1;
        } else {
            this.model.liftingRequestDueDateType = deadlineDateIndex;
            this.model.liftingRequestDueDate = -1;
            this.model.liftingRequestDueWeek = this.liftingRequestDueDate;
            this.model.liftingRequestDueDayOfWeek = this.deadlineDateWeek.selectedIndex;
        }

        // liftingConfirmDate データ作成
        const liftingConfirmDateIndex = this.replyDeadline.selectedIndex;
        if (liftingConfirmDateIndex === 0) {
            this.model.liftingConfirmDueDateType = liftingConfirmDateIndex;
            this.model.liftingConfirmDueDate = this.liftingConfirmDueDate;
            this.model.liftingConfirmDueWeek = -1;
            this.model.liftingConfirmDueDayOfWeek = -1;
        } else {
            this.model.liftingConfirmDueDateType = liftingConfirmDateIndex;
            this.model.liftingConfirmDueDate = -1;
            this.model.liftingConfirmDueWeek = this.liftingConfirmDueDate;
            this.model.liftingConfirmDueDayOfWeek = this.replyDeadlineWeek.selectedIndex;
        }

        // orderDate データ作成
        const orderDateIndex = this.nounyuDeadline.selectedIndex;
        if (orderDateIndex === 0) {
            this.model.orderDueDateType = orderDateIndex;
            this.model.orderDueDate = this.orderDueDate;
            this.model.orderDueWeek = -1;
            this.model.orderDueDayOfWeek = -1;
        } else {
            this.model.orderDueDateType = orderDateIndex;
            this.model.orderDueDate = -1;
            this.model.orderDueWeek = this.orderDueDate;
            this.model.orderDueDayOfWeek = this.nounyuDeadlineWeek.selectedIndex;
        }

        this.model.periodStart = new Date(this.startDate.value).toLocaleDateString();
        this.model.periodEnd = new Date(this.endDate.value).toLocaleDateString();
        this.model.noLiftingDays = arrayNoLiftingDays;
        this.model.startLiftingHour = this.theInputTime.toLocaleTimeString();

        this.model.liftingType = this.deduplication(this.liftingTypeData.map(x => x.name));
        this.model.elevator = this.deduplication(this.elevatorsData.map(x => x.name));
        this.model.relationName = this.deduplication(this.relationData.map(x => x.name));

        this.openStatus === 'open' ? this.model.open = true : this.model.open = false;

        console.log(this.model);
        this.isLoading = true;
        this.siteService.createSite(this.model).subscribe(
            () => {
                this.isLoading = false;
                // this.sites.push(data);
                this.displaySuccess('現場の登録に成功しました。');
                this.router.navigate(['master/construction']);
            },
            (error) => {
                this.isLoading = false;
                this.displayError(this.commonService.getErrorDetail(error));
            }
        );

    }

}
