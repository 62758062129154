import {ChangeDetectorRef, Component, OnInit, ViewChildren} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {CookieService} from 'ngx-cookie-service';
import * as input from '@grapecity/wijmo.input';
import {User} from '../user';
import {Site} from '../../construction/site';
import {UserService} from '../../../core/service/user.service';
import {SiteService} from '../../../core/service/site.service';
import {Constant} from 'src/app/core/constant';
import {CommonService} from '../../../core/common.service';

@Component({
    selector: 'app-user-detail',
    templateUrl: './user-detail.component.html',
    styleUrls: ['./user-detail.component.scss']
})
export class UserDetailComponent implements OnInit {

    constructor(
        private cookieService: CookieService,
        private route: ActivatedRoute,
        private router: Router,
        private userService: UserService,
        private siteService: SiteService,
        public changeDetectorRef: ChangeDetectorRef,
        private commonService: CommonService
    ) {
    }

    userId: string;
    userAuthority = true;
    headerText: string;
    model: any = {};
    users: User[] = [];
    sites: Site[] = [];

    updateName = '';
    updateId = '';
    relation = [];
    construction = [];
    siteList = [];
    userData: any;
    siteId: string;

    // 更新権限有無
    updateFlg = true;

    // authorityList = [
    //   {id: 1, name: "管理者"},
    //   {id: 2, name: "現場担当者"},
    //   {id: 3, name: "揚重センター"},
    //   {id: 4, name: "手配担当者"},
    //   {id: 5, name: "施工業者（野原顧客）"},
    //   {id: 6, name: "施工業者"}
    // ];

    isPhoneNumOK = true;
    isEmailOK = true;
    isMobileNumOK = true;
    isFaxNumOK = true;

    isEmailTouched = false;
    isPhoneTouched = false;
    isMobileTouched = false;
    isFaxTouched = false;

    addItem = [];

    ngOnInit(): void {
        const id = this.route.snapshot.paramMap.get('id');

        this.siteId = this.commonService.getRoutedSiteFromPath();
        const topPath = this.siteId !== '' ? this.siteId + '/login' : 'login';
        this.commonService.userLoggedIn(this.siteId).subscribe(
            (userData: User) => {

                console.log(userData);
                this.userData = userData;

                const authority = this.userData.authority;
                console.log('authority=' + authority);

                this.siteId = this.userData.site.id;

                (authority === Constant.AUTHORITY_ADMIN ||
                    authority === Constant.AUTHORITY_SITE_STAFF) ? this.userAuthority = true : this.userAuthority = false;
                this.userAuthority ? this.headerText = 'アカウント登録' : this.headerText = 'アカウント情報';

                // 管理者以外は自分以外のユーザーは更新できない
                if (authority !== Constant.AUTHORITY_ADMIN &&
                    authority !== Constant.AUTHORITY_SITE_STAFF &&
                    parseInt(id, 10) !== this.userData.id) {
                    this.updateFlg = false;
                }

                /*
                 * userIdのユーザー情報をAPIから取得してmodelに反映
                 */
                this.userService.getUser(id).subscribe(
                    (data: User) => {
                        this.model = data;
                        // ユーザーに紐づいている現場情報を格納
                        this.relation = this.model.site.map(x => x.name);
                    },
                    (error) => {
                        alert(this.commonService.getErrorDetail(error));
                    }
                );

                // /*
                //  * 現場一覧をAPIから取得
                //  */
                // this.siteService.getAllSites().subscribe(
                //   (sites: Site[]) => {
                //     this.siteList = sites.map( x => x.name );
                //   },
                //   (err) => {
                //     alert('error:' + err);
                //   }
                // );

                this.changeDetectorRef.detectChanges();
            },
            (error) => {
                // ログイン情報がない場合は、ルートに遷移
                console.log(error);
                this.router.navigate([topPath]);
            }
        );

    }

    /*
     * ユーザー情報のCRUD操作
     */
    updateData(): void {
        console.log(this.model);
        this.model.suspend = false;
        this.userService.createUser(this.model).subscribe(
            () => {
                this.router.navigate([this.siteId + '/lifting']);
            },
            (error) => {
                this.commonService.getErrorDetail(error);
            }
        );
    }


    lostFocus(sender: input.ComboBox) {
        const item = sender.text;
        if (item && this.construction.indexOf(item) < 0) {
            this.construction.push(item);
        }
    }

    passwordDisplay(event: any) {
        const elem = event.srcElement;
        const target = elem.previousElementSibling;
        const inputType = target.getAttribute('type');

        elem.classList.toggle('on');

        inputType === 'password' ?
            target.setAttribute('type', 'text') :
            target.setAttribute('type', 'password');
    }

    public onCreateItem(addConstruction) {
        this.addItem.push({
            item: addConstruction
        });
    }

    public onRemoveItem(index, elm) {
        elm.splice(index, 1);
    }

    public savePreset(form) {
        this.cookieService.set('name', this.model.name);
        this.cookieService.set('email', this.model.email);
        this.cookieService.set('company', this.model.company);
        this.cookieService.set('address', this.model.address);
        this.cookieService.set('tel', this.model.tel);
        this.cookieService.set('fax', this.model.fax);
        this.cookieService.set('cellphone', this.model.cellphone);
        // this.cookieService.set('relationEmail', this.model.relationEmail);
        // this.router.navigate(["master/user"]);
    }

    validateEmailAddress(str) {
        this.isEmailTouched = true;
        this.isEmailOK = this.commonService.validateEmailAddress(str);
        this.changeDetectorRef.detectChanges();
    }

    validatePhoneNum(str) {
        this.isPhoneTouched = true;
        this.isPhoneNumOK = this.commonService.validatePhoneNum(str);
        this.changeDetectorRef.detectChanges();
    }

    validateMobileNum(str) {
        this.isMobileTouched = true;
        this.isMobileNumOK = this.commonService.validatePhoneNum(str);
        this.changeDetectorRef.detectChanges();
    }

    validateFaxNum(str) {
        this.isFaxTouched = true;
        this.isFaxNumOK = this.commonService.validatePhoneNum(str);
        if (str === '') {
            this.isFaxNumOK = true;
        }
        this.changeDetectorRef.detectChanges();
    }
}
