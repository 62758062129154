import { MaterialsService } from './../materials.service';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable, of } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';
import { Lifting } from '../../lifting/lifting';
import { Material } from '../../lifting/material';
import {Router} from "@angular/router";
import {CommonService} from "../common.service";

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'POST'
  })
};

@Injectable({ providedIn: 'root' })
export class LiftingService {
  constructor(private http: HttpClient,
              private commonService: CommonService) {
    this.siteId = this.commonService.getRoutedSiteFromPath() !== '' ? this.commonService.getRoutedSiteFromPath() : '';
  }

  public static readonly MATERIAL_PAGE = 30;
  // getAllLiftings() {
  //   throw new Error("Method not implemented.");
  // }
  private liftingCreateData: object;
  private siteId: any;
  /*
   * API処理
   */
  // IDで指定した揚重申請情報を取得
  getLifting(id: string): Observable<Lifting> {
    return this.http.get<Lifting>(`${environment.apiUrl}/lifting/${id}?siteId=${this.siteId}`);
  }

  // 揚重申請作成
  createLifting(liftingInfo: Lifting): Observable<Lifting> {
    const body = JSON.stringify(liftingInfo);
    console.log(body);
    return this.http.post<Lifting>(`${environment.apiUrl}/lifting`, body, httpOptions).pipe(
      tap(() => console.log(`createLifting success`)),
    );
  }
  // 資材情報を取得
  // 納入依頼に紐付けられている AND 揚重申請に紐付けられていない
  getAllMaterials(page: number, searchKeys = {}, sort = {}): Observable<HttpResponse<Material[]>> {
    const limit = LiftingService.MATERIAL_PAGE;
    // offsetは0から始まる
    const offset = limit * (page - 1);
    let url = `${environment.apiUrl}/material?siteId=${this.siteId}&limit=${limit}&offset=${offset}`;

    if (0 !== Object.keys(searchKeys).length) {
      for (let key of Object.keys(searchKeys)) {
        if (key === 'orderPersonName') {
          url += '&' + 'companyName' + '=' + searchKeys[key];
        } else if (key === 'orderRequestDateTime') {
          url += '&' + 'requestDate' + '=' + searchKeys[key];
        } else if (key === 'orderRequestDateTimeAls') {
          url += '&' + 'requestTime' + '=' + searchKeys[key];
          // TODO API複数対応後、配列
          // }else if(key==='orderNumber'){
          //   url += '&' + 'number' + '=[';
          //   for(let i=0;i<searchKeys[key].length;i++){
          //     url += '"' + searchKeys[key][i] + '"';
          //     if(i!==searchKeys[key].length-1){
          //       url +=',';
          //     }
          //   }
          //   url +=  ']';
        } else {
          url += '&' + key + '=' + searchKeys[key];
        }
      }
    }

    if (sort['sortKey']) {
      // APIパラメータ用にキーを補正
      if (sort['sortKey'].indexOf('orderNumber') !== -1) {
        url += '&sort=' + 'number';
      } else if (sort['sortKey'].indexOf('orderRequestDateTime') !== -1) {
        url += '&sort=' + 'requestDateTime';
      } else if (sort['sortKey'].indexOf('orderRequestDateTimeAls') !== -1) {
        url += '&sort=' + 'requestTime';
      } else if (sort['sortKey'].indexOf('orderPersonName') !== -1) {
        url += '&sort=' + 'companyName';
      } else {
        url += '&sort=' + sort['sortKey'];
      }
      url += '&order=' + sort['sortOrder'];
    }
    console.log(url);
    return this.http.get<Material[]>(url, { observe: 'response' });
  }
  getMaterial(orderId: string): Observable<Material> {
    return this.http.get<Material>(`${environment.apiUrl}/material/${orderId}?siteId=${this.siteId}`);
  }
  // 画像ファイルアップロード
  uploadLiftingImg(imageFile: File): Observable<any> {
    const body = new FormData();
    body.append('imageFile', imageFile);
    console.log(body);
    return this.http.post<any>(`${environment.apiUrl}/lifting/upload`, body);
  }

  // 申請
  // applicationRequest(id: string): Observable<any>{
  //   return this.http.post<any>(`${environment.apiUrl}/lifting/${id}/request`, null);
  // }
  /*
   * エラー詳細を取得
   */
  // private handleError<T> (operation = 'operation', result?: T) {
  //   return (error: any): Observable<T> => {

  //     // TODO: send the error to remote logging infrastructure
  //     console.error(error); // log to console instead

  //     // TODO: better job of transforming error for user consumption
  //     console.log(`${operation} failed: ${error.message}`);

  //     // Let the app keep running by returning an empty result.
  //     return of(result as T);
  //   };
  // }

  /*
   * 揚重申請の搬入資材登録時のデータ受け渡し関数
   */
  getCreateData(): object {
    return this.liftingCreateData;
  }
  setCreateData(data: object) {
    this.liftingCreateData = data;
  }
}
