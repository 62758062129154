import {Injectable} from '@angular/core';
import {SiteService} from './service/site.service';
import * as wjcGrid from '@grapecity/wijmo.grid';
import {Observable} from 'rxjs';
import {User} from '../master/user/user';
import {environment} from '../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Router} from '@angular/router';
import {Constant} from './constant';

@Injectable({
    providedIn: 'root'
})
export class CommonService {

    // 上限設定
    limit = 50;
    // dateList;

    constructor(private siteService: SiteService,
                private http: HttpClient,
                private router: Router) {
        const siteId = this.getRoutedSiteFromPath();
        const topPath = siteId !== '' ? siteId + '/login' : 'login';

        // this.userLoggedIn(siteId).subscribe(
        //     (userData: User) => {
        //         // if (userData.site) {
        //         //     this.siteService.getOrderableDates(siteId).subscribe(data => {
        //         //         this.dateList = data.dates;
        //         //     }, error => {
        //         //         this.getErrorDetail(error);
        //         //     });
        //         // }
        //     }, error => {
        //         // ログイン失敗時、ルートに遷移
        //         this.getErrorDetail(error);
        //         this.router.navigate([topPath]);
        //     }
        // );
    }

// // サーバー時間の今日を元にした納入依頼指定可能日の一覧を返す
//     _isEnableDate(target: string) {
//         if (this.dateList && this.dateList.indexOf(target) !== -1) {
//             return true;
//         } else {
//             return false;
//         }
//     }

// 過去、休日、禁止日、工期のチェックを行う
// 設定可能日ならTRUEを返す
    isEnableDate(datetime: Date, periodStart: string, periodEnd: string, noLiftingDays: any) {
        // console.log(periodStart);
        const today = this.getDateTime();
        const startDate = new Date(periodStart);
        // 今日が禁止日にあたるかどうか判定
        // let noLiftingDaysFlag: boolean = false;
        // let str = startDate.getFullYear()
        //         + '-' + ('0' + (startDate.getMonth() + 1)).slice(-2)
        //         + '-' + ('0' + startDate.getDate()).slice(-2);
        // let result = this.getHashProperties(str, noLiftingDays);
        // if (result) { noLiftingDaysFlag = true; };

        const endDate = new Date(periodEnd);
        // 設定可能日の初日を取得
        // let day = startDate.getDate();


        // if(options.dueDateType == 0){
        //   // もし今日が当日なら一日先送りにする
        //   if (noLiftingDaysFlag) {
        //     options.dueDate++;
        //   }
        //   day = startDate.getDate() + options.dueDate;
        // } else {
        //   // n週間前期限のロジック
        //   // もし今日が当日なら一週間先送りにする
        //   if (noLiftingDaysFlag) {
        //     options.dueWeek++;
        //   }
        //   // 今日(startDate)にn週間分の日数(7*options.dueWeek)を足す
        //   day = startDate.getDate() + (7 * options.dueWeek);
        //   startDate.setDate(day);
        //   // n週間後の曜日と期限の曜日を比較する
        //   let dayofweek = startDate.getDay();
        //   // 期限の曜日までなら、その週の日曜日をstartとする
        //   if (dayofweek <= options.dueDayOfWeek) {
        //     day = startDate.getDate() - dayofweek;
        //   }
        //   // もし超えていたら、翌週の日曜日をstartとする
        //   else {
        //     day = startDate.getDate() - dayofweek + 7;
        //   }
        //   // day = startDate.getDate() + (7 * options.dueWeek);
        //   // startDate.setDate(day);
        //   // let dayofweek = startDate.getDay();
        //   // day = startDate.getDate() + (options.dueDayOfWeek - dayofweek);
        // }
        // console.log(orderDueDate);
        // startDate.setDate(day);
        // 禁止日の場合は開始日を前日に設定
        // let res = this.isNoLiftingDate(startDate, noLiftingDays);
        // while(res){
        //   // n日前設定の場合は前倒し
        //   if (options.dueDateType == 0) {
        //     day = startDate.getDate() - 1;
        //   } else {
        //     // n週間前の場合は後ろ倒し
        //     day = startDate.getDate() + 1;
        //   }
        //   startDate.setDate(day);
        //   res = this.isNoLiftingDate(startDate, noLiftingDays);
        // }
        // 比較する時間をそろえる
        datetime.setHours(0, 0, 0, 0);
        startDate.setHours(0, 0, 0, 0);
        endDate.setHours(0, 0, 0, 0);
        today.setHours(0, 0, 0, 0);
        // console.log(startDate);
        // console.log(datetime);
        // 開始日より前/完了日より後なら無効
        if (startDate.getTime() > datetime.getTime() || datetime.getTime() > endDate.getTime()) {
            return false;
        }
        // 今日より過去なら無効
        if (datetime.getTime() < today.getTime()) {
            return false;
        }
        // 禁止日かどうかをチェック
        const res = this.isNoLiftingDate(datetime, noLiftingDays);
        if (!res) {
            // 禁止日でなければTRUE
            return true;
        } else {
            return false;
        }
    }

// datetimeが禁止日ならTRUEを返す
    getHashProperties(date: string, noLiftingDays: any) {
        const r = [];
        for (let i = 0; i < noLiftingDays.length; i++) {
            r.push(noLiftingDays[i].date);
        }
        return r.includes(date);
    }

// 禁止日ならTRUEを返す
    isNoLiftingDate(datetime: Date, noLiftingDays: any) {
        let desableDate;
        let res = false;
        noLiftingDays.forEach(element => {
            desableDate = new Date(element.date + ' 00:00:00');
            if (datetime.getTime() === desableDate.getTime()) {
                res = true;
            }
        });
        return res;
    }

// 作業開始時間から+24hまでの配列を返す
    customInputTime(startTime: string) {
        const data = [];

        const hms = startTime.split(':');
        const h = parseInt(hms[0], 10);
        let m = parseInt(hms[1], 10);
        let time;
        for (let i = h; i < h + 24; i++) {
            // 次の時間にいったら0分にリセット
            if (i > h) {
                m = 0;
            }
            for (let n = m; n < 60; n++) {
                if (n % 15 === 0) {
                    time = i + ':' + ('0' + n).slice(-2);
                    data.push(time);
                }
            }
        }
        return data;
    }

// 日付取得関数
    getDateTime() {
        return new Date();
    }

// オートコンプリート用データ取得
    getRowAutoComplite(list, item) {
        // console.log(list);
        // console.log(item);
        const max = {added: 0};
        let min: any = {};
        let isNew = true;
        let rowData;
        const update: any = {};
        if (list != null && list.length !== 0) {
            min = {index: 0, added: list[0].added};
            // added最大値と最小を保存
            for (let i = 0; i < list.length; i++) {
                if (list[i].added > max.added) {
                    max.added = list[i].added;
                }
                if (min.added > list[i].added) {
                    min.index = i;
                    min.added = list[i].added;
                }
            }
            console.log(max);
            // 重複チェック
            for (let i = 0; i < list.length; i++) {
                if (item == list[i].value) {
                    isNew = false;
                    // ソート順更新用
                    update.added = (max.added + 1);
                    update.index = i;
                    break;
                }
            }
            if (isNew) {
                rowData = {
                    label: item,
                    value: item,
                    added: (max.added + 1)
                };
            }
        } else {
            rowData = {
                label: item,
                value: item,
                added: (max.added + 1)
            };
        }

        return {data: rowData, min, update};
    }

    /**
     * URLからドメイン直下にある現場IDを返す
     */
    getRoutedSiteFromPath() {
        const path = location.pathname;
        const topStr = path.split('/')[1];
        // ゼロ埋め無の場合。現場IDがゼロ埋めを許可した場合はパターンを /^\d*$/　に変える
        if (topStr && /^([1-9]\d*|0)$/.test(topStr)) {
            return topStr;
        } else {
            return '';
        }
    }

    /**
     * GridのItemsformatter関数の中でRowHeaderに編集マークを付与する
     * @param s Gridオブジェクト
     * @param e Itemsformatter関数のイベント引数
     * @param id 存在判定をする
     */
    addGridEditMark(s, e, id = '') {
        if (e.panel.cellType === wjcGrid.CellType.RowHeader && s.rows[e.row].dataItem) {
            e.cell.innerHTML = '<i class="column-picker-icon fas fa-cog"></i>';
        }
    }

    validateEmailAddress(targetInput = '') {
      if (targetInput == null || targetInput === '') {
        return true;
      }
      if (!targetInput) {
            return false;
      }
      return /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(targetInput);
    }

    validatePhoneNum(targetInput = '') {
      if (targetInput == null || targetInput === '') {
        return true;
      }
      if (!targetInput) {
          return false;
      }
      const tel = targetInput.replace(/[━.*‐.*―.*－.*\-.*ー.*\-]/gi, '');
      return /^(0[5-9]0[0-9]{8}|0[1-9][1-9][0-9]{7})$/.test(tel);
    }

    isPagingLimit(targetArray = []) {
        return targetArray.length < 30;
    }

    getErrorDetail(errorResponse) {
        console.log(errorResponse.error);
        if (errorResponse.error.code === 403) {
            const siteId = this.getRoutedSiteFromPath();
            location.href = '/' + siteId + '/redirect';
        } else {
            return errorResponse.error.message;
        }
    }

    /**
     * ユーザー情報を返す
     * @param siteId 現場ID
     */
    userLoggedIn(siteId = '')
        :
        Observable<User> {
        let url = `${environment.apiUrl}/user/loggedIn`;
        if (siteId !== '') {
            url += '?siteId=' + siteId;
        }
        return this.http.get<User>(url);
    }

    /**
     * groupBy関数
     * @param objectArray ベースの配列
     * @param property どのプロパティでグループ化するか
     */
    groupBy(objectArray, property) {
        // return objectArray.reduce((acc, obj) => {
        return objectArray.reduce((acc: { [x: string]: any[]; }, obj: { [x: string]: any; }) => {
            const key = obj[property];
            if (!acc[key]) {
                acc[key] = [];
            }
            acc[key].push(obj);
            return acc;
        }, {});
    }

    /**
     * 手配状況のサマリー作成
     * @param materials 資材リスト
     */
    orderStatus(materials: Array<any>) {
        const statusList = Constant.MATERIAL_STATUS_STR_LIST;
        if (materials.length === 0) {
            return '';
        }

        if (materials.every((currentValue) => currentValue.status === Constant.ORDER_STATUS_LIST.STATUS_ADD_NEW)) {
            return '下書き';
        }

        // 全て未手配の場合は未手配
        if (materials.every((currentValue) => currentValue.status === Constant.ORDER_STATUS_LIST.STATUS_NO_ORDER)) {
            return statusList[Constant.ORDER_STATUS_LIST.STATUS_NO_ORDER];
        }

        // 未手配が含まれる場合は未手配あり
        if (materials.find((currentValue) => currentValue.status === Constant.ORDER_STATUS_LIST.STATUS_NO_ORDER)) {
            return Constant.ORDER_HAS_NO_ORDER;
        }

        // 全て手配中の場合は手配中
        if (materials.every((currentValue) => currentValue.status === Constant.ORDER_STATUS_LIST.STATUS_ORDERING)) {
            return statusList[Constant.ORDER_STATUS_LIST.STATUS_ORDERING];
        }

        // 全て取り消しの場合は取り消し
        if (materials.every((currentValue) => currentValue.status === Constant.ORDER_STATUS_LIST.STATUS_CANCELED)) {
            return statusList[Constant.ORDER_STATUS_LIST.STATUS_CANCELED];
        }

        // 全て仮手配の場合は仮手配
        if (materials.every((currentValue) => currentValue.status === Constant.ORDER_STATUS_LIST.STATUS_ARRANGED)) {
            return statusList[Constant.ORDER_STATUS_LIST.STATUS_ARRANGED];
        }

        // 一部手配中の場合は手配中
        if (materials.find((currentValue) => currentValue.status === Constant.ORDER_STATUS_LIST.STATUS_ORDERING)) {
            return statusList[Constant.ORDER_STATUS_LIST.STATUS_ORDERING];
        }

        // 一部仮手配の場合は仮手配
        if (materials.find((currentValue) => currentValue.status === Constant.ORDER_STATUS_LIST.STATUS_ARRANGED)) {
            return statusList[Constant.ORDER_STATUS_LIST.STATUS_ARRANGED];
        }

        // 全て手配確定の場合は手配確定
        if (materials.every((currentValue) => currentValue.status === Constant.ORDER_STATUS_LIST.STATUS_ARRANGED_CONFIRMED)) {
            return statusList[Constant.ORDER_STATUS_LIST.STATUS_ARRANGED_CONFIRMED];
        }

        // 一部手配確定の場合は仮手配
        if (materials.find((currentValue) => currentValue.status === Constant.ORDER_STATUS_LIST.STATUS_ARRANGED_CONFIRMED)) {
            return statusList[Constant.ORDER_STATUS_LIST.STATUS_ARRANGED];
        }

        // 全て納入済みの場合は納入済み
        if (materials.every((currentValue) => currentValue.status === Constant.ORDER_STATUS_LIST.STATUS_DELIVERED)) {
            return statusList[Constant.ORDER_STATUS_LIST.STATUS_DELIVERED];
        }

        // 一部納入済みの場合は手配確定
        if (materials.find((currentValue) => currentValue.status === Constant.ORDER_STATUS_LIST.STATUS_DELIVERED)) {
            return statusList[Constant.ORDER_STATUS_LIST.STATUS_ARRANGED_CONFIRMED];
        }

        // 一部取り消しの場合は取り消し
        if (materials.find((currentValue) => currentValue.status === Constant.ORDER_STATUS_LIST.STATUS_CANCELED)) {
          return statusList[Constant.ORDER_STATUS_LIST.STATUS_CANCELED];
        }
        // 全て手配キャンセルの場合は手配キャンセル
        if (materials.every((currentValue) => currentValue.status === Constant.ORDER_STATUS_LIST.STATUS_ARRANGED_CANCELED)) {
            return statusList[Constant.ORDER_STATUS_LIST.STATUS_ARRANGED_CANCELED];
        }
    }


}


