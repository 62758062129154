import {Component, ChangeDetectionStrategy, ChangeDetectorRef, Input, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {User} from '../master/user/user';
import {UserService} from '../core/service/user.service';
import {ActivatedRoute} from '@angular/router';
import {SiteService} from '../core/service/site.service';
import {Constant} from 'src/app/core/constant';
import {CommonService} from '../core/common.service';
import { appVersion } from '../../../package.json';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoginComponent implements OnInit {
    version: string = appVersion;

    user: User;
    siteId: string;
    loginMessage: string;
    displayProperty;
    siteName: string;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private userService: UserService,
        private siteService: SiteService,
        public changeDetectorRef: ChangeDetectorRef,
        public commonService: CommonService) {
        // ログアウトしたらStorageをリセット
        sessionStorage.removeItem('user');
        localStorage.removeItem('user');
    }

    ngOnInit() {
        const hd = document.getElementsByTagName('header')[0];
        hd.style.display = 'block';

        this.displayProperty = 'none';
        this.siteId = this.route.snapshot.paramMap.get('siteId');

        if (this.siteId) {
            this.siteService.getSiteNameById(this.siteId).subscribe(
                (siteName: any) => {
                    this.siteName = siteName.name;
                    this.changeDetectorRef.detectChanges();
                },
                (error) => {
                    this.loginError(error.error.message);
                }
            );
        }
    }

    private loginError(message: string) {
        this.loginMessage = message;
        this.displayProperty = '';
        this.changeDetectorRef.detectChanges();
    }

    loginCheck(id: string, pass: string) {
        // serviceにid/passを渡す
        this.userService.userLogin(id, pass, this.siteId).subscribe(
            (data: User) => {
                this.user = data;

                if (this.siteId == null) {
                    // 管理ログインはアカウント画面へ
                    this.router.navigate(['master/user']);
                } else {
                    if (this.user.authority !== Constant.AUTHORITY_NOT_NOHARA_CUSTOMER &&
                        this.user.authority !== Constant.AUTHORITY_LIFTING) {
                        // 納入申請へ
                        this.router.navigate([this.siteId + '/order']);
                    } else {
                        // 非野原は揚重申請へ
                        this.router.navigate([this.siteId + '/lifting']);
                    }
                }
            },
            (error) => {
                this.loginError(error.error.message);
            });

        // serviceにid値を渡す
        // this.userServiceService.userAuthority = id;

        // if (id !== 'non-nohara') {
        //   // 納入申請へ
        //   this.router.navigate(['products/nounyu']);
        // } else {
        //   // 非野原は揚重申請へ
        //   this.router.navigate(['products/youju']);
        // }

        // if (id == 'admin') {
        //   // 管理者なら揚重申請へ
        //   this.router.navigate(["products/youju"]);
        // } else if (id == 'user') {
        //   // ユーザーならユーザー情報へ
        //   this.router.navigate(["products/nounyu"]);
        // }

    }
}
