import {Component, OnInit, ChangeDetectorRef} from '@angular/core';
import {Subject} from 'rxjs';
import * as wjcCore from '@grapecity/wijmo';
import {Router} from '@angular/router';
import {History} from './history';
import {HistoryService} from '../../core/service/history.service';
import {CommonService} from '../../core/common.service';
import {CommonFilterUiService} from '../../core/common-filter-ui.service';
import {User} from '../user/user';

@Component({
    selector: 'app-history',
    templateUrl: './history.component.html',
    styleUrls: ['./history.component.scss']
})
export class HistoryComponent implements OnInit {
    apiData: Subject<any> = new Subject();
    gridData: any = [];

    // エラー表示
    public errorMessage: string;
    public errorProperty = 'none';
    public successProperty = 'none';

    currentPage = 1;
    siteId = '';
    historyFilter;

    isLastPage = false;
    searchKeys = {};
    sortCondition = {sortKey: 'operationTime', sortOrder: 'desc'};
    historyGrid;
    isLoading = false;

    constructor(
        private historyService: HistoryService,
        public changeDetectorRef: ChangeDetectorRef,
        private commonService: CommonService,
        private commonFilterService: CommonFilterUiService,
        private router: Router) {

        this.apiData.subscribe(apidata => {
            this.isLoading = false;
            this.gridData = new wjcCore.CollectionView(apidata);

            this.gridData.sortDescriptions.clear();
            this.gridData.sortDescriptions.push(
                new wjcCore.SortDescription(this.sortCondition.sortKey, this.sortCondition.sortOrder === 'asc'));
            this.isLastPage = this.commonService.isPagingLimit(this.gridData.sourceCollection);

            if (0 !== Object.keys(this.searchKeys).length) {
                for (const key of Object.keys(this.searchKeys)) {
                    const col = this.commonFilterService.getColumnBindingIndex(this.historyGrid.columns, key);
                    const colFilter = this.historyFilter.getColumnFilter(col);
                    colFilter.conditionFilter.condition1.value = this.commonFilterService.getFilterValSkeleton(colFilter.column);
                    colFilter.conditionFilter.condition1.operator = this.commonFilterService.getFilterOperatorSkeleton(colFilter.column);
                }
                this.historyFilter.apply();
            }

            this.operationConvert(this.gridData.sourceCollection);
            this.changeDetectorRef.detectChanges();
        });

    }

    ngOnInit() {
    }

    flexInitialized(grid) {
        this.siteId = this.commonService.getRoutedSiteFromPath() !== '' ? this.commonService.getRoutedSiteFromPath() : '';

        this.historyGrid = grid;
        for (let i = 0; i < this.historyGrid.columns.length; i++) {
            this.historyGrid.columns[i].search_condition = 0;
        }
        this.historyFilter.filterColumns = [
            'operatorName',
            'operationTime',
            'operationType',
        ];
        // this.grid.columns[9]['search_condition'] = 7;

        const topPath = 'login';
        this.commonService.userLoggedIn('').subscribe(
            (userData: User) => {
                console.log(userData);

                this.isLoading = true;
                this.historyService.getHistoryData(this.currentPage, this.searchKeys, this.sortCondition).subscribe(
                    (data: History[]) => {
                        console.log(data);
                        this.apiData.next(data);
                    },
                    (error) => {
                        this.isLoading = false;
                        this.displayError(this.commonService.getErrorDetail(error));
                    }
                );

                this.changeDetectorRef.detectChanges();
            },
            (error) => {
                // ログイン情報がない場合は、ルートに遷移
                console.log(error);
                this.router.navigate([topPath]);
            }
        );

    }

    private displayError(message: string) {
        this.errorMessage = message;
        this.errorProperty = '';
        this.changeDetectorRef.detectChanges();
    }

    private displaySuccess(message: string) {
        this.errorMessage = message;
        this.successProperty = '';
        this.changeDetectorRef.detectChanges();
    }

    /*
     * ページング処理
     */
    nextPage() {
        console.log('次のページへ');
        const current = this.currentPage + 1;
        this.isLoading = true;
        this.historyService.getHistoryData(current, this.searchKeys, this.sortCondition).subscribe(
            (data: History[]) => {
                if (data.length > 0) {
                    this.apiData.next(data);
                    this.currentPage = current;
                }
            },
            (error) => {
                this.isLoading = false;
                this.displayError(this.commonService.getErrorDetail(error));
            }
        );
    }

    prevPage() {
        if (this.currentPage > 1) {
            const current = this.currentPage - 1;
            this.isLoading = true;
            this.historyService.getHistoryData(current, this.searchKeys, this.sortCondition).subscribe(
                (data: History[]) => {
                    this.apiData.next(data);
                    this.currentPage = current;
                },
                (error) => {
                    this.isLoading = false;
                    this.displayError(this.commonService.getErrorDetail(error));
                }
            );
        } else {
            return false;
        }
    }

    /**
     * フィルタアイコンを押されたとき、そのカラムのデータ型に応じた
     * フィルタUIに既存テンプレートを差し替える関数
     * @param filter フィルタオブジェクト
     * @param event イベント引数
     */
    editTemplateFilter(filter, event) {
        console.log('changed');
        this.commonFilterService.setupSearchFilter(filter, event, this.searchKeys);
    }

    /**
     * フィルタが適用/キャンセル/クリアされたときの挙動を示す関数
     * クリアとキャンセルを分けて処理できるよう判断する処理必要
     * こちらからソートするとソート時もこちらのイベントのみ発火する
     * @param filter フィルタオブジェクト
     * @param event イベント引数
     */
    applyCustomFilter(filter = null, event = null) {
        console.log(filter, event, 'FilterChanged');
        const colFilter = filter.getColumnFilter(event.col);

        // キャンセル時はソート条件が変わっているかを確認し、変わっていればデータを再取得する
        // const isClear = colFilter.column.binding in this.searchKeys && !colFilter.conditionFilter.condition1.value;
        const sortDesc = filter.grid.collectionView.sortDescriptions[0];
        const isAsc = sortDesc.ascending ? 'asc' : 'desc';
        const isSort = (sortDesc.property !== this.sortCondition.sortKey) || (isAsc !== this.sortCondition.sortOrder);

        // 適用ボタンかどうか
        if (!event.cancel) {
            const searchInputed = this.commonFilterService.getInputedFilterValue(colFilter.column);
            // 入力値があるか
            if (searchInputed !== '' && searchInputed != null) {
                this.searchKeys[colFilter.column.binding] = searchInputed;
                // フィルタ適用時,フィルター色変更のため、Wijmo内フィルタに対してもフィルタをかける
                colFilter.conditionFilter.condition1.value = this.commonFilterService.getFilterValSkeleton(colFilter.column);
                colFilter.conditionFilter.condition1.operator = this.commonFilterService.getFilterOperatorSkeleton(colFilter.column);
                filter.apply();
            } else {
                // 適用が押されたけど入力値がない
                delete this.searchKeys[colFilter.column.binding];
                colFilter.clear();
            }
        } else {
            // ソートするか
            if (isSort) {
                this.sortCondition.sortKey = sortDesc.property;
                this.sortCondition.sortOrder = sortDesc.ascending ? 'asc' : 'desc';
            } else {
                // クリア時は該当カラムの検索条件を削除してデータ取得自体は実行
                delete this.searchKeys[colFilter.column.binding];
                colFilter.clear();
            }
        }

        console.log('SearchKey', this.searchKeys);
        this.isLoading = true;
        this.currentPage = 1;
        this.historyService.getHistoryData(this.currentPage, this.searchKeys, this.sortCondition).subscribe(
            (data: History[]) => {
                this.isLoading = false;
                this.apiData.next(data);
            },
            (error) => {
                this.isLoading = false;
                this.displayError(this.commonService.getErrorDetail(error));
            }
        );
    }


    /*
     * 操作内容
     */
    private operationConvert(data: any) {
        console.log(data);
        data.forEach(val => {
            switch (val.operationType) {
                case 1:
                    val.operationType = '現場';
                    switch (val.operationCode) {
                        case '1':
                            val.operationDetail = '作成';
                            break;
                        case '2':
                            val.operationDetail = '更新';
                            break;
                        default:
                            break;
                    }
                    break;
                case 2:
                    val.operationType = 'アカウント';
                    switch (val.operationCode) {
                        case '1':
                            val.operationDetail = 'ログイン';
                            break;
                        case '2':
                            val.operationDetail = 'ログアウト';
                            break;
                        case '3':
                            val.operationDetail = 'パスワード変更';
                            break;
                        case '4':
                            val.operationDetail = 'パスワードリセット';
                            break;
                        case '1':
                        default:
                            break;
                    }
                    break;
                case 3:
                    val.operationType = 'アカウント';
                    switch (val.operationCode) {
                        case '1':
                            val.operationDetail = '作成';
                            break;
                        case '2':
                            val.operationDetail = '更新';
                            break;
                        case '3':
                            val.operationDetail = '停止';
                            break;
                        case '4':
                            val.operationDetail = '再開';
                            break;
                        default:
                            break;
                    }
                    break;
                case 4:
                    val.operationType = '納入依頼';
                    switch (val.operationCode) {
                        case '1':
                            val.operationDetail = '作成';
                            break;
                        case '2':
                            val.operationDetail = '更新';
                            break;
                        case '3':
                            val.operationDetail = '発注';
                            break;
                        case '4':
                            val.operationDetail = '削除';
                            break;
                        default:
                            break;
                    }
                    break;
                case 5:
                    val.operationType = '揚重計画';
                    switch (val.operationCode) {
                        case '1':
                            val.operationDetail = '更新';
                            break;
                        case '2':
                            val.operationDetail = '確定';
                            break;
                        default:
                            break;
                    }
                    break;
                case 6:
                    val.operationType = '揚重申請';
                    switch (val.operationCode) {
                        case '1':
                            val.operationDetail = '作成';
                            break;
                        case '2':
                            val.operationDetail = '更新';
                            break;
                        case '3':
                            val.operationDetail = '削除';
                            break;
                        case '4':
                            val.operationDetail = '申請';
                            break;
                        default:
                            break;
                    }
                    break;
                default:
                    console.log('error');
                    break;
            }
        });
    }

}
