import { Injectable } from '@angular/core';

@Injectable()
export class DataService {
  getData(num: number) {
    let partsName = ['65ランナー', '65スタット', 'C-65x60x2.3', 'Lアングル', '100スタット', '100スペーサ', '65スペーサ', '45ランナー', 'C-38', 'チャンネルホルダー', 'Gブレース', 'ボルト'],
      partsNumber = ['L=3000', 'L=3200', 'L=2300', 'L=2000', 'L=2420', 'L=2520', 'L=2500', '30ｘ30', '', 'L=1100'],
      orderPersonName = ['株）ロンテック', '（株）テスト１', 'タキザワ', 'ディレクト'],
      unit = ['本', '個'],
      orderNo = ['00025', '00003', '00184', '00095', '00123'],
      data = [];

    for (let i = 0; i <= num; i++) {
      data.push({
        sel: false,
        id: i,
        maker: '関包',
        partsName: partsName[Math.floor(Math.random() * partsName.length)],
        partsNumber: partsNumber[Math.floor(Math.random() * partsNumber.length)],
        orderPersonName: orderPersonName[Math.floor(Math.random() * orderPersonName.length)],
        unit: unit[Math.floor(Math.random() * unit.length)],
        quantity: Math.floor(Math.random() * 200),
        orderRequestDateTime: '2019/10/23 10:00',
        orderRequestSection: '23F',
        price: '0',
        weight: '',
        status: '',
        // orderNo: ('00000' + Math.floor( Math.random() * 10 )).slice(-5),
        orderNo: orderNo[Math.floor(Math.random() * orderNo.length)],
      });
    }
    //
    return data;
  }
}
