import { Injectable } from '@angular/core';
import { Constant } from './constant';

@Injectable({
  providedIn: 'root'
})
export class OrderStatusService {
  constructor() { }

  /**
   * ステータスto文字列
   *
   * @param status ステータス
   */
  public toStrings(status: number) {
    return Constant.MATERIAL_STATUS_STR_LIST[status];
  }

  /**
   * 文字列toステータス
   *
   * @param statusName 文字列
   */
  public toStatus(statusName: string) {
    switch　(statusName) {
      case Constant.MATERIAL_STATUS_STR_LIST[Constant.ORDER_STATUS_LIST.STATUS_ADD_NEW]:
        return Constant.ORDER_STATUS_LIST.STATUS_ADD_NEW;
      case Constant.MATERIAL_STATUS_STR_LIST[Constant.ORDER_STATUS_LIST.STATUS_NO_ORDER]:
        return Constant.ORDER_STATUS_LIST.STATUS_NO_ORDER;
      case Constant.MATERIAL_STATUS_STR_LIST[Constant.ORDER_STATUS_LIST.STATUS_ORDERING]:
        return Constant.ORDER_STATUS_LIST.STATUS_ORDERING;
      case Constant.MATERIAL_STATUS_STR_LIST[Constant.ORDER_STATUS_LIST.STATUS_CANCELED]:
        return Constant.ORDER_STATUS_LIST.STATUS_CANCELED;
      case Constant.MATERIAL_STATUS_STR_LIST[Constant.ORDER_STATUS_LIST.STATUS_ARRANGED]:
        return Constant.ORDER_STATUS_LIST.STATUS_ARRANGED;
      case Constant.MATERIAL_STATUS_STR_LIST[Constant.ORDER_STATUS_LIST.STATUS_ARRANGED_CONFIRMED]:
        return Constant.ORDER_STATUS_LIST.STATUS_ARRANGED_CONFIRMED;
      case Constant.MATERIAL_STATUS_STR_LIST[Constant.ORDER_STATUS_LIST.STATUS_DELIVERED]:
        return Constant.ORDER_STATUS_LIST.STATUS_DELIVERED;
      case Constant.MATERIAL_STATUS_STR_LIST[Constant.ORDER_STATUS_LIST.STATUS_ARRANGED_CANCELED]:
        return Constant.ORDER_STATUS_LIST.STATUS_ARRANGED_CANCELED;
    }
  }

  /**
   * ステータスの選択項目
   * 「仮手配」、「手配確定」、「手配キャンセル」を返す。
   */
  public getSelectableList() {
    return [Constant.MATERIAL_STATUS_STR_LIST[Constant.ORDER_STATUS_LIST.STATUS_ARRANGED],
    Constant.MATERIAL_STATUS_STR_LIST[Constant.ORDER_STATUS_LIST.STATUS_ARRANGED_CONFIRMED],
    Constant.MATERIAL_STATUS_STR_LIST[Constant.ORDER_STATUS_LIST.STATUS_ARRANGED_CANCELED],
  ];
  }

  /**
   * ステータスが変更可能かどうかを返す
   * @param originalStatus 元々のステータス
   * @param newStatus 新ステータス
   */
  public isChangeable(originalStatus: number, newStatus: number){
    switch　(originalStatus) {
      case Constant.ORDER_STATUS_LIST.STATUS_NO_ORDER:
      case Constant.ORDER_STATUS_LIST.STATUS_ORDERING:
      case Constant.ORDER_STATUS_LIST.STATUS_ARRANGED:
      case Constant.ORDER_STATUS_LIST.STATUS_ARRANGED_CONFIRMED:
        if (newStatus === Constant.ORDER_STATUS_LIST.STATUS_ARRANGED_CANCELED) {
          return false;
        } else {
          return true;
        }
      case Constant.ORDER_STATUS_LIST.STATUS_CANCELED:
        return newStatus === Constant.ORDER_STATUS_LIST.STATUS_ARRANGED_CANCELED;
      default:
        return false;
    }
  }
}
