import { Injectable } from '@angular/core';
import * as wjcCore from '@grapecity/wijmo';
import { Material } from '../lifting/material';

@Injectable({
  providedIn: 'root'
})
export class MaterialsService {
  constructor() { }

  /**
   * apiDataからusedMaterialsですでに表示しているmaterialを取り除く
   *
   * @param apiData
   * @param cargos
   */
  trimMaterials(apiData: Material[], cargos: any[], materials: any[]) {
    let trimedData: Material[] = [];

    console.log(apiData);
    console.log(cargos);
    console.log(materials);

    // 検索対象がなければそのまま返す
    if ((cargos == null || cargos.length === 0) && (materials == null || materials.length === 0)) {
      return apiData;
    }

    // 使われている可能性のあるidを集めて重複を消す
    const ids = [];
    if (cargos != null && cargos.length > 0) {
      cargos.forEach(cargo => {
        cargo.cargoMaterials.forEach(material => {
          ids.push(material.id);
        });
      });
    }

    if (materials != null && materials.length > 0) {
      materials.forEach(material => {
        ids.push(material.id);
      });
    }

    const ids2 = ids.filter((x, i, self) => {
      return self.indexOf(x) === i;
    });
    console.log(ids2);

    // 残ったidと一致しない資材を返す
    trimedData = apiData.filter((material, i) => {
      return ids2.indexOf(material.id) < 0;
    });
    console.log(trimedData);
    return trimedData;
  }
}
