import {Component, OnInit, ChangeDetectorRef} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import * as wijmo from '@grapecity/wijmo';
import * as grid from '@grapecity/wijmo.grid';
import {Site} from '../../construction/site';
import {User} from '../user';
import {UserService} from '../../../core/service/user.service';
import {SiteService} from '../../../core/service/site.service';
import {CommonService} from '../../../core/common.service';

@Component({
    selector: 'app-user-new',
    templateUrl: './user-new.component.html',
    styleUrls: ['./user-new.component.scss']
})
export class UserNewComponent implements OnInit {
    sites: Site[] = [];
    users: User[] = [];
    arr = Array;
    model: any = {};
    relationData: any = new wijmo.CollectionView([]);
    construction = [];
    siteList = [];
    arraySiteList = [];
    authorityList = [
        {id: 1, name: '管理者'},
        {id: 2, name: '現場担当者'},
        {id: 3, name: '揚重センター'},
        {id: 4, name: '手配担当者'},
        {id: 5, name: '施工業者（野原顧客）'},
        {id: 6, name: '施工業者'}
    ];
    siteMap: grid.DataMap;
    isLoading = false;
    isInvalid = false;
    searchKeys = {};
    sortCondition = {sortKey: 'name', sortOrder: 'asc'};

    isPhoneNumOK = false;
    isEmailOK = false;
    isMobileNumOK = false;
    isFaxNumOK = true;

    isEmailTouched = false;
    isPhoneTouched = false;
    isMobileTouched = false;
    isFaxTouched = false;

    isCreateNew = true;

    alertMessage: string;
    displayProperty;

    // エラー表示
    public errorMessage: string;
    public errorProperty = 'none';
    public successProperty = 'none';

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private userService: UserService,
        private siteService: SiteService,
        public changeDetectorRef: ChangeDetectorRef,
        private commonService: CommonService
    ) {
    }

    siteId = '';
    ngOnInit() {
      this.displayProperty = 'none';
      this.siteId = this.route.snapshot.paramMap.get('siteId');
      const id = this.route.snapshot.paramMap.get('id');

      this.isCreateNew = (id != null);
      if (id) {
            /*
             * userIdのユーザー情報をAPIから取得してmodelに反映
             */
            this.userService.getUser(id).subscribe(
                (data: User) => {
                    this.model = data;
                    this.isInvalid = true;
                    // ユーザーに紐づいている現場情報を格納
                    // id: name の形式で表示する
                    const siteName = [];
                    this.model.site.map(x => {
                        siteName.push({name: x.id + ': ' + x.name});
                    });

                    this.isEmailOK = this.commonService.validateEmailAddress(this.model.email);
                    this.isPhoneNumOK = this.commonService.validatePhoneNum(this.model.tel);
                    this.isMobileNumOK = this.commonService.validatePhoneNum(this.model.mobile);
                    if (this.model.fax) {
                      this.isFaxNumOK = this.commonService.validatePhoneNum(this.model.fax);
                    } else {
                      this.isFaxNumOK = true;
                    }

                    this.relationData = new wijmo.CollectionView(siteName);
                    // this.relationData = this.model.site;
                },
                (error) => {
                    this.isLoading = false;
                    this.displayError(this.commonService.getErrorDetail(error));
                }
            );
        } else {
            // 選択リストの初期設定
            this.model.authority = 0;
        }

        /*
         * 現場一覧をAPIから取得
         */
        this.siteService.getAllSites(0, false, this.searchKeys, this.sortCondition).subscribe(
            (sites: Site[]) => {
                this.sites = sites;

                // 現場リストの配列を作成
                // sites.map( x => this.siteList.push({id: x.id, name: x.name}) );
                sites.map(x => {
                    // 現場の終了日以前のものだけ対象
                    const endDate = new Date(x.periodEnd);
                    const today = new Date();
                    if (today < endDate) {
                        this.siteList.push({id: x.id, name: x.name});
                    }
                });
                // dataMapに名前だけの配列を入れる
                this.siteMap = new grid.DataMap(this.siteList.map(x => x.id + ': ' + x.name));
            },
            (error) => {
                this.isLoading = false;
                this.displayError(this.commonService.getErrorDetail(error));
            }
        );

    }

    flexInitialized(flex: grid.FlexGrid) {
        flex.cellEditEnded.addHandler((s: grid.FlexGrid, e: grid.CellEditEndingEventArgs) => {
            this.isInvalid = this.gridDataCheck();
        });
        flex.hostElement.addEventListener('keydown', (e: KeyboardEvent) => {
            if ((e.keyCode == wijmo.Key.Delete) || (e.keyCode == wijmo.Key.Back)) {
                this.isInvalid = this.gridDataCheck();
            }
        });
    }

    private gridDataCheck() {
        let check = false;
        this.relationData.items.map(x => {
            if (x.name !== '' && x.name !== undefined) {
                check = check || true;
            } else {
                check = check || false;
            }
        });
        return check;
    }

    private displayError(message: string) {
        this.errorMessage = message;
        this.errorProperty = '';
        this.successProperty = 'none';
        this.changeDetectorRef.detectChanges();
    }

    /*
     * ユーザー情報のCRUD操作
     */
    private createData(): void {
        const arraySiteData: any = [];
        const newAry = this.relationData.items.map(x => x.name);

        // site データ作成
        newAry.forEach(item => {
            // 空行は無視
            if (item !== undefined && item !== '') {
                // itemは「id: name」という形式で現場情報を持っている
                // 最初に一致する:の位置を検索
                const n = item.search(/:/);
                // 0〜nまでをIDとして抽出
                const siteId = item.slice(0, n);
                // : から末尾までを現場名として抽出
                const siteName = item.slice(n + 2);
                // 連想配列にして格納
                arraySiteData.push({
                    id: siteId,
                    name: siteName
                });
            }
        });

        // 現場情報を追加
        this.model.site = arraySiteData;
        if (this.model.site.length === 0) {
            this.displayError('現場が選択されていません。');
            return;
        }

        this.model.suspend = false;
        console.log(this.model);

        this.successProperty = 'none';
        this.changeDetectorRef.detectChanges();
        this.isLoading = true;
        this.userService.createUser(this.model).subscribe(
            () => {
                this.isLoading = false;
                // this.users.push(data);
                this.router.navigate(['master/user']);
            },
            (error) => {
                this.isLoading = false;
                this.displayError(this.commonService.getErrorDetail(error));
            }
        );
    }

    // アカウントの停止・再開
    suspendData(status: boolean) {
        this.model.suspend = status;
        console.log(this.model);
        const data: any = {};
        data.userId = this.model.id;
        data.status = status;

        this.isLoading = true;
        this.userService.userSuspend(this.model.id, data).subscribe(
            () => {
                this.isLoading = false;
                this.router.navigate(['master/user']);
            },
            (error) => {
                this.displayError(this.commonService.getErrorDetail(error));
            }
        );
    }

    resetPassword() {
        this.isLoading = true;
        this.userService.userPasswordReset(this.model.email).subscribe(
            (data: User) => {
              this.isLoading = false;
              console.log(data);
              this.alertMessage = '新しいパスワードをメールアドレスに送信しました';
              this.displayProperty = '';
              this.changeDetectorRef.detectChanges();
            },
            (error) =>{
              this.isLoading = false;
              this.alertMessage = error.error.message;
              this.displayProperty = '';
              this.changeDetectorRef.detectChanges();
            }
          );
    }

    get isValidationOK() {
      return this.isEmailOK && this.isPhoneNumOK && this.isMobileNumOK && this.isFaxNumOK;
    }

    validateEmailAddress(str) {
        this.isEmailTouched = true;
        this.isEmailOK = this.commonService.validateEmailAddress(str);
        // this.isInvalid = this.isEmailOK;
        this.changeDetectorRef.detectChanges();
    }

    validatePhoneNum(str) {
        this.isPhoneTouched = true;
        this.isPhoneNumOK = this.commonService.validatePhoneNum(str);
        // this.isInvalid = this.isPhoneNumOK;
        this.changeDetectorRef.detectChanges();
    }

    validateMobileNum(str) {
        this.isMobileTouched = true;
        this.isMobileNumOK = this.commonService.validatePhoneNum(str);
        // this.isInvalid = this.isMobileNumOK;
        this.changeDetectorRef.detectChanges();
    }

    validateFaxNum(str) {
        this.isFaxTouched = true;
        this.isFaxNumOK = this.commonService.validatePhoneNum(str);
        if (str === '') {
            this.isFaxNumOK = true;
        }
        // this.isInvalid = this.isFaxNumOK;
        this.changeDetectorRef.detectChanges();
    }

}
