import {Component, OnInit, ChangeDetectorRef} from '@angular/core';
import {Router} from '@angular/router';
import {Subject} from 'rxjs';
import * as wjcCore from '@grapecity/wijmo';
import * as wjcGrid from '@grapecity/wijmo.grid';
import {Site} from '../site';
import {SiteService} from '../../../core/service/site.service';
import {CommonFilterUiService} from '../../../core/common-filter-ui.service';
import {CommonService} from '../../../core/common.service';
import {User} from '../../user/user';

@Component({
    selector: 'app-construction-list',
    templateUrl: './construction-list.component.html',
    styleUrls: ['./construction-list.component.scss']
})
export class ConstructionListComponent implements OnInit {
    sites = new wjcCore.CollectionView([]);
    apiData: Subject<any> = new Subject();
    currentPage = 1;
    siteListFilter;
    searchKeys = {};
    isLoading = false;
    sortCondition = {sortKey: 'name', sortOrder: 'asc'};
    siteListGrid;

    isLastPage = false;

    // エラー表示
    public errorMessage: string;
    public errorProperty = 'none';
    public successProperty = 'none';

    private displayError(message: string) {
        this.errorMessage = message;
        this.errorProperty = '';
        this.changeDetectorRef.detectChanges();
    }

    private displaySuccess(message: string) {
        this.errorMessage = message;
        this.successProperty = '';
        this.changeDetectorRef.detectChanges();
    }

    constructor(
        private router: Router,
        private siteService: SiteService,
        public changeDetectorRef: ChangeDetectorRef,
        private commonFilterService: CommonFilterUiService,
        private commonService: CommonService) {

        this.apiData.subscribe(apidata => {
            this.isLoading = false;
            const gridData = [];
            let status: string;
            apidata.forEach(val => {
                val.open ? status = 'オープン' : status = 'クローズ';
                gridData.push({
                    id: val.id,
                    name: val.name,
                    address: val.address,
                    periodStart: new Date(val.periodStart),
                    periodEnd: new Date(val.periodEnd),
                    url: val.url,
                    elevator: val.elevator,
                    // appDeadline: val.appDeadline,
                    // replyDeadline: val.replyDeadline,
                    open: status,
                });
            });
            this.sites = new wjcCore.CollectionView(gridData);
            this.sites.sortDescriptions.clear();
            this.sites.sortDescriptions.push(
                new wjcCore.SortDescription(this.sortCondition.sortKey,
                    this.sortCondition.sortOrder === 'asc'));
            this.isLastPage = this.commonService.isPagingLimit(this.sites.sourceCollection);

            if (0 !== Object.keys(this.searchKeys).length) {
                for (let key of Object.keys(this.searchKeys)) {
                    const col = this.commonFilterService.getColumnBindingIndex(this.siteListGrid.columns, key);
                    const colFilter = this.siteListFilter.getColumnFilter(col);
                    colFilter.conditionFilter.condition1.value = this.commonFilterService.getFilterValSkeleton(colFilter.column);
                    colFilter.conditionFilter.condition1.operator =
                        this.commonFilterService.getFilterOperatorSkeleton(colFilter.column);
                }
                this.siteListFilter.apply();
            }
            this.sites.refresh();
            this.changeDetectorRef.detectChanges();
        });
    }

    ngOnInit() {
    }

    flexInitialized(flexgrid: wjcGrid.FlexGrid) {
        this.siteListGrid = flexgrid;
        for (let i = 0; i < this.siteListGrid.columns.length; i++) {
            this.siteListGrid.columns[i]['search_condition'] = null;
        }
        this.siteListFilter.filterColumns = [
            'name', 'address',
            'periodStart', 'periodEnd',
            'open',
        ];

        const isOpenList = [
            {key: 'オープン', value: 'true'},
            {key: 'クローズ', value: 'false'},
        ];
        this.siteListGrid.columns[9]['masterList'] =
            this.commonFilterService.createOptionsList(isOpenList, 'key', 'value', []);

        flexgrid.formatItem.addHandler((s: wjcGrid.FlexGrid, e: wjcGrid.FormatItemEventArgs) => {
            // 現場URLをテキストリンクにする
            if (e.panel !== s.columnHeaders && s.columns[e.col].binding === 'url') {
                const url = s.rows[e.row].dataItem.url;

                if (url !== '') {
                    e.cell.innerHTML = '<a href="' + url + '" target="_blank">' + url + '</a>';
                }
            }

            this.commonService.addGridEditMark(s, e, 'id');
        });

        flexgrid.addEventListener(flexgrid.hostElement, 'mouseup', (e: MouseEvent) => {
            const ht = flexgrid.hitTest(e);
            if (flexgrid.rows[ht.row]) {
                const id = flexgrid.rows[ht.row].dataItem.id;
                // 行選択したときのみリンクする
                if (ht.panel == flexgrid.rowHeaders) {
                    this.router.navigate([`master/construction/${id}`]);
                }
            }
        });

        const topPath = 'login';
        this.commonService.userLoggedIn('').subscribe(
            (userData: User) => {
                console.log(userData);

                // 現場一覧をAPIから取得
                this.isLoading = true;
                this.siteService.getAllSites(this.currentPage, true, this.searchKeys, this.sortCondition).subscribe(
                    (data: Site[]) => {
                        this.isLoading = false;
                        this.apiData.next(data);
                    },
                    (error) => {
                        this.isLoading = false;
                        this.displayError(this.commonService.getErrorDetail(error));
                    }
                );

                this.changeDetectorRef.detectChanges();
            },
            (error) => {
                // ログイン情報がない場合は、ルートに遷移
                console.log(error);
                this.router.navigate([topPath]);
            }
        );

    }

    /*
     * ページング処理
     */
    nextPage() {
        const current = this.currentPage + 1;
        this.isLoading = true;
        this.siteService.getAllSites(current, true, this.searchKeys, this.sortCondition).subscribe(
            (data: Site[]) => {
                this.isLoading = false;
                if (data.length > 0) {
                    this.apiData.next(data);
                    this.currentPage = current;
                }
            },
            (error) => {
                this.isLoading = false;
                this.displayError(this.commonService.getErrorDetail(error));
            }
        );
    }

    prevPage() {
        if (this.currentPage > 1) {
            const current = this.currentPage - 1;
            this.isLoading = true;
            this.siteService.getAllSites(current, true, this.searchKeys, this.sortCondition).subscribe(
                (data: Site[]) => {
                    this.isLoading = false;
                    this.apiData.next(data);
                    this.currentPage = current;
                },
                (error) => {
                    this.isLoading = false;
                    this.displayError(this.commonService.getErrorDetail(error));
                }
            );
        } else {
            return false;
        }
    }

    /**
     * フィルタアイコンを押されたとき、そのカラムのデータ型に応じた
     * フィルタUIに既存テンプレートを差し替える関数
     * @param filter フィルタオブジェクト
     * @param event イベント引数
     */
    editTemplateFilter(filter, event) {
        console.log('changed');
        this.commonFilterService.setupSearchFilter(filter, event, this.searchKeys);
    }

    /**
     * フィルタが適用/キャンセル/クリアされたときの挙動を示す関数
     * クリアとキャンセルを分けて処理できるよう判断する処理必要
     * こちらからソートするとソート時もこちらのイベントのみ発火する
     * @param filter フィルタオブジェクト
     * @param event イベント引数
     */
    applyCustomFilter(filter = null, event = null) {
        console.log(filter, event, 'FilterChanged');
        const colFilter = filter.getColumnFilter(event.col);

        // キャンセル時はソート条件が変わっているかを確認し、変わっていればデータを再取得する
        // const isClear = colFilter.column.binding in this.searchKeys && !colFilter.conditionFilter.condition1.value;
        const sortDesc = filter.grid.collectionView.sortDescriptions[0];
        const isAsc = sortDesc.ascending ? 'asc' : 'desc';
        const isSort = (sortDesc.property !== this.sortCondition.sortKey) || (isAsc !== this.sortCondition.sortOrder);

        // 適用ボタンかどうか
        if (!event.cancel) {
            const searchInputed = this.commonFilterService.getInputedFilterValue(colFilter.column);
            // 入力値があるか
            if (searchInputed !== '' && searchInputed != null) {
                this.searchKeys[colFilter.column.binding] = searchInputed;
                // フィルタ適用時,フィルター色変更のため、Wijmo内フィルタに対してもフィルタをかける
                colFilter.conditionFilter.condition1.value = this.commonFilterService.getFilterValSkeleton(colFilter.column);
                colFilter.conditionFilter.condition1.operator = this.commonFilterService.getFilterOperatorSkeleton(colFilter.column);
                filter.apply();
            } else {
                // 適用が押されたけど入力値がない
                delete this.searchKeys[colFilter.column.binding];
                colFilter.clear();
            }
        } else {
            // ソートするか
            if (isSort) {
                this.sortCondition.sortKey = sortDesc.property;
                this.sortCondition.sortOrder = sortDesc.ascending ? 'asc' : 'desc';
            } else {
                // クリア時は該当カラムの検索条件を削除してデータ取得自体は実行
                delete this.searchKeys[colFilter.column.binding];
                colFilter.clear();
            }
        }

        console.log('SearchKey', this.searchKeys);
        this.isLoading = true;
        this.currentPage = 1;
        this.siteService.getAllSites(this.currentPage, true, this.searchKeys, this.sortCondition).subscribe(
            (data: Site[]) => {
                this.isLoading = false;
                this.apiData.next(data);
            },
            (error) => {
                this.isLoading = false;
                this.displayError(this.commonService.getErrorDetail(error));
            }
        );
    }

}
