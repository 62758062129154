import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Cargo } from 'src/app/lifting/cargo';
import { catchError, tap } from 'rxjs/operators';
import { CommonService } from '../common.service';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
  })
};

@Injectable({
  providedIn: 'root'
})
export class CargoService {
  constructor(private http: HttpClient,
              private commonService: CommonService) {
    this.siteId = this.commonService.getRoutedSiteFromPath() !== '' ? this.commonService.getRoutedSiteFromPath() : '';
  }

  /*
   * API処理
   */
  public static readonly CARGO_PAGE = 30;

  private siteId: any;

  /**
   * 揚重申請一覧を取得
   * @param page 取得したいページ番号
   * @param searchKeys 検索条件オブジェクト
   * @param sort ソート条件オブジェクト
   */
  getAllLiftings(page: number, searchKeys = {}, sort = {}, isDelivered: boolean): Observable<HttpResponse<Cargo[]>> {
    const limit = CargoService.CARGO_PAGE;
    // offsetは0から始まる
    let offset = limit * (page - 1);
    if (offset < 0) {
      offset = 0;
    }
    let url = environment.apiUrl + '/cargo?';
    if (this.siteId) {
      url += 'siteId=' + this.siteId;
    }
    url += '&limit=' + limit;
    url += '&offset=' + offset;
    if (0 !== Object.keys(searchKeys).length) {
      for (let key of Object.keys(searchKeys)) {
        url += '&' + key + '=' + searchKeys[key];
      }
    }
    if (sort['sortKey']) {
      // APIパラメータ用にキーを補正
      if (sort['sortKey'].indexOf('requestStartDateTime') !== -1) {
        url += '&sort=' + 'requestStartDate';
      } else if (sort['sortKey'].indexOf('status') !== -1) {
        url += '&sort=' + 'applicationStatus';
      } else {
        url += '&sort=' + sort['sortKey'];
      }

      url += '&order=' + sort['sortOrder'];
    }
    url += '&delivered=' + isDelivered;
    return this.http.get<Cargo[]>(url, { observe: 'response' });
  }

  // 揚重申請一覧データ更新/揚重申請確定
  updateCargo(cargoInfo: Cargo): Observable<Cargo> {
    const body = JSON.stringify(cargoInfo);
    console.log(body);
    return this.http.post<any>(`${environment.apiUrl}/cargo`, body, httpOptions);
  }

  /*
   * エラー詳細を取得
   */
  // private handleError<T>(operation = 'operation', result?: T) {
  //   return (error: any): Observable<T> => {

  //     // TODO: send the error to remote logging infrastructure
  //     console.error(error); // log to console instead

  //     // TODO: better job of transforming error for user consumption
  //     console.log(`${operation} failed: ${error.message}`);

  //     // Let the app keep running by returning an empty result.
  //     return of(result as T);
  //   };
  // }
}
