import { Injectable, ViewChild } from '@angular/core';
import * as input from '@grapecity/wijmo.input';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class SessionErrorService {
  @ViewChild('thePopup', {static: true}) thePopup: input.Popup;

  constructor(
    private router: Router
  ) { }

  public sessionTimeout(siteId) {
    if (siteId != null) {
      this.router.navigate([siteId + '/login']);
    } else {
      this.router.navigate(['login']);
    }
  }
}
