import { environment } from 'src/environments/environment';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule }   from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { WjGridModule } from '@grapecity/wijmo.angular2.grid';
import { WjInputModule } from '@grapecity/wijmo.angular2.input';
import { WjGridFilterModule } from '@grapecity/wijmo.angular2.grid.filter';
import '@grapecity/wijmo.cultures/wijmo.culture.ja';
import { CookieService } from 'ngx-cookie-service';

import { AppRoutingModule } from './app-routing.module';
import { DataService } from './app.data';
import { UserService } from './core/service/user.service';
import { SiteService } from './core/service/site.service';
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { LoginComponent } from './login/login.component';
import { OrderListComponent } from './order/order-list/order-list.component';
import { OrderDetailComponent } from './order/order-detail/order-detail.component';
import { LiftingListComponent } from './lifting/lifting-list/lifting-list.component';
import { LiftingDetailComponent } from './lifting/lifting-detail/lifting-detail.component';
import { LiftingDetailMaterialComponent } from './lifting/lifting-detail-material/lifting-detail-material.component';

import { UserListComponent } from './master/user/user-list/user-list.component';
import { UserDetailComponent } from './master/user/user-detail/user-detail.component';
import { UserNewComponent } from './master/user/user-new/user-new.component';
import { ConstructionListComponent } from './master/construction/construction-list/construction-list.component';
import { ConstructionDetailComponent } from './master/construction/construction-detail/construction-detail.component';
import { PasswordChangeComponent } from './password/password-change/password-change.component';
import { PasswordResetComponent } from './password/password-reset/password-reset.component';
import { CommonFilterUiComponent } from './core/common-filter-ui/common-filter-ui.component';
import { DatePipe } from '@angular/common';
import * as wjcCore from '@grapecity/wijmo';
import { HistoryComponent } from './master/history/history.component';
import { AuthRedirectComponent } from './auth-redirect/auth-redirect.component';
import { CommonLoadingComponent } from './core/common-loading/common-loading.component';
import { PageNotFoundComponent } from './core/page-not-found/page-not-found.component';

wjcCore.setLicenseKey(environment.wijimoLicenseKey);

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    HeaderComponent,
    UserListComponent,
    UserDetailComponent,
    UserNewComponent,
    ConstructionListComponent,
    ConstructionDetailComponent,
    PasswordChangeComponent,
    PasswordResetComponent,
    CommonFilterUiComponent,
    OrderListComponent,
    OrderDetailComponent,
    LiftingListComponent,
    LiftingDetailComponent,
    LiftingDetailMaterialComponent,
    HistoryComponent,
    AuthRedirectComponent,
    CommonLoadingComponent,
    PageNotFoundComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    WjGridModule,
    WjInputModule,
    WjGridFilterModule,
    FormsModule,
    HttpClientModule
  ],
  providers: [DataService, CookieService, UserService, SiteService, DatePipe],
  bootstrap: [AppComponent],
})
export class AppModule { }
