import {Component, OnInit, ViewChild, ChangeDetectorRef} from '@angular/core';
import {Subject} from 'rxjs';
import {FlexGrid, CellRange} from '@grapecity/wijmo.grid';
import * as wijmo from '@grapecity/wijmo';
import * as wjcGrid from '@grapecity/wijmo.grid';
import {Order} from '../order';
import {OrderService} from '../../core/service/order.service';
import {CommonService} from '../../core/common.service';
import {CommonFilterUiService} from '../../core/common-filter-ui.service';
import {GeneralSituationCheckerService} from '../../core/general-situation-checker.service';
import {Constant} from 'src/app/core/constant';
import {User} from '../../master/user/user';
import {Router} from '@angular/router';

/**
 * wijmoのGridマージを拡張
 */
class RestrictedMergeManager extends wjcGrid.MergeManager {
    constructor(flexgrid: wjcGrid.FlexGrid) {
        super(flexgrid);
    }

    getMergedRange(p: wjcGrid.GridPanel, r: number, c: number) {
        // Noが同じならcol[5]までを結合する
        if (c > 5) {
            return null;
        }
        // create basic cell range
        let rng = null;
        // start with single cell
        rng = new wjcGrid.CellRange(r, c);

        // get reference values to use for merging
        const val = p.getCellData(r, 0, false);

        // expand up
        while (rng.row > 0 && p.getCellData(rng.row - 1, 0, false) === val) {
            rng.row--;
        }
        // expand down
        while (rng.row2 < p.rows.length - 1 && p.getCellData(rng.row2 + 1, 0, false) === val) {
            rng.row2++;
        }

        // don't bother with single-cell ranges
        if (rng.isSingleCell) {
            rng = null;
        }
        // done
        return rng;
    }
}

@Component({
    selector: 'app-order-list',
    templateUrl: './order-list.component.html',
    styleUrls: ['./order-list.component.scss']
})

export class OrderListComponent implements OnInit {
    apiData: Subject<any> = new Subject();
    gridData: any = [];
    // csvGridData: any = [];
    csvGridData = new wijmo.CollectionView([]);
    data: wijmo.CollectionView;
    userData: any = {};
    // ページング用変数
    currentPage = 1;
    totalCount = 0;

    nounyuGrid;
    nounyuListFilter;
    searchKeys = {};
    sortCondition = {sortKey: 'requestDateTime', sortOrder: 'asc'};
    isLoading = false;
    isChecked = false;

    // 定数取得
    constant = Constant;

    isLastPage = false;

    // エラー表示
    public errorMessage: string;
    public errorProperty = 'none';
    public successProperty = 'none';

    situationCheckerResult: any;

    // reference to grid component
    @ViewChild('grid', {static: true}) grid: FlexGrid;
    @ViewChild('csvGrid', {static: true}) csvGrid: FlexGrid;

    // コンストラクタでルーターを定義しておく
    constructor(
        private orderService: OrderService,
        public changeDetectorRef: ChangeDetectorRef,
        public situationChecker: GeneralSituationCheckerService,
        private commonService: CommonService,
        private uiFilter: CommonFilterUiService,
        private router: Router) {
        this.situationCheckerResult = {};

        // APIデータを取得した後の処理を定義
        this.apiData.subscribe(apidata => {
            this.isLoading = false;

            let dataList: Array<any> = [];
            dataList = this.dataMarge(apidata);

            // Noでソートする
            // this.gridData = new wijmo.CollectionView(dataList, {
            //   sortDescriptions: [ 'orderDate' ],
            // });
            this.gridData = new wijmo.CollectionView(dataList);
            this.gridData.sortDescriptions.clear();
            this.gridData.sortDescriptions.push(
                new wijmo.SortDescription(this.sortCondition.sortKey, this.sortCondition.sortOrder === 'asc'));
            this.isLastPage = this.commonService.isPagingLimit(this.gridData.sourceCollection);

            if (0 !== Object.keys(this.searchKeys).length) {
                for (const key of Object.keys(this.searchKeys)) {
                    const col = this.uiFilter.getColumnBindingIndex(this.nounyuGrid.columns, key);
                    const colFilter = this.nounyuListFilter.getColumnFilter(col);
                    colFilter.conditionFilter.condition1.value = this.uiFilter.getFilterValSkeleton(colFilter.column);
                    colFilter.conditionFilter.condition1.operator = this.uiFilter.getFilterOperatorSkeleton(colFilter.column);
                }
                this.nounyuListFilter.apply();
            }

            this.changeDetectorRef.detectChanges();

        });
    }

    ngOnInit() {
    }

    /**
     * 納入一覧Gridの初期化
     * @param flexgrid 納入一覧Grid
     */
    flexInitialized(flexgrid: wjcGrid.FlexGrid) {
        this.nounyuGrid = flexgrid;
        // カスタムフィルタの条件作成
        for (let i = 0; i < this.nounyuGrid.columns.length; i++) {
            this.nounyuGrid.columns[i].search_condition = null;
        }
        this.nounyuListFilter.filterColumns = [
            'number',
            'name',
            'orderDate', 'requestDateTime',
            'requestSection',
            // 'status'
            // 'companyName',
            // liftingNumber,
            // 'confirmDateTime', // API側でリレーション先ソート未対応なのでいったん外す
        ];
        // テキスト入力
        this.nounyuGrid.columns[0].search_condition = 0;
        this.nounyuGrid.columns[1].search_condition = 1;
        this.nounyuGrid.columns[5].search_condition = 4;
        this.nounyuGrid.columns[6].search_condition = 0;
        // カレンダー入力
        this.nounyuGrid.columns[2].search_condition = 7;
        this.nounyuGrid.columns[2].isDateCol = true;
        this.nounyuGrid.columns[3].search_condition = 7;
        this.nounyuGrid.columns[3].isDateCol = true;
        this.nounyuGrid.columns[9].search_condition = 7;
        this.nounyuGrid.columns[9].isDateCol = true;
        // 時間入力
        // this.nounyuGrid.columns[4]['search_condition'] = 0;
        // this.nounyuGrid.columns[10]['search_condition'] = 0;

        // 揚重申請Noが重複しているレコードがあればマージする
        flexgrid.mergeManager = new RestrictedMergeManager(flexgrid);

        flexgrid.formatItem.addHandler((s: wjcGrid.FlexGrid, e: wjcGrid.FormatItemEventArgs) => {
            if (s.rows.length === 0) {
                return;
            }

            const data = s.rows[e.row].dataItem;
            if (e.panel !== s.columnHeaders) {
              if (s.columns[e.col].binding === 'youjuEntry') {
                if (data.orderListDetailStatus) {
                  const createLifting = data.orderListDetailStatus.createLifting;
                  const needSiteStuffOperate = data.orderListDetailStatus.needSiteStuffOperate;
                  const needArrengeOprate = data.orderListDetailStatus.needArrengeOprate;

                  // 揚重申請作成
                  if (createLifting) {
                      e.cell.innerHTML =
                          '<a class="btn btn-outline-secondary" style="padding:0 5px;font-size:14px;" href="/'
                          + this.userData.site.id + '/lifting/new?order=true&orderId=' + data.id + '" target="_blank">揚重申請作成</a>';
                  // 現場担当要対応
                  } else if (needSiteStuffOperate) {
                      e.cell.innerHTML =
                          '<a class="btn btn-outline-info" style="padding:0 5px;font-size:14px;" href="'
                          + this.userData.site.id + '/lifting/' + data.liftingId + '" target="_blank">資材情報変更あり</a>';
                  // 手配担当要対応
                　} else if (needArrengeOprate) {
                      e.cell.innerHTML =
                          '<a class="btn btn-outline-success" style="padding:0 5px;font-size:14px;" href="'
                          + this.userData.site.id + '/order/' + data.id + '" target="_blank">資材情報変更あり</a>';
                  }
              }

              // 納入申請Noをテキストリンクにする
              } else if (e.panel !== s.columnHeaders && s.columns[e.col].binding === 'number') {
                  if (data.number !== '') {
                      e.cell.innerHTML = '<a href="/' + this.userData.site.id + '/order/' + data.id + '">' + data.number + '</a>';
                  }

                  // 揚重申請Noをテキストリンクにする
              } else if (s.columns[e.col].binding === 'liftingNumber') {
                  e.cell.innerHTML = '<a href="/' + this.userData.site.id +
                      '/lifting/' + data.liftingId + '" target="_blank">' + data.liftingNumber + '</a>';
              }
            }
        });

        const siteId = this.commonService.getRoutedSiteFromPath();
        const topPath = siteId !== '' ? siteId + '/login' : 'login';

        this.commonService.userLoggedIn(siteId).subscribe(
              (userData: User) => {
                console.log(userData);
                this.userData = userData;

                this.situationCheckerResult = this.situationChecker.getOrderListStatus(this.userData);

                this.isLoading = true;
                this.orderService.getAllOrders(1, OrderService.ORDER_PAGE, this.searchKeys, this.sortCondition, this.isChecked).subscribe(
                  (response) => {
                    this.isLoading = false;
                    this.apiData.next(response.body);

                    this.totalCount = Number(response.headers.get('X-Total-Count'));
                    console.log(this.totalCount);
                  },
                  (error) => {
                      this.isLoading = false;
                      this.displayError(this.commonService.getErrorDetail(error));
                  }
              );

              this.changeDetectorRef.detectChanges();
            },
            (error) => {
                // ログイン情報がない場合は、ルートに遷移
                console.log(error);
                this.router.navigate([topPath]);
            }
        );
    }

    /**
     * 最大ページ数
     */
    private getMaxPage(): number {
      let max = Math.floor(this.totalCount / OrderService.ORDER_PAGE);
      if (max === 0) {
        return 1;
      } else {
        if (this.totalCount % OrderService.ORDER_PAGE > 0) {
          ++max;
        }
      }
      return max;
    }

    /**
     * ページング処理
     */
    nextPage() {
      if (this.getMaxPage() >= this.currentPage + 1) {
        ++this.currentPage;

        this.isLoading = true;
        this.orderService.getAllOrders(this.currentPage, OrderService.ORDER_PAGE, this.searchKeys, this.sortCondition, this.isChecked).subscribe(
          (response) => {
            this.isLoading = false;
            this.totalCount = Number(response.headers.get('X-Total-Count'));
            console.log(this.totalCount);
            if (response.body.length > 0) {
              this.apiData.next(response.body);
              // this.currentPage = current;
            }
          },
          (error) => {
              this.isLoading = false;
              this.displayError(this.commonService.getErrorDetail(error));
          }
        );
      }
    }

    prevPage() {
      if (this.currentPage > 1) {
          --this.currentPage;
          this.isLoading = true;
          this.orderService.getAllOrders(this.currentPage, OrderService.ORDER_PAGE, this.searchKeys, this.sortCondition, this.isChecked).subscribe(
            (response) => {
              this.isLoading = false;
              this.totalCount = Number(response.headers.get('X-Total-Count'));
              console.log(this.totalCount);
              if (response.body.length > 0) {
                this.apiData.next(response.body);
              }
            },
            (error) => {
                this.isLoading = false;
                this.displayError(this.commonService.getErrorDetail(error));
            }
          );
      } else {
          return false;
      }
    }

    /**
     * フィルタアイコンを押されたとき、そのカラムのデータ型に応じた
     * フィルタUIに既存テンプレートを差し替える関数
     * @param filter フィルタオブジェクト
     * @param event イベント引数
     */
    editTemplateFilter(filter, event) {
        this.uiFilter.setupSearchFilter(filter, event, this.searchKeys);
    }

    /**
     * フィルタが適用/キャンセル/クリアされたときの挙動を示す関数
     * クリアとキャンセルを分けて処理できるよう判断する処理必要
     * こちらからソートするとソート時もこちらのイベントのみ発火する
     * @param filter フィルタオブジェクト
     * @param event イベント引数
     */
    applyCustomFilter(filter = null, event = null) {
        console.log(filter, event, 'FilterChanged');
        const colFilter = filter.getColumnFilter(event.col);

        // キャンセル時はソート条件が変わっているかを確認し、変わっていればデータを再取得する
        // const isClear = colFilter.column.binding in this.searchKeys && !colFilter.conditionFilter.condition1.value;
        const sortDesc = filter.grid.collectionView.sortDescriptions[0];
        const isAsc = sortDesc.ascending ? 'asc' : 'desc';
        const isSort = (sortDesc.property !== this.sortCondition.sortKey) || (isAsc !== this.sortCondition.sortOrder);

        // 適用ボタンかどうか
        if (!event.cancel) {
            const searchInputed = this.uiFilter.getInputedFilterValue(colFilter.column);
            // 入力値があるか
            if (searchInputed !== '' && searchInputed != null) {
                this.searchKeys[colFilter.column.binding] = searchInputed;
                // フィルタ適用時,フィルター色変更のため、Wijmo内フィルタに対してもフィルタをかける
                colFilter.conditionFilter.condition1.value = this.uiFilter.getFilterValSkeleton(colFilter.column);
                colFilter.conditionFilter.condition1.operator = this.uiFilter.getFilterOperatorSkeleton(colFilter.column);
                filter.apply();
            } else {
                // 適用が押されたけど入力値がない
                delete this.searchKeys[colFilter.column.binding];
                colFilter.clear();
            }
        } else {
            // ソートするか
            if (isSort) {
                this.sortCondition.sortKey = sortDesc.property;
                this.sortCondition.sortOrder = sortDesc.ascending ? 'asc' : 'desc';
            } else {
                // クリア時は該当カラムの検索条件を削除してデータ取得自体は実行
                delete this.searchKeys[colFilter.column.binding];
                colFilter.clear();
            }
        }

        console.log('SearchKey', this.searchKeys, this.sortCondition);
        this.isLoading = true;
        this.orderService.getAllOrders(1, OrderService.ORDER_PAGE, this.searchKeys, this.sortCondition, this.isChecked).subscribe(
          (response) => {
            this.isLoading = false;
            this.totalCount = Number(response.headers.get('X-Total-Count'));
            console.log(this.totalCount);
            const data = response.body;
            if (data.length > 0) {
              this.apiData.next(data);
              this.currentPage = 1;
            } else {
              this.apiData.next([]);
              this.currentPage = 1;
            }
          },
          // (data: Order[]) => {
          //     this.isLoading = false;
          //     if (data.length > 0) {
          //         this.apiData.next(data);
          //         this.currentPage = 1;
          //     } else {
          //         this.apiData.next([]);
          //         this.currentPage = 1;
          //     }
          // },
          (error) => {
              this.isLoading = false;
              this.displayError(this.commonService.getErrorDetail(error));
          }
        );
    }

    private displayError(message: string) {
        this.errorMessage = message;
        this.errorProperty = '';
        this.changeDetectorRef.detectChanges();
    }

    private displaySuccess(message: string) {
        this.errorMessage = message;
        this.successProperty = '';
        this.changeDetectorRef.detectChanges();
    }

    /*
  * 納入依頼一覧表示関数
  */
    listDisplayReload() {
        this.isLoading = true;
        this.orderService.getAllOrders(this.currentPage, OrderService.ORDER_PAGE, this.searchKeys, this.sortCondition, this.isChecked).subscribe(
            (response) => {
                this.isLoading = false;
                this.totalCount = Number(response.headers.get('X-Total-Count'));
                const data = response.body;
                this.apiData.next(data);
            },
            (error) => {
                this.isLoading = false;
                this.displayError(this.commonService.getErrorDetail(error));
            }
        );
    }

    get canCreateOrder() {
        return this.situationCheckerResult.CreateOrder;
    }

    get outputCSV() {
        return this.situationCheckerResult.CSVDownload;
    }

    get canCreateYoju() {
        return this.situationCheckerResult.OprateLifting;
    }

    /**
     * グリッドデータマージ関数
     */
    private dataMarge(data) {
        let dataList: Array<any> = [];
        data.forEach(order => {
            let orderStatus = '';
            // 揚重申請番号でグループ化
            // order.materialsがなければそれで1行作る
            if (order.materials.length === 0) {
                const grpOrder: any = {};
                // grpOrder.companyName = order.companyName;
                grpOrder.name = order.name;
                grpOrder.id = order.id;
                grpOrder.number = order.number;
                grpOrder.orderDate = order.orderDate;
                grpOrder.requestDateTime = this.dateTimeConversion(order.requestDateTime)[0];
                grpOrder.requestDateTimeAls = this.dateTimeConversion(order.requestDateTime)[1];
                grpOrder.requestSection = order.requestSection;
                grpOrder.liftingNumber = '';
                grpOrder.cargoIndex = '';
                grpOrder.confirmDateTime = '';
                grpOrder.confirmLift = '';
                grpOrder.liftingId = '';
                grpOrder.status = orderStatus;
                dataList.push(grpOrder);
            } else {
                // orderStatus = this.orderStatus(order.materials);
                const numberGrp = this.commonService.groupBy(order.materials, 'liftingNumber');
                Array.prototype.forEach.call(Object.keys(numberGrp), ((n: any) => {
                    // cargoIndexでグループ化
                    const idxGrp = this.commonService.groupBy(numberGrp[n], 'cargoIndex');
                    Array.prototype.forEach.call(Object.keys(idxGrp), ((i: any) => {
                        const detailStatus = this.situationChecker.getOrderListDetailStatus(this.userData, idxGrp[i]);
                        const grpOrder: any = {};
                        // grpOrder.companyName = order.companyName;
                        // Date型に変換
                        if (idxGrp[i][0].confirmDateTime) {
                            idxGrp[i][0].confirmDateTime = new Date(idxGrp[i][0].confirmDateTime);
                        }
                        orderStatus = this.commonService.orderStatus(idxGrp[i]);
                        grpOrder.name = order.name;
                        grpOrder.id = order.id;
                        grpOrder.number = order.number;
                        grpOrder.orderDate = order.orderDate;
                        grpOrder.requestDateTime = this.dateTimeConversion(order.requestDateTime)[0];
                        grpOrder.requestDateTimeAls = this.dateTimeConversion(order.requestDateTime)[1];
                        grpOrder.requestSection = order.requestSection;
                        grpOrder.liftingNumber = n === 'null' ? '' : n;
                        grpOrder.cargoIndex = i === 'null' ? '' : i;
                        grpOrder.confirmDateTime = idxGrp[i][0].confirmDateTime;
                        grpOrder.confirmLift = idxGrp[i][0].confirmLift;
                        grpOrder.liftingId = idxGrp[i][0].liftingId;
                        grpOrder.hasChanged = idxGrp[i][0].hasChanged;
                        grpOrder.status = orderStatus;
                        grpOrder.changeStatus = idxGrp[i][0].changeStatus;
                        grpOrder.orderListDetailStatus = detailStatus;
                        dataList.push(grpOrder);
                      }));
                }));
            }
        });
        return dataList;
    }
    /**
     * CSV出力用の関数
     */
    private callOrderListAPI(page) {
        console.log(this.sortCondition);
        // 最大500件ごとにAPIを呼び出してグリッドのitemSourceに追加
        this.orderService.getAllOrders(page, 500, this.searchKeys, this.sortCondition, this.isChecked).subscribe(
          (response) => {
            this.totalCount = Number(response.headers.get('X-Total-Count'));
            console.log(this.totalCount);
            const data = response.body;
            if (data.length > 0) {
                console.log(page);
                // マージ処理
                let list: Array<any> = this.dataMarge(data);
                // データをつなげていく
                this.csvGridData.sourceCollection = this.csvGridData.sourceCollection.concat(list);
                page++;
                // 再帰呼び出し
                this.callOrderListAPI(page);
            } else {
                this.isLoading = false;
                console.log("読み込み完了");
                console.log(this.csvGridData.sourceCollection);
                // console.log(this.csvGridData.sourceCollection);
                // すべて読み込み完了したら、CSVデータ作成処理を実行
                const rng = new CellRange(0, 0, this.csvGrid.rows.length - 1, this.csvGrid.columns.length - 1);
                const csv = this.csvGrid.getClipString(rng, true, true);

                const date = this.commonService.getDateTime();
                const day = ('0' + date.getDate()).slice(-2);
                const month = ('0' + (date.getMonth() + 1)).slice(-2);
                const year = date.getFullYear();
                const hour = ('0' + date.getHours()).slice(-2);
                const minutes = ('0' + date.getMinutes()).slice(-2);
                const seconds = ('0' + date.getSeconds()).slice(-2);

                const fileName = 'N' + year + month + day + '_' + hour + minutes + seconds + '.csv';
                // CSV出力関数実行
                this.exportCSV(csv, fileName);
            }
          },
            // (data: Order[]) => {
            //     if (data.length > 0) {
            //         console.log(page);
            //         // マージ処理
            //         let list: Array<any> = this.dataMarge(data);
            //         // データをつなげていく
            //         this.csvGridData.sourceCollection = this.csvGridData.sourceCollection.concat(list);
            //         page++;
            //         // 再帰呼び出し
            //         this.callOrderListAPI(page);
            //     } else {
            //         this.isLoading = false;
            //         console.log("読み込み完了");
            //         console.log(this.csvGridData.sourceCollection);
            //         // console.log(this.csvGridData.sourceCollection);
            //         // すべて読み込み完了したら、CSVデータ作成処理を実行
            //         const rng = new CellRange(0, 0, this.csvGrid.rows.length - 1, this.csvGrid.columns.length - 1);
            //         const csv = this.csvGrid.getClipString(rng, true, true);

            //         const date = this.commonService.getDateTime();
            //         const day = ('0' + date.getDate()).slice(-2);
            //         const month = ('0' + (date.getMonth() + 1)).slice(-2);
            //         const year = date.getFullYear();
            //         const hour = ('0' + date.getHours()).slice(-2);
            //         const minutes = ('0' + date.getMinutes()).slice(-2);
            //         const seconds = ('0' + date.getSeconds()).slice(-2);

            //         const fileName = 'N' + year + month + day + '_' + hour + minutes + seconds + '.csv';
            //         // CSV出力関数実行
            //         this.exportCSV(csv, fileName);
            //     }
            // },
            (error) => {
                this.isLoading = false;
                this.displayError("CSVファイルの出力に失敗しました。");
            }
        );
    }

    checkboxEdit() {
        if (!this.isChecked) {
            this.isChecked = true;
            // 「納入済みを表示する」にチェックが入っていたら
            // パラメーターにdelivered=trueを追加して一覧を取り直す
            this.listDisplayReload();
        } else {
            this.isChecked = false;
            this.listDisplayReload();
        }
    }

    exportGridToCsv() {
        // CSV出力ボタンが押されたら、グリッドの中身をリセット
        this.csvGridData.sourceCollection = [];
        let page = 1;
        this.isLoading = true;
        // API呼び出し実行
        this.callOrderListAPI(page);
    }

    exportCSV(csv: string, fileName: string) {
        const bom = new Uint8Array([0xEF, 0xBB, 0xBF]);
        const blob = new Blob([bom, csv], {type: 'text/csv;charset=utf-8'});
        if (navigator.msSaveBlob) { // IE
            navigator.msSaveBlob(blob, fileName);
        } else {
            const e = document.createElement('a');
            e.setAttribute('href', URL.createObjectURL(blob));
            e.setAttribute('download', fileName);
            e.style.display = 'none';
            document.body.appendChild(e);
            e.click();
            document.body.removeChild(e);
        }
    }

    /**
     * 納入希望日時を現場作業時間に合わせて変換する
     * @param date String date 納入希望日時
     */
    private dateTimeConversion(date: string) {
        const request: Date = new Date(date);
        let setTime: string;

        const startTime = this.userData.site.startLiftingHour;
        const split = startTime.split(':');
        const hour = Number(split[0]);
        const minuit = Number(split[1]);

        if (request.getHours() < hour) {
            // 日付を一日前にして時間を24hプラスする
            request.setDate(request.getDate() - 1);
            setTime = request.getHours() + 24 + ':' + ('0' + request.getMinutes()).slice(-2);
        } else {
            // 24時間以内の指定であれば、そのまま表示
            setTime = request.getHours() + ':' + ('0' + request.getMinutes()).slice(-2);
        }
        return [request, setTime];
    }


}
