import { Component } from '@angular/core';

import { UserService } from './core/service/user.service';

import { LoginComponent } from './login/login.component';
import { UserListComponent } from './master/user/user-list/user-list.component';
import { UserDetailComponent } from './master/user/user-detail/user-detail.component';
import { ConstructionListComponent } from './master/construction/construction-list/construction-list.component';
import { ConstructionDetailComponent } from './master/construction/construction-detail/construction-detail.component';
import { PasswordResetComponent } from './password/password-reset/password-reset.component';
import { PasswordChangeComponent } from './password/password-change/password-change.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  /*
  * API連携する場合に必要
  users = this.userService.users$;

  ngOnInit() {
    this.userService.fetchUsers();
  }
  */

  constructor(private userService: UserService) {}

  isLoginPage: boolean = false;
  isMasterPage: boolean = false;

  onActivate(e) {
    this.isLoginPage = (
      e instanceof LoginComponent ||
      e instanceof PasswordResetComponent);

    // tslint:disable-next-line:max-line-length
    this.isMasterPage = (
      e instanceof PasswordChangeComponent ||
      e instanceof UserListComponent ||
      e instanceof UserDetailComponent ||
      e instanceof ConstructionListComponent ||
      e instanceof ConstructionDetailComponent);
  }
}
