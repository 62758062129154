import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable, of } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';
import { Order } from '../../order/order';
import { CommonService } from '../common.service';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'POST'
  })
};

@Injectable({ providedIn: 'root' })
export class OrderService {
  constructor(private http: HttpClient,
              private commonService: CommonService) {
    this.siteId = this.commonService.getRoutedSiteFromPath() !== '' ? this.commonService.getRoutedSiteFromPath() : '';
  }

  public static readonly ORDER_PAGE = 30;
  private orderCreateData: object;

  private siteId: any;

  // 納入一覧を取得
  getAllOrders(page: number, limit: number, searchKeys = {}, sort = {}, isDelivered= false): Observable<HttpResponse<Order[]>> {
    // const limit = 30;
    // offsetは0から始まる
    let offset = limit * (page - 1);
    if (offset < 0) {
      offset = 0;
    }
    let url = `${environment.apiUrl}/order?siteId=${this.siteId}&limit=${limit}&offset=${offset}`;

    if (0 !== Object.keys(searchKeys).length) {
      for (let key of Object.keys(searchKeys)) {
        // URLパラメータ補正
        if(key==='name'){
        //   url += '&' + 'name' + '=[';
        //   for(let i=0;i<searchKeys[key].length;i++){
        //     url += '"' + searchKeys[key][i] + '"';
        //     if(i!==searchKeys[key].length-1){
        //       url +=',';
        //     }
        //   }
        //   url +=  ']';
          url += '&' + 'name' + '=' + searchKeys[key];
        }else if(key==='requestDateTime'){
          url += '&' + 'requestDate' + '=' + searchKeys[key];
        }else if(key==='confirmDateTime'){
          url += '&' + 'confirmDate' + '=' + searchKeys[key];
        }else{
          url += '&' + key + '=' + searchKeys[key];
        }
      }
    }

    if (sort['sortKey']) {
      url += '&sort=' + sort['sortKey'];
      url += '&order=' + sort['sortOrder'];
    }
    url += '&delivered=' + isDelivered;
    return this.http.get<Order[]>(url, { observe: 'response' });
  }
  // orderIdで指定した納入依頼情報を取得
  getOrder(id: number): Observable<Order> {
    return this.http.get<any>(`${environment.apiUrl}/order/${id}?siteId=${this.siteId}`);
  }

  createOrder(orderInfo: Order): Observable<Order> {
    const body = JSON.stringify(orderInfo);
    console.log(body);
    return this.http.post<Order>(`${environment.apiUrl}/order`, body, httpOptions);
  }

  // orderIdで指定した納入依頼情報を削除
  deleteOrder(id: string) {
    return this.http.post(`${environment.apiUrl}/order/${id}/delete`, null);
  }

  // 発注
  orderingOrder(orderInfo: Order): Observable<Order> {
    const body = JSON.stringify(orderInfo);
    console.log(body);
    return this.http.post<Order>(`${environment.apiUrl}/order/ordering`, body, httpOptions).pipe(
      tap(() => console.log(`orderingOrder success ${orderInfo.id}`)),
    );
  }

  // /*
  //  * エラー詳細を取得
  //  */
  // private handleError<T> (operation = 'operation', result?: T) {
  //   return (error: any): Observable<T> => {

  //     // TODO: send the error to remote logging infrastructure
  //     console.error(error); // log to console instead

  //     // TODO: better job of transforming error for user consumption
  //     console.log(`${operation} failed: ${error.message}`);

  //     // Let the app keep running by returning an empty result.
  //     return of(result as T);
  //   };
  // }

  /*
   * 納入依頼の作成データ受け渡し関数（コピー用）
   */
  getCreateData(): object {
    const data = this.orderCreateData;
    this.orderCreateData = {};  // 保持データリセット
    return data;
  }
  setCreateData(data: object) {
    this.orderCreateData = data;
  }
}
