import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { ConstructionListComponent } from './master/construction/construction-list/construction-list.component';
import { ConstructionDetailComponent } from './master/construction/construction-detail/construction-detail.component';
import { UserListComponent } from './master/user/user-list/user-list.component';
import { UserDetailComponent } from './master/user/user-detail/user-detail.component';
import { UserNewComponent } from './master/user/user-new/user-new.component';
import { PasswordChangeComponent } from './password/password-change/password-change.component';
import { PasswordResetComponent } from './password/password-reset/password-reset.component';
import { HistoryComponent } from './master/history/history.component';
import { OrderListComponent } from './order/order-list/order-list.component';
import { OrderDetailComponent } from './order/order-detail/order-detail.component';
import { LiftingListComponent } from './lifting/lifting-list/lifting-list.component';
import { LiftingDetailComponent } from './lifting/lifting-detail/lifting-detail.component';
import { LiftingDetailMaterialComponent } from './lifting/lifting-detail-material/lifting-detail-material.component';
import {AuthRedirectComponent} from './auth-redirect/auth-redirect.component';
import {PageNotFoundComponent} from './core/page-not-found/page-not-found.component';

const routes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: ':siteId/', component: LoginComponent },
  { path: ':siteId/login', component: LoginComponent },
  { path: ':siteId/redirect', component: AuthRedirectComponent },
  { path: 'password/change', component: PasswordChangeComponent },
  { path: ':siteId/password/change', component: PasswordChangeComponent },
  { path: 'password/reset', component: PasswordResetComponent },
  { path: ':siteId/password/reset', component: PasswordResetComponent },
  { path: ':siteId/order', component: OrderListComponent },
  { path: ':siteId/order/create', component: OrderDetailComponent },
  { path: ':siteId/order/:id', component: OrderDetailComponent },
  { path: ':siteId/lifting', component: LiftingListComponent },
  { path: ':siteId/lifting/new', component: LiftingDetailComponent },
  { path: ':siteId/lifting/:id', component: LiftingDetailComponent },
  // 揚重申請＋資材未登録
  { path: ':siteId/lifting/new/create', component: LiftingDetailMaterialComponent },
  // 揚重申請登録済み・新規資材登録
  { path: ':siteId/lifting/:youjuId/create', component: LiftingDetailMaterialComponent },
  // 揚重申請＋資材登録済み
  { path: ':siteId/lifting/:youjuId/:id', component: LiftingDetailMaterialComponent },
  { path: 'master/construction', component: ConstructionListComponent },
  { path: 'master/construction/create', component: ConstructionDetailComponent },
  { path: 'master/construction/:id', component: ConstructionDetailComponent },
  { path: 'master/user', component: UserListComponent },
  { path: 'master/user/create', component: UserNewComponent },
  { path: 'master/user/:id', component: UserNewComponent },
  { path: 'master/user/show/:id', component: UserDetailComponent },
  { path: ':siteId/master/user', component: UserListComponent },
  { path: ':siteId/master/user/create', component: UserNewComponent },
  { path: ':siteId/master/user/:id', component: UserNewComponent },
  { path: ':siteId/master/user/show/:id', component: UserDetailComponent },
  { path: ':siteId/master/history', component: HistoryComponent },
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: '**', component: PageNotFoundComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: []
})
export class AppRoutingModule { }
