import {ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {UserService} from '../core/service/user.service';
import {Router, ActivatedRoute, NavigationEnd} from '@angular/router';
import {Subject} from 'rxjs';
import {SiteService} from '../core/service/site.service';
import {Constant} from 'src/app/core/constant';
import {CommonService} from '../core/common.service';
import {GeneralSituationCheckerService} from '../core/general-situation-checker.service';
import {User} from '../master/user/user';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
    // ヘッダー出し分け用
    @Input() needsDescription: boolean; // needsDescription はルートコンポーネントからもらう
    @Input() masterControl: boolean;
    loginData: Subject<any> = new Subject();
    userData: any = {};
    siteName = '';
    nowActive = 'order';
    siteId = '';
    topPath = '';
    // loginRegex = RegExp('/[0-9]+/login');
    // adminLoginRegex = RegExp('/login');
    // passResetRegex = RegExp('/password/reset');
    orderPath = 'order';
    liftingPath = 'lifting';

    situationCheckerResult: any;

    constructor(
        private userService: UserService,
        private route: ActivatedRoute,
        private router: Router,
        private siteService: SiteService,
        private commonsService: CommonService,
        public situationChecker: GeneralSituationCheckerService,
        public changeDetectorRef: ChangeDetectorRef,
    ) {
        // URLからsiteId（数字）を抜き出し
        this.siteId = this.commonsService.getRoutedSiteFromPath();
        this.topPath = this.siteId !== '' ? this.siteId + '/login' : 'login';

        this.loginData.subscribe(url => {

            if (this.siteId) {
                this.siteService.getSiteNameById(this.siteId).subscribe(
                    (siteName: any) => {
                        this.siteName = siteName.name;
                        this.changeDetectorRef.detectChanges();
                    },
                    (error) => {
                        console.log(error);
                        // this.router.navigate([this.topPath]);
                    }
                );
            }

            // if (this.siteId) {
            this.commonsService.userLoggedIn(this.siteId).subscribe(
                (userData: User) => {
                    console.log(userData);
                    this.userData = userData;
                    this.situationCheckerResult = this.situationChecker.getHeaderStatus(this.userData);

                    if (this.userData.site && this.siteName === '') {
                        this.siteName = this.userData.site.name;
                    }

                    if (url.indexOf(this.orderPath) !== -1) {
                        this.changeActive(this.orderPath);
                    } else if (url.indexOf(this.liftingPath) !== -1) {
                        this.changeActive(this.liftingPath);
                    }

                    this.changeDetectorRef.detectChanges();
                },
                (error) => {
                    // ログイン情報がない場合は、ルートに遷移
                    console.log(error);
                    // this.router.navigate([this.topPath]);
                }
            );
            // }
        });
    }

    ngOnInit() {
        // URL取得
        this.router.events.subscribe(e => {
            if (e instanceof NavigationEnd) {
                console.log(e);
                this.loginData.next(e.url);
            }
        });
    }

    // 管理ログインか？
    get isAdminLogin() {
        return !(this.userData.site);
    }

    logout() {
        this.userService.userLogout(this.userData.email).subscribe(
            () => {
                this.router.navigate([this.topPath]);
            },
            (error) => {
                console.log(error);
                this.router.navigate([this.topPath]);
            }
        );
    }

    changeActive(targetMode = 'order') {
        this.nowActive = targetMode;
        this.changeDetectorRef.detectChanges();
    }

}
