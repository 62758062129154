import {Injectable} from '@angular/core';
import * as wjGridFilter from '@grapecity/wijmo.grid.filter';

@Injectable({
    providedIn: 'root'
})
export class CommonFilterUiService {

    constructor() {
    }

    setupSearchFilter(filter, event, search_keys) {
        console.log(filter, event);
        const colFilter = filter.getColumnFilter(event.col);

        // カラムの検索条件によって検索条件のキャプションを作成
        const conditionText = this.getSearchConditionText(colFilter.column.search_condition);

        // 現在の検索条件で値が入っていた場合は開くフィルタにも値を反映
        let filterValue;
        for (const k in search_keys) {
            if (k === colFilter.column.binding) {
                filterValue = search_keys[k];
            }
        }

        // カラム毎のテンプレート条件に応じて差し替えるフィルタテンプレートを切り替え
        // 前回テンプレートの入力値を削除してから再使用する
        let template;
        if (colFilter.column.isDateCol && colFilter.column.search_condition === 7) {
            // 日付型の場合
            document.getElementById('date-search-condition-text').innerText = conditionText;
            (<HTMLInputElement>document.getElementById('start-date-filter')).min = '0001-01-01';
            template =
                document.getElementById('date-filter-ui').innerHTML.replace(
                    'start-date-filter', 'start-date-filter-dom').replace(
                    'end-date-filter', 'end-date-filter-dom');
            if (filterValue) {
                // filterValue[0] = filterValue[0].substring(0, 10);
                // filterValue[1] = filterValue[1].substring(0, 10);
                template = template.replace('"start-date-filter-dom"', '"start-date-filter-dom" value="' + filterValue + '"');
                // template = template.replace('"end-date-filter-dom"', '"end-date-filter-dom" value="' + filterValue[1] + '"');
            }
        } else if (colFilter.column.search_condition === 8) {
            // 時間型の場合
            document.getElementById('time-search-condition-text').innerText = conditionText;
            template =
                document.getElementById('time-filter-ui').innerHTML.replace(
                    'start-time-filter', 'start-time-filter-dom').replace(
                    'end-time-filter', 'end-time-filter-dom');
            if (filterValue) {
                const splited = filterValue.split(':');
                template = template.replace('"start-time-filter-dom"', '"start-time-filter-dom" value="' + parseInt(splited[0],10) + '"');
                template = template.replace('"end-time-filter-dom"', '"end-time-filter-dom" value="' + parseInt(splited[1],10) + '"');
            }
        } else if (colFilter.column.masterList) {
            // カラムにマスタ設定（Datamap）が仕込まれている場合はマスタからのドロップダウン
            document.getElementById('select-search-condition-text').innerText = conditionText;
            // document.getElementById('select-multi-caption-text').innerText = document.getElementById(
            //     'multi-select-help-text').innerText;
            const mst = colFilter.column.masterList;
            const element = document.getElementById('select-filter');
            while (element.firstChild) {
                element.removeChild(element.firstChild);
            }
            for (let i = 0; i < mst.length; i++) {
                const ph = document.createElement('option');
                if (i === 0) {
                    ph.value = '';
                    ph.label = colFilter.column.header;
                    ph.disabled = true;
                    element.appendChild(ph);
                }
                const op = document.createElement('option');
                op.value = mst[i].value;
                op.label = mst[i].label;
                element.appendChild(op);
            }
            template = document.getElementById('select-filter-ui').innerHTML.replace('select-filter', 'select-filter-dom');
            if (filterValue) {
                for (let i = 0; i < filterValue.length; i++) {
                    template = template.replace('value="' + filterValue[i] + '"', 'selected value="' + filterValue[i] + '"');
                }
            }
        // } else if (colFilter.column.isNumCol && colFilter.column.search_condition === 7) {
        //     // 数値型の場合
        //     document.getElementById('num-search-condition-text').innerText = conditionText;
        //     template =
        //         document.getElementById('num-filter-ui').innerHTML.replace(
        //             'start-num-filter', 'start-num-filter-dom').replace(
        //             'end-num-filter', 'end-num-filter-dom');
        //     if (filterValue) {
        //         template = template.replace('"start-num-filter-dom"', '"start-num-filter-dom" value="' + filterValue[0] + '"');
        //         template = template.replace('"end-num-filter-dom"', '"end-num-filter-dom" value="' + filterValue[1] + '"');
        //     }
        } else if (colFilter.column.search_condition === 1) {
            // 完全一致は改行区切り複数入力可能のテキスト型とする
            // document.getElementById('separate-caption').innerText = document.getElementById('separate-input-text').innerText;
            // document.getElementById('separate-caption').innerText = '改行区切り';
            document.getElementById('separate-caption').innerText = 'カンマ区切り';

            (<HTMLInputElement>document.getElementById('area-input-filter')).placeholder = colFilter.column.header;

            // (<HTMLInputElement>document.getElementById('area-input-filter')).innerText = '';
            // template = document.getElementById('text-filter-ui').innerHTML.replace(
            //     'area-input-filter', 'area-input-filter-dom');
            // if (filterValue) {
            //     template = template.replace('</textarea>', filterValue.join('\n') + '</textarea>');
            // }

            // Enter押下で改行ができずフィルタが実行されてしまう問題が解決できず、暫定対応
            template = document.getElementById('text-filter-ui').innerHTML.replace('area-input-filter', 'area-input-filter-dom');
            if (filterValue) {
                template = template.replace('"area-input-filter-dom"', '"area-input-filter-dom" value="' + filterValue.join(',') + '"');
            }

        } else if (colFilter.column.search_condition === 4) {
            // 上記以外は単一の入力
            document.getElementById('text-search-condition-text').innerText = conditionText;
            (<HTMLInputElement>document.getElementById('single-input-filter')).placeholder = colFilter.column.header;
            template = document.getElementById('single-text-filter-ui').innerHTML.replace('single-input-filter', 'single-input-filter-dom');
            if (filterValue) {
                template = template.replace('"single-input-filter-dom"', '"single-input-filter-dom" value="' + filterValue + '"');
            }
            // } else if (colFilter.column.search_condition !== null && colFilter.column.search_condition !== 'null') {
        } else {
            // ソートのみ
            template = document.getElementById('sort-only-ui').innerHTML;
        }

        // console.log(template);
        wjGridFilter.ColumnFilterEditor.controlTemplate = template;
    }

    getInputedFilterValue(column) {
        // どのテンプレートのフィルタなのかによって入力値取得処理を切り替え
        let search_value;
        if (column.isDateCol && column.search_condition === 7) {
            const date = ['', ''];
            if ((<HTMLInputElement>document.getElementById('start-date-filter-dom')).value !== null) {
                date[0] = (<HTMLInputElement>document.getElementById('start-date-filter-dom')).value;
                // if (column.isDateTimeCol) {
                //     date[0] = date[0] + 'T00:00:00';
                // }
            }
            // if ((<HTMLInputElement>document.getElementById('end-date-filter-dom')).value !== null) {
            //     date[1] = (<HTMLInputElement>document.getElementById('end-date-filter-dom')).value;
            //     if (column.isDateTimeCol) {
            //         date[1] = date[1] + 'T23:59:59';
            //     }
            // }
            search_value = date[0];
        } else if (column.search_condition === 8) {
            const time = ['', ''];
            if ((<HTMLInputElement>document.getElementById('start-time-filter-dom')).value !== null) {
                time[0] = (<HTMLInputElement>document.getElementById('start-time-filter-dom')).value;
            }
            if ((<HTMLInputElement>document.getElementById('end-time-filter-dom')).value !== null) {
                time[1] = (<HTMLInputElement>document.getElementById('end-time-filter-dom')).value;
            }
            if(time[0]!=='' && time[1]!==''){
                search_value = time[0] + ':' + time[1];
            }
        } else if (column.masterList) {
            const selected_list = [];
            const target_elm = (<HTMLInputElement>document.getElementById('select-filter-dom'));
            for (let i = 0; i < column.masterList.length + 1; i++) {
                if (target_elm[i].selected) {
                    selected_list.push(target_elm[i].value);
                }
            }
            search_value = selected_list;
        // } else if (column.isNumCol && column.search_condition === 7) {
        //     const num = ['', ''];
        //     if ((<HTMLInputElement>document.getElementById('start-num-filter-dom')).value !== null) {
        //         num[0] = (<HTMLInputElement>document.getElementById('start-num-filter-dom')).value;
        //         num[0] = this.formatTelCode(num[0]);
        //     }
        //     if ((<HTMLInputElement>document.getElementById('end-num-filter-dom')).value !== null) {
        //         num[1] = (<HTMLInputElement>document.getElementById('end-num-filter-dom')).value;
        //         num[1] = this.formatTelCode(num[1]);
        //     }
        //     search_value = num;
        } else if (column.search_condition === 1) {
            search_value = (<HTMLInputElement>document.getElementById('area-input-filter-dom')).value;
            search_value = this.relpaceBlankToComma(search_value).split(',');
        } else if (column.search_condition === 4){
            search_value = (<HTMLInputElement>document.getElementById('single-input-filter-dom')).value;
        }else{
            search_value = '';
        }

        return search_value;
    }

    getFilterValSkeleton(column) {
        let skel_val;
        if (column.isNumCol) {
            skel_val = -99999999999;
        } else {
            skel_val = '_filterValueIsSetting_';
        }
        return skel_val;
    }

    getFilterOperatorSkeleton(column) {
        let skel_op;
        if (column.isNumCol) {
            skel_op = 'GT';
        } else {
            skel_op = 'NE';
        }
        return skel_op;
    }

    getSearchConditionText(search_condition_num) {
        // const searchConditions = environment.searchConditions;
        const conditionText = '絞り込み(完全一致)';
        // for (let i = 0; i < searchConditions.length; i++) {
        //     if (searchConditions[i].no === search_condition_num) {
        //         conditionText = document.getElementById(searchConditions[i].domId).innerText;
        //     }
        // }
        return conditionText;
    }

    /**
     * 電話番号全角、ハイフン削除対応
     * @param input_tel 整形前入力電話番号
     */
    formatTelCode(input_tel = '') {
        let formattedTel = input_tel;
        formattedTel = formattedTel.replace(/[０-９]/g, function (s) {
            return String.fromCharCode(s.charCodeAt(0) - 65248);
        });
        formattedTel = formattedTel.replace(/[^0-9]/g, '');

        return formattedTel;
    }

    relpaceBlankToComma(target_str, join_grue = ',') {
        const base_array = target_str
            .replace(/\r?\n/g, ',').replace(/ /g, ',').replace(/　/g, ',').replace(/\t/g, ',')
            .split(',');
        const return_str = [];
        for (let i = 0; i < base_array.length; i++) {
            if (base_array[i] !== '') {
                return_str.push(base_array[i]);
            }
        }
        return return_str.join(join_grue);
    }

    getColumnBindingIndex(gridColumnList = [], targetBindingName = '') {
        for (let i = 0; i < gridColumnList.length; i++) {
            if (gridColumnList[i].binding === targetBindingName) {
                return i;
            }
        }
    }

    createOptionsList(base_arr = [], displayKey = '', valueKey = '', concatedDisplayPair = []) {
        const return_list = [];
        for (let i = 0; i < base_arr.length; i++) {
            let disp = '';
            const val = base_arr[i][valueKey];
            if (concatedDisplayPair.length !== 0) {
                let left_str = '';
                let right_str = '';
                if (base_arr[i][concatedDisplayPair[0]]) {
                    left_str = base_arr[i][concatedDisplayPair[0]];
                }
                if (base_arr[i][concatedDisplayPair[1]]) {
                    right_str = base_arr[i][concatedDisplayPair[1]];
                }
                // disp = base_arr[i][concatedDisplayPair[0]] + ' : ' + base_arr[i][concatedDisplayPair[1]];
                disp = left_str + ' : ' + right_str;
            } else {
                disp = base_arr[i][displayKey];
            }
            return_list.push({label: disp, value: val});
        }
        return return_list;
    }


}
