import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {History} from '../../master/history/history';
import {CommonService} from '../common.service';
import {Cargo} from "../../lifting/cargo";

@Injectable({providedIn: 'root'})
export class HistoryService {
    private siteId: any;

    constructor(private http: HttpClient,
                private commonService: CommonService) {
        this.siteId = this.commonService.getRoutedSiteFromPath() !== '' ? this.commonService.getRoutedSiteFromPath() : '';
    }

    // 操作一覧を取得
    getHistoryData(page: number, searchKeys = {}, sort = {}): Observable<History[]> {
        const limit = 30;
        // offsetは0から始まる
        let offset = limit * (page - 1);
        if (offset < 0) {
            offset = 0;
        }
        let url = environment.apiUrl + '/history?';
        if (this.siteId) {
            url += 'siteId=' + this.siteId;
        }
        url += '&limit=' + limit;
        url += '&offset=' + offset;

        if (0 !== Object.keys(searchKeys).length) {
            for (let key of Object.keys(searchKeys)) {
                url += '&' + key + '=' + searchKeys[key];
            }
        }

        if (sort['sortKey']) {
            // APIパラメータ用にキーを補正
            if (sort['sortKey'].indexOf('operationTime') !== -1) {
                url += '&sort=' + 'operation_date_time';
            } else if (sort['sortKey'].indexOf('operatorName') !== -1) {
                url += '&sort=' + 'user_id';
            } else if (sort['sortKey'].indexOf('operationType') !== -1) {
                url += '&sort=' + 'type';
            } else {
                url += '&sort=' + sort['sortKey'];
            }

            url += '&order=' + sort['sortOrder'];
        }
        return this.http.get<History[]>(url);
    }
}
