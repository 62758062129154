import {Injectable} from '@angular/core';
import {User} from '../master/user/user';
import {Material} from '../lifting/material';
import {Cargo} from '../lifting/cargo';
import {Lifting} from '../lifting/lifting';
import {Order} from '../order/order';
import {Constant} from './constant';

@Injectable({
    providedIn: 'root'
})
export class GeneralSituationCheckerService {

    constructor() {
    }
    // ORDER_STATUS_LIST.STATUS_ADD_NEW	//新規入力後、保存も発注もしていない＝多分NULL
    // ORDER_STATUS_LIST.STATUS_ADD_NEW	//未公開 -1
    // ORDER_STATUS_LIST.STATUS_NO_ORDER	//未手配 0
    // ORDER_STATUS_LIST.STATUS_ORDERING	//手配中
    // ORDER_STATUS_LIST.STATUS_CANCELED	//取消し
    // ORDER_STATUS_LIST.STATUS_ARRANGED	//仮手配
    // ORDER_STATUS_LIST.STATUS_DELIVERED	//納入済み
    // ORDER_STATUS_LIST.STATUS_EXISTS_NO_ORDER	//未手配あり
    // ORDER_STATUS_LIST.STATUS_ARRANGED_CONFIRMED	//手配確定
    // ORDER_STATUS_LIST.STATUS_ARRANGED_CANCELED	//手配キャンセル
    //
    // CARGO_STATUS_LIST.STATUS_BEFORE_REQUEST	//未申請
    // CARGO_STATUS_LIST.STATUS_REQUESTED	//申請中
    // CARGO_STATUS_LIST.STATUS_REQUESTED_DEADLINE_PASSED	//申請中（締切済み）
    // CARGO_STATUS_LIST.STATUS_CONFIRMED	//確定
    // CARGO_STATUS_LIST.STATUS_DELIVERED	//納入完了

     // ＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊
    // ヘッダーメニュー
    // ＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊

    /**
     * ヘッダーメニュー
     * @param {User} userInfo
     * @returns {{MenuOrder: boolean; MenuLifting: boolean; MenuAcount: boolean; MenuAdmin: boolean}}
     */
    getHeaderStatus(userInfo: User){

        // console.log(userInfo);

        const headerMenuStatus = {
            MenuOrder: false, //納入依頼
            MenuLifting: false, //揚重申請
            MenuAcount: false, //アカウント
            MenuAdmin: false, //管理
        };

        //　ページの閲覧権限
        switch (userInfo.authority){
            case Constant.AUTHORITY_ADMIN:	//1:管理者
            case Constant.AUTHORITY_SITE_STAFF:	//2:現場担当者
                headerMenuStatus.MenuOrder = true;
                headerMenuStatus.MenuLifting = true;
                headerMenuStatus.MenuAcount = true;
                headerMenuStatus.MenuAdmin = true;
                break;
            case Constant.AUTHORITY_ARRANGE_STAFF:	//4:手配担当者
            case Constant.AUTHORITY_NOHARA_CUSTOMER:	//5:野原顧客
                headerMenuStatus.MenuOrder = true;
                headerMenuStatus.MenuLifting = true;
                headerMenuStatus.MenuAcount = true;
                break;
            case Constant.AUTHORITY_LIFTING:	//3:揚重センター
            case Constant.AUTHORITY_NOT_NOHARA_CUSTOMER:	//6:非野原顧客
                headerMenuStatus.MenuLifting = true;
                headerMenuStatus.MenuAcount = true;
                break;
        };
        // console.log(headerMenuStatus);
        return headerMenuStatus;

    }


    // ＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊
    // 納入依頼関係
    // ＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊

    /**
     * 納入一覧のヘッダーボタン
     * @param {User} userInfo
     * @returns {{PageAccess: boolean; CreateOrder: boolean; CSVDownload: boolean; OprateLifting: boolean}}
     */
    getOrderListStatus(userInfo: User) {
        // console.log(userInfo, order);

        const orderListStatus = {
            PageAccess: false, //この機能へのアクセス許可
            CreateOrder: false, //納入依頼新規作成
            CSVDownload: false, //CSVダウンロード
            OprateLifting: false, //揚重申請関係の操作（明細毎になにができるか別途判断するが、操作をすることがあるかどうか）
        };

        //　ページの閲覧権限
        switch (userInfo.authority){
            case Constant.AUTHORITY_ADMIN:	//1:管理者
            case Constant.AUTHORITY_SITE_STAFF:	//2:現場担当者
                orderListStatus.PageAccess = true;
                orderListStatus.CreateOrder = true;
                orderListStatus.CSVDownload = true;
                orderListStatus.OprateLifting = true;
                break;
            case Constant.AUTHORITY_ARRANGE_STAFF:	//4:手配担当者
                orderListStatus.PageAccess = true;
                orderListStatus.CSVDownload = true;
                orderListStatus.OprateLifting = true;
                break;
            case Constant.AUTHORITY_NOHARA_CUSTOMER:	//5:野原顧客
                orderListStatus.PageAccess = true;
                orderListStatus.CreateOrder = true;
                orderListStatus.CSVDownload = true;
                break;
            case Constant.AUTHORITY_LIFTING:	//3:揚重センター
            case Constant.AUTHORITY_NOT_NOHARA_CUSTOMER:	//6:非野原顧客
                // 揚重センターや非野原顧客は、この機能は全く使えない。
                break;
        };
        console.log(orderListStatus);
        return orderListStatus;

    }

    /**
     * 納入一覧の明細
     * @param {User} userInfo
     * @param order
     * @returns {{CreateLifting: boolean; NeedSiteStuffOperate: boolean; NeedArrengeOprate: boolean}}
     */
    getOrderListDetailStatus(userInfo: User, materials: any[]) {
        // console.log(userInfo, material);

        const orderListDetailStatus = {
            createLifting: false, // 揚重申請作成
            needSiteStuffOperate: false, // 現場担当要対応
            needArrengeOprate: false, // 手配担当要対応
        };
        if (materials.length === 0) {
          return 0;
        }

        // ページの閲覧権限
        switch (userInfo.authority) {
            case Constant.AUTHORITY_ADMIN:	// 1:管理者
            case Constant.AUTHORITY_SITE_STAFF:	// 2:現場担当者
                // 揚重申請番号が空で
                if (materials[0].liftingNumber == null || materials[0].liftingNumber === '') {
                  // すべて下書き or すべて取り消し or すべて手配キャンセルの場合は揚重申請作成しない
                  if (!materials.every((currentValue) =>
                      currentValue.status === Constant.ORDER_STATUS_LIST.STATUS_ADD_NEW ||
                      currentValue.status === Constant.ORDER_STATUS_LIST.STATUS_CANCELED ||
                      currentValue.status === Constant.ORDER_STATUS_LIST.STATUS_ARRANGED_CANCELED)) {
                    orderListDetailStatus.createLifting = true;
                  }
                } else {
                    if (materials.find((currentValue) => currentValue.status === Constant.ORDER_STATUS_LIST.STATUS_ARRANGED_CANCELED)) {
                        // 揚重申請番号ありで手配キャンセルの場合もtrue
                        orderListDetailStatus.needSiteStuffOperate = true;
                    } else if (materials.find((currentValue) => currentValue.changeStatus === Constant.ORDER_CHENGE_STATUS.ORDER_CHANGE)) {
                        //  if (material.changeStatus === Constant.ORDER_CHENGE_STATUS.ORDER_CHANGE) {
                        // 資材の変更あり
                        orderListDetailStatus.needSiteStuffOperate = true;
                    }
                }
                break;
            case Constant.AUTHORITY_ARRANGE_STAFF:	// 4:手配担当者
                if (materials[0].liftingNumber && materials[0].liftingNumber !== '') {
                  if (materials.find((currentValue) => currentValue.changeStatus === Constant.ORDER_CHENGE_STATUS.LIFTING_CHANGE)) {
                  // if (material.changeStatus === Constant.ORDER_CHENGE_STATUS.LIFTING_CHANGE) {
                    orderListDetailStatus.needArrengeOprate = true;
                  }
                }
                break;
        }
        console.log(orderListDetailStatus);
        return orderListDetailStatus;
    }

    /**
     * 納入依頼のヘッダーボタン
     * @param {User} userInfo
     * @param {Order} order
     * @returns {{PageAccess: boolean; Order: boolean; Save: boolean; Delete: boolean; Cancel: boolean; Copy: boolean; Back: boolean; CreateLifting: boolean; EditInputArea: boolean; ReauestDateValidate: boolean; detailAddRow: boolean; detailReadOnly: boolean; deleteRow: boolean; orderClosed: boolean; hasLockMaterial: boolean; allLockMaterial: boolean; allCloseMaterial: boolean; allUnLockMaterial: boolean}}
     */
    getOrderHeaderStatus(userInfo: User, order: Order) {
        console.log(userInfo,order);

        const orderHeaderStatus = {
            PageAccess: false,//この機能へのアクセス許可
            Order:false, // 発注ボタン
            Save:false, //保存ボタン
            Delete:false, //削除ボタン
            Cancel:false,   //キャンセルボタン
            Copy:false, //コピーボタン
            Back:true,  //戻るボタン
            CreateLifting:false,    //揚重申請作成ボタン

            EditInputArea:false,    //入力エリアの編集許可
            canEditReauestDate:false,   //希望日入力可
            ReauestDateValidate:false,   //希望日入力チェック
            detailAddRow: false,    //明細に行追加
            detailReadOnly: true,   //明細全体が読み取り専用
            deleteRow: false,   //明細の削除可

            orderNoOpen: false,  //作成後、保存だけの状態で、野原に依頼が届いていない
            hasLockMaterial: false,  //締め切り済み、手配確定、納入完了（ロック）の明細をひとつでも持っている⇒未手配・手配中・仮手配・締め切り前と混じっている
            allLockMaterial: false,  //全部の明細がロックされている
            allCloseMaterial: false,  //全部の明細が納入済みされている
            allUnLockMaterial: false,   // 全て明細が公開され、ロックされていない（
            inArranging: false,   // 手配担当が編集できる資材情報がある
            hasNoOrder:false,   //未手配が
        };

        // データオーナー
        let isCreateUser = false;
        let isRerationUser = false;
        //新規作成か、更新か
        let isNewInput = false;

        if (!order || Object.keys(order).length === 0 || !order.materials || !order.id || order.id === null) {
            // ※一旦、新規の時はOrderをなしで送ってもらう
            // if (order is null || order.materials is null || order.id is null || order.materials.length == 0 || order.materials.every((material) => material.status === Constant.ORDER_STATUS_LIST.STATUS_ADD_NEW)) {
            // 新規作成ボタン、あるいはコピーボタンを押して、納入依頼Noが発行される前の状態から始まったかどうか
            // ※この条件で大丈夫か？
            isNewInput = true;
            isCreateUser = true;
        } else {
            // すでに納入依頼Noが発行されている場合

            //作成者
            if (order.createUserId === userInfo.id) {
                // データオーナーが作成者の場合
                isCreateUser = true;
            } else if (order.relationMails && order.relationMails.some(material => material.mail === userInfo.email)) {
                // 連絡先に設定されている場合
                isRerationUser = true;
            }

            //明細状態
            if (order.materials.every((material) => material.status === Constant.ORDER_STATUS_LIST.STATUS_ADD_NEW)) {
                //保存しただけの状態
                orderHeaderStatus.orderNoOpen = true;
            }else if (order.materials.every((material) => material.status === Constant.ORDER_STATUS_LIST.STATUS_DELIVERED)) {
                // 全て納入済み
                orderHeaderStatus.allLockMaterial = true;
                orderHeaderStatus.allCloseMaterial = true;
            } else if (order.materials.every((material) => material.status === Constant.ORDER_STATUS_LIST.STATUS_ARRANGED_CANCELED)){
                //全て手配キャンセル
                //※誰も触れない
                orderHeaderStatus.allLockMaterial = true;
                orderHeaderStatus.allCloseMaterial = true;
            }

        }

        //　ページの閲覧権限
        switch (userInfo.authority){
            case Constant.AUTHORITY_ADMIN:	//1:管理者
            case Constant.AUTHORITY_SITE_STAFF:	//2:現場担当者
            case Constant.AUTHORITY_ARRANGE_STAFF:	//4:手配担当者
                orderHeaderStatus.PageAccess = true;
                break;
            case Constant.AUTHORITY_NOHARA_CUSTOMER:	//5:野原顧客
                if (isCreateUser || isRerationUser) orderHeaderStatus.PageAccess = true;
                break;
            case Constant.AUTHORITY_LIFTING:	//3:揚重センター
            case Constant.AUTHORITY_NOT_NOHARA_CUSTOMER:	//6:非野原顧客
                // 揚重センターや非野原顧客は、この機能は全く使えないので処理終了。
                break;
        };
        // ページにアクセスする権限ない人は退場。
        if (!orderHeaderStatus.PageAccess) {
            console.log("notaccess", orderHeaderStatus);
            return orderHeaderStatus;
        }

        // ***************************************
        // 以降の処理はページにこれる人だけ
        // ***************************************

        if (!isNewInput){
            // 納入依頼Noがついている場合は、いつでもコピー可能
            switch (userInfo.authority){
                case Constant.AUTHORITY_ADMIN:	//1:管理者
                case Constant.AUTHORITY_SITE_STAFF:	//2:現場担当者
                case Constant.AUTHORITY_NOHARA_CUSTOMER:	//5:野原顧客
                    orderHeaderStatus.Copy = true;
                    break;
                case Constant.AUTHORITY_ARRANGE_STAFF:	//4:手配担当者
                    break;
            };
        }

        // 新規登録、または、保存状態の時
        if (isNewInput || orderHeaderStatus.orderNoOpen) {
            //新規入力後、保存も発注もしていない
            orderHeaderStatus.Order = true;
            orderHeaderStatus.Save = true;
            orderHeaderStatus.EditInputArea = true;
            orderHeaderStatus.canEditReauestDate = true;
            // 明細行全体で、行追加、行削除、行更新可
            orderHeaderStatus.detailAddRow = true;
            orderHeaderStatus.detailReadOnly = false;
            orderHeaderStatus.deleteRow = true;

            // 日付の入力チェックは、野原顧客のみ。
            switch (userInfo.authority){
                case Constant.AUTHORITY_ADMIN:	//1:管理者
                case Constant.AUTHORITY_SITE_STAFF:	//2:現場担当者
                case Constant.AUTHORITY_ARRANGE_STAFF:	//4:手配担当者
                    break;
                case Constant.AUTHORITY_NOHARA_CUSTOMER:	//5:野原顧客
                    orderHeaderStatus.ReauestDateValidate = true;
                    break;
            };
            // 削除は保存時のみ有効
            if (orderHeaderStatus.orderNoOpen)
                orderHeaderStatus.Delete = true;

            // 新規の時はこれ以上何も考えないので退場
            console.log("new input", orderHeaderStatus);
            return orderHeaderStatus;
        }
        // // 全て納入済みはコピー以外は何もできないので退場
        // if (orderHeaderStatus.allCloseMaterial){
        //     console.log(orderHeaderStatus);
        //     return orderHeaderStatus;
        // }
        // 全て納入済みはコピー以外は何もできないので退場
        if (orderHeaderStatus.allCloseMaterial){
            console.log("all close", orderHeaderStatus);
            return orderHeaderStatus;
        }
        //連絡先はコピー以外できないので退場
        if (isRerationUser){
            console.log("reration user", orderHeaderStatus);
            return orderHeaderStatus;
        }

        // ***************************************
        // 以降の処理は必ず納入依頼Noがついていて、依頼書が公開になっていて、全部納入済みでもない
        // ***************************************





        //明細の状態を調べる
        if (order.materials.every((material) => material.wasClosed)) {
            // 全て締め切り済み
            orderHeaderStatus.allLockMaterial = true;
        } else if (order.materials.every((material) => material.status === Constant.ORDER_STATUS_LIST.STATUS_ARRANGED_CONFIRMED)) {
            // 全て手配確定
            orderHeaderStatus.allLockMaterial = true;
        } else if (order.materials.every((material) => material.status === Constant.ORDER_STATUS_LIST.STATUS_DELIVERED)) {
            // 全て納入済み
            orderHeaderStatus.allLockMaterial = true;
            orderHeaderStatus.allCloseMaterial = true;
        } else if (order.materials.some((material) => material.wasClosed)) {
            // 締め切り済み含む
            orderHeaderStatus.hasLockMaterial = true;
        } else if (order.materials.some((material) => material.status === Constant.ORDER_STATUS_LIST.STATUS_ARRANGED_CONFIRMED)) {
            // 手配確定含む
            orderHeaderStatus.hasLockMaterial = true;
        } else if (order.materials.some((material) => material.status === Constant.ORDER_STATUS_LIST.STATUS_DELIVERED)) {
            // 納入済み含む
            orderHeaderStatus.hasLockMaterial = true;
        } else {
            orderHeaderStatus.allUnLockMaterial = true;
        }

        if (!orderHeaderStatus.orderNoOpen && !orderHeaderStatus.allCloseMaterial ) {
            //手配中以降、納入済みより前で
            if( order.materials.some((material) => material.status === Constant.ORDER_STATUS_LIST.STATUS_ORDERING)
                || order.materials.some((material) => material.status === Constant.ORDER_STATUS_LIST.STATUS_ARRANGED)
                || order.materials.some((material) => material.status === Constant.ORDER_STATUS_LIST.STATUS_ARRANGED_CONFIRMED)
                || order.materials.some((material) => material.status === Constant.ORDER_STATUS_LIST.STATUS_CANCELED))
            {
                //　一つでも手配中・仮手配・手配確定・取消明細がある
                //手配担当が触れる
                orderHeaderStatus.inArranging = true;
            }
        }



        // 全ての資材が納入済み、あるいは、連絡先ユーザー（作成者じゃない）とき
        // if (orderHeaderStatus.allCloseMaterial || (!isCreateUser && userInfo.authority === Constant.AUTHORITY_NOHARA_CUSTOMER)){
        //     // コピーだけ使えるが、あとは全く触れないので退場
        //     console.log("notaccess", orderHeaderStatus);
        //     return orderHeaderStatus;
        // }

        // ***************************************
        // 以降の処理は作成者か、野原で、全部納入済みになっていない
        // ***************************************

        // 手配中以降は、資材の手配に関する操作ができる。希望日変更はできなくなる
        if (orderHeaderStatus.inArranging){
            // 日付の入力チェックは、野原顧客のみ。
            switch (userInfo.authority){
                case Constant.AUTHORITY_ADMIN:	//1:管理者
                case Constant.AUTHORITY_SITE_STAFF:	//2:現場担当者
                case Constant.AUTHORITY_NOHARA_CUSTOMER:	//5:野原顧客
                    break;
                case Constant.AUTHORITY_ARRANGE_STAFF:	//4:手配担当者
                    //表示自体の読み取り専用は解除するが、各行ごとの制御は明細ステータスの方で行う
                    orderHeaderStatus.Order = true;
                    orderHeaderStatus.detailReadOnly = false;
                    break;
            };
        } else {
            // 希望日変更は手配中になる前まで。日付の入力チェックは、野原顧客のみ。
            switch (userInfo.authority){
                case Constant.AUTHORITY_ADMIN:	//1:管理者
                case Constant.AUTHORITY_SITE_STAFF:	//2:現場担当者
                    orderHeaderStatus.canEditReauestDate = true;
                    break;
                case Constant.AUTHORITY_NOHARA_CUSTOMER:	//5:野原顧客
                    orderHeaderStatus.ReauestDateValidate = true;
                    orderHeaderStatus.canEditReauestDate = true;
                    break;
                case Constant.AUTHORITY_ARRANGE_STAFF:	//4:手配担当者
                    break;
            };

        }

        //すべて締め切り済み・手配確定済み
        if (orderHeaderStatus.allLockMaterial) {
            //inArrangingであれば手配の操作だけで、資材情報など触れないので、退場
            console.log("allLockMaterial", orderHeaderStatus);
            return orderHeaderStatus;
        };

        //未手配・手配中・仮手配・締め切り前など、資材がいじれる状態のときは、ここに来るはず

        //未手配明細が残っているとき->現場担当の揚重申請作成
        if (order.materials.some((material) => material.status === Constant.ORDER_STATUS_LIST.STATUS_NO_ORDER && !material.liftingNumber)) {
            switch (userInfo.authority){
                case Constant.AUTHORITY_ADMIN:	//1:管理者
                case Constant.AUTHORITY_SITE_STAFF:	//2:現場担当者
                    orderHeaderStatus.CreateLifting = true;
                    break;
                case Constant.AUTHORITY_NOHARA_CUSTOMER:	//5:野原顧客
                case Constant.AUTHORITY_ARRANGE_STAFF:	//4:手配担当者
                    break;
            };
        }
        //締切を迎えた明細がない場合だけ、行追加・キャンセル可能
        if (!orderHeaderStatus.hasLockMaterial) {
            switch (userInfo.authority){
                case Constant.AUTHORITY_ADMIN:	//1:管理者
                case Constant.AUTHORITY_SITE_STAFF:	//2:現場担当者
                case Constant.AUTHORITY_NOHARA_CUSTOMER:	//5:野原顧客
                    orderHeaderStatus.Order = true;
                    orderHeaderStatus.Cancel = true;
                    // 行追加可能
                    orderHeaderStatus.detailAddRow = true;
                    break;
                case Constant.AUTHORITY_ARRANGE_STAFF:	//4:手配担当者
                    break;
            };
        }

        //手配担当以外は入力エリアの編集許可
        switch (userInfo.authority){
            case Constant.AUTHORITY_ADMIN:	//1:管理者
            case Constant.AUTHORITY_SITE_STAFF:	//2:現場担当者
                orderHeaderStatus.Order = true;
                orderHeaderStatus.EditInputArea = true;
                // 行追加可能
                orderHeaderStatus.detailAddRow = true;
                break;
            case Constant.AUTHORITY_NOHARA_CUSTOMER:	//5:野原顧客
                orderHeaderStatus.Order = true;
                orderHeaderStatus.EditInputArea = true;
                // 行追加可能
                orderHeaderStatus.detailAddRow = true;
                break;
            case Constant.AUTHORITY_ARRANGE_STAFF:	//4:手配担当者
                break;
        };
        //表示自体の読み取り専用は解除するが、各行ごとの制御は明細ステータスの方で行う
        orderHeaderStatus.detailReadOnly = false;

        console.log("last",orderHeaderStatus);
        return orderHeaderStatus;

    }

    /**
     * 納入依頼の明細
     * ヘッダー側で制御するグリッド丸ごとのステータスの方が強い
     * この関数の前にそもそも触れない人などはヘッダー側で制御するので、個別の制御のみ行う
     * @param {User} userInfo
     * @param {Order} order
     * @param {number} rowIndex
     * @returns {{Edit: boolean; ReadOnly: boolean; CancelRow: boolean; OnlyStatusChange: boolean; deleteRow: boolean}}
     */
    getOrderDetailStatus(userInfo: User, order: Order, rowIndex : number) {
        console.log(userInfo, order, rowIndex);

        const orderDetailStatus = {
            Edit: false,    //資材情報の編集
            OnlyStatusChange: false,    //手配ステータス変更可能
            ReadOnly: false,    //行全体読み取り専用
            CancelRow: false,   //キャンセルボタン表示
            deleteRow: false, //削除ボタン表示
        }

        // 対象データがない場合はチェックなし
        if (!order.materials || Object.keys(order.materials).length <= rowIndex) {
            orderDetailStatus.deleteRow = true;
            console.log(orderDetailStatus);
            return orderDetailStatus;
        }

        //
        let material = order.materials[rowIndex];

        switch (userInfo.authority){
            case Constant.AUTHORITY_ADMIN:	//1:管理者
            case Constant.AUTHORITY_SITE_STAFF:	//2:現場担当者
            case Constant.AUTHORITY_NOHARA_CUSTOMER:	//5:野原顧客
                switch (material.status){
                    case Constant.ORDER_STATUS_LIST.STATUS_ADD_NEW: //未公開
                        orderDetailStatus.deleteRow = true;
                        orderDetailStatus.Edit = true;
                        break;
                    case Constant.ORDER_STATUS_LIST.STATUS_NO_ORDER://未手配
                        orderDetailStatus.Edit = true;
                        //締切前ならキャンセル可
                        if (!material.wasClosed){
                            orderDetailStatus.CancelRow = true;
                        }
                        break;
                    case Constant.ORDER_STATUS_LIST.STATUS_ORDERING://手配中
                    case Constant.ORDER_STATUS_LIST.STATUS_ARRANGED://仮手配
                        //締切前ならキャンセル可
                        if (!material.wasClosed){
                            orderDetailStatus.CancelRow = true;
                        }
                        break;
                    case Constant.ORDER_STATUS_LIST.STATUS_ARRANGED_CONFIRMED://手配確定
                    case Constant.ORDER_STATUS_LIST.STATUS_DELIVERED://納入済み
                    case Constant.ORDER_STATUS_LIST.STATUS_CANCELED://取消し
                    case Constant.ORDER_STATUS_LIST.STATUS_ARRANGED_CANCELED://手配キャンセル
                        break;
                }
                break;
            case Constant.AUTHORITY_ARRANGE_STAFF:	//4:手配担当者
                switch (material.status){
                    case Constant.ORDER_STATUS_LIST.STATUS_ADD_NEW:　//未公開
                    case Constant.ORDER_STATUS_LIST.STATUS_NO_ORDER:　//未手配
                    case Constant.ORDER_STATUS_LIST.STATUS_DELIVERED:　//納入済み
                    case Constant.ORDER_STATUS_LIST.STATUS_ARRANGED_CANCELED:　//手配キャンセル
                        // 触れない
                        break;
                    case Constant.ORDER_STATUS_LIST.STATUS_ORDERING:　//手配中
                    case Constant.ORDER_STATUS_LIST.STATUS_ARRANGED:　//仮手配
                    case Constant.ORDER_STATUS_LIST.STATUS_ARRANGED_CONFIRMED:　//手配確定
                    case Constant.ORDER_STATUS_LIST.STATUS_CANCELED:　//取消し
                        orderDetailStatus.OnlyStatusChange = true;
                        break;
                }
                break;

            case Constant.AUTHORITY_LIFTING:	//3:揚重センター
                break;
            case Constant.AUTHORITY_NOT_NOHARA_CUSTOMER:	//6:非野原顧客
                break;
        };

        //ステータスが未手配or空白or揚重申請締切前の場合のみボタンを表示
        //納入済み isReadOnly
        // 手配担当の場合はステータスとチェックボックスのみ操作できる
        // それ以外は未手配以外の場合は行ごと操作不可にする

        console.log(orderDetailStatus);
        return orderDetailStatus;
    }

    // ＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊
    // 揚重申請関係
    // ＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊
    /**
     * 揚重一覧のヘッダーボタン
     * @param {User} userInfo
     * @returns {{PageAccess: boolean; CreateLifting: boolean; CreateLiftingFromOrder: boolean; UpdateStatus: boolean; OprateLifting: boolean}}
     */
    getLiftingListStatus(userInfo: User) {
        console.log(userInfo);

        const liftingListStatus = {
            PageAccess: false, //この機能へのアクセス許可
            CreateLifting: false, //納入依頼新規作成
            CreateLiftingFromOrder: false, //納入依頼新規作成（納入依頼から作成）
            UpdateStatus: false, //確定・更新ボタン
            OprateLifting: false, //揚重申請関係の操作（明細毎になにができるか別途判断するが、操作をすることがあるかどうか）
        };

        //　ページの閲覧権限
        switch (userInfo.authority){
            case Constant.AUTHORITY_ADMIN:	//1:管理者
            case Constant.AUTHORITY_SITE_STAFF:	//2:現場担当者
                liftingListStatus.PageAccess = true;
                liftingListStatus.CreateLifting = true;
                liftingListStatus.CreateLiftingFromOrder = true;
                liftingListStatus.UpdateStatus = true;
                liftingListStatus.OprateLifting = true;
                break;
            case Constant.AUTHORITY_LIFTING:	//3:揚重センター
                liftingListStatus.CreateLifting = true;
                liftingListStatus.UpdateStatus = true;
                liftingListStatus.OprateLifting = true;
                break;
            case Constant.AUTHORITY_NOT_NOHARA_CUSTOMER:	//6:非野原顧客
                liftingListStatus.CreateLifting = true;
                break;
            case Constant.AUTHORITY_ARRANGE_STAFF:	//4:手配担当者
            case Constant.AUTHORITY_NOHARA_CUSTOMER:	//5:野原顧客
                liftingListStatus.PageAccess = true;
                break;
        };
        console.log(liftingListStatus);
        return liftingListStatus;

    }

    /**
     * 揚重申請のヘッダーボタン
     * @param {User} userInfo
     * @param {Lifting} lifting
     * @param orderflg
     * @returns {{PageAccess: boolean; editable: boolean; Application: boolean; Save: boolean; Report: boolean; Back: boolean; BackNoSave: boolean; editApplicantContact: boolean; editRelationContacts: boolean; editDeliveryInfo: boolean; CreateCargo: boolean; hasOrder: boolean; allCloseCargo: boolean; hasConfirmed: boolean; allBeforeRequest: boolean; allApplicationClose: boolean}}
     */
    getLiftingHeaderStatus(userInfo: User, lifting: Lifting, orderflg) {
        console.log(userInfo, lifting, orderflg);

        const liftingHeaderStatus = {
            PageAccess: false,

            editable: false,   //何らか編集できる
            Application: false, //申請ボタン
            Save: false, //保存ボタン
            Report: false, //帳票出力ボタン
            Back: true, //戻るボタン
            BackNoSave: false, //保存せずに戻るボタン

            editApplicantContact: false, //申請者情報編集許可
            editRelationContacts: false, //元請けと荷受人情報編集許可
            //元請け情報が編集できる場合は荷受人も一緒の権限なのでeditRelationContactsに統一
            //editConsignee: false, //荷受人編集許可
            editDeliveryInfo: false, //搬入区分、エレベーター編集許可
            CreateCargo: false, // 搬入揚重情報新規作成
            hasOrder: false, //納入依頼からの作成（納入依頼情報グリッドの表示）
            canOrderAccess: false, //納入情報へのアクセス許可

            allCloseCargo: false,  //全部のトラックが納入済み
            hasConfirmed : false, //確定ステータスを持つトラックがいる
            allBeforeRequest: false, //すべて未申請
            allApplicationClose: false, //すべて締切済み
            noCargo: true, //トラック登録なし

            isCreateUser:false, //作成者
            isRerationUser:false, //連絡先
            isNewInput:false, //新規作成
        };

        //新規作成か、更新か
        if (!lifting || Object.keys(lifting).length === 0 || ! lifting.id || lifting.id === null || !lifting.cargos) {
            // ※一旦、新規の時はliftingをなしで送ってもらう
            // ※この条件で大丈夫か？
            liftingHeaderStatus.isNewInput = true;
            liftingHeaderStatus.isCreateUser = true;
        } else {
            // すでに揚重Noが発行されている場合
            if (lifting.createUserId === userInfo.id) {
                // データオーナーが作成者の場合
                liftingHeaderStatus.isCreateUser = true;
                //※ lifting.applicantContact.personMail===userInfo.email も作成者とみなす
            } else if (lifting.relationContacts && lifting.relationContacts.some(relation => relation.personMail === userInfo.email)) {
                // 連絡先に設定されている場合
                liftingHeaderStatus.isRerationUser = true;
            };
        };

        if (liftingHeaderStatus.isNewInput) {
            liftingHeaderStatus.allBeforeRequest = true; //すべて未申請
            liftingHeaderStatus.hasOrder = orderflg;  //納入依頼からの申請
        } else {
            if (lifting.cargos.every(cargo => cargo.status === Constant.CARGO_STATUS_LIST.STATUS_DELIVERED)){
                liftingHeaderStatus.allCloseCargo = true;  //全部のトラックが納入済み
            } else if (lifting.cargos.every(cargo => cargo.status === Constant.CARGO_STATUS_LIST.STATUS_BEFORE_REQUEST)){
                liftingHeaderStatus.allBeforeRequest = true; //すべて未申請
            } else if (lifting.cargos.every(cargo => cargo.wasClosed)){
                liftingHeaderStatus.allApplicationClose = true;  //すべて締切済み
            };
            if (lifting.cargos.some(cargo => cargo.status === Constant.CARGO_STATUS_LIST.STATUS_CONFIRMED)){
                liftingHeaderStatus.hasConfirmed  = true; //確定ステータスを持つトラックがいる
            };
            if (orderflg || (lifting.cargos.every(cargo => cargo.cargoMaterials && cargo.cargoMaterials.some( material=> material.orderNumber && material.orderNumber !== '' )))){
                liftingHeaderStatus.hasOrder = true;  //納入依頼からの申請
            };
        };


        //　納入情報へのアクセス許可
        if (liftingHeaderStatus.hasOrder){
            switch (userInfo.authority) {
                case Constant.AUTHORITY_ADMIN:	//1:管理者
                case Constant.AUTHORITY_SITE_STAFF:	//2:現場担当者
                case Constant.AUTHORITY_ARRANGE_STAFF:	//4:手配担当者
                case Constant.AUTHORITY_NOHARA_CUSTOMER:	//5:野原顧客
                    liftingHeaderStatus.canOrderAccess = true;　//納入情報へのアクセス許可
                    break;
                case Constant.AUTHORITY_LIFTING:	//3:揚重センター
                case Constant.AUTHORITY_NOT_NOHARA_CUSTOMER:	//6:非野原顧客
                    break;
            }
        }

        // ***************************************
        // 揚重申請状態による出し分け
        // ***************************************

        if (liftingHeaderStatus.isNewInput) {
            // 新規作成時
            switch (userInfo.authority) {
                case Constant.AUTHORITY_ADMIN:	//1:管理者
                case Constant.AUTHORITY_SITE_STAFF:	//2:現場担当者
                case Constant.AUTHORITY_LIFTING:	//3:揚重センター
                case Constant.AUTHORITY_NOT_NOHARA_CUSTOMER:	//6:非野原顧客
                    liftingHeaderStatus.editable = true;
                    liftingHeaderStatus.Application = true;  //申請ボタン
                    liftingHeaderStatus.Save = true;  //保存ボタン
                    liftingHeaderStatus.BackNoSave = true;  //保存せずに戻るボタン
                    liftingHeaderStatus.Back = false;

                    liftingHeaderStatus.editApplicantContact = true;  //申請者情報編集許可
                    liftingHeaderStatus.editRelationContacts = true;  //元請け情報編集許可
                    liftingHeaderStatus.editDeliveryInfo = true;  //搬入区分、エレベーター編集許可
                    liftingHeaderStatus.CreateCargo = true;  // 搬入揚重情報新規作成

                    break;
                case Constant.AUTHORITY_ARRANGE_STAFF:	//4:手配担当者
                case Constant.AUTHORITY_NOHARA_CUSTOMER:	//5:野原顧客
                    break;
            }
        }　else if (liftingHeaderStatus.allCloseCargo) {
            // すべて納入済み
            switch (userInfo.authority) {
                case Constant.AUTHORITY_ADMIN:	//1:管理者
                case Constant.AUTHORITY_SITE_STAFF:	//2:現場担当者
                case Constant.AUTHORITY_LIFTING:	//3:揚重センター
                case Constant.AUTHORITY_NOT_NOHARA_CUSTOMER:	//6:非野原顧客
                case Constant.AUTHORITY_ARRANGE_STAFF:	//4:手配担当者
                  liftingHeaderStatus.Application = false;  //申請ボタン
                  liftingHeaderStatus.BackNoSave = false;  //保存せずに戻るボタン
                  liftingHeaderStatus.Back = true;
                  liftingHeaderStatus.Report = true;  // 帳票出力ボタン
                  break;
                case Constant.AUTHORITY_NOHARA_CUSTOMER:	//5:野原顧客
                    break;
            }
        } else {
            //　納入済みになっていないトラックが含まれるとき
            switch (userInfo.authority) {
                case Constant.AUTHORITY_ADMIN:	//1:管理者
                case Constant.AUTHORITY_SITE_STAFF:	//2:現場担当者

                    liftingHeaderStatus.editable = true;
                    liftingHeaderStatus.Application = true;  //申請ボタン
                    liftingHeaderStatus.BackNoSave = true;  //保存せずに戻るボタン
                    liftingHeaderStatus.CreateCargo = true;  // 搬入揚重情報新規作成
                    liftingHeaderStatus.Report = true;  //帳票出力ボタン
                    liftingHeaderStatus.Back = false;

                    liftingHeaderStatus.editApplicantContact = true;  //申請者情報編集許可
                    liftingHeaderStatus.editRelationContacts = true;  //元請け情報編集許可
                    liftingHeaderStatus.editDeliveryInfo = true;  //搬入区分、エレベーター編集許可
                    //未申請の時だけ保存可
                    if (liftingHeaderStatus.allBeforeRequest) {
                        liftingHeaderStatus.Save = true;  //保存ボタン
                    }
                    break;
                case Constant.AUTHORITY_LIFTING:	//3:揚重センター
                    liftingHeaderStatus.Report = true;  //帳票出力ボタン
                    //揚重センターは、自分の作った揚重申請以外は手を入れることはない
                    //揚重センターは、締切や確定を超えて揚重申請を作ることができる
                    if (liftingHeaderStatus.isCreateUser){
                        liftingHeaderStatus.editable = true;
                        liftingHeaderStatus.Application = true;  //申請ボタン
                        liftingHeaderStatus.BackNoSave = true;  //保存せずに戻るボタン
                        liftingHeaderStatus.CreateCargo = true;  // 搬入揚重情報新規作成
                        liftingHeaderStatus.Back = false;

                        liftingHeaderStatus.editApplicantContact = true;  //申請者情報編集許可
                        liftingHeaderStatus.editRelationContacts = true;  //元請け情報編集許可
                        liftingHeaderStatus.editDeliveryInfo = true;  //搬入区分、エレベーター編集許可

                        //未申請の時だけ保存可
                        if (liftingHeaderStatus.allBeforeRequest) {
                            liftingHeaderStatus.Save = true;  //保存ボタン
                        }
                    }
                    break;

                case Constant.AUTHORITY_NOT_NOHARA_CUSTOMER:	//6:非野原顧客
                    // 非野原顧客は、作った人
                    if (liftingHeaderStatus.isCreateUser) {
                      liftingHeaderStatus.Report = true;  //帳票出力ボタン
                      if (!liftingHeaderStatus.allApplicationClose) {
                            //締切前なら編集可能
                            liftingHeaderStatus.editable = true;
                            liftingHeaderStatus.Application = true;  //申請ボタン
                            liftingHeaderStatus.BackNoSave = true;  //保存せずに戻るボタン
                            liftingHeaderStatus.CreateCargo = true;  // 搬入揚重情報新規作成
                            liftingHeaderStatus.Back = false;

                            liftingHeaderStatus.editApplicantContact = true;  //申請者情報編集許可
                            liftingHeaderStatus.editRelationContacts = true;  //元請け情報編集許可
                            liftingHeaderStatus.editDeliveryInfo = true;  //搬入区分、エレベーター編集許可

                            //未申請の時だけ保存可
                            if (liftingHeaderStatus.allBeforeRequest) {
                                liftingHeaderStatus.Save = true;  //保存ボタン
                            }

                        } else {
                            //締切後は、納入前までドライバー情報更新可能
                            liftingHeaderStatus.Application = true;  //申請ボタン
                            liftingHeaderStatus.BackNoSave = true;  //保存せずに戻るボタン
                        }
                    }
                    break;
                case Constant.AUTHORITY_ARRANGE_STAFF:	//4:手配担当者
                    liftingHeaderStatus.Report = true;  //帳票出力ボタン
                // 手配担当は、ドライバー情報を登録するために申請ボタンと戻るボタンのみ利用できる
                    // ※以下の確定ステータスは、揚重の確定。揚重確定⇒手配確定⇒トラックとドライバー確定になる
                    // if (liftingHeaderStatus.hasConfirmed) {
                    if (!liftingHeaderStatus.allBeforeRequest) {
                        liftingHeaderStatus.editable = true;
                        liftingHeaderStatus.Application = true;  //申請ボタン
                        liftingHeaderStatus.BackNoSave = true;  //保存せずに戻るボタン
                        liftingHeaderStatus.Back = false;
                      }
                    break;
                case Constant.AUTHORITY_NOHARA_CUSTOMER:	//5:野原顧客
                    break;
            }
       }
        // console.log(liftingHeaderStatus);
        return liftingHeaderStatus;
    }


    /**
     * 揚重申請（搬入・揚重情報）のヘッダーボタン
     * @param {User} userInfo
     * @param {Lifting} lifting
     * @param {number} cargoID
     * @param orderflg
     * @returns {{PageAccess: boolean; Save: boolean; Back: boolean; editLiftingRequest: boolean; viewCargoDetail: boolean; CreateLiftingFromOrder: boolean; EditMaterialNoOrder: boolean; editDriverInfo: boolean; editDeliveryInfo: boolean; CreateCargo: boolean; OpenOrderList: boolean; hasOrder: boolean; allReadOnly: boolean}}
     */
    getCargoHeaderStatus(userInfo: User, cargo: any, createUserId: number, orderflg) {
        console.log(userInfo, cargo, createUserId, orderflg);
        // CARGO_STATUS_LIST.STATUS_BEFORE_REQUEST
        // CARGO_STATUS_LIST.STATUS_REQUESTED
        // CARGO_STATUS_LIST.STATUS_CONFIRMED
        // CARGO_STATUS_LIST.STATUS_DELIVERED

        const cargoHeaderStatus = {
            PageAccess: false,
            Save: false, //登録
            Back: true, //戻る

            editLiftingRequest: false, // 資材情報合計・搬入希望情報・資材情報編集許可（ドライバー情報以外の編集）
            viewCargoDetail: false, //資材情報合計・荷姿及び梱包方法の入力エリア表示
            CreateLiftingFromOrder: false, //納入依頼から資材追加ボタン（手動行追加禁止）
            EditMaterialNoOrder: false, //納入依頼から資材追加ボタン（手動行追加、上書き、削除可）

            editDriverInfo: false, //ドライバー情報編集
            canEditReauestDate:false,   //希望日入力可
            ReauestDateValidate:false,   //希望日入力チェック

            editDeliveryInfo: false, //搬入区分、エレベーター編集許可
            CreateCargo: false, // 搬入揚重情報新規作成
            OpenOrderList: false, //明細行がない状態（新規）で、画面が開くと、資材情報がでる
            hasOrder: false, //納入依頼からの作成

            allReadOnly: false, //納入後、権限などで、見るだけしかできない（一番強い縛り）
            //納入依頼グリッド作成

            isCreateUser: false, //データオーナー
            isNewInput: false, //新規作成か、更新か
            wasClosed: false, //締切済み

        };


        // データオーナー
        // let isRerationUser = false;
        //新規作成か、更新か

        if (!cargo || Object.keys(cargo).length === 0 || !cargo.id || cargo.id === null) {
            // 新規作成
            cargoHeaderStatus.isNewInput = true;
            cargoHeaderStatus.isCreateUser = true;
        } else {
            // すでに揚重Noが発行されている場合
            if (createUserId === userInfo.id) {
                // データオーナーが作成者の場合
                cargoHeaderStatus.isCreateUser = true;
                //※ lifting.applicantContact.personMail===userInfo.email も作成者とみなす
            // } else if (lifting.relationContacts && lifting.relationContacts.some(relation => relation.personMail === userInfo.email)) {
            //     // 連絡先に設定されている場合
            //     isRerationUser = true;
            }
            cargoHeaderStatus.wasClosed = cargo.wasClosed;
            if (cargo.status === Constant.CARGO_STATUS_LIST.STATUS_DELIVERED){
                // 納入後は全員みるだけ
                cargoHeaderStatus.allReadOnly = true;
            }
        }

        //納入依頼から資材追加
        if (orderflg || (cargo && cargo.cargoMaterials && cargo.cargoMaterials.some( material=> material.orderNumber && material.orderNumber !== '' ))){
            cargoHeaderStatus.hasOrder = true;  //納入依頼からの申請
        }

        //表示エリア設定
        switch (userInfo.authority) {
            case Constant.AUTHORITY_ADMIN:	//1:管理者
            case Constant.AUTHORITY_SITE_STAFF:	//2:現場担当者
            case Constant.AUTHORITY_ARRANGE_STAFF:	//4:手配担当者
                //資材情報合計・荷姿及び梱包方法の入力エリア表示
                cargoHeaderStatus.viewCargoDetail = true;
                break;
            case Constant.AUTHORITY_LIFTING:	//3:揚重センター
                // 揚重センターは、作った人以外はみるだけ
                cargoHeaderStatus.viewCargoDetail = true;//資材情報合計・荷姿及び梱包方法の入力エリア表示
                if (!cargoHeaderStatus.isCreateUser) {
                    cargoHeaderStatus.allReadOnly = true;
                }
                break;
            case Constant.AUTHORITY_NOT_NOHARA_CUSTOMER:	//6:非野原顧客
                // 非野原顧客は、作った人以外はみるだけ
                if (cargoHeaderStatus.isCreateUser) {
                    cargoHeaderStatus.viewCargoDetail = true;//資材情報合計・荷姿及び梱包方法の入力エリア表示
                } else {
                    cargoHeaderStatus.allReadOnly = true;
                    if (!cargoHeaderStatus.hasOrder){
                        //非野原だが、Cargoが納入依頼ありの場合は、見れる情報が少ない
                        cargoHeaderStatus.viewCargoDetail = true;//資材情報合計・荷姿及び梱包方法の入力エリア表示
                    }
                }
                break;
            case Constant.AUTHORITY_NOHARA_CUSTOMER:	//5:野原顧客
                // 野原顧客はみるだけ
                cargoHeaderStatus.allReadOnly = true;
                break;
        }

        if (cargoHeaderStatus.allReadOnly){
            //見るだけの場合は退場
            console.log(cargoHeaderStatus);
            return cargoHeaderStatus;
        }

        // ***********************************************************************************
        // 以降の処理は作成者の非野原・作成者の揚重センターか、野原関係者で、納入済み前
        // ***********************************************************************************

        // 確定済みか
        //const wasConfirmed = cargo.status === Constant.CARGO_STATUS_LIST.STATUS_CONFIRMED;
        switch (userInfo.authority) {
            case Constant.AUTHORITY_ADMIN:	//1:管理者
            case Constant.AUTHORITY_SITE_STAFF:	//2:現場担当者
                // 確定後も更新可能
                cargoHeaderStatus.Save = true; //登録
                cargoHeaderStatus.editLiftingRequest = true; // 資材情報合計・搬入希望情報・資材情報編集許可（ドライバー情報以外の編集）
                cargoHeaderStatus.editDriverInfo = true; //ドライバー情報編集
                cargoHeaderStatus.editDeliveryInfo = true; //搬入区分、エレベーター編集許可
                cargoHeaderStatus.CreateCargo = true; // 搬入揚重情報新規作成

                if (!cargoHeaderStatus.wasClosed) {
                    //締切後は希望日変更不可。入力できる場合も、入力チェックはなし
                    cargoHeaderStatus.canEditReauestDate = true; //希望日入力可
                }

                //納入依頼から資材追加
                if (cargoHeaderStatus.hasOrder){
                    cargoHeaderStatus.CreateLiftingFromOrder = true; //納入依頼から資材追加ボタン（手動行追加禁止）
                    if (cargoHeaderStatus.isNewInput) {
                        cargoHeaderStatus.OpenOrderList = true; //明細行がない状態（新規）で、画面が開くと、資材情報がでる
                    }
                } else {
                    cargoHeaderStatus.EditMaterialNoOrder = true; //資材手動（手動行追加、上書き、削除可）
                    // 納入依頼のない資材は、納入前まで変更可能とする（1/31）
                    // if (wasConfirmed) {
                    //     cargoHeaderStatus.EditMaterialNoOrder = false; //資材手動（手動行追加、上書き、削除可）
                    // }
                }
                break;
            case Constant.AUTHORITY_LIFTING:	//3:揚重センター
                // 確定後も更新可能
                cargoHeaderStatus.Save = true; // 登録
                cargoHeaderStatus.editLiftingRequest = true; // 資材情報合計・搬入希望情報・資材情報編集許可（ドライバー情報以外の編集）
                cargoHeaderStatus.editDriverInfo = true; // ドライバー情報編集
                cargoHeaderStatus.editDeliveryInfo = true; // 搬入区分、エレベーター編集許可
                cargoHeaderStatus.CreateCargo = true; // 搬入揚重情報新規作成
                cargoHeaderStatus.EditMaterialNoOrder = true; //資材手動（手動行追加、上書き、削除可）

                if (!cargoHeaderStatus.wasClosed) {
                    // 締切後は希望日変更不可。入力できる場合も、入力チェックはなし
                    cargoHeaderStatus.canEditReauestDate = true; // 希望日入力可
                } else {
                    // 納入依頼のない資材は、納入前まで変更可能とする（1/31）
                    // if (wasConfirmed) {
                    //   cargoHeaderStatus.EditMaterialNoOrder = false; // 資材手動（手動行追加、上書き、削除可）
                    // }
                }
                break;
            case Constant.AUTHORITY_NOT_NOHARA_CUSTOMER:	//6:非野原顧客
                if (!cargoHeaderStatus.wasClosed){
                    //確定前は登録可能
                    cargoHeaderStatus.Save = true; //登録
                    cargoHeaderStatus.editLiftingRequest = true; // 資材情報合計・搬入希望情報・資材情報編集許可（ドライバー情報以外の編集）
                    cargoHeaderStatus.EditMaterialNoOrder = true; //資材手動（手動行追加、上書き、削除可）
                    cargoHeaderStatus.editDriverInfo = true; //ドライバー情報編集
                    cargoHeaderStatus.editDeliveryInfo = true; //搬入区分、エレベーター編集許可
                    cargoHeaderStatus.CreateCargo = true; // 搬入揚重情報新規作成
                    cargoHeaderStatus.canEditReauestDate = true; //希望日入力可
                    cargoHeaderStatus.ReauestDateValidate = true; //希望日入力チェック
                } else {
                    //確定後はドライバーのみ
                    cargoHeaderStatus.editDriverInfo = true; //ドライバー情報編集
                    cargoHeaderStatus.Save = true; //登録
                }
                break;
            case Constant.AUTHORITY_ARRANGE_STAFF:	//4:手配担当者
                //※ドライバー登録は特に納入前ならいつできてもよいのでは。
                //if (cargo && cargo.status === Constant.CARGO_STATUS_LIST.STATUS_CONFIRMED){
                    cargoHeaderStatus.editDriverInfo = true; //ドライバー情報編集
                    cargoHeaderStatus.Save = true; //登録
                //}
                break;
            case Constant.AUTHORITY_NOHARA_CUSTOMER:	//5:野原顧客
                // 事前に退場しているのでここにはこない
                break;
        }
        // console.log(cargoHeaderStatus);
        return cargoHeaderStatus;
    }

    /**
     * 搬入・揚重情報の資材情報の編集ステータス
     * @param {User} userInfo
     * @param cargoHeaderStatus
     * @param cargo
     * @param {number} rowIndex
     * @returns {{Edit: boolean; deleteRow: boolean}}
     */
    getCargoMaterialStatus(userInfo: User, cargoHeaderStatus: any, cargo: any, rowIndex : number) {
        const cargoMaterialStatus = {
            deleteRow: false, //削除ボタン表示
        };
        // 対象データがない場合はチェックなし
        if (!cargo.cargoMaterials || Object.keys(cargo.cargoMaterials).length <= rowIndex) {
            cargoMaterialStatus.deleteRow = true;
            console.log(cargoMaterialStatus);
            return cargoMaterialStatus;
        };

        //
        let material = cargo.cargoMaterials[rowIndex];

        switch (userInfo.authority){
            case Constant.AUTHORITY_ADMIN:	//1:管理者
            case Constant.AUTHORITY_SITE_STAFF:	//2:現場担当者
                if (cargoHeaderStatus.hasOrder){
                    //　納入依頼から作成された場合は、手配ステータスによって削除ができる
                    switch (material.orderStatus){

                        case Constant.ORDER_STATUS_LIST.STATUS_NO_ORDER://未手配
                        case Constant.ORDER_STATUS_LIST.STATUS_ORDERING://手配中
                        case Constant.ORDER_STATUS_LIST.STATUS_ARRANGED://仮手配
                        case Constant.ORDER_STATUS_LIST.STATUS_CANCELED://取消し
                        case Constant.ORDER_STATUS_LIST.STATUS_ARRANGED_CANCELED://手配キャンセル
                            cargoMaterialStatus.deleteRow = true;
                            break;
                        case Constant.ORDER_STATUS_LIST.STATUS_ARRANGED_CONFIRMED://手配確定
                        case Constant.ORDER_STATUS_LIST.STATUS_DELIVERED://納入済み
                            break;
                    }
                } else {
                    //ヘッダー側の編集ステータスに従う
                    cargoMaterialStatus.deleteRow = cargoHeaderStatus.editLiftingRequest;
                }

                break;
            case Constant.AUTHORITY_ARRANGE_STAFF:	//4:手配担当者
                //手配担当はみるだけ。操作は納入依頼に対してのみ行う
                //cargoMaterialStatus.deleteRow = false;
                break;
            case Constant.AUTHORITY_LIFTING:	//3:揚重センター
            case Constant.AUTHORITY_NOT_NOHARA_CUSTOMER:	//6:非野原顧客
                //ヘッダー側の編集ステータスに従う
                cargoMaterialStatus.deleteRow = cargoHeaderStatus.editLiftingRequest;
                break;
            case Constant.AUTHORITY_NOHARA_CUSTOMER:	//5:野原顧客
                break;
        };
        console.log(cargoMaterialStatus);
        return cargoMaterialStatus;

    }

}
