import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';
import { User } from '../../master/user/user';
import { ɵBrowserPlatformLocation } from '@angular/platform-browser';
import {Cargo} from "../../lifting/cargo";

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'POST'
  })
};

@Injectable({ providedIn: 'root' })
export class UserService {

  constructor(private http: HttpClient){}

  // ユーザー一覧を取得
  getAllUsers(page: number, searchKeys = {}, sort = {}): Observable<User[]> {
    const limit = 30;
    const authorityList = ['管理者', '現場担当者', '揚重センター', '手配担当者', '施工業者（野原顧客）', '施工業者'];

    // offsetは0から始まる
    let offset = limit * (page - 1);
    if (offset < 0) {
      offset = 0;
    }
    let url = environment.apiUrl + '/user?';
    url += 'limit=' + limit;
    url += '&offset=' + offset;
    if (0 !== Object.keys(searchKeys).length) {
      for (let key of Object.keys(searchKeys)) {
        if(key === 'site.name'){
          url += '&' + 'site' + '=' + searchKeys[key];
        } else if (key === 'authority') {
          // 権限の場合はidに変換
          let id = authorityList.indexOf(searchKeys[key])+1;
          url += '&' + key + '=' + id;
        } else {
          url += '&' + key + '=' + searchKeys[key];
        }
      }
    }
    if (sort['sortKey']) {
      // APIパラメータ用にキーを補正
      if(sort['sortKey'].indexOf('site.name')!==-1){
        url += '&sort=' + 'site';
      }else{
        url += '&sort=' + sort['sortKey'];
      }
      url += '&order=' + sort['sortOrder'];
    }
    return this.http.get<User[]>(url);
  }
  // userIdで指定したユーザー情報を取得
  getUser(id: string): Observable<User> {
    return this.http.get<User>(`${environment.apiUrl}/user/${id}`);
  }

  updateUser(id: string, userInfo: User): Observable<User> {
    const body = JSON.stringify(userInfo);
    return this.http.put<User>(`${environment.apiUrl}/user/${id}`, body, httpOptions).pipe(
      tap(() => console.log(`updateUser success`))
    );
  }

  createUser(userInfo: User): Observable<User> {
    const body = JSON.stringify(userInfo);
    console.log(body);
    return this.http.post<User>(`${environment.apiUrl}/user`, body, httpOptions).pipe(
      tap(() => console.log(`createUser success`))
    );
  }

  userLogin(userId: string, password: string, siteId: string): Observable<User> {
    const body = new FormData();
    body.append('userId', userId);
    body.append('password',  password);
    if (siteId) {
      body.append('siteId', siteId);
    }
    return this.http.post<User>(`${environment.apiUrl}/user/login`, body ).pipe(
      tap(() => console.log(`login id=${userId} password=${password}`))
    );
  }

  userLogout(userId: string): Observable<User> {
    const body = new FormData();
    body.append('userId', userId);
    return this.http.post<User>(`${environment.apiUrl}/user/logout`, body );
  }

  userPasswordChange(userId: string, oldPassword: string, newPassword: string): Observable<User>{
    const body = new FormData();
    body.append('userId', userId);
    body.append('oldPassword',  oldPassword);
    body.append('newPassword',  newPassword);
    return this.http.post<User>(`${environment.apiUrl}/user/password/change`, body ).pipe(
      tap(() => console.log(`login id=${userId} oldPassword=${oldPassword} newPassword=${newPassword}`))
    );
  }

  userPasswordReset(userId: string): Observable<User>{
    const body = new FormData();
    body.append('userId', userId);
    return this.http.post<User>(`${environment.apiUrl}/user/password/reset`, body ).pipe(
      tap(() => console.log(`login id=${userId}`))
    );
  }

  // アカウントの停止・再開
  userSuspend(id: string,userInfo: User): Observable<User>{
    const body:any = JSON.stringify(userInfo);
    console.log(body);
    return this.http.post<User>(`${environment.apiUrl}/user/suspend`, body, httpOptions).pipe(
      tap(() => console.log(`login id=${id}`))
    );
  }

  /*
   * エラー詳細を取得
   */
  // private handleError<T> (operation = 'operation', result?: T) {
  //   return (error: any): Observable<T> => {

  //     // TODO: send the error to remote logging infrastructure
  //     console.error(error); // log to console instead

  //     // TODO: better job of transforming error for user consumption
  //     console.log(`${operation} failed: ${error.message}`);

  //     // Let the app keep running by returning an empty result.
  //     return of(result as T);
  //   };
  // }
}
