import { MaterialsService } from './../../core/materials.service';
import { Component, Inject, OnInit, ViewChild, Renderer2, ElementRef, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { Subject } from 'rxjs';
import * as wjcCore from '@grapecity/wijmo';
import * as wjcGrid from '@grapecity/wijmo.grid';
import * as wjcFilter from '@grapecity/wijmo.grid.filter';
import * as wjcInput from '@grapecity/wijmo.input';
import { DataService } from '../../app.data';
import { Lifting } from '../lifting';
import { Material } from '../material';
import { LiftingService } from '../../core/service/lifting.service';
import { CustomGridEditor } from '../../core/custom-grid-editor';
import { CommonService } from '../../core/common.service';
import { CommonFilterUiService } from '../../core/common-filter-ui.service';
import { GeneralSituationCheckerService } from '../../core/general-situation-checker.service';
import { Constant } from 'src/app/core/constant';
import {DatePipe} from '@angular/common';

import * as $ from 'jquery';
import { User } from '../../master/user/user';
import { SiteService } from 'src/app/core/service/site.service';

declare var $: $;

@Component({
  selector: 'app-lifting-detail-material',
  templateUrl: './lifting-detail-material.component.html',
  styleUrls: ['./lifting-detail-material.component.scss']
})

export class LiftingDetailMaterialComponent implements OnInit {

  materials = new wjcCore.CollectionView([]);
  unitMap: wjcGrid.DataMap;
  apiData: Subject<any> = new Subject();
  apiMaterialData: Subject<any> = new Subject();
  userData: any = {};

  youjuId: string;
  cargoId: number;
  cargoIndex: number;
  materialsData: any;
  liftingData: any;
  orderFlag: boolean;
  orderNum: string;
  liftingNumber = null;
  liftingTemporary: any;
  siteId: string;
  createUser: number;

  // 確定情報関連
  confirmInfo: any = {
    date: '',
    time: '',
    lift: '',
    number: ''
  };
  originConfirmDate: string;
  cargoMaterials = new wjcCore.CollectionView([]);
  requestDate: Date = this.commonService.getDateTime();
  requestStartTime: string;
  requestEndTime: string;
  useLiftStartTime: string;
  useLiftEndTime: string;
  timeDataStart: any = [];
  timeDataEnd: any = [];

  cargos: any = [];
  currentCargos: any = {
    otherWeight: 0,
    liftingCount: 0,
    truckCount: 0,
  };

  ordersMaterials: any = [];

  totalWeight = 0;
  authority: any;
  workType: object = Constant.WORKTYPE;
  statusList = Constant.MATERIAL_STATUS_STR_LIST;

  isLoading = false;
  validateCheck = true;
  cargoStatus: number;
  isUnapplied: string;
  editDisabled: boolean = false;
  editDriverDisabled: boolean = false;
  editReauestDateDisabled: boolean = false;
  createLiftingFromOrder: boolean;
  editMaterialNoOrder: boolean;
  viewCargoDetail: boolean;
  allReadOnly: boolean;

  isRequestReverseTime = false;
  isUseReverseTime = false;

  imgUrls: any = [];
  imgUrlIds = [];

  periodStart: Date;
  periodEnd: Date;

  materialsUnitList = [];
  makerUnitList = [];
  partsNameUnitList = [];
  partsNumberUnitList = [];

  /* カスタムフィルタ用変数 */
  materialsModalGrid;
  materialsModalFilter;
  searchKeys = {};
  sortCondition = { sortKey: 'orderNumber', sortOrder: 'asc' };
  currentPage = 1;

  // 定数取得
  constant = Constant;

  isLastPage = false;

  // エラー表示
  public errorMessage: string;
  public errorProperty = 'none';
  public warningMessage: string;
  public warningProperty = 'none';
  public successProperty = 'none';
  public requestDateProperty = 'none';

  situationCheckerResult: any;
  materialPopUp;
  flex: wjcGrid.FlexGrid;

  @ViewChild('comboWorkType', { static: true }) comboWorkType: wjcInput.ComboBox;
  @ViewChild('imgUrl', { static: false }) imgUrl: ElementRef;
  @ViewChild('filter', { static: true }) filter: wjcFilter.FlexGridFilter;
  @ViewChild('customRequestStartTime', { static: false }) customRequestStartTime: wjcInput.ComboBox;
  @ViewChild('customRequestEndTime', { static: false }) customRequestEndTime: wjcInput.ComboBox;
  @ViewChild('customUseLiftStartTime', { static: false }) customUseLiftStartTime: wjcInput.ComboBox;
  @ViewChild('customUseLiftEndTime', { static: false }) customUseLiftEndTime: wjcInput.ComboBox;
  @ViewChild('thePopup', { static: true }) thePopup: wjcInput.Popup;

  private orderableDateList;
  totalCount: number;

  constructor(
    @Inject(DataService) private dataService: DataService,
    private liftingService: LiftingService,
    private route: ActivatedRoute,
    private router: Router,
    private location: Location,
    private render: Renderer2,
    public changeDetectorRef: ChangeDetectorRef,
    public commonService: CommonService,
    public situationChecker: GeneralSituationCheckerService,
    private uiFilter: CommonFilterUiService,
    private siteService: SiteService,
    private materialService: MaterialsService,
    private datepipe: DatePipe) {
    this.situationCheckerResult = {};

    // APIデータ戻り後の処理を定義(揚重申請を取ってきた後の動き)
    this.apiData.subscribe(apidata => {
      // this.liftingData = apidata.cargos;
      this.createUser = apidata.createUserId;
      // cargos全体のデータ
      this.cargos = apidata.cargos;
      // cargoIdに一致する明細のデータ
      this.currentCargos = apidata.cargos.find(material => material.id == this.cargoId);

      if (this.currentCargos == undefined) {
        this.currentCargos = {};
      }
      console.log(this.cargos);
      console.log(this.currentCargos);
      // 確定日時を格納
      if (this.currentCargos && this.currentCargos.confirmDateTime) {
        if (this.currentCargos.confirmDateTime) {
          this.originConfirmDate = this.currentCargos.confirmDateTime;
          this.confirmInfo = {
            date: this.confirmDateConversion(this.currentCargos.confirmDateTime)[0],
            time: this.confirmDateConversion(this.currentCargos.confirmDateTime)[1],
            lift: this.currentCargos.confirmLift,
            number: this.currentCargos.confirmLiftingNumber
          };
        }
      }

      // 表示用データ作成関数
      this.displayDataCreate(this.currentCargos);

      // 資材モーダルとの連携用に配列格納
      this.materialsData = this.currentCargos.cargoMaterials;
      this.cargoMaterialsCreate(this.materialsData, false);

      // LiftingTemporaryの内容が変わっているので再呼び
      this.situationCheckerResult = this.situationChecker.getCargoHeaderStatus(
        this.userData, this.currentCargos, this.createUser, this.orderFlag);
      this.changeDetectorRef.detectChanges();
      console.log("既存データ: ", this.situationCheckerResult);

      // ステータス反映
      this.statusReflection(this.situationCheckerResult);
    });

    // APIデータ戻り後の処理を定義(資材情報を取ってきた後の動き)
    this.apiMaterialData.subscribe(apidata => {
      const gridData = [];
      let selItem: boolean;
      apidata.map(val => {
        if (this.orderNum) {
          val.orderNumber === this.orderNum ? selItem = true : selItem = false;
          // let num = this.orderData.map(x => x.number);
          // num.indexOf(val.orderNumber) !== -1 ? selItem = true : selItem = false;
        } else {
          selItem = false;
        }

        gridData.push({
          sel: selItem,
          id: val.id,
          orderId: val.orderId,
          orderNumber: val.orderNumber,
          orderPersonName: val.orderPersonName,
          maker: val.maker,
          partsName: val.partsName,
          partsNumber: val.partsNumber,
          quantity: val.quantity,
          unit: val.unit,
          orderRequestDateTime: new Date(val.orderRequestDateTime),
          orderRequestDateTimeAls: new Date(val.orderRequestDateTime),
          orderRequestSection: val.orderRequestSection,
          orderStatus: val.orderStatus
        });
      });

      this.materials = new wjcCore.CollectionView(gridData);
      this.materials.sortDescriptions.clear();
      this.materials.sortDescriptions.push(
        new wjcCore.SortDescription(this.sortCondition.sortKey, this.sortCondition.sortOrder === 'asc'));
      this.isLastPage = this.commonService.isPagingLimit(this.materials.sourceCollection);

      if (0 !== Object.keys(this.searchKeys).length) {
        for (const key of Object.keys(this.searchKeys)) {
          const col = this.uiFilter.getColumnBindingIndex(this.materialsModalGrid.columns, key);
          const colFilter = this.materialsModalFilter.getColumnFilter(col);
          colFilter.conditionFilter.condition1.value = this.uiFilter.getFilterValSkeleton(colFilter.column);
          colFilter.conditionFilter.condition1.operator = this.uiFilter.getFilterOperatorSkeleton(colFilter.column);
        }
        this.materialsModalFilter.apply();
      }
      this.changeDetectorRef.detectChanges();
    });
  }

  // サーバー時間の今日を元にした納入依頼指定可能日の一覧を返す
  isOrderableDate(target: string) {
    if (this.orderableDateList && this.orderableDateList.indexOf(target) !== -1) {
      return true;
    } else {
      return false;
    }
  }

  // ページに入ってきたら走る
  ngOnInit() {

    // URLパラメーターから各IDを取得
    this.siteId = this.commonService.getRoutedSiteFromPath() !== '' ? this.commonService.getRoutedSiteFromPath() : '';
    this.youjuId = this.route.snapshot.params.youjuId;
    this.cargoId = Number(this.route.snapshot.params.id);
    this.cargoIndex = Number(this.route.snapshot.queryParamMap.get('cargoIndex'));
    const order = this.route.snapshot.queryParamMap.get('order');
    this.orderFlag = order === 'true';

    // 仮データの取得
    // this.liftingTemporary = this.liftingService.getCreateData();
    this.cargos = JSON.parse(sessionStorage.getItem('cargosData'));
    sessionStorage.removeItem('cargosData'); // 呼んだら消す

    // order情報を取得
    let getPassingData = JSON.parse(sessionStorage.getItem('passing'));
    if (getPassingData) {
      this.orderNum = getPassingData.num;
      this.requestDate = new Date(getPassingData.orderRequestDate);
      this.requestStartTime = this.datepipe.transform(this.requestDate, "H:mm");
      this.requestEndTime = this.requestStartTime 
      sessionStorage.removeItem('passing');
    }

    // ステータスを取得
    this.isUnapplied = sessionStorage.getItem('isUnapplied');
    sessionStorage.removeItem('isUnapplied');
  }

  /**
   * 搬入資材情報Gridの初期化
   * @param flex Grid
   */
  initializeGrid(flex: wjcGrid.FlexGrid) {
    this.flex = flex;
    // 単位の入力補助：LocalStorage
    if (localStorage.getItem('materialsUnitList')) {
      this.materialsUnitList = JSON.parse(localStorage.getItem('materialsUnitList'));
    } else {
      this.materialsUnitList = [{ label: '個', value: '個', added: 0 }, { label: '本', value: '本', added: 1 }];
      localStorage.setItem('materialsUnitList', JSON.stringify(this.materialsUnitList));
    }
    // ソート
    if (this.materialsUnitList != null && this.materialsUnitList.length !== 0) {
      this.materialsUnitList.sort(function (val1, val2) {
        return (val1.added < val2.added ? 1 : -1);
      });
    }

    // メーカーの入力補助：LocalStorage
    this.makerUnitList = JSON.parse(localStorage.getItem('makerUnitList'));
    // ソート
    if (this.makerUnitList != null && this.makerUnitList.length !== 0) {
      this.makerUnitList.sort(function (val1, val2) {
        return (val1.added < val2.added ? 1 : -1);
      });
    }

    // 品名の入力補助：LocalStorage
    this.partsNameUnitList = JSON.parse(localStorage.getItem('partsNameUnitList'));
    // ソート
    if (this.partsNameUnitList != null && this.partsNameUnitList.length !== 0) {
      this.partsNameUnitList.sort(function (val1, val2) {
        return (val1.added < val2.added ? 1 : -1);
      });
    }

    // 品番・規格の入力補助：LocalStorage
    this.partsNumberUnitList = JSON.parse(localStorage.getItem('partsNumberUnitList'));
    // ソート
    if (this.partsNumberUnitList != null && this.partsNumberUnitList.length !== 0) {
      this.partsNumberUnitList.sort(function (val1, val2) {
        return (val1.added < val2.added ? 1 : -1);
      });
    }

    // tslint:disable:no-unused-expression
    new CustomGridEditor(flex, 'dueDate', wjcInput.InputDate, {
      format: 'd'
    }, null);
    new CustomGridEditor(flex, 'deliveryTime', wjcInput.InputTime, {
      format: 't',
      step: 15
    }, null);
    new CustomGridEditor(flex, 'unit', wjcInput.AutoComplete, {
      displayMemberPath: 'label',
      itemsSource: this.materialsUnitList
    }, 10);
    new CustomGridEditor(flex, 'maker', wjcInput.AutoComplete, {
      displayMemberPath: 'label',
      itemsSource: this.makerUnitList
    }, 200);
    new CustomGridEditor(flex, 'partsName', wjcInput.AutoComplete, {
      displayMemberPath: 'label',
      itemsSource: this.partsNameUnitList
    }, 200);
    new CustomGridEditor(flex, 'partsNumber', wjcInput.AutoComplete, {
      displayMemberPath: 'label',
      itemsSource: this.partsNumberUnitList
    }, 200);

    // 数字入力バリデータ(編集終了直前のイベント)
    flex.cellEditEnding.addHandler((s: wjcGrid.FlexGrid, e: wjcGrid.CellEditEndingEventArgs) => {
      const c = s.columns[e.col];
      if (c && (c.binding === 'quantity' || c.binding === 'weight')) {
        // let value = wjcCore.changeType(s.activeEditor.value, wjcCore.DataType.Number, c.format);
        const value = s.activeEditor.value;
        // 0〜9の整数のみ
        const pattern = /^([0-9]\d*|0)$/;
        if (value !== '' && !pattern.test(value)) {
          // e.cancel = true;
          this.displayWarning('半角数字で入力してください。');
        } else {
          this.warningProperty = 'none';
        }
      }
    });

    // 入力された奴らをオートコンプリートに保存と、数字の補正
    flex.cellEditEnded.addHandler((s: wjcGrid.FlexGrid, e: wjcGrid.FormatItemEventArgs) => {
      const r = s.rows[e.row];
      const c = s.columns[e.col];
      if (!r || !c) {
        return;
      }

      if (c.binding === 'unit') {
        const item = r.dataItem.unit;
        this.addNewMaterialsUnit(item);
      } else if (c.binding === 'maker') {
        const item = r.dataItem.maker;
        this.addNewMaterialsMaker(item);
      } else if (c.binding === 'partsName') {
        const item = r.dataItem.partsName;
        this.addNewMaterialsPartsName(item);
      } else if (c.binding === 'partsNumber') {
        const item = r.dataItem.partsNumber;
        this.addNewMaterialsPartsNumber(item);
      } else if (c.binding === 'quantity') {
        // 負の数が入力されたら正に変換
        const item = Number(r.dataItem.quantity);
        if (item < 0) {
          r.dataItem.quantity = item * -1;
        }
      } else if (c.binding === 'weight') {
        // 負の数が入力されたら正に変換
        const item = Number(r.dataItem.weight);
        if (item < 0) {
          r.dataItem.weight = item * -1;
        }
      }
    });

      // 編集制限と、ステータスに応じて削除ボタンの付与
    flex.formatItem.addHandler((s: wjcGrid.FlexGrid, e: wjcGrid.FormatItemEventArgs) => {
        if (s.rows.length === 0) {
            return;
        }

        // 納入依頼ありの場合、重量以外は編集不可
        if (this.orderFlag) {
            const c = s.columns[e.col];
            if (c.binding !== 'weight') {
                c.isReadOnly = true;
            }
        }

        // 削除ボタンの表示
        if (this.cargoMaterials.items.length > 0) {
            if (e.panel === s.cells) {
                const r = s.rows[e.row];
                const c = s.columns[e.col];
                if (r.dataItem !== undefined && c.binding === 'recodeRemove') {
                    // ステータスによって削除カラムのボタン表示有無を制御
                    const canDeleteRow = this.situationChecker.getCargoMaterialStatus(
                        this.userData, this.situationCheckerResult, this.currentCargos, e.row).deleteRow;

                    if (!this.allReadOnly) {
                        if (canDeleteRow) {
                            e.cell.innerHTML = '<div class="btn btn-outline-dark px-3 py-0 removeBtn">削除</div>';
                        } else {
                            e.cell.innerHTML = '<p class="mb-0">-</p>';
                        }
                    } else {
                        e.cell.innerHTML = '<p class="mb-0">-</p>';
                    }
                }
            }
        }

        // 納入依頼Noをテキストリンクにする
        if (e.panel !== s.columnHeaders && s.columns[e.col].binding === 'orderNumber') {
            const data = s.rows[e.row].dataItem;
            if (data !== undefined) {
                e.cell.innerHTML = '<a href="/' + this.userData.site.id +
                    '/order/' + data.orderId + '" target="_blank">' + data.orderNumber + '</a>';
            }
        }
        // グリッド内の合計重量を計算
        this.weightSum(s);
    });

    // クリック時、チェックボックスを全選択にするのと、行削除のボタン押したときの動きを定義
    flex.hostElement.addEventListener('click', (e: MouseEvent) => {
      // 搬入資材Gridで選択って枠がない気がすんだけど
      if (wjcCore.hasClass(e.target as HTMLInputElement, 'select-all') &&
          e.target instanceof HTMLInputElement) {
          flex.deferUpdate(() => {
              flex.collectionView.items.forEach((item: any) => {
                  item.sel = (e.target as HTMLInputElement).checked;
              });
          });
      }

      // 削除ボタンを押したときの処理
      if ((e.target as HTMLElement).classList.contains('removeBtn')) {
          const view = flex.collectionView;
          // prevent the grid from getting the key
          e.preventDefault();
          this.thePopup.show(true, (sender: wjcInput.Popup) => {
              // delete the row
              if (sender.dialogResult === 'wj-hide-ok') {
                console.log(view.currentItem);
                // delete the row
                (view as wjcCore.CollectionView).remove(view.currentItem);
              }
          });
      }
    });

    // カラムに応じてクイック編集をかける(セルの選択が変更されたとき)
    flex.selectionChanged.addHandler((s: wjcGrid.FlexGrid, e: wjcGrid.FormatItemEventArgs) => {
      const c = s.columns[e.col];
      if (c.binding !== 'recodeRemove') {
        setTimeout(() => {
          flex.startEditing(false);
        }, 50);
      }
    });

    const topPath = this.siteId !== '' ? this.siteId + '/login' : 'login';
    this.commonService.userLoggedIn(this.siteId).subscribe(
      (userData: User) => {
        console.log(userData);
        this.userData = userData;
        this.authority = this.userData.authority;

        // 納入依頼期限日設定
        this.siteService.getOrderableDates(this.siteId).subscribe(data => {
          this.orderableDateList = data.dates;
          if (data.dates && data.dates.length > 0 && !this.orderNum) {
            if (this.youjuId === undefined) {
              this.requestDate = new Date(data.dates[0]);
            } else {
              this.requestDate = new Date();
            }
          }

          // 開始日/終了日を設定
          this.timeDataStart = this.commonService.customInputTime(this.userData.site.startLiftingHour);
          this.timeDataEnd = this.timeDataStart;
          this.periodStart = this.userData.site.periodStart;
          this.periodEnd = this.userData.site.periodEnd;
          // ユーザー情報を取得した後にカレンダーを初期化
          // this.customizeCalendar(this.calendarItem);
          
          // API呼び出し(揚重申請の情報を取るAPI)
          this.callLiftingAPI();

          // this.situationCheckerResult = this.situationChecker.getCargoHeaderStatus(
          //     this.userData, this.currentCargos, this.createUser, this.orderFlag);

          // 資材Grid用のAPI呼び出し、本来資材Grid初期化部分にあるものだが、
          // ユーザー情報取得のタイミング変更によってこの時間軸で呼ぶ必要がある
          // orderIDがある場合は該当の資材にフィルタをかけて表示
          if (this.orderNum) {
            const colFilter = this.filter.getColumnFilter(flex.columns[2]);
            // let num = this.orderData.map(x => x.number);
            // this.searchKeys[colFilter.column.binding] = num;
            this.searchKeys[colFilter.column.binding] = this.orderNum;
            console.log(this.searchKeys);
            // フィルタ適用時,フィルター色変更のため、Wijmo内フィルタに対してもフィルタをかける
            colFilter.conditionFilter.condition1.value = this.uiFilter.getFilterValSkeleton(colFilter.column);
            colFilter.conditionFilter.condition1.operator = this.uiFilter.getFilterOperatorSkeleton(colFilter.column);

            this.filter.apply();

            // 資材モーダル出すかどうか
            if (this.situationCheckerResult.OpenOrderList) {
              this.callMaterialAPI(this.currentPage);
            }
          }

          this.changeDetectorRef.detectChanges();
        }, error => {
          // this.getErrorDetail(error);
        });
      },
      (error) => {
        // ログイン情報がない場合は、ルートに遷移
        console.log(error);
        this.router.navigate([topPath]);
      }
    );

  }

  // モーダルで出る資材Gridの初期化
  initializeModalGrid(flex: wjcGrid.FlexGrid) {
    this.materialsModalGrid = flex;
    // カスタムフィルタの条件作成
    for (let i = 0; i < this.materialsModalGrid.columns.length; i++) {
      this.materialsModalGrid.columns[i].search_condition = null;
    }
    this.materialsModalFilter.filterColumns = [
      'orderNumber', 'orderPersonName', 'maker', 'partsName',
      'partsNumber', 'orderRequestDateTime', 'requestSection',
    ];
    // テキスト入力
    this.materialsModalGrid.columns[2].search_condition = 1;
    this.materialsModalGrid.columns[3].search_condition = 4;
    this.materialsModalGrid.columns[4].search_condition = 4;
    this.materialsModalGrid.columns[5].search_condition = 4;
    this.materialsModalGrid.columns[6].search_condition = 4;
    this.materialsModalGrid.columns[10].search_condition = 7;
    this.materialsModalGrid.columns[11].search_condition = 0;
    this.materialsModalGrid.columns[12].search_condition = 4;

    // 選択用のチェックボックスを付ける
    flex.formatItem.addHandler((s: wjcGrid.FlexGrid, e: wjcGrid.FormatItemEventArgs) => {
      // チェックボックスを表示
      if (e.panel == s.columnHeaders && s.columns[e.col].binding === 'sel') {
        e.cell.innerHTML = '<input class="select-all" tabindex="-1" type="checkbox">';
        this._updateSelectAllBox(s);
      } else if (e.panel == s.cells && s.rows[e.row].dataItem !== undefined) {
        wjcCore.setAttribute(
          e.cell.parentElement,
          'aria-selected',
          s.rows[e.row].dataItem.sel
        );
      }
    });

    // 全選択用のイベント定義
    flex.hostElement.addEventListener('click', (e: MouseEvent) => {
      if (wjcCore.hasClass(e.target as HTMLInputElement, 'select-all') &&
        e.target instanceof HTMLInputElement) {
        flex.deferUpdate(() => {
          flex.collectionView.items.forEach((item: any) => {
            item.sel = (e.target as HTMLInputElement).checked;
          });
        });
      }
    });

  }

  // 資材Gridを出すモーダルの初期化(条件をベースに立ち上がりにモーダル出すか決める)
  // 納入ありで、ステータスと権限が該当する場合
  initializeMaterialModal(popup: wjcInput.Popup) {
    this.materialPopUp = popup;
  }

  /*
   * ボタン出し分け処理
   */
  get showSave() {
    // 野原顧客は許可しない
    // if (this.authority === this.constant.AUTHORITY_NOHARA_CUSTOMER) {
    //   return false;
    // } else {
    //   // 新規の場合誰でも許可
    //   if (this.youjuId === undefined) {
    //     return true;
    //   } else {
    //     if (
    //       this.cargoStatus !== 3 && this.authority === this.constant.AUTHORITY_ADMIN ||
    //       (this.authority === this.constant.AUTHORITY_SITE_STAFF ||
    //       (this.authority === this.constant.AUTHORITY_LIFTING && this.createUserCheck()) ||
    //       (this.authority === this.constant.AUTHORITY_ARRANGE_STAFF && this.cargoStatus == 2) ||
    //       (this.authority === this.constant.AUTHORITY_NOT_NOHARA_CUSTOMER && this.createUserCheck()))
    //       ) {
    //       return true;
    //     } else {
    //       return false;
    //     }
    //   }
    // }
    // return this.situationChecker.getCargoHeaderStatus(this.userData, this.liftingTemporary, this.createUser, this.orderFlag).Save;
    return this.situationCheckerResult.Save;
  }

  // private editDisabledJudgment() {
  //   // 手配担当もしくは非野原の場合、確定ステータスになるとドライバー情報以外は編集不可
  //   if (this.youjuId !== undefined &&
  //      (this.authority === this.constant.AUTHORITY_ARRANGE_STAFF ||
  //       this.authority === this.constant.AUTHORITY_NOT_NOHARA_CUSTOMER) && this.cargoStatus == 2) {
  //     this.flex.isReadOnly = true;
  //     this.flex.refresh();
  //     return true;
  //   }
  // }

  private toStatusString(status: number) {
    if (status === -1) {
      return '';
    } else {
      return this.statusList[status];
    }
  }

  private toStatunNumber(status: string) {
    if (status === '') {
      return -1;
    } else {
      return this.statusList.indexOf(status);
    }
  }

  private displayError(message: string) {
    this.errorMessage = message;
    this.errorProperty = '';
    this.successProperty = 'none';
    this.changeDetectorRef.detectChanges();
  }

  private displayWarning(message: string) {
    this.warningMessage = message;
    this.warningProperty = '';
    this.successProperty = 'none';
    this.changeDetectorRef.detectChanges();
  }

  /*
  * Function
  */

  /**
   * 最大ページ数
   */
  private getMaxPage(): number {
    let max = Math.floor(this.totalCount / LiftingService.MATERIAL_PAGE);
    if (max === 0) {
      return 1;
    } else {
      if (this.totalCount % LiftingService.MATERIAL_PAGE > 0) {
        ++max;
      }
    }
    return max;
  }

  // 資材モーダルGridに入れるデータを取ってくるAPI
  callMaterialAPI(page: number) {
    if (!this.createLiftingFromOrder) {
      return;
    }

    this.isLoading = true;
    this.liftingService.getAllMaterials(page, this.searchKeys, this.sortCondition).subscribe(
      (response) => {
        this.totalCount = Number(response.headers.get('X-Total-Count'));
        let data = response.body;
        if (data && data.length > 0) {
          data = this.materialService.trimMaterials(data, this.cargos, this.cargoMaterials.items);
        }

        this.isLoading = false;
        if (page >= 2) {
          if (data.length > 0) {
            this.apiMaterialData.next(data);
            this.currentPage = page;
          }
        } else {
          this.apiMaterialData.next(data);
          this.currentPage = page;
        }

        this.materialPopUp.show(true);
      },
      (error) => {
        this.isLoading = false;
        this.displayError(this.commonService.getErrorDetail(error));
      }
    );
  }

  // 揚重詳細を取るAPI呼び出し(揚重の仮データがなくて、かつ既存揚重の編集の場合のみAPI実行)
  private callLiftingAPI() {
    // 資材モーダル表示に不具合が起きるのでstorage経由でステータスを取得
    this.cargoStatus = Number(sessionStorage.getItem('cargoStatus'));

    // 入力可否判定
    // this.editDisabled = this.editDisabledJudgment();
    // this.editDisabled = !this.situationChecker.getCargoHeaderStatus(
    //     this.userData, this.liftingTemporary, this.cargoId, this.orderFlag).editLiftingRequest;

    console.log("仮データがあるか: ", this.cargos);
    // 仮データがあるかどうか
    if (this.cargos) {
      // 作成者は自分
      this.createUser = this.userData.id;
      // cargoIndexに一致する明細のデータをピックアップ
      this.currentCargos = this.cargos.find(material => material.cargoIndex == this.cargoIndex);
      // 対象がなければ初期化
      if (this.currentCargos == undefined) {
        this.currentCargos = {};
      }

      this.situationCheckerResult = this.situationChecker.getCargoHeaderStatus(
        this.userData, this.currentCargos, this.createUser, this.orderFlag);
      console.log("登録前データがある: ", this.situationCheckerResult);
      // alert("② 仮登録データの編集の場合");
      // if (this.liftingData.cargos) {
      //   this.cargos = this.liftingData.cargos.find(material => material.id == this.cargoId);
      // } else {
      //   this.cargos = this.liftingData.find(material => material.cargoIndex == this.cargoIndex);
      // }

      this.displayDataCreate(this.currentCargos);

      if (this.currentCargos && this.currentCargos.confirmDateTime) {
        this.originConfirmDate = this.currentCargos.confirmDateTime;
        this.confirmInfo = {
          date: this.confirmDateConversion(this.currentCargos.confirmDateTime)[0],
          time: this.confirmDateConversion(this.currentCargos.confirmDateTime)[1],
          lift: this.currentCargos.confirmLift,
          number: this.currentCargos.confirmLiftingNumber
        };
      }

      // 揚重ステータスを取得
      this.cargoStatus = this.currentCargos.status;

      // 揚重申請番号表示用
      // this.liftingNumber = this.cargos.liftingNumber;

      // 資材モーダルとの連携用に配列格納
      this.materialsData = this.currentCargos.cargoMaterials;
      // グリッドデータ作成
      this.cargoMaterialsCreate(this.materialsData, false);
      // ステータス反映
      this.statusReflection(this.situationCheckerResult);

      this.changeDetectorRef.detectChanges();
    } else if (this.youjuId) {
      // alert("③ 登録済みデータの編集の場合");
      // API情報取得
      this.isLoading = true;
      this.liftingService.getLifting(this.youjuId).subscribe(
        (liftings: Lifting) => {
          this.isLoading = false;
          this.apiData.next(liftings);
          // 揚重申請番号表示用
          this.liftingNumber = liftings.liftingNumber;
        },
        (error) => {
          this.isLoading = false;
          this.displayError(this.commonService.getErrorDetail(error));
        }
      );
    } else {
      // どちらもなければ完全新規
      // alert("① 完全新規");
      // 作成者は自分
      this.createUser = this.userData.id;
      this.situationCheckerResult = this.situationChecker.getCargoHeaderStatus(
        this.userData, this.currentCargos, this.createUser, this.orderFlag);
      console.log("新規作成: ", this.situationCheckerResult);
      // ステータス反映
      this.statusReflection(this.situationCheckerResult);
    }

    // 初期値で入力された搬入希望日の日付の整合性チェック
    this.getMessage(this.requestDate);
  }

  private statusReflection(status) {
    // グリッドの編集可否
    console.log(status);
    if (this.situationCheckerResult.CreateLiftingFromOrder) {
        this.flex.allowAddNew = false;
    } else if (!this.situationCheckerResult.editLiftingRequest) {
        this.flex.isReadOnly = true;
    }

    // 入力可否判定
    this.editDisabled = !this.situationCheckerResult.editLiftingRequest;
    this.editDriverDisabled = !this.situationCheckerResult.editDriverInfo;
    this.editReauestDateDisabled = !this.situationCheckerResult.canEditReauestDate;
    this.createLiftingFromOrder = this.situationCheckerResult.CreateLiftingFromOrder;
    this.editMaterialNoOrder = this.situationCheckerResult.EditMaterialNoOrder;
    this.viewCargoDetail = this.situationCheckerResult.viewCargoDetail;
    this.allReadOnly = this.situationCheckerResult.allReadOnly;

    if (this.situationCheckerResult.OpenOrderList) {
      //  揚重申請に紐づかないすべての資材情報を取得(orderIDがないけど、納入申請があったら)
      this.callMaterialAPI(this.currentPage);
    }
  }

  // 表示用データの作成
  private displayDataCreate(data: any) {
    this.currentCargos = $.extend(true, {
      otherWeight: 0,
      liftingCount: 0,
      truckCount: 0
    }, data);
    if (!this.currentCargos.otherWeight) {
      this.currentCargos.otherWeight = 0;
    }
    if (!this.currentCargos.liftingCount) {
      this.currentCargos.liftingCount = 0;
    }
    if (!this.currentCargos.truckCount) {
      this.currentCargos.truckCount = 0;
    }
    // 日時計算
    if (this.currentCargos.requestStartDateTime && this.currentCargos.requestEndDateTime) {
      this.dateTimeConversion();
    }

    if (this.currentCargos.useLiftStartTime) {
      // 先頭が0だった場合は1文字目は除外
      if (this.currentCargos.useLiftStartTime.match(/^0/)) {
        this.useLiftStartTime = this.currentCargos.useLiftStartTime.substr(1, 4);
      } else {
        this.useLiftStartTime = this.currentCargos.useLiftStartTime.substr(0, 5);
      }
      if (this.currentCargos.useLiftEndTime.match(/^0/)) {
        this.useLiftEndTime = this.currentCargos.useLiftEndTime.substr(1, 4);
      } else {
        this.useLiftEndTime = this.currentCargos.useLiftEndTime.substr(0, 5);
      }
    }

    // 資材イメージ表示
    if (this.currentCargos.materialImageUrl) {
      for (const url of this.currentCargos.materialImageUrl) {
        const img = this.render.createElement('img');
        // 画像表示でthis.imgUrl.nativeElementがundeninedの対策
        setTimeout(() => {
          this.render.setAttribute(img, 'src', url.url);
          this.render.appendChild(this.imgUrl.nativeElement, img);
          // this.imgUrls.push(data.url);
          this.imgUrls.push(url.url);

          const sData: any = {};
          // sData.id = data.id;
          sData.id = url.id;
          // this.imgUrlIds.push(sData);
          this.imgUrlIds.push(url);
        }, 500);
      }
    }

    console.log(this.currentCargos);
  }

  /**
   * APIを呼んでから資材選択ポップアップ表示
   *
   * @param popup
   */
  private showMaterialPopup(popup) {
    this.callMaterialAPI(this.currentPage);
  }

  // 資材グリッドのデータ作成
  private cargoMaterialsCreate(data: any, modalFlag: boolean) {
    const createData = [];
    if (data === undefined) {
      return;
      // } else if (modalFlag) {
      //     if (this.materialsData) {
      //         data = this.materialsData.concat(data);
      //     }
    }

    data.forEach((val, index) => {
      const newItem = this.cargoMaterials.addNew();
      newItem.id = val.id;
      newItem.orderId = val.orderId;
      newItem.orderNumber = val.orderNumber;
      newItem.orderPersonName = val.orderPersonName,
        newItem.maker = val.maker;
      newItem.partsName = val.partsName;
      newItem.partsNumber = val.partsNumber;
      newItem.quantity = val.quantity;
      newItem.unit = val.unit;
      newItem.weight = val.weight;
      newItem.orderRequestDateTime = new Date(val.orderRequestDateTime);
      newItem.orderRequestSection = val.orderRequestSection;
      newItem.orderStatus = this.toStatusString(val.orderStatus);
      // createData.push({
      //       id: val.id,
      //       orderId: val.orderId,
      //       orderNumber: val.orderNumber,
      //       orderPersonName: val.orderPersonName,
      //       maker: val.maker,
      //       partsName: val.partsName,
      //       partsNumber: val.partsNumber,
      //       quantity: val.quantity,
      //       unit: val.unit,
      //       weight: val.weight,
      //       orderRequestDateTime: new Date(val.orderRequestDateTime),
      //       orderRequestSection: val.orderRequestSection,
      //       orderStatus: this.toStatusString(val.orderStatus),
      //   });
    });
    console.log(this.cargoMaterials.items);
    // CollectionViewをリセット
    // this.cargoMaterials = new wjcCore.CollectionView(createData);
    this.cargoMaterials.refresh();
  }

  // collectionViewの重量合計を計算
  private weightSum(grid: wjcGrid.FlexGrid) {
    // 値リセット
    this.totalWeight = 0;
    const items = grid.collectionView.items;
    for (let i = 0; i < items.length; i++) {
      if (items[i].weight) {
        this.totalWeight += (Number(items[i].quantity) * Number(items[i].weight));
      }
    }
    this.totalWeight += this.currentCargos.otherWeight;
  }

  /*
  * Function: dateTimeConversion
  * 搬入希望日時を現場作業時間に合わせて変換する
  * @param null
  */
  private dateTimeConversion() {
    let startDate = new Date(this.currentCargos.requestStartDateTime);
    let endDate = new Date(this.currentCargos.requestEndDateTime);
    let startTime = startDate.toLocaleTimeString().slice(0, -3);
    let endTime = endDate.toLocaleTimeString().slice(0, -3);

    // 日付が不正な値の場合
    if (startDate.toString() === 'Invalid Date' || endDate.toString() === 'Invalid Date') {
      // 時間をカットして日付を格納
      const date = this.currentCargos.requestStartDateTime.slice(0, -6);
      startDate = new Date(date);
      // 開始時間と終了時間はそのまま表示
      startTime = this.currentCargos.requestStartDateTime.slice(-5);
      endTime = this.currentCargos.requestEndDateTime.slice(-5);
    } else {
      // 現場の作業開始時間よりも前になっていたら、24時以降の時間を設定したと判断
      const settingTime = this.userData.site.startLiftingHour;
      const split = settingTime.split(':');
      const hour = Number(split[0]);
      const minuit = Number(split[1]);

      // 日付を一日前倒し、時間は24hプラスする
      if (startDate.getHours() < hour) {
        startDate.setDate(startDate.getDate() - 1);
        startTime = startDate.getHours() + 24 + ':' + ('0' + startDate.getMinutes()).slice(-2);
      }
      if (endDate.getHours() < hour) {
        endTime = endDate.getHours() + 24 + ':' + ('0' + endDate.getMinutes()).slice(-2);
      }
      // 日付が異なっていたら、24時以降の時間を設定したと判断
      // if (startDate.getMonth() + 1 === endDate.getMonth() + 1) {
      //     // 月が同じで日にちが異なる場合
      //     if (startDate.getDate() < endDate.getDate()) {
      //         // 終了時間に24hプラスして表示
      //         endTime = endDate.getHours() + 24 + ':' + ('0' + endDate.getMinutes()).slice(-2);
      //     } else {
      //         // そうでなければそのまま表示
      //         endTime = endDate.getHours() + ':' + ('0' + endDate.getMinutes()).slice(-2);
      //     }
      // } else {
      //     // 月をまたいだ場合
      //     if (startDate.getDate() > endDate.getDate()) {
      //         // 終了時間に24hプラスして表示
      //         endTime = endDate.getHours() + 24 + ':' + ('0' + endDate.getMinutes()).slice(-2);
      //     }
      // }
    }

    this.requestDate = startDate;
    this.requestStartTime = startTime;
    this.requestEndTime = endTime;
  }

  /*
  * Function: confirmDateConversion
  * 確定日時を現場作業時間に合わせて変換する
  * @param {String} date 確定日時
  * @return {any} [day 確定日, setTime 確定時間]
  */
  private confirmDateConversion(date: string) {
    const day = new Date(date);

    const startTime = this.userData.site.startLiftingHour;
    const split = startTime.split(':');
    const hour = Number(split[0]);
    const minuit = Number(split[1]);
    let setTime;

    if (day.getHours() < hour) {
      // 日付を一日前にして時間を24hプラスする
      day.setDate(day.getDate() - 1);
      setTime = day.getHours() + 24 + ':' + ('0' + day.getMinutes()).slice(-2);
    } else {
      // 24時間以内の指定であれば、そのまま表示
      setTime = day.getHours() + ':' + ('0' + day.getMinutes()).slice(-2);
    }

    const dayStr = day.getFullYear() + '/'
      + ('0' + (day.getMonth() + 1)).slice(-2) + '/'
      + ('0' + day.getDate()).slice(-2);
    // 日付と時間を返す
    return [dayStr, setTime];
  }

  // input要素のtype=numberでmaxLengthを適用させる
  private numberMaxLength(event: any) {
    const value = event.target.value;
    if (value > 999999) {
      event.target.value = value.slice(0, 6);
    }
  }

  // モーダルで選択した搬入資材の情報を取得する(資材モーダルで追加を押したとき)
  private materialsAdd(grid: wjcGrid.FlexGrid) {
    const gridData = grid.itemsSource.sourceCollection;
    const selectedArray = [];
    gridData.filter((val, index, arr) => {
      if (val.sel) {
        selectedArray.push(val);
      }
    });
    this.cargoMaterialsCreate(selectedArray, true);
  }

  // update the select all checkbox state
  private _updateSelectAllBox(grid: wjcGrid.FlexGrid) {
    const cb = grid.hostElement.querySelector('.select-all');
    if (cb instanceof HTMLInputElement) {
      const items = grid.collectionView.items;
      let last = null;
      let indeterminate = false;

      for (let i = 0; i < items.length; i++) {
        if (last != null && items[i].sel != last) {
          indeterminate = true;
          break;
        }
        last = items[i].sel;
      }
      cb.checked = last;
      if (indeterminate) {
        cb.indeterminate = true;
      }
    }
  }

  // 揚重申請ページへデータを送る(登録ボタンを押されたとき)
  private submitData() {
    /*
     * 資材情報のチェック
     */
    const _materials = this.cargoMaterials.items;
    let flag = true;
    console.log(_materials);
    if (_materials.length > 0) {
      _materials.filter((s) => {
        // どれか一つでも入力があれば
        if (s.maker || s.partsName || s.partsNumber || s.quantity || s.unit || s.weight) {
          // 以下すべての項目の入力が必要
          if (s.maker && s.partsName && s.partsNumber && s.quantity) {
            this.warningProperty = 'none';
            flag = true;
          } else {
            // 入力に漏れがあればエラーメッセージを表示
            this.displayWarning('搬入資材情報に未入力項目があります。');
            window.scrollTo(0, 0);
            // アニメーションつき
            // $('html,body').animate({
            //   scrollTop: 0
            // }, 'fast');
            flag = false;
          }
        } else {
          // 一つも入力がなければ空行と判定する
          this.warningProperty = 'none';
          flag = true;
        }
      });
    }
    /*****/

    if (flag) {
      const createData: any = this.submitDataCreate();
      console.log(createData);
      console.log(this.cargos);
      // this.liftingService.setCreateData(createData);

      // 完全新規ならそのままpush
      if (this.cargos == null) {
        this.cargos = [];
        this.cargos.push(createData);
      } else {
        // cargoIndexがあれば、対象を上書き
        if (this.cargoIndex) {
          this.cargos[this.cargoIndex - 1] = createData;
        } else {
          // cargoIndexがなければ末尾に追加
          this.cargos.push(createData);
        }
      }
      console.log(this.cargos);
      // 登録済みデータがある場合
      // if (this.liftingData) {
      //   // liftingDataの一致する配列を探す
      //   let marged = this.liftingData.filter((val, index, arr) => {
      //     // 配列の情報を上書きして、丸ごとStorageに入れる
      //     if (val.id == this.cargoId) val = createData;
      //     return arr;
      //   })
      //   sessionStorage.setItem('liftingData', JSON.stringify(marged));
      // }
      // パラメーターにcargoIndexがある場合
      // if (this.cargoIndex) {
      //     // cargoIndexがあれば更新
      //     this.liftingTemporary[this.cargoIndex - 1] = createData;
      //     sessionStorage.setItem('liftingData', JSON.stringify(this.liftingTemporary));
      // } else {
      //     if (this.liftingTemporary && this.liftingTemporary.length > 0) {
      //         const marged = this.liftingTemporary.concat(createData);
      //         sessionStorage.setItem('liftingData', JSON.stringify(marged));
      //     } else {
      //         sessionStorage.setItem('liftingData', JSON.stringify([createData]));
      //     }
      // }
      sessionStorage.setItem('cargosData', JSON.stringify(this.cargos));
      sessionStorage.removeItem('cargoStatus');
      // 保存したら前のページに戻る
      this.location.back();
    }

  }

  // 前のページに戻る(ただ戻っているのではなく未登録データを保持して戻る)
  private pageBack() {
    // createUserが自分だったら、cargosデータを保持する
    if (!this.allReadOnly) {
      sessionStorage.setItem('cargosData', JSON.stringify(this.cargos));
    }
    sessionStorage.removeItem('cargoStatus');
    sessionStorage.removeItem('isUnapplied');
    this.location.back();
  }

  // データ整形関数(登録を押されたときに使う)
  private submitDataCreate() {
    let data: any = {};
    let _cargoIndex = 1;

    // cargoIndexがあれば更新
    if (this.cargoIndex) {
      _cargoIndex = this.cargoIndex;
    } else {
      // cargoIndexがない場合は連番で登録
      if (this.cargos) {
        _cargoIndex = this.cargos.length + 1;
      }
    }

    const materialData = new Array();
    for (let i = 0; i < this.cargoMaterials.items.length; i++) {
      const _data = this.cargoMaterials.items[i];
      // データがあれば処理する
      if (_data.maker !== undefined) {
        // ステータスをindexに変換
        _data.orderStatus = this.toStatunNumber(_data.orderStatus);
        _data.cargoIndex = _cargoIndex;

        if (_data.orderRequestDateTime !== undefined) {
          _data.orderRequestDateTime =
            new Date(_data.orderRequestDateTime).toLocaleDateString() +
            ' ' + new Date(_data.orderRequestDateTime).toLocaleTimeString();
        }

        // 重量が未入力なら0をセット
        if (_data.weight) {
          _data.weight = Number(_data.weight);
        } else {
          _data.weight = 0;
        }

        // TODO 必須エラー回避のコード
        if (!_data.orderPersonName) {
          _data.orderPersonName = 'p';
        }

        // TODO 必須エラー回避のコード
        if (!_data.orderRequestSection) {
          if (this.currentCargos.requestSection) {
            _data.orderRequestSection = this.currentCargos.requestSection;
          } else {
            _data.orderRequestSection = 's';
          }
        }

        materialData.push(_data);
      }
    }

    let requestSectionStr: string;
    const requestSectionSum: any = [];
    if (!this.orderFlag) {
      requestSectionStr = this.currentCargos.requestSection;
    } else {
      const cargosRequestSection = this.cargoMaterials.items;
      cargosRequestSection.forEach(val => {
        // すでに配列内に同じ値がなければ追加する
        if (requestSectionSum.indexOf(val.orderRequestSection) == -1) {
          requestSectionSum.push(val.orderRequestSection);
        }
      });
      requestSectionStr = requestSectionSum.join(',');
    }

    /*** ステータスチェック ***/
    // 新規かつ未申請のみのものが既にある
    if (this.isUnapplied == '0') {
      this.currentCargos.status = 0;
    } else if (this.isUnapplied == '1') {
      this.currentCargos.status = 1;
    } else {
      this.currentCargos.status = this.cargoStatus;
    }
    // this.cargoStatus ? this.cargos.status = this.cargoStatus : this.cargos.status = 0;

    data = {
      id: this.currentCargos.id,
      cargoIndex: _cargoIndex,
      otherWeight: this.currentCargos.otherWeight,
      liftingCount: this.currentCargos.liftingCount,
      truckCount: this.currentCargos.truckCount,
      loadingFloor: this.currentCargos.loadingFloor,
      unloadingFloor: this.currentCargos.unloadingFloor,
      requestStartDateTime: new Date(this.requestDate).toLocaleDateString() + ' ' + this.customRequestStartTime.selectedItem,
      requestEndDateTime: new Date(this.requestDate).toLocaleDateString() + ' ' + this.customRequestEndTime.selectedItem,
      confirmDateTime: this.originConfirmDate,
      confirmLift: this.confirmInfo.lift,
      confirmLiftingNumber: this.confirmInfo.number,
      requestSection: requestSectionStr,
      requestGate: this.currentCargos.requestGate,
      useLiftStartTime: this.customUseLiftStartTime.selectedItem,
      useLiftEndTime: this.customUseLiftEndTime.selectedItem,
      workType: this.comboWorkType.selectedIndex,
      driverInfo: this.currentCargos.driverInfo,
      materialName: this.currentCargos.materialName,
      mixed: this.currentCargos.mixed,
      packingMaterial: this.currentCargos.packingMaterial,
      trolley: this.currentCargos.trolley,
      palette: this.currentCargos.palette,
      batakaku: this.currentCargos.batakaku,
      truckSize: this.currentCargos.truckSize,
      truckType: this.currentCargos.truckType,
      unic: this.currentCargos.unic,
      forkLift: this.currentCargos.forkLift,
      remark: this.currentCargos.remark,
      cargoWidth: this.currentCargos.cargoWidth,
      cargoDepth: this.currentCargos.cargoDepth,
      cargoHeight: this.currentCargos.cargoHeight,
      status: this.currentCargos.status,
      useForkLift: this.currentCargos.useForkLift,
      necessaryTrolley: this.currentCargos.necessaryTrolley,
      materialImageUrl: this.imgUrlIds,
      cargoMaterials: materialData,
      wasClosed: this.currentCargos.wasClosed
    };
    return data;
  }

  // 画像アップロードと表示(ForでAPI呼んでるから順番の保証はない)
  uploadImg(file: FileList) {
    if (file.length > 0) {
      this.isLoading = true;
    }
    for (let i = 0; i < file.length; i++) {
      this.liftingService.uploadLiftingImg(file[i]).subscribe(
        (data) => {
          if (i === file.length - 1) {
            this.isLoading = false;
          }
          console.log(data);
          const img = this.render.createElement('img');
          this.render.setAttribute(img, 'src', data.url);
          this.render.appendChild(this.imgUrl.nativeElement, img);
          this.imgUrls.push(data.url);

          const sData: any = {};
          sData.id = data.id;
          // this.imgUrlIds.push(sData);
          this.imgUrlIds.push(data);
        },
        (error) => {
          this.isLoading = false;
          this.displayError(this.commonService.getErrorDetail(error));
        }
      );
    }
    console.log(this.imgUrls);
  }

  /*
  * カレンダーカスタマイズ
  */

  // カレンダースタイル設定
  customizeCalendar(calendar: wjcInput.Calendar) {
    console.log(this.situationCheckerResult);
    calendar.formatItem.addHandler((sender: wjcInput.Calendar, e: wjcInput.FormatItemEventArgs) => {
      const weekday = e.data.getDay();
      const today = e.data;
      let isEnableDate: boolean;
      // 非野原＋新規の場合
      // 【TODO】 日付チェックの仕様を検討中なので後ほど対応
      if (this.situationCheckerResult.ReauestDateValidate) {
        // 締切日を考慮した選択可否をカレンダーに反映
        const year = today.getFullYear();
        const month = ('0' + (today.getMonth() + 1)).slice(-2);
        const day = ('0' + today.getDate()).slice(-2);
        const target = year + '-' + month + '-' + day;
        isEnableDate = this.isOrderableDate(target);
      } else {
        // それ以外は、工期・過去・休日・禁止日をカレンダーに反映
        const periodStart = this.userData.site.periodStart;
        const periodEnd = this.userData.site.periodEnd;
        const noLiftingDays = this.userData.site.noLiftingDays;
        isEnableDate = this.commonService.isEnableDate(today, periodStart, periodEnd, noLiftingDays);
      }

      wjcCore.toggleClass(e.item, 'date-invalid', !isEnableDate);
      wjcCore.toggleClass(e.item, 'date-sunday', weekday == 0);
      wjcCore.toggleClass(e.item, 'date-saturday', weekday == 6);
    });
  }

  // 禁止日選択時のメッセージ
  getMessage(date: Date) {
    // let commonService = new CommonService();
    // let today = commonService.getDateTime();
    // // 開始日を今日に設定
    // let periodStart = today.getFullYear() + '/' + (today.getMonth()+1) + '/' + today.getDate();
    // //let periodStart = user.site.periodStart;
    // let periodEnd = user.site.periodEnd;
    // let noLiftingDays = user.site.noLiftingDays;
    // let options = {
    //   dueDate: user.site.orderDueDate,
    //   dueDateType: user.site.orderDueDateType,
    //   dueWeek: user.site.orderDueWeek,
    //   dueDayOfWeek: user.site.orderDueDayOfWeek
    // }
    // let isEnableDate = commonService.isEnableDate(this.requestDate, periodStart, periodEnd, noLiftingDays, options);
    // console.log(isEnableDate);
    const selectDate = new Date(date);
    const year = selectDate.getFullYear();
    const month = ('0' + (selectDate.getMonth() + 1)).slice(-2);
    const day = ('0' + selectDate.getDate()).slice(-2);
    const target = year + '-' + month + '-' + day;

    let isEnableDate: boolean = this.isOrderableDate(target);

    // ステータスが確定済み以降であるもの
    // 非野原だけど既存のもの
    // 非野原ではない場合はチェックしない
    // 【TODO】 日付チェックの仕様を検討中のため後ほど対応
    // if (this.cargoStatus === 2 || this.cargoStatus === 3) {
    //     isEnableDate = true;
    // } else {
    //     if ((this.authority === this.constant.AUTHORITY_NOT_NOHARA_CUSTOMER && this.cargoIndex) ||
    //         (this.authority !== this.constant.AUTHORITY_NOT_NOHARA_CUSTOMER)) {
    //         isEnableDate = true;
    //     }
    // }

    // ReauestDateValidate=TRUEなら日付チェックを行う
    if (!isEnableDate && this.situationCheckerResult.ReauestDateValidate) {
      this.requestDateProperty = '';
      this.validateCheck = false;
    } else {
      this.requestDateProperty = 'none';
      this.validateCheck = true;
    }
  }

  // 開始時間と終了時間の逆行判定
  checkReverseTime(start, end, type = '') {
    // console.log(start, end);
    if (start && end && start.text && end.text) {
      const startNum = parseInt(start.text.split(':').join(''), 10);
      const endNum = parseInt(end.text.split(':').join(''), 10);
      const isReverse = startNum > endNum;
      if (type === 'request') {
        this.isRequestReverseTime = isReverse;
      } else if (type === 'use') {
        this.isUseReverseTime = isReverse;
      }
    }
  }

  /*****/

  // オートコンプリートのリスト追加：単位
  addNewMaterialsUnit(item) {
    if (!item) {
      return;
    }
    const res = this.commonService.getRowAutoComplite(this.materialsUnitList, item);
    console.log(res);
    if (res.data) {
      // リストに追加
      if (this.materialsUnitList != null) {
        this.materialsUnitList.push(res.data);
      } else {
        this.materialsUnitList = [res.data];
      }
    } else {
      // ソート順更新用
      this.materialsUnitList[res.update.index].added = res.update.added;
    }
    // 上限を超えたらaddedが低いものを削除
    if (this.materialsUnitList.length > this.commonService.limit) {
      console.log(this.materialsUnitList[res.min.index]);
      this.materialsUnitList.splice(res.min.index, 1);
    }
    // ソート
    this.materialsUnitList.sort(function (val1, val2) {
      return (val1.added < val2.added ? 1 : -1);
    });
    console.log(this.materialsUnitList);
    localStorage.setItem('materialsUnitList', JSON.stringify(this.materialsUnitList));
  }

  // オートコンプリートのリスト追加：メーカー
  addNewMaterialsMaker(item) {
    if (!item) {
      return;
    }
    const res = this.commonService.getRowAutoComplite(this.makerUnitList, item);
    console.log(res);
    if (res.data) {
      // リストに追加
      if (this.makerUnitList != null) {
        this.makerUnitList.push(res.data);
      } else {
        this.makerUnitList = [res.data];
      }
    } else {
      // ソート順更新用
      this.makerUnitList[res.update.index].added = res.update.added;
    }
    // 上限を超えたらaddedが低いものを削除
    if (this.makerUnitList.length > this.commonService.limit) {
      console.log(this.makerUnitList[res.min.index]);
      this.makerUnitList.splice(res.min.index, 1);
    }
    // ソート
    this.makerUnitList.sort(function (val1, val2) {
      return (val1.added < val2.added ? 1 : -1);
    });
    console.log(this.makerUnitList);
    localStorage.setItem('makerUnitList', JSON.stringify(this.makerUnitList));
  }

  // オートコンプリートのリスト追加：品名
  addNewMaterialsPartsName(item) {
    if (!item) {
      return;
    }
    const res = this.commonService.getRowAutoComplite(this.partsNameUnitList, item);
    console.log(res);
    if (res.data) {
      // リストに追加
      if (this.partsNameUnitList != null) {
        this.partsNameUnitList.push(res.data);
      } else {
        this.partsNameUnitList = [res.data];
      }
    } else {
      // ソート順更新用
      this.partsNameUnitList[res.update.index].added = res.update.added;
    }
    // 上限を超えたらaddedが低いものを削除
    if (this.partsNameUnitList.length > this.commonService.limit) {
      console.log(this.partsNameUnitList[res.min.index]);
      this.partsNameUnitList.splice(res.min.index, 1);
    }
    // ソート
    this.partsNameUnitList.sort(function (val1, val2) {
      return (val1.added < val2.added ? 1 : -1);
    });
    console.log(this.partsNameUnitList);
    localStorage.setItem('partsNameUnitList', JSON.stringify(this.partsNameUnitList));
  }

  // オートコンプリートのリスト追加：品番・規格
  addNewMaterialsPartsNumber(item) {
    if (!item) {
      return;
    }
    const res = this.commonService.getRowAutoComplite(this.partsNumberUnitList, item);
    console.log(res);
    if (res.data) {
      // リストに追加
      if (this.partsNumberUnitList != null) {
        this.partsNumberUnitList.push(res.data);
      } else {
        this.partsNumberUnitList = [res.data];
      }
    } else {
      // ソート順更新用
      this.partsNumberUnitList[res.update.index].added = res.update.added;
    }
    // 上限を超えたらaddedが低いものを削除
    if (this.partsNumberUnitList.length > this.commonService.limit) {
      console.log(this.partsNumberUnitList[res.min.index]);
      this.partsNumberUnitList.splice(res.min.index, 1);
    }
    // ソート
    this.partsNumberUnitList.sort(function (val1, val2) {
      return (val1.added < val2.added ? 1 : -1);
    });
    console.log(this.partsNumberUnitList);
    localStorage.setItem('partsNumberUnitList', JSON.stringify(this.partsNumberUnitList));
  }

  /**
   * フィルタアイコンを押されたとき、そのカラムのデータ型に応じた
   * フィルタUIに既存テンプレートを差し替える関数
   * @param filter フィルタオブジェクト
   * @param event イベント引数
   */
  editTemplateFilter(filter, event) {
    console.log('changed');
    this.uiFilter.setupSearchFilter(filter, event, this.searchKeys);
  }

  /**
   * フィルタが適用/キャンセル/クリアされたときの挙動を示す関数
   * クリアとキャンセルを分けて処理できるよう判断する処理必要
   * こちらからソートするとソート時もこちらのイベントのみ発火する
   * @param filter フィルタオブジェクト
   * @param event イベント引数
   */
  applyCustomFilter(filter = null, event = null) {
    console.log(filter, event, 'FilterChanged');
    const colFilter = filter.getColumnFilter(event.col);

    // キャンセル時はソート条件が変わっているかを確認し、変わっていればデータを再取得する
    // const isClear = colFilter.column.binding in this.searchKeys && !colFilter.conditionFilter.condition1.value;
    const sortDesc = filter.grid.collectionView.sortDescriptions[0];
    const isAsc = sortDesc.ascending ? 'asc' : 'desc';
    const isSort = (sortDesc.property !== this.sortCondition.sortKey) || (isAsc !== this.sortCondition.sortOrder);

    // 適用ボタンかどうか
    if (!event.cancel) {
      const searchInputed = this.uiFilter.getInputedFilterValue(colFilter.column);
      // 入力値があるか
      if (searchInputed != '' && searchInputed != null) {
        this.searchKeys[colFilter.column.binding] = searchInputed;
        // フィルタ適用時,フィルター色変更のため、Wijmo内フィルタに対してもフィルタをかける
        colFilter.conditionFilter.condition1.value = this.uiFilter.getFilterValSkeleton(colFilter.column);
        colFilter.conditionFilter.condition1.operator = this.uiFilter.getFilterOperatorSkeleton(colFilter.column);
        filter.apply();
      } else {
        // 適用が押されたけど入力値がない
        delete this.searchKeys[colFilter.column.binding];
        colFilter.clear();
      }
    } else {
      // ソートするか
      if (isSort) {
        this.sortCondition.sortKey = sortDesc.property;
        this.sortCondition.sortOrder = sortDesc.ascending ? 'asc' : 'desc';
      } else {
        // クリア時は該当カラムの検索条件を削除してデータ取得自体は実行
        delete this.searchKeys[colFilter.column.binding];
        colFilter.clear();
      }
    }

    console.log('SearchKey', this.searchKeys);
    // フィルタかけた後は1ページ目から
    this.currentPage = 1;
    this.callMaterialAPI(this.currentPage);
  }

  /*
   * ページング処理
   */
  nextPage() {
    if (this.getMaxPage() >= this.currentPage + 1) {
      ++this.currentPage;
      this.callMaterialAPI(this.currentPage);
    }
  }

  prevPage() {
    if (this.currentPage > 1) {
      --this.currentPage;
      this.callMaterialAPI(this.currentPage);
    } else {
      return false;
    }
  }
}
