import {Component, OnInit, ViewChild,ChangeDetectorRef} from '@angular/core';
import {Router} from '@angular/router';
import { FlexGrid } from '@grapecity/wijmo.grid';
import * as wjcCore from '@grapecity/wijmo';
import * as wjcGrid from '@grapecity/wijmo.grid';
import * as wjcInput from '@grapecity/wijmo.input';
import * as wjGridXlsx from '@grapecity/wijmo.grid.xlsx';
import {Subject} from 'rxjs';
import {CommonService} from '../../core/common.service';
import {CargoService} from '../../core/service/cargo.service';
import {CustomGridEditor} from '../../core/custom-grid-editor';
import {Cargo} from '../cargo';
import {CommonFilterUiService} from '../../core/common-filter-ui.service';
import {GeneralSituationCheckerService} from '../../core/general-situation-checker.service';
import {DatePipe} from '@angular/common';
import {Constant} from 'src/app/core/constant';
import { User } from '../../master/user/user';

@Component({
    selector: 'app-lifting-list',
    templateUrl: './lifting-list.component.html',
    styleUrls: ['./lifting-list.component.scss']
})
export class LiftingListComponent implements OnInit {
    userData: any;
    authority: string;
    apiData: Subject<any> = new Subject();
    gridDataYouju: any = [];
    cargoStatus = Constant.CARGO_STATUS_STR_LIST;
    // ページング用変数
    currentPage = 1;
    pageCount: number;
    totalCount = 0;
    siteId: string;

    grid;
    youjuListFilter;
    searchKeys = {};
    // sortCondition = {sortKey: 'confirmDate', sortOrder: 'asc'};
    sortCondition = {sortKey: '', sortOrder: ''};
    isLoading = false;
    isChecked = false;

    // 定数取得
    constant = Constant;

    isLastPage = false;

    // エラー表示
    public errorMessage: string;
    public errorProperty = 'none';
    public successProperty = 'none';

    situationCheckerResult: any;

    @ViewChild('gridYouju', {static: true}) gridYouju: FlexGrid;

    // コンストラクタでルーターを定義しておく
    constructor(
        private router: Router,
        private commonService: CommonService,
        private cargoService: CargoService,
        public changeDetectorRef: ChangeDetectorRef,
        public situationChecker: GeneralSituationCheckerService,
        private uiFilter: CommonFilterUiService,
        private datepipe: DatePipe) {
        this.situationCheckerResult = {};

        // APIのデータをGridに入れるところ
        this.apiData.subscribe(apidata => {
            this.gridDataYouju = apidata;
            this.gridDataYouju.map((data) => {
                // ステータスを文字列で表示
                data.status = this.toStatusString(data.applicationStatus, data.wasClosed);
                // data.status = this.cargoStatus[data.applicationStatus];

                if (data.confirmDateTime) {
                    // 日時変換
                    const convertData: any = this.dateTimeConversion(data.confirmDateTime);
                    data.confirmDate = convertData[0];
                    data.confirmTime = convertData[1];
                } else {
                    data.confirmDate = null;
                    data.confirmTime = null;
                }
                if (data.requestStartDateTime) {
                    data.requestStartDateTime = new Date(data.requestStartDateTime);
                    // bindingかぶってるとフィルタに差し支えるため変更
                    data.requestStartDateTimeAls = new Date(data.requestStartDateTime);
                }
                data.sel = false;

                data.isConfirmed = data.isConfirmed ? '登録済み' : '未登録' ;

                //エクセル出力用にデータを用意
                let i = 0;
                data.orderNumber = '';
                data.orderRequest.forEach(item => {
                    const orderNumber = item.orderNumber;
                    const id = item.id;
                    if (orderNumber !== '') {
                        data.orderNumber += orderNumber;
                        if(data.orderRequest.length - 1 !== i){
                            data.orderNumber += ","
                        }
                    }
                    ++i;
                });
            });

            // グループ化設定
            this.gridDataYouju = new wjcCore.CollectionView(this.gridDataYouju);
            this.gridDataYouju.sortDescriptions.clear();
            // 確定済みを常に第1ソートとして指定
            this.gridDataYouju.sortDescriptions.push(
                new wjcCore.SortDescription('isConfirmed', false));
            this.gridDataYouju.sortDescriptions.push(
                new wjcCore.SortDescription(this.sortCondition.sortKey, this.sortCondition.sortOrder === 'asc'));

            // ステータスでソートされた場合のみ、日付グループ化を切る
            this.gridDataYouju.groupDescriptions.clear();
            if (this.sortCondition.sortKey !== 'status') {
              // 確定済みを第1グループ化として指定
              this.gridDataYouju.groupDescriptions.push(
                    new wjcCore.PropertyGroupDescription('isConfirmed'));
              this.gridDataYouju.groupDescriptions.push(
                    new wjcCore.PropertyGroupDescription('confirmDate'));
            }

            this.isLastPage = this.commonService.isPagingLimit(this.gridDataYouju.sourceCollection);

            if (0 !== Object.keys(this.searchKeys).length) {
                for (const key of Object.keys(this.searchKeys)) {
                    const col = this.uiFilter.getColumnBindingIndex(this.grid.columns, key);
                    const colFilter = this.youjuListFilter.getColumnFilter(col);
                    colFilter.conditionFilter.condition1.value = this.uiFilter.getFilterValSkeleton(colFilter.column);
                    colFilter.conditionFilter.condition1.operator = this.uiFilter.getFilterOperatorSkeleton(colFilter.column);
                }
                this.youjuListFilter.apply();
            }

            this.changeDetectorRef.detectChanges();

        });
    }

    // ページに来た時に走る
    ngOnInit() {
    }

    /**
     * 揚重一覧Grid初期化
     * @param flexgrid Grid
     */
    flexInitialized(flexgrid: wjcGrid.FlexGrid) {
        // カスタムフィルタの条件作成
        this.grid = flexgrid;
        for (let i = 0; i < this.grid.columns.length; i++) {
            this.grid.columns[i].search_condition = null;
        }
        this.youjuListFilter.filterColumns = [
            'confirmDate',
            'confirmLift', 'requestSection',
            'confirmLiftingNumber',
            'requestStartDateTime',
            'liftingNumber',
            'status',
            // 'confirmTime',
            // 'companyName',
            // 'requestStartDateTimeAls',
        ];
        // this.uiFilter.getColumnBindingIndex(this.grid.columns,'confirmDate');
        this.grid.columns[1].search_condition = 7;
        this.grid.columns[1].isDateCol = true;
        this.grid.columns[2].search_condition = 8;

        this.grid.columns[11].search_condition = 4;
        // this.grid.columns[9]['search_condition'] = 7;
        // this.grid.columns[9]['isDateCol'] = true;
        // this.grid.columns[10]['search_condition'] = 8;
        // this.grid.columns[12]['search_condition'] = 4;
        // this.grid.columns[18]['search_condition'] = 4;
        this.grid.columns[12].search_condition = 0;
        this.grid.columns[9].search_condition = 0;
        this.grid.columns[10].search_condition = 0;
        this.grid.columns[4].search_condition = 0;

        /*
         * カレンダーカスタマイズ
         */
        const multiColumnEditor = new CustomGridEditor(flexgrid, 'confirmDate', wjcInput.InputDate, {
            format: 'd',
            isRequired: false
        }, null);

        const calendar = multiColumnEditor.control as wjcInput.InputDate;
        calendar.itemFormatter = (date, elem) => {
            const day = date.getDay();
            wjcCore.toggleClass(elem, 'date-sunday', day == 0);
            wjcCore.toggleClass(elem, 'date-saturday', day == 6);
        };

        flexgrid.formatItem.addHandler((s: wjcGrid.FlexGrid, e: wjcGrid.FormatItemEventArgs) => {
            if (!s.columns[e.col]) {
                return;
            }

            // 揚重申請Noをテキストリンクにする
            if (e.panel !== s.columnHeaders && s.columns[e.col].binding === 'liftingNumber') {
                if (s.rows[e.row].dataItem) {
                    const liftingNumber = s.rows[e.row].dataItem.liftingNumber;
                    const id = s.rows[e.row].dataItem.liftingId;

                    if (liftingNumber !== '') {
                        e.cell.innerHTML = '<a href="/' + this.userData.site.id + '/lifting/' + id + '">' + liftingNumber + '</a>';
                    }
                }

            }

            this.commonService.addGridEditMark(s, e, 'liftingId');

            // 行選択のクラス設定
            if (e.panel == s.cells && s.rows[e.row].dataItem !== undefined) {
                wjcCore.setAttribute(
                    e.cell.parentElement,
                    'aria-selected',
                    s.rows[e.row].dataItem.sel
                );
            }

            // 確定した情報の背景を変更する
            if (e.panel !== s.columnHeaders
                && (s.columns[e.col].binding === 'confirmDate'
                    || s.columns[e.col].binding === 'confirmTime'
                    || s.columns[e.col].binding === 'confirmLift'
                    || s.columns[e.col].binding === 'confirmNumber')
            ) {
                const d = flexgrid.getCellData(e.row, e.col, false);
                if (d && d !== '') {
                    e.cell.style.backgroundColor = 'lightpink';
                    // e.panel.rows[e.row].isReadOnly = true;
                    return;
                }
            }
            e.cell.style.backgroundColor = null;
        });

        // カラムに応じてクイック編集をかける
        flexgrid.selectionChanged.addHandler((s, e) => {
            if (s.columns[e['col']].binding === 'note') {
                setTimeout(() => {
                    flexgrid.startEditing(false);
                }, 50);
            }
        });

        /*
        * カラムヘッダーにリンクを設定
        */
        flexgrid.addEventListener(flexgrid.hostElement, 'mouseup', (e: MouseEvent) => {
            if (flexgrid.rows.length === 0) {
                return;
            }

            const ht = flexgrid.hitTest(e);
            const id = flexgrid.rows[ht.row].dataItem.liftingId;
            if (ht.panel === flexgrid.topLeftCells || id === undefined) {
                e.preventDefault();
            } else {
                if (ht.row >= 0) {
                    // 行選択したときのみリンクする
                    if (ht.panel === flexgrid.rowHeaders) {
                        this.router.navigate([`${this.userData.site.id}/lifting/${id}`]);
                    }
                }
            }

            // セルのデータを参照
            // console.log(flexgrid.cells.getCellData(ht.row, ht.col, true));

        });

        // ユーザー情報取得後、揚重一覧取得
        this.siteId = this.commonService.getRoutedSiteFromPath() !== '' ? this.commonService.getRoutedSiteFromPath() : '';
        const topPath = this.siteId !== '' ? this.siteId + '/login' : 'login';
        this.commonService.userLoggedIn(this.siteId).subscribe(
            (userData: User) => {
                console.log(userData);
                this.userData = userData;
                this.authority = this.userData.authority;

                this.situationCheckerResult = this.situationChecker.getLiftingListStatus(this.userData);

                const ele = this.userData.site.elevator;
                const elearr = [];
                for (let i = 0; i < ele.length; i++) {
                    const obj = {key: '', value: ''};
                    obj.key = ele[i];
                    obj.value = ele[i];
                    elearr.push(obj);
                }
                this.grid.columns[3].masterList = this.uiFilter.createOptionsList(elearr, 'key', 'value', []);

                calendar.itemValidator = (date: Date) => {
                    const user = this.userData;
                    const today = this.commonService.getDateTime();
                    // 禁止日の設定の基準は今日のため、開始日を今日に設定
                    const periodStart = today.getFullYear() + '/' + (today.getMonth() + 1) + '/' + today.getDate();
                    const periodEnd = user.site.periodEnd;
                    const noLiftingDays = user.site.noLiftingDays;
                    // 締切日等の制限は特になし
                    // 工期中かどうか＆禁止日かどうかの判定
                    let isEnableDate = this.commonService.isEnableDate(date, periodStart, periodEnd, noLiftingDays);

                    // 加えて、明日以降であれば選択可能
                    if (date > today) {
                        isEnableDate = isEnableDate && true;
                    } else {
                        isEnableDate = isEnableDate && false;
                    }

                    return isEnableDate;
                };

                // tslint:disable:no-unused-expression
                new CustomGridEditor(flexgrid, 'confirmTime', wjcInput.ComboBox, {
                    itemsSource: this.commonService.customInputTime(this.userData.site.startLiftingHour),
                    isRequired: false
                  }, null);

                new CustomGridEditor(flexgrid, 'confirmLift', wjcInput.ComboBox, {
                    itemsSource: ele,
                    isRequired: false
                }, null);

                // 揚重申請一覧取得
                this.listDisplayReload();

                // grid readOnlyの設定
                this.grid.isReadOnly = !this.situationCheckerResult.UpdateStatus;

                this.changeDetectorRef.detectChanges();
            },
            (error) => {
                // ログイン情報がない場合は、ルートに遷移
                console.log(error);
                this.router.navigate([topPath]);
            }
        );
    }

    /*
  * 揚重申請一覧表示関数
  */
    listDisplayReload() {
        this.isLoading = true;
        this.cargoService.getAllLiftings(this.currentPage, this.searchKeys, this.sortCondition, this.isChecked).subscribe(
            (response) => {
                this.isLoading = false;
                this.totalCount = Number(response.headers.get('X-Total-Count'));
                const data = response.body;
                this.apiData.next(data);
            },
            (error) => {
                this.isLoading = false;
                this.displayError(this.commonService.getErrorDetail(error));
            }
        );
    }

    /*
     * ボタン出し分け処理
     */
    get canUpdate() {
        return this.situationCheckerResult.UpdateStatus;
    }

    get canCreateLiftingFromOrder() {
        return this.situationCheckerResult.CreateLiftingFromOrder;
    }

    get canCreateLifting() {
        return this.situationCheckerResult.CreateLifting;
    }


    private displayError(message: string) {
        this.errorMessage = message;
        this.errorProperty = '';
        this.changeDetectorRef.detectChanges();
    }

    private displaySuccess(message: string) {
        this.errorMessage = message;
        this.successProperty = '';
        this.changeDetectorRef.detectChanges();
    }

    private clearAlert() {
        this.successProperty = 'none';
        this.errorProperty = 'none';
        this.changeDetectorRef.detectChanges();
    }

    private toStatusString(status: number, deadFlag: boolean) {
        /*
         * 搬入希望日が揚重申請締切日を超えているかどうかの判定
         */
        // let commonService = new CommonService();
        // // 今日の日付を取得
        // let today = commonService.getDateTime();
        // let day = today.getDate();
        // // 締切日の日付を換算
        // let options = {
        //   dueDateType: user.site.liftingRequestDueDateType,
        //   dueDate: user.site.liftingRequestDueDate,
        //   dueWeek: user.site.liftingRequestDueWeek,
        //   dueDayOfWeek: user.site.liftingRequestDueDayOfWeek,
        // };
        // // n日前
        // if (options.dueDateType == 0) {
        //   day += options.dueDate;
        // } else {
        //   let dayofweek = today.getDay();
        //   // 今日の曜日と期限の曜日を比較
        //   if (options.dueDayOfWeek < dayofweek) {
        //     // もし期限の曜日を超えていたら、締め切りは+1週間後の日曜日
        //     day += 7 * (options.dueWeek + 1) - dayofweek;
        //   } else {
        //     // そうでなければ、単純にn週間後の日曜日
        //     day += (7 * options.dueWeek) - dayofweek;
        //   }
        // }
        // let deadline = commonService.getDateTime();
        // deadline.setDate(day);
        // // 希望日との前後比較をする
        // let requestDate = new Date(days);
        /*****/

        if (status === -1) {
            return '';
        } else if (status === 1 && deadFlag) {
            // 申請中かつ揚重申請締切日が搬入希望日を超えている場合は
            // 申請中（締め切り済み）と表示する
            return '申請中（締切済み）';
        } else {
            return this.cargoStatus[status];
        }
    }

    /*
    * Function: dateTimeConversion
    * 納入希望日時を現場作業時間に合わせて変換する
    * @param {String} date 納入希望日時
    * @return {any} [day 確定日, setTime 確定時間]
    */
    private dateTimeConversion(date: string) {
        const day = new Date(date);

        const startTime = this.userData.site.startLiftingHour;
        const split = startTime.split(':');
        const hour = Number(split[0]);
        const minuit = Number(split[1]);
        let setTime;

        if (day.getHours() < hour) {
            // 日付を一日前にして時間を24hプラスする
            day.setDate(day.getDate() - 1);
            setTime = day.getHours() + 24 + ':' + ('0' + day.getMinutes()).slice(-2);
        } else {
            // 24時間以内の指定であれば、そのまま表示
            setTime = day.getHours() + ':' + ('0' + day.getMinutes()).slice(-2);
        }

        const date2 = this.datepipe.transform(day, 'yyyy/MM/dd');
        // 日付と時間を返す
        return [date2, setTime];
    }


    // 更新ボタン動作
    updateCargo(statusChange: boolean) {
        // 更新用にデータを整形
        const list: any = [];
        const statusCheck = [];
        // for (const item of this.gridDataYouju.sourceCollection.items) {
        for (const item of this.gridDataYouju.items) {
            // for(var i=0;i<this.gridDataYouju.items.length;i++){
            // チェックされた項目のみ更新
            if (item.sel) {
                const data: any = {};
                data.id = item.id;
                data.note = item.note;
                data.confirmLift = item.confirmLift;

                // ステータスを更新する
                if (statusChange) {
                    data.applicationStatus = 2;
                } else {
                    data.applicationStatus = item.applicationStatus;
                }

                  // 日時を更新する
                if (item.confirmDate && item.confirmTime) {
                // if (item.confirmDate != null) {
                //   // 更新で時間入力がない場合は0:00を設定する
                //   if (!statusChange && item.confirmTime == null) {
                //     const split = this.userData.site.startLiftingHour.split(':');
                //     const hour = split[0];
                //     const minuit = split[1];
                //     item.confirmTime = hour + ':' + minuit;
                //   }
                  const date = this.datepipe.transform(new Date(item.confirmDate), 'yyyy/MM/dd');
                  console.log(date);

                  let time;
                  if (item.confirmTime instanceof Date) {
                      time = this.datepipe.transform(new Date(item.confirmTime), 'HH:mm');
                  } else {
                      time = item.confirmTime;
                  }

                  item.confirmDateTime = date + ' ' + time;
                  data.confirmDateTime = item.confirmDateTime;
                } else {
                  // 日付、確定日ののどちらかだけの場合はエラー
                  if (!item.confirmDate && !item.confirmTime) {

                  } else if (!item.confirmDate) {
                    statusCheck.push('日付');
                  } else if (!item.confirmTime) {
                    statusCheck.push('時間');
                  }
                }
                console.log(data);

                // 更新データをセット
                list.push(data);
                // ステータスをセット
                statusCheck.push(item.status);
                if (statusChange === true && item.wasClosed === false) {
                    statusCheck.push('締切前');
                }
            }
        }

        this.clearAlert();

        if (list.length === 0) {
            this.displayError('更新する揚重申請をチェックしてください。');
        } else if (statusCheck.indexOf('未申請') !== -1) {
            this.displayError('未申請の揚重申請が含まれています。');
        } else if (statusCheck.indexOf('締切前') !== -1) {
            this.displayError('締切前の揚重申請が含まれています。');
        } else if (statusCheck.indexOf('日付') !== -1) {
            this.displayError('日付が未入力です。');
        } else if (statusCheck.indexOf('時間') !== -1) {
            this.displayError('確定時間が未入力です。');
        } else {
            this.isLoading = true;
            this.cargoService.updateCargo(list).subscribe(
                () => {
                    this.isLoading = false;
                    this.displaySuccess('更新完了');
                    // 一覧更新
                    this.listDisplayReload();
                },
                (error) => {
                    this.isLoading = false;
                    this.displayError(this.commonService.getErrorDetail(error));
                }
            );
        }
    }

    newEdit(flag: boolean) {
        sessionStorage.removeItem('liftingData');
        if (flag) {
            // flag = trueならorderありで揚重申請作成
            this.router.navigate([`${this.userData.site.id}/lifting/new`], {queryParams: {order: 'true'}});
        } else {
            this.router.navigate([`${this.userData.site.id}/lifting/new`]);
        }
    }

    /**
     * 最大ページ数
     */
    private getMaxPage(): number {
      let max = Math.floor(this.totalCount / CargoService.CARGO_PAGE);
      if (max === 0) {
        return 1;
      } else {
        if (this.totalCount % CargoService.CARGO_PAGE > 0) {
          ++max;
        }
      }
      return max;
    }

    /*
     * ページング処理
     */
    nextPage() {
      if (this.getMaxPage() >= this.currentPage + 1) {
        ++this.currentPage;

        console.log('次のページへ');
        this.isLoading = true;
        this.cargoService.getAllLiftings( this.currentPage, this.searchKeys, this.sortCondition, this.isChecked).subscribe(
            (response) => {
                this.isLoading = false;
                this.totalCount = Number(response.headers.get('X-Total-Count'));
                const data = response.body;
                if (data.length > 0) {
                    this.apiData.next(data);
                }
            },
            (error) => {
                this.isLoading = false;
                this.displayError(this.commonService.getErrorDetail(error));
            }
        );
      }
    }

    prevPage() {
      if (this.currentPage > 1) {
        --this.currentPage;
        this.isLoading = true;
        this.cargoService.getAllLiftings(this.currentPage, this.searchKeys, this.sortCondition, this.isChecked).subscribe(
          (response) => {
              this.isLoading = false;
              this.totalCount = Number(response.headers.get('X-Total-Count'));
              const data = response.body;
              if (data.length > 0) {
                this.apiData.next(data);
              }
          },
          (error) => {
              this.isLoading = false;
              this.displayError(this.commonService.getErrorDetail(error));
          }
        );
      } else {
          return false;
      }
  }

    /**
     * フィルタアイコンを押されたとき、そのカラムのデータ型に応じた
     * フィルタUIに既存テンプレートを差し替える関数
     * @param filter フィルタオブジェクト
     * @param event イベント引数
     */
    editTemplateFilter(filter, event) {
        console.log('changed');
        this.uiFilter.setupSearchFilter(filter, event, this.searchKeys);
    }

    /**
     * フィルタが適用/キャンセル/クリアされたときの挙動を示す関数
     * クリアとキャンセルを分けて処理できるよう判断する処理必要
     * こちらからソートするとソート時もこちらのイベントのみ発火する
     * @param filter フィルタオブジェクト
     * @param event イベント引数
     */
    applyCustomFilter(filter = null, event = null) {
        console.log(filter, event, 'FilterChanged');
        const colFilter = filter.getColumnFilter(event.col);

        // キャンセル時はソート条件が変わっているかを確認し、変わっていればデータを再取得する
        // const isClear = colFilter.column.binding in this.searchKeys && !colFilter.conditionFilter.condition1.value;
        const sortDesc = filter.grid.collectionView.sortDescriptions[0];
        const isAsc = sortDesc.ascending ? 'asc' : 'desc';
        const isSort = (sortDesc.property !== this.sortCondition.sortKey) || (isAsc !== this.sortCondition.sortOrder);

        // 適用ボタンかどうか
        if (!event.cancel) {
            const searchInputed = this.uiFilter.getInputedFilterValue(colFilter.column);
            // 入力値があるか
            if (searchInputed !== '' && searchInputed != null) {
                this.searchKeys[colFilter.column.binding] = searchInputed;
                // フィルタ適用時,フィルター色変更のため、Wijmo内フィルタに対してもフィルタをかける
                colFilter.conditionFilter.condition1.value = this.uiFilter.getFilterValSkeleton(colFilter.column);
                colFilter.conditionFilter.condition1.operator = this.uiFilter.getFilterOperatorSkeleton(colFilter.column);
                filter.apply();
            } else {
                // 適用が押されたけど入力値がない
                delete this.searchKeys[colFilter.column.binding];
                colFilter.clear();
            }
        } else {
            // ソートするか
            if (isSort) {
                this.sortCondition.sortKey = sortDesc.property;
                this.sortCondition.sortOrder = sortDesc.ascending ? 'asc' : 'desc';
            } else {
                // クリア時は該当カラムの検索条件を削除してデータ取得自体は実行
                delete this.searchKeys[colFilter.column.binding];
                colFilter.clear();
            }
        }

        console.log('SearchKey', this.searchKeys);
        this.isLoading = true;
        this.currentPage = 1;
        this.listDisplayReload();
    }

    /**
     * フィルターの検索条件を全クリア
     */
    clearFilterAll(grid) {
        for (let i = 0; i < grid.columns.length; i++) {
            const colFilter = this.youjuListFilter.getColumnFilter(i);
            colFilter.clear();
        }
        grid.refresh();
    }

    checkboxEdit() {
        if (!this.isChecked) {
            this.isChecked = true;
            // 「納入済みの揚重申請を表示する」にチェックが入っていたら
            // cargoのパラメーターにdelivered=trueを追加して一覧を取り直す
            this.listDisplayReload();
        } else {
            this.isChecked = false;
            this.listDisplayReload();
        }
    }

  exportGridToExcel() {
    const now = this.datepipe.transform(new Date(), 'yyyyMMddHHmm');
    wjGridXlsx.FlexGridXlsxConverter.saveAsync(
      this.gridYouju,
      {
        includeColumnHeaders: true,
        includeCellStyles: true,
        includeColumns(column) {
          return column.binding !== 'sel' && column.binding !== 'liftingId';
        }
      }, '揚重申請一覧' + now + '.xlsx');
  }
}

